@import 'assets/theme';

.title {
  padding: 24px 32px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #323c47;
  border-bottom: 1px solid #ebeff2;
}

.content {
  padding: 24px 32px;
  max-height: 600px;
  overflow-y: auto;

  .heading {
    margin-bottom: 24px;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;
  }

  .label {
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorTextualGray;
  }

  .selectLabel {
    @extend .label;
    margin-bottom: -10px;
  }

  .paymentType,
  .accountName {
    margin-bottom: 24px;
  }

  .subtitle {
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: $colorTextualBlack;
  }

  .description {
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: $colorTextualBlack;
  }

  .paymentMethodsContainer {
    margin: 32px 0;

    .noPaymentMethods {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;

      .text {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.1px;
        color: $black;
      }

      .button {
        flex: none;
        display: flex;
        align-items: center;
        width: max-content;
        border-radius: 4px;

        .icon {
          margin-right: 8px;
        }
      }
    }
  }

  .noteContainer {
    position: relative;

    .note {
      width: 100%;
      margin-top: 12px;
      padding: 16px;
      padding-bottom: 32px;
      resize: none;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434e0;
      border: 1px solid #c4c4c4;
      box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
    }

    .characterCount {
      position: absolute;
      left: 16px;
      bottom: 16px;
      font-size: 12px;
      color: #90a0b7;
    }
  }

  .cDetailContainer {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }

  .buttonRow {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    .saveButton {
      width: 30%;
    }
  }
}
