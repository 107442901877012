@import 'assets/theme';

.container {
  width: 360px;
  display: flex;
  flex-direction: column;

  .slotList {
    display: flex;
    flex: 1 1;
    align-items: center;
    flex-flow: wrap;
  }

  .timeSlot {
    width: 112px;
    height: 30px;
    background: #d6f3e3;
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $colorBlue3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .timeSlot:not(:nth-child(3n)) {
    margin-right: 12px;
  }

  .timeSlot:nth-child(n + 4) {
    margin-top: 10px;
  }
}

.controlLink {
  height: 16px;
  margin-top: 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: $colorBlue1;
  display: flex;
  user-select: none;
  cursor: pointer;
  white-space: break-spaces;

  .moreTimesNumber {
    font-weight: 700;
  }
}

.expandIcon {
  margin-left: 5px;
  font-size: 20px;
}
