@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(16px);

  .label {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #949aa2;
    margin-bottom: 8px;
  }

  .listWrapper {
    display: flex;
    align-items: center;

    .selectList {
      flex: 1;
      max-width: 280px;
    }

    .addBtn {
      display: flex;
      align-items: center;
      color: $colorBlue1;
      margin-left: 20px;
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;

      &.disabled {
        color: #c4c4c4;
        cursor: not-allowed;
      }

      .addIcon {
        margin-right: 4px;
      }
    }
  }

  .facilitatorCard {
    display: flex;
    align-items: center;
    @include columnGap(8px);

    .facilitatorAvatar {
      border-radius: 50%;
      width: 36px;
    }

    .facilitatorName {
      font-size: 16px;
      line-height: 24px;
      color: #414449;
    }
  }

  .noFacilitator {
    font-size: 13px;
    opacity: 0.5;
    font-style: italic;
  }
}
