@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  padding: $standard-padding * 2 0;
  justify-content: space-between;

  .leftSection {
    display: flex;
    align-items: flex-end;

    .titleWrapper {
      display: flex;
      flex-direction: column;
      padding-right: 24px;

      .title {
        font-weight: 600;
        font-size: 22px;
        letter-spacing: 0.01em;
        color: $colorTextualBlack;
      }
    }
  }

  .rightSection {
    display: flex;
    flex-direction: column;

    .addNewBtnWrapper {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      border: 1px solid $mhh-yellow;
      box-sizing: border-box;
      font-weight: 600;
      text-align: center;
      letter-spacing: 0.02em;
      color: $mhh-dark-blue;
      background: $mhh-yellow;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;

      .icon {
        padding-right: 6px;
      }
    }

    .menuWrapper {
      position: relative;
      z-index: 1;

      .menuBoxHide {
        position: absolute;
        background: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        visibility: hidden;
        opacity: 0;
        transform: translateY(0px);
        transition: all 0.3s;

        .listBox {
          padding: 16px;
          cursor: pointer;
          color: #707683;
          transition: all 0.2s ease;
          position: relative;

          &:not(:last-child) {
            border-bottom: 1px solid #e7e4e4;
          }

          &:hover {
            color: $mhh-dark-blue;
          }

          .listTitle {
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            padding-bottom: 4px;
          }

          .listDesc {
            font-size: 10px;
            line-height: 12px;
          }

          .comingSoon {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #{$white}80;
            z-index: 1;
            cursor: default;
          }

          .tooltip {
            max-width: 200px;
            border-radius: 8px;
            line-height: 16px;
            font-size: 14px;
          }
        }
      }

      .menuBoxShow {
        @extend .menuBoxHide;
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .sendAdhocBtn {
    height: 46px;
    // box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
    border-radius: 4px;
    background-color: $white;
    color: $colorBlue3;
    border: 1px solid $mhh-yellow;

    .sendAdhocBtnIcon {
      height: 20px;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
