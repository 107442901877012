.container {
  table {
    th,
    td {
      font-weight: 600;
      font-size: 13px;
    }

    tbody > tr {
      border-bottom: 1px solid #e8ebef;
    }

    .nameColumn {
      width: 25%;
      overflow: hidden;
    }

    .classColumn {
      width: 15%;
      overflow: hidden;
    }

    .assessmentColumn {
      width: 25%;
      overflow: hidden;
    }

    .dateColumn {
      width: 15%;
      overflow: hidden;
    }

    .submitColumn {
      width: 20%;
      overflow: hidden;
    }

    .submitContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .checkContainer {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;

      .icon {
        color: #25be69;
        font-size: 30px;
      }

      .dateText {
        color: #8d9bae;
      }
    }
  }
}
