@import 'assets/theme';

.noPsychometricFoundContainer {
  display: flex;
  padding: 24px 16px;
  border-bottom: 0.5px solid #ecedef;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;

  .header {
    background: #f9f9ff;
    padding: 16px 0;
    border-bottom: solid 1px #e2e8f0;
    border-top: solid 1px #e2e8f0;
  }

  .loadingWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 0 !important;
    @include rowGap(8px);

    .loading {
      width: 100%;
      border-radius: 4px;
      padding: 0 8px;
      height: 30px;
    }
  }

  .listItem {
    padding: 10px 0;
    border-bottom: 0.5px solid #ecedef;
    align-items: center;

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      word-break: normal;
      overflow-wrap: anywhere;
    }

    .isAssessmentSent,
    .isAssessmentComplete {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }

    .isSentText,
    .isCompleteText {
      font-size: 12px;
      line-height: 21px;
    }

    .scoreText {
      @extend .isSentText;
      display: flex;
      gap: 10px;

      .scoreKey {
        text-transform: capitalize;
      }
    }

    .noValue {
      display: flex;
      justify-content: center;
    }
  }
}
