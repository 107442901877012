@import 'assets/theme';

.modal {
  .header {
    font-weight: 600;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .headerTitle {
      display: block;
      font-weight: 400;
      font-size: 16px;

      .studentTitle {
        font-weight: 600;
        font-size: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .buttonChange {
          font-size: 14px;

          .icon {
            font-size: 16px;
            margin-right: 2px;
          }
        }
      }
    }

    .headerRightButtons {
      display: flex;
      align-items: center;
    }

    .backToAssessmentList {
      color: $mhh-dark-blue;
      font-weight: 600;
      font-size: 14px;
      padding: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      i {
        font-size: 13px;
        margin-right: 12px;
      }
    }
  }
}

.confirmContent {
  font-weight: 600;
  font-size: 18px;
  color: #202225;

  .highLight {
    color: $mhh-yellow;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 12px;
  }
}

.body {
  .expandItem {
    background-color: $white;
    border: solid 1px #e8ebef;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;

    .title {
      padding: 20px;
      font-weight: 600;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      i {
        margin-top: 2px;
      }
    }
    .content {
      padding: 0 20px 10px;
    }

    &.expanded {
      border: solid 1px $mhh-yellow;
    }
  }
}

.body,
.selectStudent,
.confirm {
  display: none;

  &.visible {
    display: block;
  }
}
