@import 'assets/theme';

.container {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 24px 16px;
  transition: all 0.5s ease;
  position: relative;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .groupName {
    display: flex;
    align-items: center;
    color: #414449;
    opacity: 0.75;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
  }

  .groupSince {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    color: $colorBlue3;
    flex-direction: column;
  }

  .groupClosed {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: $colorBlue3;
    align-items: center;
  }

  .groupLead {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: $colorBlue3;
  }

  &:hover {
    background: #fbfbfb;
  }

  .icon {
    border: 2px solid #6575b1;
    color: #6575b1;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
  }
}
