@import 'assets/theme';

.modalContainer {
  min-width: 600px;

  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #323c47;
  }
}

.header {
  padding: 0px 20px 20px;
}
.columnWrapper {
  display: flex;

  .columnStyles {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #414449;
  }

  .groupNameCol {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 20%;
    padding-right: 8px;
    flex: 1 5;
  }

  .groupLeadCol {
    @extend .columnStyles;
    min-width: 150px;
    max-width: 15%;
    padding-right: 8px;
    flex: 1 5;
  }
  .groupDesCol {
    @extend .columnStyles;
    min-width: 150px;
    max-width: 70%;
    padding-right: 8px;
    flex: 3 5;
    overflow: hidden;
  }
}
.title {
  padding: 24px 32px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #323c47;
  border-bottom: 1px solid #ebeff2;
}

.groupList {
  @include rowGap(20px);
  .grayOutLabel {
    text-align: center;
    color: $colorTextualGray;
  }
  .listItem {
    height: 80px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 24px;
    align-items: center;
    color: #414449;
    cursor: pointer;
    &.active {
      border: 1px solid $colorBlue1;
    }
    .groupName {
      font-size: 16px;
      font-weight: bold;
    }

    .groupLead {
      font-weight: 600;
      font-size: 14px;
    }

    .groupDes {
      font-weight: 600;
      font-size: 14px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .icon {
      cursor: pointer;
    }
  }
}
