@import 'assets/theme';

.container {
  min-width: 720px;
  background: #fff;
  box-shadow: 0 2px 16px #0000001a;
  border-radius: 8px;

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 320px;
    background-color: $white;
    border-radius: 8px;
  }

  .header {
    padding: 16px 24px;
    border-bottom: 1px solid #ebeff2;

    .title {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 28px;
      color: $colorTextualBlack;
    }

    .description {
      font-size: 13px;
      letter-spacing: 0.1px;
      line-height: 20px;
      color: #323c4780;
    }
  }

  .addButton {
    display: flex;
    align-items: center;
    margin: 16px 0 0 16px;
    border-radius: 4px;

    .icon {
      margin-right: 4px;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 32px;

    .saveButton {
      display: flex;
      justify-content: center;
      box-shadow: none;
    }
  }
}
