@import 'assets/theme';

.container {
  display: flex;
  min-height: 50px;
  align-items: center;

  .titleContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding-right: 16px;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      color: #323c47;
      margin-bottom: 4px;
      .selector {
        flex: 1 auto;
        margin-left: 16px;
      }
    }

    .desc {
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #343434;
    }
  }

  .backBtnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 15%;

    .backBtn {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $colorBlue1;
      cursor: pointer;

      .icon {
        font-size: 20px;
      }
    }
  }

  .progressContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 15%;

    .label {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: $mhh-dark-blue;
    }

    .barContainer {
      max-width: 90px;
    }
  }

  .showContent {
    opacity: 1;
    transition: all 0.5s ease;
  }

  .hideContent {
    @extend .showContent;
    opacity: 0;
    height: 0;
    transition: all 0.5s ease;
    overflow: hidden;
  }
}

:export {
  blueColor: $mhh-yellow;
}
