@import 'assets/theme';

.container {
  display: flex;
  @include columnGap(16px);

  .toggleWrapper {
    display: flex;
    align-items: center;
    border-radius: 50%;
    padding: 8px;
    user-select: none;
    cursor: pointer;

    .icon {
      color: #61666e;
    }
  }

  .toggleWrapperActive {
    @extend .toggleWrapper;
    background: $colorBlue1;

    .icon {
      color: #fff;
    }
  }
}
