@import 'assets/theme';

.filterContainer {
  display: flex;
  padding: 0;
  margin-bottom: 16px;
  cursor: auto;
}

.paginationWrapper {
  padding-top: 16px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: 400px;
}
