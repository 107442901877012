@import 'assets/theme';

.columnWrapper {
  display: flex;

  .columnStyles {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #414449;
    flex-grow: 1;
    flex-shrink: 7;
  }

  .avatar {
    @extend .columnStyles;
    justify-content: center;
    min-width: 120px;
    max-width: 15%;
    padding-right: 8px;
  }

  .fullName {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 20%;
    padding-right: 8px;
  }

  .formSent {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 15%;
    padding-right: 8px;
  }

  .formSigned {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 15%;
    padding-right: 8px;
  }

  .dateSigned {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 25%;
    padding-right: 8px;
  }

  .remind {
    @extend .columnStyles;
    min-width: 60px;
    max-width: 5%;
    padding-right: 8px;
  }

  .view {
    @extend .columnStyles;
    min-width: 60px;
    max-width: 5%;
    padding-right: 8px;
    justify-content: flex-end;
  }

  .active {
    font-weight: 600;
    color: $colorBlue1;
  }
}
