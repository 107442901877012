@import 'assets/theme/index';

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;

  .modalContent {
    position: fixed;
    max-width: 600px;
    height: auto;
    top: 40%;
    left: 40%;
    display: flex;
    align-items: center;
    transform: translate(-50%, -50%);
    animation: show_modal 0.6s forwards;

    &.hide {
      animation: hide_modal 0.6s forwards;
    }

    @keyframes show_modal {
      0% {
        transform: scale(0);
      }
      60% {
        transform: scale(1.15);
      }
      80% {
        transform: scale(0.95);
      }
      100% {
        transform: scale(1);
      }
    }

    @keyframes hide_modal {
      0% {
        transform: scale(1);
      }
      20% {
        transform: scale(1.3);
      }
      100% {
        transform: scale(0);
      }
    }
  }
}
