.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;

  .content {
    display: flex;
    flex-direction: column;
    padding: 16px;

    .editorWrapper {
      padding-top: 10px;
    }
  }

  .error {
    color: #ff1900;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 8px;
  }
}
