@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 11px;
    font-weight: 500;
    color: $colorTextualGray;
    padding-bottom: 4px;
  }

  .filters {
    display: flex;
    @include columnGap($standard-padding * 3);

    & > div {
      min-width: 200px;
    }
  }
}
