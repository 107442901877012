@import 'assets/theme';

.container {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 60%;
  margin-top: 40px;
  margin-right: 4px;

  .title {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #5666a6;
  }

  .paymentMethodContainer {
    flex: 1 0 48%;
    max-width: 48%;
    margin-top: 8px;

    .label {
      font-size: 14px;
      font-weight: bold;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #343434;
    }

    .description {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #343434;

      .value {
        font-weight: 600;
        color: $colorBlue1;
      }
    }
  }
}
