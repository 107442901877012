@import 'assets/theme';

.header {
  padding: 20px 32px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #323c47;
  border-bottom: 1px solid #ebeff2;

  display: flex;
  justify-content: space-between;
  align-items: center;
  @include columnGap(44px);

  .title {
    flex: 1;

    .highlighted {
      color: $colorBlue1;
    }
  }

  .backButton {
    border: none;
    background-color: transparent;

    font-size: 12px;
    font-weight: 600;
    color: $colorBlue1;

    display: flex;
    align-items: center;
  }

  .processContainer {
    display: flex;
    flex-direction: column;

    .processText {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: $colorBlue1;
    }

    .processBarContainer {
      display: flex;

      .processBar {
        flex: 1;
        border-bottom: 4px solid #ededed;

        &.active {
          border-bottom-color: $colorBlue1;
        }
      }
    }
  }

  .closeButton {
    font-size: 18px;
    color: #c2cfe0;

    border: none;
    background-color: transparent;

    display: flex;
    align-items: center;
  }
}
