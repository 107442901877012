@import 'assets/theme';

.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: $mhh-yellow;
  margin-bottom: 16px;
}

.heading {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: $colorTextualBlack;
}

.disabledEmailLabel {
  font-size: 11px;
  font-weight: 600;
  color: $colorTextualGray;
}

.disabledEmailValue {
  display: flex;
  align-items: center;
  height: 38px;
  width: 100%;
  font-size: 13px;
  line-height: 18px;
  color: #343434;
  background-color: #e3e3e3;
  border: none;
  border-bottom: 1px solid $colorLineGray;
  border-radius: 0;
  box-sizing: border-box;
  cursor: not-allowed;
}
