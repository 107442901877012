@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
}

.loadingWrapper {
  display: flex;

  .sideBarLoading {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    background-color: #fff;
    padding: 16px;
  }

  .contentLoading {
    margin-left: 24px;
    width: calc(100% - 324px);
    background-color: #fff;
    padding: 16px;
  }
}

.createActionPlanBtn {
  position: fixed;
  bottom: 40px;
  right: 38px;
  background: $mhh-yellow;
  box-shadow: 0 4px 10px rgba(52, 52, 52, 0.35);
  border-radius: 55px !important;
  border-color: $mhh-yellow;
  height: 55px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: $mhh-dark-blue;
  min-height: 48px;
  min-width: 175px;

  &:hover {
    background: $mhh-yellow;
    border-color: $mhh-yellow;
    color: $mhh-dark-blue;
  }

  @media (max-height: 740px) {
    bottom: 10px;
    right: 155px;
  }

  .addIcon {
    margin-right: 4px;
  }
}
