@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  flex: 1 2 40%;

  .subTitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #949aa2;
    padding-bottom: 4px;
  }

  .contactWrapper {
    display: flex;
    flex-direction: column;
    @include rowGap(4px);

    .name {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
    }

    .contact {
      display: flex;
      font-size: 14px;
      line-height: 21px;

      .value {
        font-weight: 600;
      }
    }
  }

  .leadProfileWrapper {
    display: flex;
    flex-direction: column;

    .leadAvatarWrapper {
      display: flex;
      margin-bottom: 4px;

      .leadAvatarImg {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        border: 1px solid #c7c9cd;
      }
    }
  }

  .practitionerWrapper {
    margin-top: 28px;

    .practitionerInfo {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      .practitionerAvatarWrapper {
        display: flex;
        margin-right: 12px;

        .practitionerAvatarImg {
          border-radius: 50%;
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
