@import 'assets/theme';

.label {
  font-weight: 600;
  font-size: 10px;
  border-radius: 20px;
  min-width: 90px;
  width: fit-content;
  padding: 0 4px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $mhh-dark-blue;
  color: $white;

  &.very-low {
    background-color: $mhh-tiffany-blue;
    color: $white;
  }

  &.very-high {
    background-color: $mhh-orange;
    color: $white;
  }

  &.low {
    background-color: $mhh-purple;
    color: $white;
  }

  &.high {
    background-color: $mhh-yellow;
    color: $mhh-dark-blue;
  }

  &.slightly-raised &.slighty-raised {
    background-color: $mhh-dark-blue;
    color: $white;
  }

  &.slightly-lowered {
    background-color: $mhh-noActive-yellow;
    color: $mhh-dark-blue;
  }

  &.close-to-average {
    background-color: $mhh-green;
    color: $mhh-dark-blue;
  }
}
