@import 'assets/theme';

.filterContainer {
  display: flex;
  padding: 0 16px;
  margin-bottom: 16px;
  cursor: auto;

  .profileLabel {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: $mhh-yellow;
  }
}

.paginationWrapper {
  padding-top: 16px;
}

.loading {
  display: flex;
  justify-content: center;
}
