.container {
  display: flex;
  align-items: center;

  .label {
    color: #949aa2;
    font-weight: 600;
    font-size: 12px;
  }
}
