@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: $standard-padding * 4 $standard-padding * 2;
  @include rowGap($standard-padding * 2);

  .label {
    font-weight: 600;
    font-size: 16px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @include columnGap($standard-padding * 2);

    .button {
      color: $colorTextualBlack;
      box-shadow: none;
      max-height: 40px;
    }

    .cancelButton {
      @extend .button;
      background-color: white;
      border: 1px $mhh-yellow solid;
    }

    .nextButton {
      @extend .button;
      background-color: $mhh-yellow;
    }
  }
}
