@import 'assets/theme';

.title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 0.1px;
  color: $colorTextualBlack;
  padding-bottom: 16px;
}

.cPreference {
  margin: 4px 0 8px 32px;
  max-width: 200px;
  transition: all 0.8s ease;
  max-height: 500px;

  .capability {
    padding-bottom: 16px;
  }
}

.cPreferenceHidden {
  @extend .cPreference;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}
