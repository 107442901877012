@import 'assets/theme';

.loadingContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .loadingBox {
    display: flex;
    align-items: center;
    min-height: 30px;
    padding: 16px 24px;
    border-bottom: 1px solid #ecedef;
    box-sizing: border-box;
    border-radius: 4px;

    .loadingName {
      width: 90%;
    }
  }
}

.container {
  padding: 12px 16px;
  display: flex;

  .groupInformation {
    color: #414449;

    .groupName {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .activeMembers {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .selected {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding-left: 32px;
    gap: 10px;

    .selectedPill {
      padding: 0px 8px;
      background-color: $colorBlue3;
      color: #fff;
      border-radius: 15px;
      height: 23px;
      line-height: 23px;
    }
  }
}

.groupWrapper {
  padding: 12px 16px;
  display: flex;

  .groupInformation {
    color: #414449;

    .groupName {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .activeMembers {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
