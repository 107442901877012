@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 700px;
  margin-bottom: 36px;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #323c47;
    letter-spacing: 0.1px;
  }

  .referralInfo {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    .referralSourceWrapper {
      display: flex;
      flex-direction: column;
      padding-top: 16px;

      .label {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: $colorTextualGray;
      }

      .sourceValue {
        font-weight: 600;
        line-height: 28px;
        display: flex;
        align-items: center;
      }
    }
  }

  .referralContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 12px 0 16px;
    @include rowGap(8px);

    .refDocRecord {
      display: flex;
      color: $black;
      letter-spacing: 0.1px;
      font-size: 13px;
      align-items: center;

      .refDocName {
        width: 30%;
        font-weight: 600;
        line-height: 28px;
        display: flex;
        align-items: center;

        .listIcon {
          background-color: $colorBlue1;
          color: #fff;
          border-radius: 50%;
          font-size: 16px;
          font-weight: bold;
          padding: 4px;
          margin-right: 8px;
        }
      }

      .refDocAddedDetails {
        width: 40%;

        span {
          font-weight: 600;
        }
      }

      .viewAction {
        width: 15%;

        button {
          user-select: none;
          display: flex;
          line-height: 24px;
          border: none;
          color: $colorBlue1;
          background-color: #fff;
        }

        i {
          color: #0085bd;
        }
      }

      .deleteAction {
        user-select: none;
        width: 15%;
        color: #fb1a1a;

        button {
          font-weight: 600;
          border: none;
          background-color: #fff;
        }
      }
    }
  }

  .addRefAttachment {
    .button {
      border: none;
      border: none;
      display: flex;
      width: 200px;
      justify-content: space-between;
      font-weight: 600;
      font-size: 15px;
      padding: 0;
      line-height: 30px;

      &:active {
        background-color: transparent;
      }

      i {
        font-size: 30px;
      }
    }
  }
}
