@import 'assets/theme';

.container {
  min-height: 150px;
  margin: -4px;
  padding: 4px;
  border-radius: 4px;
  position: relative;

  .inactive {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #f8f9fd;
    opacity: 0.7;
  }

  .tooltip {
    max-width: 200px;
    border-radius: 8px;
    line-height: 16px;
    font-size: 14px;
  }

  .clientTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: $colorTextualBlack;
    padding-bottom: 4px;
  }

  .clientDesc {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    line-height: 20px;
    color: $colorTextualGray;
    margin-bottom: 8px;

    .addClientBtn {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: $colorBlue1;
      cursor: pointer;
    }
  }

  .clientCard {
    display: flex;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 16px 16px 16px 20%;

    &:not(:last-child) {
      border-bottom: 1px solid #ebeff2;
    }

    &:hover {
      background: #fbfbfb;
    }

    .name {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #323c47;
    }
  }
}
