@import 'assets/theme';

.container {
  display: flex;

  .card {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 350px;
    padding: 16px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-height: 350px;

    .cardTitle {
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #323c47;
      padding-bottom: 16px;
    }

    .clientListWrapper {
      max-height: 300px;
      overflow: auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $grey-light;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 2px;
        background-color: $grey-light;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $mid-grey-dark;
      }
    }

    .clientListCard {
      display: flex;
      align-items: center;
      padding: 8px 16px;

      .avatarWrapper {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        border-radius: 50%;

        .initialName {
          font-size: 10px;
        }
      }

      .name {
        font-size: 16px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0.1px;
        color: #323c47;
      }

      .addBtn {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $colorBlue1;
        padding-left: 8px;
        cursor: pointer;
      }

      .removeBtn {
        @extend .addBtn;
        color: #ff2500;
      }
    }
  }

  .clientListContainer {
    @extend .card;
    margin-right: 40px;
  }

  .clientCartContainer {
    @extend .card;
  }
}
