@import 'assets/theme';

.titleContainer {
  display: flex;
  align-items: center;
  @include columnGap(8px);
}

.title {
  display: flex;
  @include columnGap($standard-padding * 4);

  font-size: 18px;
  font-weight: 700;

  .button {
    font-size: 12px;
    font-weight: 600;
  }
}

.helpIcon {
  margin: 0;
  color: #fd8468;
}

.subtitle {
  font-size: 13px;
  font-weight: 400;
}
