@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(20px);
  color: #343434;

  .status {
    font-weight: 600;
  }

  .reviewTypeContainer {
    .reviewQuestion {
      font-size: 16px;
      font-weight: 600;
    }

    .radioButton {
      padding-left: 8px;
    }

    .labelClass {
      flex-direction: column;
      color: #202225;
      font-size: 16px;
      font-weight: 600;
      @include rowGap(4px);
    }

    .subLabelClass {
      margin: 0px;
      color: #202225;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .feedbackCommentContainer {
    display: flex;
    flex-direction: column;
    @include rowGap(4px);

    .addComment {
      color: #61666e;
    }

    .textarea {
      width: 100%;
      border: none;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434;
      resize: none;
      border-radius: 4px;
      padding: 8px;
      border: 1px solid #dadbde;
    }
  }

  .button {
    box-shadow: none;
    font-size: 16px;
  }
}
