@import 'assets/theme';

.container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 16px;
  padding-bottom: 0;

  .fieldContainer {
    flex: 1 0 33%;
    display: flex;
    align-items: flex-start;
    max-width: 33%;
    padding: 16px 1.5%;
    user-select: none;

    &.disabled {
      .checkboxContainer {
        color: #828282 !important;
        cursor: default;

        .checkbox {
          background-color: #828282 !important;
          border-color: #828282 !important;
        }
      }

      .text {
        .label {
          color: #343434 !important;
        }

        .description {
          color: #343434 !important;
        }
      }

      .button {
        &.active {
          .starIcon {
            color: #828282 !important;
          }
        }
      }
    }

    .checkboxContainer {
      display: flex;
      cursor: pointer;

      .checkbox {
        appearance: none;
        margin: 0;
        height: 20px;
        width: 20px;
        min-width: 20px;
        background-color: transparent;
        border: 2px solid #d5d5d5;
        border-radius: 4px;
        color: #d5d5d5;
        cursor: pointer;
        outline: none;
        transition: all 0.1s linear;

        &:after {
          content: '\2713';
          display: none;
          justify-content: center;
          align-items: center;
          width: 16px;
        }

        &:checked {
          font-size: 12px;
          line-height: 16px;
          font-weight: 800;
          color: #fff;
          background-color: $mhh-yellow;
          border: 2px solid $mhh-yellow;

          &:after {
            display: flex;
          }
        }
      }

      .text {
        margin-left: 8px;

        &.active {
          .label {
            color: $mhh-yellow;
          }
        }

        .label {
          font-size: 15px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #343434;
          cursor: pointer;
        }

        .description {
          font-size: 11px;
          line-height: 23px;
          letter-spacing: 0.1px;
          color: $mhh-dark-blue;

          cursor: pointer;
        }
      }
    }

    .button {
      display: flex;
      align-items: center;
      height: 20px;
      margin-left: 8px;
      padding: 0;
      background-color: transparent;

      &.active {
        .starIcon {
          color: $mhh-yellow;
        }
      }
    }

    .starIcon {
      font-size: 15px;
      line-height: 20px;
      color: $mhh-light-yellow;
      transition: color 0.1s;
    }

    .editIcon {
      font-size: 15px;
      line-height: 20px;

      &:hover {
        color: $mhh-yellow;
      }
    }
  }

  .createFieldWrapper {
    display: flex;
    align-items: center;
    width: 30%;
    margin: 16px 1.5%;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $mhh-dark-blue;
    border: none;

    .createFieldBtn {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .icon {
      font-size: 22px;
      margin-right: 12px;
    }
  }
}
