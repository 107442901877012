@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .header {
    .headerTitle {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 23px;
    }

    .headerFilter {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;

      .headerFilterDropdown {
        font-size: 12px;
        font-weight: bold;
        color: $mhh-yellow;
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    margin-top: 6px;
    background: #fff;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 60px;
    padding: 16px;

    .monthGroupContainer {
      max-height: 500px;
      width: 100%;
      padding-right: 8px;
      overflow: auto;

      .activityFeedLoading {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

:global {
  .ant-dropdown-menu-item-selected {
    background-color: $mhh-noActive-yellow;
    color: $mhh-dark-blue;
  }

  .ant-dropdown-menu-item:hover {
    background: $mhh-light-yellow;
  }
}
