@import 'assets/theme';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  border-radius: 4px;
  padding: 4px;
  max-width: 108px;
  min-width: 108px;
}

.activeBadge {
  @extend .container;
  background: #63c364;
  color: #f2f2f2;
}

.closedBadge {
  @extend .container;
  background: #f9b5a5;
  color: #f2f2f2;
}
