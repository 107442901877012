@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: $standard-padding * 3;
  background-color: #fff;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  .headerTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 16px;

    .leftWrapper {
      display: flex;
      flex: 1 2 40%;
      align-items: center;
    }

    .rightWrapper {
      display: flex;
      padding-left: 32px;
      align-items: center;
      flex: 1 2 60%;
    }
  }

  .content {
    display: flex;

    .leftWrapper {
      padding-right: 32px;
      border-right: 2px solid #ebeff2;
      flex: 1 2 40%;
      .diffNameBox {
        .checkBox {
          margin-bottom: 16px;
        }
      }
      .titleBox {
        display: flex;
        padding-bottom: 16px;

        .toggleSwitchContainer {
          border-bottom: 1px solid #c4c4c4;
        }
      }
      .titleInput {
        margin-top: 20px;
        font-weight: 600;
        font-size: 20px;
      }

      .descBox {
        margin-top: 8px;
        padding-bottom: 40px;

        .descInput {
          margin-top: 20px;
        }
      }

      .tagBox {
        margin-top: 8px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        flex-wrap: wrap;
        .tagInput {
          margin-right: 16px;
          margin-bottom: 16px;
          width: 145px;
          .titleInput {
            font-size: 13px;
          }
        }
        .tagItem {
          display: flex;
          align-items: center;
          margin-right: 8px;
          margin-bottom: 16px;
          background: $colorBlue1;
          width: fit-content;
          height: fit-content;
          padding: 4px 8px;
          border-radius: 4px;
          color: $white;
          font-size: 13px;
          .icon {
            font-size: 13px;
            margin-left: 8px;
            cursor: pointer;
          }
        }
        .addTagButton {
          display: flex;
          align-items: center;
          margin-top: 8px;
          padding: 8px;
          width: fit-content;
          font-size: 13px;
          color: $mhh-dark-blue;
          border: dashed $mhh-yellow 1.5px;
          cursor: pointer;
          border-radius: 8px;

          .icon {
            font-size: 18px;
            margin-right: 4px;
          }
        }
      }

      .noteBox {
        max-width: 420px;
        .noteTitle {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
          color: $colorTextualBlack;
          padding-bottom: 16px;
        }

        .selectedBadge {
          display: flex;
          margin-bottom: 16px;
        }

        .clinicalListWrapper {
          max-height: 450px;
          overflow: auto;

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: $grey-light;
          }

          &::-webkit-scrollbar {
            width: 6px;
            background-color: $grey-light;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $mid-grey-dark;
          }
        }
      }
    }

    .rightWrapper {
      padding-left: 32px;
      flex: 1 2 60%;

      .buttonWrapper {
        display: flex;
        justify-content: space-between;
        padding-top: 16px;

        .saveBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 16px;
          min-width: 150px;
          border: 1px solid $colorBlue1;
          box-sizing: border-box;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          text-align: center;
          color: $white;
          background: $colorBlue1;
          box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
          border-radius: 4px;
          cursor: pointer;

          .icon {
            padding-right: 6px;
          }
        }

        .saveBtnFixed {
          position: fixed;
          bottom: 40px;
          right: 38px;
          border-radius: 55px !important;
          height: 55px;

          @media only screen and (max-height: 740px) {
            bottom: 11px;
            right: 150px;
          }
        }

        .saveBtnDisable {
          @extend .saveBtn;
          opacity: 0.7;
          cursor: not-allowed;
        }

        .addQBtn {
          @extend .saveBtn;
          border: 1px solid $mhh-yellow;
          color: $mhh-dark-blue;
          background: #fff;
          box-shadow: none;

          .icon {
            padding-right: 6px;
          }
        }

        .addQBtnDisable {
          @extend .addQBtn;
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
}
