@import 'assets/theme';

.container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  width: 100%;
  @media (min-width: $medium-screen-min-width) {
    width: unset;
    border-radius: 0px 0px 0px 10px;
  }

  background: $white;
  box-shadow: 0px 4.53388px 4.53388px rgba(0, 0, 0, 0.25);
  padding: $standard-padding $standard-padding * 2;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .profilesContainer {
    display: flex;
    @include columnGap($standard-padding);

    img {
      border-radius: 50%;
      max-height: 70px;
      max-width: 70px;
    }

    .namesContainer {
      display: none;

      @media (min-width: $medium-screen-min-width) {
        display: flex;
      }
      flex-direction: column;
      justify-content: center;
      align-items: center;

      margin-right: $standard-padding * 5;

      .name {
        font-size: 12px;
      }

      .plus {
        font-size: 8px;
      }
    }
  }

  .exitContainer {
    border-radius: 50%;
    border: 1px solid $colorBlue3;
    background: transparent;
    width: 70px;
    height: 70px;

    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: $colorBlue3;
    }
  }
}
