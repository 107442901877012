@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  margin-top: 40px;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);

  .saveRadioContainer {
    padding: 8px 16px;

    .label {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $colorTextualBlack;
    }
  }

  .saveNShareContainer {
    padding: 16px;

    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: $colorTextualBlack;
    }
  }

  .button {
    align-self: center;
    margin: 8px 0 16px;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
  }
}
