@import 'assets/theme';

.profileWrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  @include columnGap(16px);

  .profile {
    width: 40px;
    height: 40px;

    .initialAvatar {
      font-size: 18px;
    }
  }
  .description {
    .note {
      display: flex;
      color: $colorTextualGray;
      font-size: 13px;

      .proxyProfilesDropdown {
        padding: 0px 8px;
      }

      .headerFilterDropdown {
        color: $colorBlue1;
      }
    }
  }
}

.disableProfileWrapper {
  @extend .profileWrapper;

  background: #edf0f4;
  border-radius: 4px;
  cursor: not-allowed;
  user-select: none;

  .initialAvatar,
  .profile {
    opacity: 0.5;
  }
}

.submitBtn {
  display: flex;
  justify-content: flex-end;
}

.disabledClient {
  cursor: not-allowed;
}
