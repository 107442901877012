@import 'assets/theme';

:global {
  .tacklit-text-editor {
    .ql-toolbar {
      border: none;
    }

    .ql-editor {
      min-height: 90px;
    }

    .ql-container {
      border: none;
    }
  }
}

.container {
  background: $white;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

:export {
  blackColor: $black;
}
