@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(16px);
  padding: 16px $standard-padding * 4;

  .formContainer {
    display: flex;
    flex-direction: column;
    @include rowGap(16px);
    padding-top: 8px;
  }
}

.label {
  font-size: 11px;
  font-weight: 500;
  color: $colorTextualGray;
  padding-bottom: 6px;
}

.eventTitleContainer {
  width: 50%;
}

.footer {
  display: flex;
  justify-content: flex-end;

  .saveButtonContainer {
    min-width: 250px;
  }
}
