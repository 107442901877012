@import 'assets/theme';

.title {
  font-weight: 600;
  font-size: 18px;
}

.label {
  font-weight: 600;
  font-size: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: $standard-padding * 2 $standard-padding * 2;
  @include rowGap($standard-padding);

  .icon {
    font-size: 24px;
    margin-right: $standard-padding;
  }

  .info {
    display: flex;
    flex-direction: row;
    background-color: $mhh-lightest-yellow;
    transition: all 0.3s ease;
    max-height: 0px;
    padding: 0px $standard-padding * 2;
    overflow: hidden;

    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .showShortInfo {
    padding: $standard-padding * 2;
    max-height: 80px;
  }
  .showInfo {
    padding: $standard-padding * 2;
    max-height: 100px;
  }

  .selectionContainer {
    display: flex;
    flex-direction: column;
    @include rowGap($standard-padding);

    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-radius: 4px;
      border: solid 1px $mhh-dark-blue-10;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
      padding: $standard-padding * 3 $standard-padding * 2;
      cursor: pointer;

      .checkIcon {
        @extend .icon;
        visibility: hidden;
        color: $mhh-yellow;
      }
      &.selected {
        border: solid 1px $mhh-yellow;
        .checkIcon {
          visibility: visible;
        }
      }
    }
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    margin-top: 4px;

    .checkboxDescription {
      font-size: 16px;
      font-weight: 600;
    }
    .selectedCheckboxDescription {
      @extend .checkboxDescription;
      color: $mhh-yellow;
    }
    .disabledCheckbox {
      background-color: none !important;
    }
  }

  .errorMessage {
    font-size: 12px;
    color: #ff4d4f;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @include columnGap($standard-padding * 2);

    .button {
      color: $colorTextualBlack;
      box-shadow: none;
      max-height: 40px;
    }

    .cancelButton {
      @extend .button;
      background-color: white;
      border: 1px $mhh-yellow solid;
    }

    .nextButton {
      @extend .button;
      background-color: $mhh-yellow;
    }
  }
}
