@import 'assets/theme';

.modalContainer {
  min-width: 800px;

  .title {
    font-weight: 600;
    font-size: 24px;
    color: #414449;
    padding: 8px 0px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(32px);

  .formContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 600px;
    color: #414449;
    @include rowGap(8px);

    .input {
      border-bottom: 1px solid #d3d8dd;
      padding-left: 8px;
      width: 280px;

      &:valid {
        border-bottom: 1px solid #d3d8dd;
      }
      &:read-only {
        border-bottom: 1px solid #d3d8dd;
      }
      &:focus {
        border-bottom: 1px solid #d3d8dd;
      }
    }

    .locationLoading {
      @extend .locationContainer;
      width: 280px;
      height: 70px;
    }

    .locationContainer {
      display: flex;
      margin-bottom: 24px;

      .locationInputContainer {
        width: 100%;
      }

      .locationDisplayContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        width: 100%;
        @include columnGap(16px);

        .locationDisplayLabel {
          font-size: 14px;
        }
        .locationDisplayValue {
          font-size: 16px;
        }
      }
    }

    .dropdownLabel {
      padding-left: 0px;
    }
  }

  .agreementContainer {
    display: flex;
    @include columnGap(16px);

    .checkbox {
      margin-top: 2px;
    }

    .statement {
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
    }

    .checked {
      color: $colorBlue1;
    }

    .unchecked {
      color: #343434;
    }
  }

  .hiddenError {
    opacity: 0;
    color: #ff1900;
    font-size: 12px;
    font-weight: 600;
  }
  .showError {
    @extend .hiddenError;
    opacity: 1;
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    .button {
      box-shadow: none;
    }
  }
}
