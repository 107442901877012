@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  .loadingWrapper {
    display: flex;

    .sideBarLoading {
      display: flex;
      flex-direction: column;
      min-width: 250px;
      background-color: #fff;

      .loadingCard {
        display: flex;
        width: 100%;
        padding: 16px;

        &:not(:last-child) {
          border-bottom: 1px solid #ecedef;
        }

        .loadingDescWrapper {
          display: flex;
          flex: 1;
          flex-direction: column;
          margin-left: 8px;
          @include rowGap(8px);

          .loadingTitle {
            height: 30px;
            max-width: 85%;
          }

          .loadingDesc {
            height: 20px;
          }
        }
      }
    }

    .contentLoading {
      margin-left: 24px;
      width: calc(100% - 250px);
      background-color: #fff;
      padding: 16px;
    }
  }

  .content {
    display: flex;

    @media (max-width: $medium-screen-min-width) {
      flex-direction: column;
    }
  }
}
