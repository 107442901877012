@import 'assets/theme';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input,
textarea,
button {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
}

button {
  &:hover {
    cursor: pointer;
  }
}

*,
*::before,
*::after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

#root {
  height: 100%;
}

.ant-notification {
  display: flex !important;
  align-self: center !important;
  justify-content: center !important;
  width: 100% !important;
  top: unset !important;
  bottom: 24px !important;
  pointer-events: none;
}

.ant-notification-notice {
  background: rgba(52, 52, 52, 0.75) !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.1px;
  height: max-content;
  padding: 16px;
  pointer-events: auto;

  div {
    color: $white;
  }

  .ant-notification-close-x {
    .success {
      color: #4abd03;
      margin-left: 4px;
    }
    .notify {
      color: #faad14;
      margin-left: 4px;
    }
  }
}

@keyframes NotificationFadeIn {
  0% {
    top: 348px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes NotificationFadeOut {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 348px;
    opacity: 0;
  }
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}
