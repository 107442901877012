@import 'assets/theme';

.modalContainer {
  width: 100% !important;
  max-width: 900px;

  .title {
    font-weight: bold;
    font-size: 18px;
    padding: 2px 0;
  }
}

.container {
  .practiceInfoWrapper {
    display: flex;

    .image {
      height: 80px;
      width: 80px;
      margin-right: 8px;
      border-radius: 16px;
    }

    .practiceDetails {
      display: flex;
      flex-direction: column;

      .name {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #343434;
      }

      .detail {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #343434;
        opacity: 0.5;
      }
    }
  }

  .clientInfoWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 24px;
    @include rowGap(4px);

    @media (max-width: $medium-screen-min-width) {
      flex-direction: column;
    }

    .clientLabel {
      font-weight: bold;
      font-size: 14px;
    }
  }

  .contentBodyWrapper {
    display: flex;
    margin: 48px 0;
    max-height: 600px;
    overflow: auto;
  }

  .signatureContainer {
    display: flex;
    justify-content: space-between;
    padding: 24px 0;

    @media (max-width: $medium-screen-min-width) {
      flex-direction: column;
      @include rowGap(24px);
    }

    .signatureWrapper {
      display: flex;
      flex-direction: column;
      min-width: 200px;

      .signatureBox {
        background: #eff2fc;
        border-radius: 8px;
        font-size: 14px;
        line-height: 19px;
        color: #7c7c7e;
        padding: 12px 16px;
        width: 200px;
      }

      .signLabel {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        padding-bottom: 4px;
      }

      .previewSignature {
        display: flex;
        flex-direction: column;

        .signature {
          display: flex;
          align-items: center;
          font-family: Kaushan Script;
          font-size: 36px;
          color: $black;
          user-select: none;

          .signatureImg {
            max-width: 200px;
            max-height: 80px;
            margin: 8px 0;
          }
        }
      }
    }
  }
}
