@import 'assets/theme';

.bulkButtonWrapper {
  display: flex;
  position: absolute;
  z-index: 1;
  margin-top: -27px;
  margin-left: -12px;
  @include columnGap(16px);

  .bulkButton {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    &:enabled:active {
      background-color: #fcd88f;
    }
  }
}

.header {
  display: flex;
  padding: 0 8px;
  margin-bottom: 16px;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #6a707e;
  @include columnGap(16px);
}

.assessmentsContainer {
  @include rowGap(16px);

  .loading {
    width: 100%;
  }

  .assessmentItem {
    display: flex;
    justify-content: space-between;
    padding: 24px 8px;
    font-size: 14px;
    line-height: 16px;
    color: #414449;
    word-break: break-all;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    position: relative;
    @include columnGap(16px);

    .checkBox {
      &:checked {
        background-color: $mhh-purple;
        border-color: $mhh-purple;
      }
    }

    .assessmentType {
      font-weight: 600;
    }

    .student {
      @include rowGap(4px);

      .name {
        font-weight: 600;
      }
    }

    .staffMember {
      font-weight: 600;
    }

    .flags {
      @include rowGap(8px);

      .flagPill {
        width: max-content;
        padding: 4px 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #202225;
        background: #d9dcff;
        border-radius: 43px;
      }
    }

    .buttons {
      .actionButtonWrapper {
        display: flex;
        flex-direction: column;

        .actionButton {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          padding: 4px;
          border: 1px solid $colorBlue1;
          box-sizing: border-box;
          font-weight: 600;
          letter-spacing: 0.1px;
          text-align: center;
          color: $colorBlue1;
          border-radius: 4px;
          cursor: pointer;

          @media (max-width: $large-screen-min-width) {
            font-size: 12px;
            height: 30px;
          }

          .icon {
            margin-left: 4px;

            @media (max-width: $medium-large_screen-min-width) {
              font-size: 16px;
            }
          }
        }

        .menuWrapper {
          display: flex;
          justify-content: flex-end;
          position: relative;
          width: 100%;
          z-index: 1;

          .menuBoxHide {
            position: absolute;
            width: 100%;
            background: #fff;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s;
            transform: scaleY(0);
            transform-origin: top;

            .listBox {
              display: flex;
              align-items: center;
              width: 100%;
              padding: 16px 8px;
              color: #707683;
              transition: all 0.2s ease;
              cursor: pointer;
              position: relative;

              @media (max-width: $medium-large_screen-min-width) {
                padding: 12px 8px;
              }

              &:not(:last-child) {
                border-bottom: 1px solid #e7e4e4;
              }

              &:hover {
                color: $colorBlue1;
              }

              .listTitle {
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;

                @media (max-width: $medium-large_screen-min-width) {
                  font-size: 11px;
                }
              }

              .comingSoon {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: #ffffff80;
                z-index: 1;
                cursor: default;
              }

              .tooltip {
                max-width: 200px;
                border-radius: 8px;
                line-height: 16px;
                font-size: 14px;
              }
            }
          }

          .menuBoxShow {
            @extend .menuBoxHide;
            visibility: visible;
            opacity: 1;
            transform: scaleY(1);
          }
        }
      }
    }
  }
}
