.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  position: relative;
}

.header {
  position: absolute;
  top: -60px;
  left: 0;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 44px;

  .caseNotesCount {
    width: 20%;
    padding: 0 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #323c47;
  }

  .inputContainer {
    width: 80%;
    max-width: 200px;
    input {
      background-color: transparent;
    }
  }

  .noteFormatButton {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    border-radius: 4px;

    .icon {
      margin-right: 12px;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 500px;
}

.content {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
  border-radius: 8px;
  min-height: 500px;

  .noteListing {
    width: 20%;
    border-right: 1px solid #c2cfe0;
    position: relative;
  }

  .noteDetailsContainer {
    width: 80%;
    position: relative;

    .noCaseNotes,
    .noCaseNoteFound,
    .noCaseNoteSelected {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}
