@use 'sass:math';
@import 'assets/theme';

.container {
  padding: $standard-padding $standard-padding * 2;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
}

.bookableClientsContainer {
  @include rowGap($standard-padding);
}

.client {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .clientName {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
  }

  .contactButton {
    color: #90a0b7;
    font-size: 13px;
  }

  .initialName {
    font-weight: 600;
    font-size: 16px;
  }

  .clientName {
    font-weight: 600;
  }
}

.addButton {
  font-size: 13px;
}

.listContainer {
  height: 240px;
  overflow-y: auto;
}

.loaderContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.contactsContainer {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding);
  height: 280px;
  overflow-y: auto;

  .profileContainer {
    display: flex;

    .initialName {
      font-weight: 600;
      font-size: 16px;
    }

    .clientName {
      font-weight: 600;
    }
  }

  .contactContainer {
    display: flex;

    .initialName {
      font-weight: 600;
      font-size: 16px;
    }

    .clientName {
      font-weight: 600;
    }

    .nameAndTags {
      display: flex;
      align-items: center;
      @include columnGap($standard-padding);
      font-size: 11px;
      font-weight: 400;

      .pill {
        font-size: 10px;
        background-color: #f3f3f3;
        border-radius: 20px;
        padding: math.div($standard-padding, 2) $standard-padding;
      }
    }
  }
}
