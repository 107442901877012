.materialSelect {

  .valueWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #D3D8DD;
    cursor: pointer;
    user-select: none;
    width: 180px;
    padding: 0 6px;

    .valueBox {
      padding: 2px 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: #192A3E;
    }

    .icon {
      color: #1941DA;
    }
  }

  .listWrapperHide {
    background-color: #fff;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    position: absolute;
    max-width: 180px;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-5px);
    transition: all 0.2s;
    max-height: 200px;
    overflow: auto;

    .listBox {
      padding: 8px 12px;
      cursor: pointer;
      transition: all 0.2s ease;
      font-weight: 600;

      &:hover {
        background-color: #eee;
      }
    }
  }

  .listWrapperShow {
    @extend .listWrapperHide;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    z-index: 1;
  }
}