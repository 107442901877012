@import 'assets/theme';

.container {
  margin-top: 8px;
  //   background-color: #f2f9ff;
  position: relative;

  .discardButton {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 0;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $main-red;
    background-color: transparent;
  }

  .titleText {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #323c47;
    border-bottom: 1px solid #ebeff2;
    padding: 16px;
  }

  .body {
    padding: 16px;

    .header {
      display: flex;
      width: 60%;

      .icon {
        margin-right: 8px;
      }

      .titleInput {
        flex: 1;
        font-size: 15px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0.1px;
        color: #343434;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #c0c0c0;
      }
    }
  }

  .content {
    display: flex;
    margin-top: 16px;
    margin-left: 32px;

    .leftCol {
      width: 30%;
      padding-right: 8px;

      .selectLabel {
        margin-bottom: -10px;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: $colorTextualGray;
      }

      .switchContainer {
        margin: 16px 0;
        user-select: none;

        .label {
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: $colorTextualGray;
        }

        .switchToggle {
          position: relative;
          display: flex;
          align-items: center;
          height: 14px;
          width: 31px;
          margin-top: 16px;
          margin-left: 24px;
          border-radius: 25px;
          background-color: #969cd5;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
          cursor: pointer;

          input {
            display: none;

            &:checked + span {
              left: 50%;
              background-color: $colorBlue1;
            }
          }

          .indicator {
            position: absolute;
            border-radius: 50%;
            background-color: #dfdfdf;
            transition: 0.1s ease;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
            width: 20px;
            height: 20px;
            top: -3px;
            left: -3px;
          }

          .switchLabel {
            position: absolute;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #343434;

            &.active {
              color: $colorBlue1;
            }

            &:first-of-type {
              left: -24px;
            }

            &:last-of-type {
              right: -32px;
            }
          }
        }
      }
    }

    .middleCol {
      width: 50%;
      padding: 0 8px;

      .label {
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: $colorTextualGray;
      }

      .commonOptionsContainer,
      .customOptionsContainer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        .inputContainer {
          display: none;
          width: 60%;
          margin-left: 4%;

          &.visible {
            display: block;
          }

          .commonOptions,
          .customOptions {
            height: 38px;
            font-size: 13px;
            font-weight: 600;
            line-height: 38px;
            letter-spacing: 0.1px;
            color: $colorTextualBlack;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            border-bottom: 1px solid $colorLineGray;
            cursor: text;
          }

          .addOptionsContainer,
          .customChoiceInputs {
            display: none;

            &.visible {
              display: block;
            }
          }

          .pill {
            margin: 4px 0;
            padding: 4px 8px;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.1px;
            color: $white;
            background: $colorBlue3;
            border-radius: 20px;
          }

          .addMoreButton {
            display: flex;
            align-items: center;
            margin-top: 8px;
            padding: 0;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: $colorBlue1;
            border: none;

            .icon {
              font-size: 18px;
              margin-right: 4px;
            }
          }

          .additionalCommonOptionsInput {
            margin-top: 24px;
            background-color: transparent;
          }

          .customOptionsInput {
            background-color: transparent;
          }

          .input {
            background-color: transparent;
          }

          .tip {
            font-size: 10px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.2px;
            color: $colorTextualGray;
          }
        }
      }

      .customOptionsContainer {
        .inputContainer {
          width: 60%;
          margin-left: 4%;

          .input {
            background-color: transparent;

            &:last-of-type {
              margin-top: 16px;
            }
          }

          .tip {
            font-size: 10px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.2px;
            color: $colorTextualGray;
          }
        }
      }
    }

    .rightCol {
      display: flex;
      width: 20%;
      padding-left: 8px;

      .saveButton {
        align-self: flex-end;
        margin: 0;
        padding: 12px 8px;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1px;
        border-radius: 4px;
      }
    }
  }
}
