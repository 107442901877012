@import 'assets/theme';

.container {
  .backBtn {
    border: 0;
    background: none;
    color: $mhh-dark-blue;
    display: flex;
    align-content: center;

    .icon {
      font-size: 20px;
    }
  }

  .sendInviteContainer {
    margin-top: 32px;
    margin-left: 20px;

    .sendInviteTitle {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: $colorTextualBlack;

      .icon {
        margin-left: 8px;
        color: #fd8468;
        border-radius: 50%;
        cursor: pointer;
      }

      .tooltip {
        max-width: 200px;
        border-radius: 8px;
        line-height: 16px;
        font-size: 14px;
      }
    }

    .customSendAtError {
      margin-left: 40px;
    }
  }

  .addNoteTitle {
    margin: 16px;
    margin-top: 34px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: $colorTextualBlack;
  }

  .addNoteEditor {
    margin: 16px;
  }

  .addNoteTextArea {
    border: 1px solid #dadbde;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .addNoteSubmit {
    margin: 16px;
    margin-top: 34px;
  }
}

.profileWrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  margin: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  @include columnGap(16px);

  .profile {
    width: 40px;
    height: 40px;

    .initialAvatar {
      font-size: 18px;
    }
  }
}

.selectedAssessmentPill {
  padding: 0px 15px;
  background-color: $colorBlue3;
  color: #fff;
  border-radius: 15px;
  height: 30px;
  line-height: 30px;
}

.submitBtn {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}
