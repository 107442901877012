@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 100px;

  .title {
    width: max-content;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #414449;
    margin-right: 24px;
    margin-bottom: 16px;
    position: sticky;
    left: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 20px;
    color: #414449;
  }
}
