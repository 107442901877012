@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .nameContainer {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 16px;
  }

  .linkContainer {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    justify-content: space-between;

    .linkWrapper {
      display: flex;
      max-width: 100%;
      align-items: center;

      .link {
        font-size: 18px;
        line-height: 25px;
        align-items: center;
        color: #6575b1;
        margin-right: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .copyBtn {
        display: flex;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  .qrCodeWrapper {
    display: flex;
    justify-content: center;
  }
}

:export {
  blackColor: $black;
  blueColor: $white;
  whiteColor: $white;
}
