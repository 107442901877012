@import 'assets/theme';

.container {
  display: flex;
  min-height: 50px;
  align-items: center;

  .titleContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #414449;
    }
  }

  .stepContainer {
    display: flex;
    @include columnGap(20px);

    .stepBackButton {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $colorBlue1;
    }

    .stepProgressBar {
      margin-right: 37px;
    }
  }

  .closeButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #334433;
    user-select: none;
  }
}
