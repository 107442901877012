@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 24px;

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 24px;
    font-weight: 600;
    font-size: 32px;

    .searchBarWrapper {
      display: flex;
      justify-content: flex-end;

      .searchBarCard {
        min-width: 400px;
      }
    }
  }

  .cardContainer {
    background: #fff;
    color: $mhh-light-black;
    border-radius: 4px;
  }
}
