@import 'assets/theme';

.modalContainer {
  min-width: 800px;

  .title {
    font-weight: 600;
    font-size: 24px;
    color: #414449;
    padding: 8px 0px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(32px);

  .descriptionsContainer {
    display: flex;
    @include columnGap(24px);

    .icon {
      width: 150px;
    }

    .instructionsContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-weight: 600;
        font-size: 18px;
        color: #414449;
      }

      .instructions {
        font-size: 16px;
        color: #343434;
      }
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 600px;
    @include rowGap(30px);

    .locationNameInput {
      width: 280px;
    }
  }

  .agreementContainer {
    display: flex;
    @include columnGap(16px);

    .checkbox {
      margin-top: 2px;
    }

    .statement {
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
    }

    .checked {
      color: $colorBlue1;
    }

    .unchecked {
      color: #343434;
    }
  }

  .error {
    color: #ff1900;
    font-size: 12px;
    font-weight: 600;
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    .button {
      box-shadow: none;
    }
  }
}
