@import 'assets/theme';

.headingField {
  width: 30%;
  padding-top: 16px;
  position: relative;

  &:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 16px;
  }

  .error {
    margin-bottom: 0;
  }

  .date {
    height: 31px !important;
    padding: 0 !important;
    border: 0;
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid $colorLineGray;
    box-shadow: none;
    outline: none;

    &::placeholder {
      color: red;
    }
  }
}
