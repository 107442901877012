@import 'assets/theme';

.button {
  align-self: center;
  margin-top: $standard-padding * 2;
  width: 284px;
  background-color: #3f52fbc9;
  border: 1px solid $selected;
  color: $white;

  &:active {
    opacity: 0.75;
  }
}
