.loadingContent {
  height: 80vh;
}

.container {
  padding: 24px;
  background: #fff;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  table {
    width: 100%;
    margin-bottom: 20px;

    thead > tr > th {
      text-align: left;
    }

    th,
    td {
      padding: 10px 0;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
