@import 'assets/theme';

.container {
  display: flex;
  justify-content: space-between;
  padding: 14px 0;

  .loading {
    width: 200px;
    height: 55px;
  }

  .providerIconContainer {
    .icon {
      height: 55px;
    }
  }

  .actionButtonContainer {
    display: flex;
    @include columnGap(8px);

    .connectButton {
      padding: 14px 20px;
      font-size: 16px;
      font-weight: 600;
    }
    .dataMappingButton {
      padding: 14px 20px;
      box-shadow: none;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
