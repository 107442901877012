@import 'assets/theme';

.header {
  color: $mhh-light-black;
  display: flex;
  justify-content: space-between;

  .left {
    .title {
      font-weight: 600;
      font-size: 18px;
    }

    .desc {
      margin-top: 8px;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
    }
  }

  .select {
    color: $mhh-dark-blue;
    font-weight: 600;
    font-size: 14px;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 10px;

    .button {
      width: 36px;
      height: 36px;
      border-radius: 36px;
      color: $mhh-dark-blue;
      cursor: pointer;
      display: flex;

      justify-content: center;
      align-items: center;

      &.active {
        background-color: $mhh-yellow;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.list {
  margin-top: 20px;

  .item {
    padding: 12px 28px;
    margin-bottom: 8px;
    background: $white;
    border: 1px solid #e8ebef;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .checkIcon {
      display: none;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
    }

    .class {
      margin-top: 4px;
      font-weight: 400;
      font-size: 14px;
    }

    &.active {
      border: solid 1px $mhh-yellow;
      .checkIcon {
        display: block;
      }
    }
  }
}

.footer {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
}
