@import 'assets/theme';

.container {
  padding: 16px 24px 24px;

  @media (max-width: $medium-large_screen-min-width) {
    padding: 16px;
  }

  @media (max-width: $medium-screen-min-width) {
    padding: 8px 16px 16px;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .titleWrapper {
      display: flex;

      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 23px;
        color: #323c47;

        @media (max-width: $medium-screen-min-width) {
          font-size: 16px;
        }
      }
    }

    .filter {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;

      .dropdownText {
        display: flex;
        align-items: center;
        margin-left: 8px;
        color: $colorBlue1;
        user-select: none;
        cursor: pointer;

        .icon {
          color: #aeaeae;
        }
      }
    }
  }

  .statusRow {
    display: flex;
    max-width: 300px;
    align-items: center;
    margin-top: 12px;
    border: 1px solid #0085bd;
    border-radius: 4px;
    padding: 8px 24px;

    @media (max-width: $large-screen-min-width) {
      padding: 8px 16px;
    }

    @media (max-width: $medium-screen-min-width) {
      padding: 8px;
    }

    .label {
      display: flex;
      flex: 1 1 40%;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: $colorBlue3;
      padding-right: 16px;
    }

    .details {
      display: flex;
      flex: 1 1 60%;
      flex-direction: column;
      transition: all 0.2s ease;

      .sessionWrapper {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #343434;
        padding-bottom: 2px;

        @media (max-width: $medium-large_screen-min-width) {
          font-size: 12px;
        }

        .sessionCount {
          font-size: 18px;
          font-weight: bold;
        }
      }

      .costWrapper {
        font-size: 12px;
        line-height: 20px;
        color: #0085bd;

        .cost {
          font-weight: bold;
        }
      }
    }
  }
}
