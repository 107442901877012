@import 'assets/theme';

.filterStyles {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  font-weight: 600;
  font-size: 16px;
}

.profileSection {
  @extend .filterStyles;
  max-width: 300px;
  padding-left: 40px;
  padding-right: 40px;
  flex: 1 5 12%;
  font-weight: 600;
}

.sortHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  color: $mhh-yellow;

  &:hover {
    opacity: 0.8;
  }
}

.classSection {
  @extend .filterStyles;
  max-width: 120px;
  padding-right: 40px;
  flex: 1 5 10%;
}

.pendingAssessmentSection {
  @extend .filterStyles;
  align-items: center;
  max-width: 230px;
  padding-right: 50px;
  overflow: visible;
  flex: 1 5 20%;
  gap: 0;
  text-align: center;
}

.groupSection {
  @extend .filterStyles;
  padding-right: 40px;
  max-width: 250px;
  flex: 1 5 20%;
}

.tagSection {
  @extend .filterStyles;
  padding-right: 40px;
  max-width: 300px;
  flex: 1 5 20%;
}

.buttonSection {
  @extend .filterStyles;
  max-width: 220px;
  align-items: flex-start;
  flex: 1 5 15%;
  margin-right: 16px;
}
