@import 'assets/theme';

.filterContainer {
  display: flex;
  padding: 0 16px;
  margin-bottom: 16px;
  cursor: auto;

  .profileLabel {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: $colorBlue1;
  }
}

.paginationWrapper {
  padding-top: 16px;
}
