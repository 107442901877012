@import 'assets/theme';

.title {
  display: flex;
  align-items: flex-end;
}

.container {
  .patientSelectContainer {
    padding: $standard-padding * 2;
  }

  .active {
    visibility: visible;
    opacity: 1;
    height: 100%;
    transition: opacity 500ms;
  }

  .inactive {
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: opacity 500ms;
    overflow: hidden;
  }
}

.profileWrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  margin: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  .profile {
    width: 40px;
    height: 40px;
    margin-right: 16px;

    .initialAvatar {
      font-size: 18px;
    }
  }
}
