.filterStyles {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #6a707e;
}

.profileSection {
  @extend .filterStyles;
  align-items: center;
  min-width: 170px;
  max-width: 200px;
  padding-right: 8px;
  flex: 1 5 15%;
}

.cSinceSection {
  @extend .filterStyles;
  max-width: 130px;
  padding-right: 22px;
  flex: 1 5 15%;
}

.pTypeSection {
  @extend .filterStyles;
  max-width: 140px;
  min-width: 140px;
  padding-right: 40px;
  flex: 1 5 15%;
}

.buttonSection {
  @extend .filterStyles;
  align-items: flex-end;
  flex: 1 1 40%;
}
