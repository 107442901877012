@import 'assets/theme';

.labelContainer {
  display: flex;
  @include columnGap(8px);
  align-items: center;

  .label {
    font-weight: 500;
    font-size: 11px;
    color: $colorTextualGray;
  }

  .helpIcon {
    font-size: 20px;
    margin: 0;
  }
}

.inputContainer {
  display: flex;
  font-size: 18px;
  font-weight: 600;

  .prefixContainer,
  .inputField {
    border: none;
    border-bottom: 1px solid $colorLineGray;
  }

  .inputField {
    width: 100%;
    background-color: transparent;
  }

  &.error > * {
    border-color: #ff1900;
  }
}
