.container {
  padding: 0 0 24px;
  margin-top: 16px;

  .checkBoxContainer {
    padding: 16px 0;
  }

  .noteContainer {
    transition: all 0.3s ease;
    max-height: 500px;
    opacity: 1;
    padding: 0 26px;

    .error {
      margin-top: 4px;
    }
  }

  .noteContainerHidden {
    @extend .noteContainer;
    max-height: 0;
    transition: all 0.3s ease;
    overflow: hidden;
    opacity: 0;
  }

  .fieldContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 8px;
    max-width: 380px;
    background: #fff;
    padding-top: 24px;
  }
}

.highlightContainer {
  @extend .container;
  background-color: #fff;
}
