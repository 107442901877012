@import 'assets/theme';

.error {
  margin-top: 0;
}

.surveysValueContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;

  .survey {
    display: flex;
    align-items: center;
    margin: 2px;
    padding: 8px 12px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $white;
    background-color: rgb(42, 70, 112);
    border-radius: 24px;
    overflow: hidden;

    .iconContainer {
      display: flex;
      align-items: center;
      margin-left: 4px;
      padding: 4px;
      cursor: pointer;

      &:hover {
        background-color: #dddddd80;
      }

      .icon {
        font-size: 14px;
      }
    }
  }
}
