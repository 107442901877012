@import 'assets/theme';

.container {
  .labelContainer {
    padding-bottom: 24px;

    .label {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: $colorBlue1;
    }
  }

  .fieldContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 32px;
    max-width: 380px;

    &.fieldError,
    .fieldError {
      font-size: 12px;
      color: #ff4d4f;

      input[type='text'] {
        border-bottom: 1px solid #ff4d4f !important;
      }
    }

    .noneComms {
      font-size: 11px;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: #2eb886;
    }
  }

  .phoneContainer {
    @extend .fieldContainer;
    margin: -10px 0 24px;
  }

  .withCheckBoxContainer {
    display: flex;
    justify-content: space-between;

    .checkBoxField {
      color: rgba(0, 0, 0, 0.85);
      padding-top: 8px;
      font-size: 14px;
    }
  }

  .disableMessageContainer {
    display: flex;
    padding: 0px 8px;

    .lockIcon {
      color: $colorBlue1;
      font-size: 24px;
    }
    .disabledMessage {
      padding-left: 8px;

      .helpIcon {
        position: relative;
        top: 4px;
        color: $main-red;
        font-size: 20px;
      }
    }
  }
}

.containerWithBox {
  @extend .container;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: -8px -16px 24px;
  padding: 16px;
}

.cPreference {
  margin: 4px 0 8px 32px;
  max-width: 200px;
  transition: all 0.8s ease;
  max-height: 500px;

  .capability {
    padding-bottom: 16px;
  }
}
