@import 'assets/theme';

.container + .container {
  margin-top: 40px;
}

.title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 0.1px;
  color: $colorTextualBlack;
  padding-bottom: 8px;
}

.wrapper {
  display: flex;
  background-color: #f2f9ff;
  padding: 8px 16px;
  justify-content: space-between;
  min-height: 65px;

  .noListLabel {
    font-size: 12px;
    color: $colorTextualGray;
  }

  .checkBox {
    align-items: center;
  }

  .selection {
    display: flex;
    flex-direction: column;
    width: 40%;

    .onBoardingBox {
      &:not(:last-child) {
        padding-bottom: 8px;
      }
    }
  }
}

.optionContainer {
  transition: all 0.3s ease;
  max-height: 500px;
  opacity: 1;
  background-color: #f2f9ff;

  .onBoardingOption {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-top: 16px;
    padding-bottom: 16px;

    .subTitle {
      display: flex;
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      padding-bottom: 4px;
    }

    .onBoardingRadio {
      display: flex;
      flex-direction: column;
    }
  }
}

.optionContainerHidden {
  @extend .optionContainer;
  max-height: 0;
  transition: all 0.3s ease;
  overflow: hidden;
  opacity: 0;
}

.notSelectWrapper {
  @extend .wrapper;
  background-color: #fff;
}
