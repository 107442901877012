@import 'assets/theme';

.cardContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 18px;
}

.cardWrapper {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .listCardMenu {
    position: relative;
    display: flex;
    justify-content: flex-end;
    top: 20px;
    right: 20px;
    margin: -15px;
  }
}

.loaderContainer {
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  transition: all 0.2s ease;

  &.editable {
    cursor: pointer;

    &:hover {
      background-color: #f2f9ff;
    }
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: #4c5862;
    }
  }

  .dateWrapper {
    padding: 4px 0 0 0;

    .date {
      font-size: 13px;
      line-height: 20px;
      color: #4c5862;
    }

    .dateLabel {
      @extend .date;
      opacity: 0.5;
    }
  }

  .subContextWrapper {
    padding: 0;

    .subContext {
      font-size: 13px;
      line-height: 20px;
      color: #4c5862;
    }

    .subContextLabel {
      @extend .subContext;
      opacity: 0.5;
    }
  }

  .subscribeWrapper {
    display: flex;
    padding-top: 24px;
    align-items: center;

    .clientWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 88px;

      .avatarWrapper {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        object-fit: cover;

        &:nth-child(2) {
          position: relative;
          right: 10px;
        }

        &:nth-child(3) {
          position: relative;
          right: 20px;
        }
      }
    }

    .clientLabelWrapper {
      padding-left: 4px;

      .clientLabel {
        font-weight: 300;
        font-size: 12px;
        color: #90a0b7;
      }
    }
  }

  .pd2px {
    padding: 2px 0;
  }

  .badgeWrapper {
    display: flex;

    .badge {
      background: $colorBlue1;
    }
  }

  .defaultBtnWrapper {
    display: flex;
    justify-content: flex-end;

    .defaultBtn {
      font-size: 26px;
      color: #e6e6e6;
    }

    .spinBtn {
      animation: spin-animation 0.5s infinite;
    }

    @keyframes spin-animation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }

    .defaultBtnActive {
      @extend .defaultBtn;
      color: $mhh-yellow;
    }
  }
}

.active {
  border-left: 4px solid #6fcf97;
}

.onBoarding {
  border-left: 4px solid #ffb946;
}

.checkIn {
  border-left: 4px solid $colorBlue2;
}
