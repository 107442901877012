@import 'assets/theme';

.selectionRow {
  display: flex;

  @media only screen and (max-width: $medium-screen-min-width) {
    flex-direction: column;
    padding-bottom: 10px;
  }

  &.active {
    background-color: #f2f9ff;
  }

  .checkboxContainer {
    padding: 12px;
    width: 25%;
  }

  .timeSelectionLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorTextualGray;
    display: none;
    padding: 0px 10px 10px;

    @media only screen and (max-width: $medium-screen-min-width) {
      display: flex;
      @include columnGap(24px);
    }

    .timeLabel {
      width: 50%;
    }
  }

  .timeSelectionsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 75%;

    @media only screen and (max-width: $medium-screen-min-width) {
      width: 100%;
    }
  }

  .errorContainer {
    padding: 12px;
  }
}
