@import 'assets/theme';

.modalContainer {
  min-width: 800px;
  border-top: solid $white;
  border-radius: 8px;
  overflow: auto;

  & > div {
    border-radius: 8px;
    box-shadow: none;
  }
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input[type='text'] {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }

  .label {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #61666e;
  }

  .textarea {
    width: 100%;
    height: 148px;
    padding: 8px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    box-shadow: 0px 6px 18px 0px #0000000f;
    resize: none;
  }
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;

  .cancelButton {
    margin-right: 8px;
    min-width: 120px;
  }
}
