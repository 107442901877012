@import 'assets/theme';

.loading {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 32px;

  .card {
    display: flex;
    flex: 1;
    margin-bottom: 32px;
    background: #fff;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .groupWrapper {
      display: flex;
      flex: 1;
      max-width: 200px;
      padding: 16px;
      flex-direction: column;
      border-right: 1px solid #ededed;

      .groupTitle {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        color: $colorTextualBlack;
        padding-bottom: 16px;

        .icon {
          color: $colorBlue1;
          font-size: 24px;
        }
      }

      .questionAnswered {
        font-size: 12px;
        line-height: 20px;
        color: $black;
      }
    }

    .answerWrapper {
      display: flex;
      flex: 1;
      flex-direction: column;

      .answerCard {
        display: flex;
        padding: 16px;
        background: #f6f8fc;

        .leftPanel {
          display: flex;
          align-items: flex-start;

          .number {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            font-size: 12px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 10px;
            color: $colorBlue3;
            border: 1px solid $colorBlue3;
          }
        }

        .rightPanel {
          display: flex;
          flex: 1;
          flex-direction: column;

          .questionTitle {
            font-size: 14px;
            line-height: 20px;
            color: #2b4670;
            padding-top: 2px;
            padding-bottom: 8px;
          }

          .responseOptions {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 8px;

            .pill {
              margin: 2px;
              padding: 8px 16px;
              background-color: $colorBlue3;
              border-radius: 16px;
              font-size: 13px;
              font-weight: 600;
              text-align: center;
              color: $white;

              &.colorbox {
                height: 36px;
                width: 36px;
              }
            }
          }

          .answer {
            font-size: 16px;
            line-height: 20px;
            color: $black;
          }

          .skipped {
            font-size: 16px;
            font-style: italic;
            font-weight: 300;
          }

          .download {
            margin-top: $standard-padding;

            .viewReferralBtn {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 8px;
              max-width: 200px;
              border: 1px solid $colorBlue1;
              border-radius: 4px;
              color: $colorBlue1;
              cursor: pointer;
            }
          }
        }
      }

      .answerCardWhite {
        @extend .answerCard;
        background: #fff;
      }
    }
  }
}
