@import 'assets/theme';

.container {
  display: flex;
  padding: $standard-padding * 2 0;

  .leftSection {
    width: 300px;
    flex: 1 2 30%;
    padding-right: 24px;
    box-sizing: border-box;

    .title {
      font-weight: 600;
      font-size: 26px;
      line-height: 35px;
      color: $colorTextualBlack;
    }

    .button {
      display: flex;
      align-items: center;
      background-color: transparent;
      padding: 8px 4px;
      margin: 8px 0;

      .icon {
        margin-right: 4px;
        font-weight: normal;
        color: $colorBlue1;
      }

      .text {
        font-size: 16px;
        font-weight: normal;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: $colorBlue1;
      }
    }
  }

  .rightSection {
    flex: 1 2 70%;

    .templateHeader {
      display: flex;
      justify-content: space-between;
      height: 80px;

      .reportNameWrapper {
        display: flex;
        flex-direction: column;

        .reportLabel {
          color: $colorTextualGray;
          font-size: 11px;
          font-weight: 600;
          left: 2px;
          bottom: 22px;
          pointer-events: none;
        }

        .reportName {
          background-color: #f8f9fd;
          font-size: 24px;
          line-height: 20px;
          color: #323c47;
          margin-top: 4px;
          font-weight: 600;
        }
      }
    }
  }
}
