@import 'assets/theme';

.container {
  display: flex;
  height: 34px;
  cursor: pointer;
}

.pointBottom_Top {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 10px solid $white;
  position: relative;
  top: 10px;
  z-index: 1;
}

.pointBottom_Down {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 10px solid;
}

.pointTop_Top {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 10px solid;
  z-index: 1;
}

.pointTop_Down {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 10px solid $white;
  position: relative;
  top: -10px;
}

.scaleDown {
  width: 40px;
  height: 30px;

  .scaleText {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    text-shadow: 1px 1px 8px #000;
    font-size: 12px;
    height: 48px;
  }

  .scaleTextTop {
    @extend .scaleText;
    height: 16px;
  }

  .scaleTextMiddle {
    @extend .scaleText;
    height: 30px;
  }
}
