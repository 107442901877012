@import 'assets/theme';

.containerLoading {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  @include rowGap(12px);

  .paginationLoading {
    display: flex;
    justify-content: space-between;

    .perPageLoading {
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .showLoading {
      max-width: 250px;
      width: 100%;
      height: 25px;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .header {
    background: #f9f9ff;
    padding: 16px 0;
  }

  .loadingWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include rowGap(8px);

    .loading {
      width: 100%;
      border-radius: 4px;
      padding: 0 16px;
      height: 20px;
    }
  }

  .listItem {
    padding: 24px 0;
    border-bottom: 0.5px solid #ecedef;
    align-items: center;

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .multipleValue {
      display: flex;
      flex-direction: column;
      word-break: break-word;
    }

    .noValue {
      display: flex;
      padding: 0 8px;
      justify-content: center;
    }

    .icon {
      border: 2px solid $colorBlue3;
      color: $colorBlue3;
      padding: 4px;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .noMemberFoundContainer {
    display: flex;
    padding: 24px 16px;
    border-bottom: 0.5px solid #ecedef;
    justify-content: center;
  }

  .paginationWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    .recordPerPageWrapper {
      display: flex;
      align-items: center;
      color: #64748b;

      .label {
        margin-left: 4px;
      }
    }
  }
}
