@import 'assets/theme';

.container {
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  padding: 16px 20px;
  border-radius: 8px;
  margin-bottom: 16px;

  .guideLineTitle {
    display: flex;
    padding-bottom: 16px;

    .label {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: $colorTextualBlack;
    }

    .desc {
      display: flex;
      align-items: center;
      padding-left: 24px;
      font-size: 11px;
      line-height: 20px;
      color: #323c47;

      .icon {
        padding-right: 4px;
      }

      .iconDesc {
        opacity: 0.5;
      }
    }
  }

  .pointDesc {
    display: flex;
    align-items: center;
    padding: 8px 16px 8px 0;
    font-size: 12px;

    .checkIcon {
      background: $mid-grey;
      border-radius: 4px;
      color: #fff;
      margin-right: 8px;
      padding: 2px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
