@import 'assets/theme';

.container {
  overflow: hidden;

  .noInvoicesFilter {
    display: flex;
    justify-content: center;
    padding: 24px 0 8px;
  }

  .noInvoices {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .image {
      max-width: 250px;
      width: 100%;

      @media (max-width: $medium-large_screen-min-width) {
        max-width: 150px;
      }
    }

    .prompt {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 40%;

      .text {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.1px;
        text-align: center;
        color: $black;
      }

      .link {
        display: flex;
        align-items: center;
        margin-top: 24px;
        padding: 8px 16px;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $mhh-dark-blue;
        border: 1px solid $mhh-yellow;
        border-radius: 4px;
        box-sizing: border-box;

        .icon {
          margin-right: 12px;
        }
      }
    }
  }

  .filter {
    display: flex;
    align-items: center;
    flex: 1;

    .filterLabel {
      line-height: 18px;
      letter-spacing: 0.1px;
      font-size: 14px;
      color: #414449;
      font-weight: 600;
    }

    .dropdownText {
      display: flex;
      align-items: center;
      margin-left: 8px;
      color: $colorBlue1;
      user-select: none;
      cursor: pointer;

      .icon {
        color: #aeaeae;
      }
    }

    .toggleWrapper {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  .listHeader {
    display: flex;
    margin-top: 24px;

    .item {
      padding: 0 4px;
      font-size: 12px;
      line-height: 16px;
      color: #34343480;

      @media (max-width: $large-screen-min-width) {
        font-size: 11px;
      }
    }

    .profile {
      @extend .item;
      width: 15%;

      @media (max-width: $large-screen-min-width) {
        width: 8%;
      }
    }

    .nameLabel {
      @extend .item;
      width: 15%;
      padding: 0;

      @media (max-width: $large-screen-min-width) {
        width: 8%;
      }
    }

    .invoiceId {
      @extend .item;
      width: 15%;

      @media (max-width: $large-screen-min-width) {
        width: 13%;
      }
    }

    .issueDate {
      @extend .item;
      width: 15%;
    }

    .description {
      @extend .item;
      width: 20%;

      @media (max-width: $large-screen-min-width) {
        width: 22%;
      }
    }

    .amount {
      @extend .item;
      width: 12%;
    }

    .status {
      @extend .item;
      width: 13%;

      @media (max-width: $large-screen-min-width) {
        width: 15%;
      }
    }

    .action {
      @extend .item;
      width: 15%;

      @media (max-width: $large-screen-min-width) {
        width: 15%;
      }
    }

    .button {
      display: flex;
      background-color: transparent;
      color: $colorBlue1;

      .icon {
        margin-top: -4px;
      }
    }
  }
}
