@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  @include rowGap($standard-padding * 2);

  .header {
    .navigation {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 20px;
        font-weight: 700;
        color: $colorTextualBlack;
        align-items: center;
        display: flex;
      }
    }

    .controls {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sortContainer {
        display: flex;
        align-items: center;

        color: #6a707e;
        font-size: 12px;

        span {
          margin-left: $standard-padding;
          color: $colorBlue1;
          cursor: pointer;
          display: flex;
          align-items: center;

          .arrow {
            margin: 0;
            color: #aeaeae;
          }
        }
      }
    }
  }

  .loadingSkeleton {
    width: 310px;
  }

  .patientContainer {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow: auto;

    border: 1px solid #f3f3f3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .patient {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 16px 24px;
      background-color: $white;
      border: none;
      border-bottom: #ebeff2 1px solid;
      box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);

      &:hover {
        background: #fbfbfb;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      &.active {
        background-color: #e0f1fa;
      }

      .avatarNameContainer {
        display: flex;
        align-items: center;

        .profile {
          width: 50px;
          height: 50px;
          margin-right: 16px;
        }

        .name {
          margin-left: $standard-padding * 2;
          font-weight: 400;
          font-size: 15px;
          color: #323c47;
        }
      }

      .selectedBadge {
        span {
          font-size: $standard-padding * 4;
          color: $colorBlue1;
        }
      }

      .pill {
        padding: 0px 4px;
        background-color: #ff0000;
        border-radius: 4px;
        font-style: italic;
        color: $white;

        &.active {
          background-color: #25be69;
        }
      }
    }

    .multiProfileContainer {
      .profileLabel {
        span {
          color: #fff;
          font-size: 10px;
          font-weight: 400;
          padding: 5px 5px;
          background-color: $colorBlue1;
          border-radius: 3px;
        }
      }

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include rowGap(20px);
    }
  }

  .searchBar {
    position: relative;
    font-weight: 600;
    font-size: 14px;

    .input {
      border: 1px solid #dadbde;
      border-radius: 4px;
      padding: 6px 12px;
      padding-left: 36px;
      width: 100%;
      color: $colorTextualBlack;

      &:focus {
        border: 1px solid $mhh-yellow;
      }

      &::placeholder {
        color: $colorTextualGray;
      }
    }

    .searchBarIcon {
      position: absolute;
      top: 0;
      left: 0;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      gap: 10px;

      i {
        color: $colorTextualGray;
        font-size: 20px;
      }
    }
  }

  .noItems {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: $colorTextualGray;
      font-size: 20px;
      margin-right: 6px;
    }
  }
}

.compactContainer {
  display: flex;
  flex-direction: column;

  .selectedBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 8px 10px;
    border-radius: 8px;
    user-select: none;
    cursor: pointer;

    .icon {
      color: $colorBlue1;
    }
  }

  .loadingBox {
    @extend .selectedBox;
    min-height: 54px;
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    .listHide {
      background: #fff;
      border: 1px solid #eee;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.2s ease;
      position: absolute;
      width: 100%;

      .searchBox {
        display: flex;
        align-items: center;
        padding: 8px 10px;

        .searchIcon {
          position: absolute;
          left: 18px;
          color: #b1b1b1;
          font-size: 24px;
        }

        .searchInput {
          display: flex;
          flex: 1 1;
          font-size: 16px;
          line-height: 16px;
          padding: 8px 16px 8px 40px;
          border-radius: 6px;
          border: 1px solid #eee;
          background-color: #fff;
          outline: none;
          transition: all 0.2s ease;
        }
      }

      .itemWrapper {
        max-height: 200px;
        overflow: auto;

        .item {
          padding: 8px 10px;
          cursor: pointer;

          &:hover {
            color: rgba(0, 0, 0, 0.85);
            font-weight: 600;
            background-color: #f5f5f5;
            transition: background 0.3s ease;
          }
        }
      }

      .itemSelected {
        padding: 8px 10px;
        cursor: pointer;
        background-color: #e6f7ff;
      }
    }

    .listShow {
      @extend .listHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }
}
