@import 'assets/theme/index';

.container {
  height: 100%;
  max-width: 100%;
}

.headerContainer {
  display: flex;
  align-items: center;
  padding: 24px 0;
  justify-content: space-between;

  .titleWrapper {
    display: flex;
    flex-direction: column;
    padding-right: 24px;

    .title {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 22px;
      letter-spacing: 0.01em;
      color: $colorTextualBlack;

      .menuIcon {
        margin-right: 6px;
        font-size: 32px;
      }
    }
  }
}
