.item {
  direction: ltr;
  padding: 5px 12px;
  min-height: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;

  .text {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    user-select: none;
  }

  .label {
    user-select: none;
    font-weight: 600;

    .matchedValue {
      text-decoration: underline;
    }
  }

  .coloredLabel {
    padding: 0 4px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 20px;
    min-width: 110px;
    text-align: center;
  }

  .checkBox {
    margin-right: 10px;
  }

  .checkBoxInput {
    background-color: transparent;

    &:checked {
      background-color: blue;
      border-color: blue;
    }
    border-color: #414449;
  }

  &.active {
    color: blue;
  }

  &:not(:last-child) {
    border-bottom: solid 1px #ecedef;
  }

  &.admin {
    .checkBoxInput {
      &:checked {
        background-color: blue;
        border-color: blue;
      }
    }
  }
}
