.searchBar {
  display: flex;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;

  &.clearable {
    .input {
      padding: 6px 36px 6px 36px;
    }
  }

  .input {
    display: flex;
    flex: 1;
    border: 1px solid #dadbde;
    border-radius: 4px;
    padding: 6px 12px 6px 36px;
    width: 100%;
    color: black;

    &:focus {
      border: 1px solid blue;
    }

    &::placeholder {
      color: gray;
    }
  }

  .searchBarIcon {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 10px;

    i {
      color: gray;
      font-size: 20px;
    }
  }

  .clearTextIcon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
      color: gray;
      font-size: 20px;
    }
  }
}
