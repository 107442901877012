@import 'assets/theme';

.container {
  padding: 16px 24px 24px;
  position: relative;

  .deleting {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #ffffff80;
    z-index: 1;
  }

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .createdAt,
    .updatedAt {
      display: flex;
      margin-right: 24px;

      .label {
        margin-right: 2px;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: $colorTextualGray;
      }

      .value {
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #343434;
      }
    }

    .deleteNoteButton {
      position: absolute;
      right: 0;
      min-height: 0;
      min-width: 0;
      height: auto;
      padding: 0;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: $main-red;
      background: transparent;
      border-radius: 0;
      border: none;
      box-shadow: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    margin-top: 48px;
    position: relative;

    .editButton {
      position: absolute;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 50px;
      height: max-content;
      min-width: 0;
      max-width: 39%;
      border-radius: 4px;

      .icon {
        margin-right: 8px;
      }
    }

    .rightButtons {
      display: flex;
      max-width: 59%;
    }

    .downloadButton {
      display: flex;
      justify-content: center;
      align-items: center;
      height: max-content;
      min-height: 50px;
      margin-right: 16px;
      padding: 8px 16px;
      border-radius: 4px;

      .icon {
        margin-right: 8px;
      }
    }

    .downloadActive {
      background-color: #f8f9fd !important;

      &:before {
        background-color: #f0f9f0;
      }
    }

    .shareButton {
      flex: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: max-content;
      min-height: 50px;
      width: auto;
      min-width: 0;
      margin: 0;
      padding: 8px 16px;
      border-radius: 4px;
    }
  }
}
