@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(12px);
}

.header {
  display: flex;

  .showOnDesktop {
    width: 75%;
    display: flex;

    @media only screen and (max-width: $medium-screen-min-width) {
      display: none;
    }

    .headerCell {
      width: 33%;
    }
  }

  .headerCell {
    width: 25%;
    padding: 12px;

    @media only screen and (max-width: $medium-screen-min-width) {
      width: 100%;
      padding: 0;
    }

    .label {
      font-size: 11px;
      font-weight: 500;
      color: $colorTextualGray;
    }
  }
}
