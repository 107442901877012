@import 'assets/theme';

.container {
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 1;
  }
}

.label {
  font-size: 11px;
  font-weight: 500;
  color: $colorTextualGray;
  padding-bottom: 4px;
}

.clinicianSelect {
  width: 280px;

  .profileBadgeStyle {
    max-width: 234px;
  }
}

.eventFormatContainer {
  display: flex;
}
