@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 6px;

  .label {
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    color: $mhh-dark-blue;
    padding-bottom: 4px;
  }

  .switchToggle {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 31px;
    height: 14px;
    border-radius: 25px;
    background-color: $mhh-light-yellow;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

    .switchLabel {
      position: absolute;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: #343434;

      &.active {
        color: $mhh-yellow;
      }

      &:first-of-type {
        left: -24px;
      }

      &:last-of-type {
        right: -32px;
      }
    }

    input {
      display: none;

      &:checked + div {
        left: 50%;
        background-color: $mhh-yellow;
      }
    }

    .dot {
      position: absolute;
      border-radius: 50%;
      background-color: #dfdfdf;
      transition: 0.1s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      width: 20px;
      height: 20px;
      top: -3px;
      left: -3px;
    }

    .loadingDot {
      @extend .dot;

      .loadingSwitch {
        position: absolute;
        top: -3px;
        left: -3px;
      }
    }
  }

  .disabled {
    opacity: 0.8;
    background-color: $mid-grey;
    cursor: not-allowed;
  }
}
