@import 'assets/theme';

.calendarContainer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .calendarTitle {
    font-size: 12px;
    color: $colorTextualGray;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  .calendarName {
    font-size: 16px;
    font-weight: 600;
  }

  .settingCheckbox {
    display: flex;
    align-items: center;
    @include columnGap(12px);
  }

  .checkbox {
    appearance: none;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 2px solid #d5d5d5;
    border-radius: 4px;
    color: #d5d5d5;
    cursor: pointer;
    outline: none;
    transition: all 0.1s linear;

    &:after {
      content: '\2713';
      display: none;
      width: 10px;
    }

    &:checked {
      font-size: 12px;
      line-height: 12px;
      font-weight: 800;
      color: #fff;
      background-color: $colorBlue1;
      border: 2px solid $colorBlue1;

      &:after {
        display: flex;
      }
    }
  }

  .connectSettingLabel {
    font-size: 14px;
    font-weight: 600;
    color: #343434;
  }

  .disabledSettingCheckbox {
    display: flex;
    align-items: center;
    color: $mid-grey-medium;
    @include columnGap(12px);

    .checkbox {
      &:checked {
        background-color: $mid-grey-medium;
        border: 2px solid $mid-grey-medium;
      }
    }
  }

  .configsContainer {
    display: flex;
    flex-direction: column;
    @include rowGap(16px);
    padding-left: 28px;

    .settingOptionContainer {
      display: flex;
      flex-direction: column;

      .connectSettingDescription {
        font-size: 12px;
        padding-left: 28px;
        color: #827e7e;
        @include rowGap(4px);
      }
    }
  }
}
