@import 'assets/theme';

.referrersContainer {
  .loading {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 500px;
  }

  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #fff;
  @include rowGap(40px);
}
