@import 'assets/theme';

.container {
  display: flex;
  padding: $standard-padding * 2 0;

  .leftSection {
    width: 300px;
    flex: 1 2 30%;
    padding-right: 24px;
    box-sizing: border-box;

    .titleSection {
      display: flex;
      flex-direction: column;
      height: 80px;
      min-width: 280px;
      @include rowGap(8px);

      .title {
        font-weight: 600;
        font-size: 26px;
        line-height: 35px;
        color: $colorTextualBlack;
      }

      .backButton {
        display: flex;
        align-items: center;
        color: $colorBlue1;
        width: max-content;
        @include columnGap(8px);
        cursor: pointer;

        .backButtonlabel {
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }

  .rightSection {
    flex: 1 2 70%;

    .templateHeader {
      display: flex;
      justify-content: flex-end;
      height: 80px;

      .error {
        color: #ff1900;
        font-size: 12px;
        margin-top: 4px;
        margin-bottom: 8px;
      }

      .reportName {
        background-color: #f8f9fd;
        font-size: 24px;
        line-height: 20px;
        color: #323c47;
        margin-top: 20px;
        font-weight: 600;
      }

      .rightHeadingWrapper {
        display: flex;
        flex-direction: column;
        padding: 0px 4px;
        @include rowGap(10px);

        .buttonsWrapper {
          display: flex;
          @include columnGap(8px);

          .draftBtn {
            background-color: transparent;
            border: 1px solid $colorBlue1;
            color: $colorBlue1;
            font-weight: 400;
          }

          .draftActive {
            background-color: #f8f9fd !important;

            &:before {
              background-color: #f0f9f0;
            }
          }

          .previewBtn {
            margin: 0px;
            box-shadow: none;
          }
        }

        .lastEditMessage {
          display: flex;
          justify-content: flex-end;
          font-size: 12px;
          color: #7c7c7e;
        }
      }
    }
  }

  .previewAndSendBtn {
    display: flex;
    position: fixed;
    bottom: 40px;
    right: 180px;
    border-radius: 55px !important;
    height: 55px;
    z-index: 9;

    @media only screen and (max-height: 740px) {
      bottom: 11px;
      right: 160px;
    }
  }
}

.loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.previewContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 840px + 32px;
}
