@import 'assets/theme';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  padding: $standard-padding * 4;
  @include rowGap($standard-padding * 2);
}

.row {
  display: flex;
  align-items: center;
  @include columnGap($standard-padding * 2);

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    span {
      font-size: 20px;
      color: $colorBlue3;
    }
  }

  .content {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    white-space: pre-wrap;
  }
}

.attendeesContainer {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding);

  .emailIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin-right: $standard-padding * 2;
  }
}
