.container {
  padding: 20px;

  .row {
    display: flex;
    justify-content: space-between;
  }

  .topRow {
    @extend .row;
    flex-direction: row;
    margin-bottom: 32px;

    .practice {
      display: flex;
      max-width: 45%;
      overflow: hidden;

      .image {
        height: 80px;
        width: 80px;
        margin-right: 8px;
        border-radius: 16px;
      }

      .practiceDetails {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          color: #343434;
        }

        .description {
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          color: #343434;
          opacity: 0.5;
        }
      }
    }

    .clinician {
      display: flex;
      max-width: 45%;
      overflow: hidden;

      .image {
        height: 80px;
        width: 80px;
        margin-right: 8px;
        border-radius: 50%;
      }

      .clinicianDetails {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          color: #343434;
        }

        .description {
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          color: #343434;
          opacity: 0.5;
        }
      }
    }
  }

  .bottomRow {
    @extend .row;

    .item {
      display: flex;
      flex-direction: column;
      max-width: 25%;

      &:last-child {
        margin-left: 36px;
      }

      .label {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        color: #343434;
      }

      .description {
        font-size: 12px;
        line-height: 16px;
        color: #343434;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
