@import 'assets/theme';

.columnWrapper {
  display: flex;

  .columnStyles {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #414449;
    flex-grow: 1;
    flex-shrink: 7;
  }

  .sortActive {
    font-weight: 600;
    color: $mhh-yellow;
  }

  .label {
    @extend .columnStyles;
    min-width: 130px;
    width: 15%;
    padding-right: 16px;
  }

  .whenDate {
    @extend .columnStyles;
    width: 18%;
    padding-right: 12px;
  }

  .whenTime {
    @extend .columnStyles;
    width: 18%;
    padding-right: 12px;
  }

  .where {
    @extend .columnStyles;
    min-width: 120px;
    width: 15%;
    padding-right: 8px;
  }

  .who {
    @extend .columnStyles;
    width: 15%;
    padding-right: 8px;
  }

  .participating {
    @extend .columnStyles;
    min-width: 100px;
    width: 8%;
    padding-right: 8px;
  }

  .action {
    @extend .columnStyles;
    justify-content: flex-end;
    min-width: 50px;
    max-width: 5%;
    padding-right: 8px;
  }
}
