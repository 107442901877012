@import 'assets/theme';

.avatarMultipleBox {
  display: flex;
  align-items: center;

  .avatarMultipleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    .avatarMultipleWrapper {
      border-radius: 50%;
      border: 2px solid #fff;
      width: 50px;
      height: 50px;
      object-fit: cover;
      z-index: 6;

      &:nth-child(2) {
        position: absolute;
        left: 30px;
        z-index: 5;
      }

      &:nth-child(3) {
        position: absolute;
        left: 60px;
        z-index: 4;
      }

      &:nth-child(4) {
        position: absolute;
        left: 90px;
        z-index: 3;
      }

      &:nth-child(5) {
        position: absolute;
        left: 120px;
        z-index: 2;
      }

      &:nth-child(6) {
        position: absolute;
        left: 150px;
        z-index: 1;
      }
    }

    .imgProfile {
      width: 100%;
      border-radius: 50%;
    }
  }

  .showBalanceLabel {
    margin-left: 4px;
  }
}
