@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: $standard-padding * 2;
  margin-bottom: $standard-padding * 2;
  border-bottom: 1px solid #dce2f4;
  user-select: none;
  max-width: 1110px;
  width: 100%;
  position: relative;

  .btnBox {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      font-size: 30px;
      color: $mid-grey-medium;
    }

    .date {
      display: flex;
      align-items: center;
      font-size: 11px;
      color: #343434;

      .label {
        max-width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 4px;
      }
    }

    &.left {
      position: absolute;
      left: 0;
    }

    &.right {
      position: absolute;
      right: 0;
    }
  }

  .text {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #2b4670;
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    flex-direction: column;

    .title {
      font-weight: bold;
      color: #2b4670;
      padding-right: 8px;
    }

    .date {
      font-weight: 600;
      color: #5666a6;
    }
  }
}
