@import 'assets/theme';

.columnWrapper {
  display: flex;

  .columnStyles {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #414449;
    flex: 1 5;
  }

  .avatar {
    @extend .columnStyles;
    justify-content: flex-start;
    min-width: 120px;
    max-width: 13%;
    padding-left: 20px;
  }

  .fullName {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 17%;
    padding-right: 8px;
  }

  .assessmentSent {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 21%;
    padding-right: 8px;
  }

  .assessmentComplete {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 21%;
    padding-right: 8px;
  }

  .score {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 23%;
    padding-right: 8px;
  }

  .view {
    @extend .columnStyles;
    min-width: 60px;
    max-width: 4%;
    padding-right: 8px;
    justify-content: flex-end;
  }

  .active {
    font-weight: 600;
    color: $colorBlue1;
  }
}
