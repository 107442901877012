@import 'assets/theme';

.back {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  cursor: pointer;

  .backBtn {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    line-height: 18px;
    color: $colorBlue1;

    .icon {
      font-size: 32px;
      width: 30px;
    }
  }
}
