@import 'assets/theme';

.container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  padding: 24px 16px 0;
  @include rowGap(24px);

  .header {
    display: flex;
    align-items: center;

    .backBtn {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      line-height: 30px;
      color: $colorBlue1;

      .icon {
        font-size: 30px;
        width: 20px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.body {
  display: flex;
  flex-grow: 1;
  @include columnGap(12px);
  margin-bottom: 120px;
}

.submenuContainer {
  background-color: $white;
  box-shadow: 0 6px 18px 0 #0000000f;
  border-radius: 4px;
  height: 100%;

  .submenu {
    border-right: 4px solid transparent;
    border-bottom: 1px solid #ebeff2;
    padding: 24px;
    min-width: 300px;
    display: flex;
    @include columnGap(8px);

    .iconContainer {
      font-size: 20px;
      color: #90a0b7;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $colorTextualBlack;
      padding-bottom: 4px;
    }

    .subname {
      font-size: 12px;
      line-height: 16px;
      color: #90a0b7;
    }

    &.active {
      border-right-color: $colorBlue1;

      .iconContainer {
        color: $colorBlue1;
      }

      .name {
        color: $colorBlue1;
      }

      .subname {
        color: #343434;
      }
    }
  }
}

.content {
  padding: 20px;
  background-color: $white;
  box-shadow: 0px 6px 18px 0px #0000000f;
  border-radius: 4px;

  flex-grow: 1;
}
