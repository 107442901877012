.container {
  display: flex;

  .switchToggle {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 40px;
    height: 20px;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

    input {
      display: none;

      &:checked + span {
        left: 50%;
        background-color: #25be69;
      }
    }

    span {
      position: absolute;
      border-radius: 50%;
      background-color: #dfdfdf;
      transition: 0.1s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      width: 26px;
      height: 26px;
      top: -3px;
      left: -3px;
    }
  }
}
