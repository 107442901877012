@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(40px);
}

.rulesContainer {
  display: flex;
  flex-direction: column;
  @include rowGap(8px);

  padding: 12px 0;
}

.button {
  width: 240px;
  align-self: flex-start;
}
