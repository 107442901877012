@import 'assets/theme';

.container {
  .contentContainer {
    display: flex;
    padding: 24px 0px;
    flex-direction: column;
    @include rowGap(10px);

    .title {
      font-size: 18px;
      font-weight: 600;
    }
    .message {
      font-size: 16px;
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    @include columnGap(8px);
  }
}
