@import 'assets/theme';

.fieldLabel {
  font-size: 11px;
  font-weight: 600;
  color: $colorTextualGray;
}

.phoneInputWrapper {
  display: flex;

  .loadingWrapper {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .loadingBox {
      position: absolute;
      left: -26px;
    }
  }
}

.mobileNumberInput {
  width: 100% !important;
  height: 31px !important;
  padding-left: 52px !important;
  border: 1px solid $white !important;
  border-bottom: 1px solid $colorTextualGray !important;
  border-radius: 0 !important;
  outline: none !important;
  color: #343434 !important;
}

.countrySelect {
  background-color: transparent !important;
  border: 1px solid $white !important;
  border-bottom: 1px solid $colorTextualGray !important;
  border-right: none !important;
  border-radius: 8px 0 0 0 !important;

  div,
  div:hover {
    background-color: transparent !important;
  }
}

.countryDropdown {
  max-height: 100px;

  li {
    margin-top: 0 !important;
  }
}

.redBorderBottom {
  border-bottom: 1px solid #ff4d4f !important;
}
