@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .questionWrapper {
    padding: 8px 16px;

    .title {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: $colorTextualBlack;
    }

    .radioContainer {
      padding: 16px;
    }

    .sendError {
      margin-left: 48px;
    }

    .checkboxContainer {
      padding: 16px 16px 8px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .checkbox {
        appearance: none;
        margin: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border: 2px solid #d5d5d5;
        border-radius: 4px;
        color: #d5d5d5;
        cursor: pointer;
        outline: none;
        transition: all 0.1s linear;

        &:after {
          content: '\2713';
          display: none;
          justify-content: center;
          align-items: center;
          width: 16px;
        }

        &:checked {
          font-size: 12px;
          line-height: 16px;
          font-weight: 800;
          color: #fff;
          background-color: $colorBlue1;
          border: 2px solid $colorBlue1;

          &:after {
            display: flex;
          }
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: #2b4670;
          border: 2px solid #2b4670;
        }
      }

      .text {
        display: flex;
        align-items: center;
        margin-left: 16px;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #343434;
        cursor: pointer;
      }
    }

    .inputContainer {
      margin-top: 24px;
      margin-left: 48px;
      margin-right: 16px;
    }

    .sendToError {
      margin-left: 16px;
    }
  }

  .includeNoteContainer {
    padding: 16px;
    position: relative;

    .noteTitle {
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: $colorTextualBlack;
    }

    .textarea {
      width: 100%;
      margin-top: 4px;
      padding: 8px 8px 24px;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434;
      border: 1px solid #c4c4c4;
      border-radius: 4px;
      box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
      resize: none;
    }

    .wordCount {
      position: absolute;
      left: 24px;
      bottom: 24px;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #90a0b7;
    }
  }
}

.button {
  align-self: center;
  margin: 8px 0 16px;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
}
