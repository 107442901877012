@import 'assets/theme';

.container {
  display: block;
  width: 50%;
  padding-left: 36px;
  padding-right: 20px;

  .label {
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorTextualGray;

    .icon {
      margin-left: 8px;
      color: #fd8468;
      border-radius: 50%;
      cursor: pointer;
    }

    .tooltip {
      max-width: 200px;
      border-radius: 8px;
      line-height: 16px;
      font-size: 14px;
    }
  }

  .select {
    margin-bottom: 20px;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    margin-right: 6%;
    margin-bottom: 20px;

    .checkbox {
      appearance: none;
      margin: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      color: #d5d5d5;
      cursor: pointer;
      outline: none;
      transition: all 0.1s linear;

      &:after {
        content: '\2713';
        display: none;
        justify-content: center;
        align-items: center;
        width: 16px;
      }

      &:checked {
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
        color: $white;
        background-color: $colorBlue1;
        border: 2px solid $colorBlue1;

        &:after {
          display: flex;
        }

        + .checkboxLabel {
          color: $colorBlue1;
        }
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #2b4670;
        border: 2px solid #2b4670;
      }
    }

    .checkboxLabel {
      display: flex;
      align-items: center;
      flex: 1;
      padding-left: $standard-padding * 1.5;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #323c47;
      cursor: pointer;
    }
  }

  .flatFeeContainer {
    display: flex;
    margin-top: 28px;
    margin-bottom: 20px;

    .moneySymbol {
      margin-top: 4px;
    }

    .input {
      margin-left: 4px;

      input {
        background-color: transparent;
      }
    }
  }
}
