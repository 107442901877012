@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .label {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    text-align: left;
    color: $colorTextualGray;
    margin-bottom: 8px;
  }

  .deliveryTypeOptions {
    display: flex;
    align-items: center;
    @include columnGap(32px);

    i {
      font-size: 23px;

      &.active {
        color: #27ae60;
      }

      &.inactive {
        color: $mid-grey;
      }
    }
  }
}
