@import 'assets/theme/index';

.wrapperBox {
  padding: 16px;
  background-color: #fff;
  border-radius: 6px;
  width: 450px;

  .label {
    display: flex;
    justify-content: center;
    font-weight: 600;
  }

  .btnWrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: 16px;

    .yesBtn {
      cursor: pointer;
      display: flex;
      flex: 1;
      justify-content: center;
      padding: 8px;
      box-sizing: border-box;
      color: #fff;
      line-height: 18px;
      outline: none;
      align-self: center;
      width: 100%;
      max-width: 100px;
      background-color: #38b87c;
      border: 1px solid #38b87c;
      font-size: 14px;

      &:active {
        background-color: #38b87c;
      }
    }

    .noBtn {
      @extend .yesBtn;

      background-color: #696f73;
      border: 1px solid #696f73;
      font-size: 14px;

      &:active {
        background-color: #696f73;
      }
    }
  }
}
