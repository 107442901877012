@import 'assets/theme';

.widgetGroup {
  padding: 16px 12px;
  align-items: center;

  &:not(:first-child) {
    border-top: 1px solid #ebeff2;
  }
}

.widgetWrapper {
  padding: 16px 12px;
}

.hidden {
  display: none;
}

.messageContainer {
  border-top: 1px solid #ebeff2;
  padding: 20px;
  display: flex;
  align-items: center;
  @include columnGap(20px);

  .rightSection {
    .message {
      font-size: 16px;
      font-weight: 600;
    }

    .backButton {
      display: flex;
      color: $colorBlue1;
      align-items: center;
      margin: 12px;
      cursor: pointer;
      @include columnGap(4px);

      .editIcon {
        font-size: 14px;
      }

      .backMessage {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
