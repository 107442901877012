@import 'assets/theme';

.container {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: $colorTextualBlack;
  }

  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #323c47;
    opacity: 0.5;
  }

  .popular {
    margin-top: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorTextualGray;
    text-transform: uppercase;
  }

  .optionListWrapper {
    max-height: 400px;
    margin-top: 6px;
    padding-right: 4px;
    overflow: hidden scroll;
    &::-webkit-scrollbar {
      width: 21px;
    }

    &::-webkit-scrollbar-track {
      background: #f0eeee;
      border-left: 9px solid #fff;
      border-right: 9px solid #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #c4cae1;
      border-radius: 9px;
      border-left: 6px solid #fff;
      border-right: 6px solid #fff;
    }

    .option {
      margin: 16px 0px;

      .hiddenCheckbox {
        display: none;
      }

      label {
        cursor: pointer;
      }

      .optionTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #343434;
      }

      .description {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.2px;
        color: #343434;
      }

      .isSelected {
        color: $mhh-yellow;
      }
    }
  }
}

.searchBar {
  display: flex;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;

  .input {
    display: flex;
    flex: 1;
    margin-right: 16px;
    border: 1px solid #dadbde;
    border-radius: 4px;
    padding: 6px 12px 6px 36px;
    width: 100%;
    color: $colorTextualBlack;

    &:focus {
      border: 1px solid $mhh-yellow;
    }

    &::placeholder {
      color: $colorTextualGray;
    }
  }

  .searchBarIcon {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 10px;

    i {
      color: $colorTextualGray;
      font-size: 20px;
    }
  }
}

.noItems {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: $colorTextualGray;
    font-size: 20px;
    margin-right: 6px;
  }
}
