@import 'assets/theme';

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .notificationBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #c4c4c4;
    color: #c4c4c4;
    background-color: #fff;
    transition: 0.2s ease-in-out;

    &:hover {
      border: 1px solid $mhh-dark-blue;
      color: $mhh-dark-blue;
      background-color: #c8e4f4;
    }

    .bubbleWrapper {
      position: relative;

      .bubble {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        background: $mhh-dark-blue;
        font-size: 10px;
        font-weight: bold;
        color: #fff;
        border-radius: 50%;
        border: 1px solid #fff;
        right: -13px;
        bottom: 7px;
      }
    }
  }

  .notificationBtnActive {
    @extend .notificationBtn;
    border: 1px solid $mhh-dark-blue;
    color: $mhh-dark-blue;
    background-color: #c8e4f4;
  }

  .notificationBtnAvailable {
    @extend .notificationBtn;
    border: 1px solid $mhh-dark-blue;
    color: $main-red;
  }
}
