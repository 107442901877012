@import 'assets/theme';

.modal {
  z-index: 100;
  max-width: 1000px;
  width: 90%;

  @media screen and (max-width: $medium-screen-min-width) {
    max-width: 700px;
  }

  @media screen and (max-width: $medium-small-screen-min-width) {
    max-width: 100%;
  }
}

.modalTitle {
  font-weight: 600;
  font-size: 20px;
}

.loading {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  .tip {
    padding: 20px;
  }

  &.init {
    .inputLabel {
      height: 230px;
      background: #f9f9fa;
      border: 2px dashed #ecedef;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #949aa2;
      text-decoration: underline;
      cursor: pointer;

      .uploadIcon {
        i {
          font-size: 58px;
          margin: 8px;
        }
        line-height: 1;
        color: #c7c9cd;
        text-decoration: none;
      }
    }

    .preview {
      display: none;
    }
  }

  &.singleFile {
    .preview {
      height: unset;

      .items {
        .item {
          &.edittor {
            width: 100%;
            min-width: unset;
            border: none;
            overflow: visible;
            position: static;
            float: none;
          }
        }
      }
    }
  }

  .inputLabel {
    padding: 20px;
    font-size: 16px;

    &:hover {
      opacity: 0.7;
    }
  }

  .maxImageZeroLabel {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview {
    position: relative;
    width: 100%;
    min-height: 400px;

    .items {
      &.fullWidth {
        width: 100%;
        display: flex;

        .item {
          margin: 10px;
        }
      }

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: lightgray;
      }

      .item {
        display: inline-block;
        margin-bottom: 20px;
        margin-right: 10px;
        max-width: 100%;

        @media screen and (max-width: $medium-screen-min-width) {
          transform: scale(0.5);
        }

        @media screen and (max-width: $medium-small-screen-min-width) {
          transform: none;
        }

        .itemEdittor {
          pointer-events: none;
        }
      }
    }

    .closeEditor {
      position: absolute;
      right: 0;
      top: -20px;
      z-index: 11;
      width: 40px;
      height: 40px;
      background-color: white;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: gray;
      box-shadow: 1px 1px 6px rgb(176, 176, 176);
      cursor: pointer;

      @media screen and (max-width: $medium-screen-min-width) {
        position: fixed;
        top: 10px;
        right: 10px;
      }

      &:hover {
        color: darkgray;
      }
    }

    .edittor {
      animation: floatFromLeft 0.2s;
      transform: none !important;
      float: right;
      width: 40%;
      min-width: 420px;
      position: absolute;
      text-align: center;
      z-index: 10;
      top: 0;
      right: 0;
      max-height: 100%;
      overflow-y: auto;
      padding: 20px;
      border-left: solid 2px rgb(237, 237, 237);
      background-color: white;

      @media screen and (max-width: $medium-screen-min-width) {
        width: 100vw;
        height: 100vh;
        max-width: unset !important;
        min-width: unset !important;
        padding: 60px 20px;
        position: fixed;
        text-align: center;
        top: 0;
        left: 0;
        border: none;
      }

      .itemEdittor {
        pointer-events: all !important;
      }

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        background-color: rgb(217, 217, 217);
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(179, 179, 179);
      }
    }
  }

  .footer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 24px;

    .uploadButton {
      padding: 14px 20px;
      min-width: unset;
    }

    @media screen and (max-width: $medium-small-screen-min-width) {
      flex-direction: column;
    }
  }
}

@keyframes floatFromLeft {
  0% {
    transform: translateX(-100px) scale(0.9);
    opacity: 0;
  }

  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
}
