@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  border-top: 1px solid #ebeff2;
  @include rowGap(10px);

  .messageToggleContainer {
    display: flex;
    justify-content: space-between;

    .toggleMessage {
      color: #414449;
      font-size: 16px;

      span {
        font-weight: 700;
      }
    }
  }

  .reminder {
    font-style: italic;
    font-size: 14px;
    color: #426eb1;
  }
}

.sessionLockedContainer {
  display: flex;
  padding: 20px;
  align-items: center;
  border-top: 1px solid #ebeff2;
  @include columnGap(20px);

  .avatar {
    max-height: 88px;
    border-radius: 50%;
  }

  .lockedMessage {
    color: #414449;
    font-size: 14px;

    span {
      font-weight: 700;
      color: #426eb1;
    }
  }
}
