@import 'assets/theme';

.badge {
  display: flex;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  min-width: 90px;
  width: 50%;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;

  .selectedBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 8px 10px;
    border-radius: 8px;
    user-select: none;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    .icon {
      color: $colorBlue1;
    }
    .placeHolder {
      color: $colorTextualGray;
    }
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 7;

    .listHide {
      background: #fff;
      border: 1px solid #eee;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.2s ease;
      position: absolute;
      width: 100%;

      .searchBox {
        display: flex;
        align-items: center;
        padding: 8px 10px;

        .searchIcon {
          position: absolute;
          left: 18px;
          color: #b1b1b1;
          font-size: 24px;
        }

        .searchInput {
          display: flex;
          flex: 1 1;
          font-size: 16px;
          line-height: 16px;
          padding: 8px 16px 8px 40px;
          border-radius: 6px;
          border: 1px solid #eee;
          background-color: #fff;
          outline: none;
          transition: all 0.2s ease;
          max-width: 100%;
        }
      }

      .itemWrapper {
        max-height: 200px;
        overflow: auto;

        .item {
          padding: 8px 10px;
          cursor: pointer;

          &:hover {
            color: rgba(0, 0, 0, 0.85);
            font-weight: 600;
            background-color: #f5f5f5;
            transition: background 0.3s ease;
          }
        }
      }

      .itemSelected {
        padding: 8px 10px;
        cursor: pointer;
        background-color: #e6f7ff;
      }
    }

    .listShow {
      @extend .listHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }
}
