.modalContainer {
  width: 100%;
  max-width: 500px;
  border-radius: 8px;

  & > div {
    border-radius: 8px;
    box-shadow: none;
  }
}

.modalNoOptionContainer {
  @extend .modalContainer;
  max-width: 448px;
}

.titleContainer {
  display: flex;
  margin-top: 24px;

  .iconWrapper {
    display: flex;
    margin-right: 12px;

    .icon {
      color: #ff7d45;
      font-size: 32px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #414449;
  }
}

.stageOptions {
  display: flex;
  flex-direction: column;
  padding: 0px 32px 8px 44px;

  .nonStageCanBeMoveTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #414449;
    margin-bottom: 4px;
  }

  .errorMessage {
    color: #ff4d4f;
    margin-left: 4px;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  .deleteButtonNon {
    min-width: 135px;
  }

  .cancelButton {
    margin-right: 16px;
    min-width: 120px;
  }

  .cancelButtonNon {
    @extend .cancelButton;
    min-width: 100px;
  }
}
