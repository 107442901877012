@import 'assets/theme';

.container {
  display: flex;
  flex: 1 1;
  padding-bottom: 16px;

  .connectSetting {
    flex: 1 5 54%;
    display: flex;
    flex-direction: column;
    @include rowGap(9px);

    .connectSettingItem {
      padding-left: 24px;
    }

    .disabledConnectSettingItem {
      padding-left: 24px;
      color: $mid-grey-medium;

      .connectSettingCheckbox {
        .checkbox {
          &:checked {
            background-color: $mid-grey-medium;
            border: 2px solid $mid-grey-medium;
          }
        }
      }
    }

    .connectSettingCheckbox {
      display: flex;
      align-items: center;
      @include columnGap(12px);
      user-select: none;
      cursor: pointer;

      .connectSettingLabel {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        letter-spacing: 0.1px;
      }

      .checkbox {
        appearance: none;
        height: 16px;
        width: 16px;
        background-color: transparent;
        border: 2px solid #d5d5d5;
        border-radius: 4px;
        color: #d5d5d5;
        cursor: pointer;
        outline: none;
        transition: all 0.1s linear;

        &:after {
          content: '\2713';
          display: none;
          width: 10px;
        }

        &:checked {
          font-size: 12px;
          line-height: 12px;
          font-weight: 800;
          color: #fff;
          background-color: $colorBlue1;
          border: 2px solid $colorBlue1;

          &:after {
            display: flex;
          }
        }
      }
    }
  }

  .connectStatus {
    flex: 1 5 46%;
  }
}
