@import 'assets/theme';

.container {
  display: flex;
  column-gap: $standard-padding * 2;
  margin-top: 16px;
}

.iconContainer {
  padding-top: $standard-padding;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  row-gap: $standard-padding * 2;
}

.field {
  display: flex;
  @include columnGap($standard-padding * 2);
  flex-direction: column;

  & > span {
    color: $colorBlue3;
    font-size: 20px;
  }
}

.dateField {
  display: flex;
  align-items: center;

  & > span {
    margin-right: 8px;
  }
}

.timeField {
  display: flex;
  @include columnGap($standard-padding);
  margin-top: 16px;
}

.recurringField {
  display: flex;
  align-items: baseline;
  @include columnGap($standard-padding * 2);

  label {
    align-self: center;
  }
}

.header {
  display: flex;
  @include columnGap($standard-padding * 4);
  align-items: center;

  .title {
    font-size: 20px;
    font-weight: 700;
    color: #323c47;
  }

  .button {
    font-size: 12px;
    font-weight: 600;
  }

  .appointmentNameContainer {
    display: flex;
    flex-direction: column;

    .label {
      font-size: 11px;
      font-weight: 500;
      color: $colorTextualGray;
    }

    .text {
      font-size: 18px;
      font-weight: 600;
      color: #343434;
    }
  }

  .customise {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: $colorBlue1;
    cursor: pointer;
    margin-left: 16px;

    i {
      font-size: 18px;
      margin-left: 4px;
    }
  }
}
