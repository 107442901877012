@import 'assets/theme';

.clickable {
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
  border-bottom: 1px solid #ebeff2;
  color: $colorBlue3;
  min-height: 70px;

  &:hover {
    background: #fbfbfb;
  }

  .id {
    display: flex;
    align-items: center;
    flex: 1;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }

  .providerId {
    display: flex;
    align-items: center;
    flex: 1;
    font-weight: 600;
  }

  .field {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 12px;
  }

  .status {
    display: flex;
    align-items: center;
    flex: 1;
    @include columnGap(8px);

    .statusIconContainer {
      display: flex;
      align-items: center;

      .success {
        font-size: 16px;
        color: #4bc27d;
      }

      .retried {
        font-size: 16px;
        color: $colorBlue1;
      }

      .pending {
        font-size: 16px;
        color: #ff9d73;
      }

      .failed {
        font-size: 16px;
        color: #eb4545;
      }
    }

    .retry {
      font-weight: 600;
      color: $colorBlue1;
      text-decoration: underline;
      cursor: pointer;
    }

    .disabled {
      font-weight: 600;
      color: $colorTextualGray;
      text-decoration: underline;
      cursor: not-allowed;
    }
  }

  .action {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;

    .icon {
      border: 2px solid #6575b1;
      color: #6575b1;
      padding: 6px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
