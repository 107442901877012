@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: $standard-padding * 3;
  background-color: #fff;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  .badgeWrapper {
    display: flex;
    padding-bottom: 16px;

    .badge {
      padding: 4px 16px;
      margin: 0;
      background: #f2c94c;
      border-radius: 4px;
      color: #343434;
      font-size: 12px;
    }

    .newBadge {
      @extend .badge;
      color: #fff;
      background: #219653;
    }
  }

  .content {
    display: flex;

    .leftWrapper {
      padding-right: 32px;
      border-right: 2px solid #ebeff2;
      flex: 1 2 50%;

      .titleBox {
        display: flex;
        padding-bottom: 16px;

        .titleInput {
          margin-top: 20px;
          font-weight: 600;
          font-size: 20px;
        }

        .toggleSwitchContainer {
          border-bottom: 1px solid #c4c4c4;
        }
      }

      .descBox {
        padding-bottom: 40px;

        .descInput {
          margin-top: 20px;
        }
      }
    }

    .rightWrapper {
      padding-left: 32px;
      flex: 1 2 50%;

      .buttonWrapper {
        display: flex;
        justify-content: space-between;
        padding-top: 16px;

        .saveBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 16px;
          min-width: 150px;
          border: 1px solid $colorBlue1;
          box-sizing: border-box;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          text-align: center;
          color: $white;
          background: $colorBlue1;
          box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
          border-radius: 4px;
          cursor: pointer;

          .icon {
            padding-right: 6px;
          }
        }

        .saveBtnFixed {
          position: fixed;
          bottom: 40px;
          right: 38px;
          border-radius: 55px !important;
          height: 55px;

          @media only screen and (max-height: 740px) {
            bottom: 11px;
            right: 150px;
          }
        }

        .saveBtnDisable {
          @extend .saveBtn;
          opacity: 0.7;
          cursor: not-allowed;
        }

        .addQBtn {
          @extend .saveBtn;
          border: 1px solid $mhh-yellow;
          color: $mhh-dark-blue;
          background: #fff;
          box-shadow: none;

          .icon {
            padding-right: 6px;
          }
        }

        .addQBtnDisable {
          @extend .addQBtn;
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
}
