@import 'assets/theme';

.container {
  display: flex;
  background: #f5f6ff;
  border: 1px solid #dadbde;
  box-sizing: border-box;
  border-radius: 4px;
  color: #414449;
  padding: 10px 12px;
  align-items: center;

  .widgetItem {
    display: flex;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }

  .activeDot {
    width: 12px;
    height: 12px;
    background-color: #4bc27d;
    border-radius: 50%;
    margin-left: 8px;
  }
}
