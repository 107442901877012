.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 8px 12px;
  cursor: move;
  border: 0.5px dashed #0085bd;
  border-radius: 4px;
  height: 100%;
  background: #f5f6ff;
  overflow: hidden;
  line-height: 22px;

  .title {
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #343434;
  }

  .input {
    border: none;
    font-weight: 600;
    font-size: 12px;
    padding: 0;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #343434;
  }
}

.previewContainer {
  @extend .container;
  border: 0.5px dashed transparent;
  cursor: auto;
}
