@import 'assets/theme';

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #414449;
  margin-bottom: 16px;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 24px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
}
