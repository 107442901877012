@import 'assets/theme';

.backBtn {
  border: 0px;
  background: none;
  color: $colorBlue1;

  .backBtnIcon {
    height: 20px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.pickAssessmentWrapper {
  padding: 16px;
  @include rowGap(20px);
}

.clientItem {
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.submitBtn {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}
