@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  width: 40%;

  .header {
    display: flex;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #949aa2;
    }

    .editBtn {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $colorBlue1;
      padding: 8px 16px;

      .icon {
        margin-right: 4px;
        font-size: 16px;
      }
    }
  }
}
