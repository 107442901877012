@import 'assets/theme';

.modalContainer {
  .header {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #202225;
  }
}

.container {
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: $mhh-light-black;
  }
  .selectedCount {
    color: $mhh-yellow;
  }

  .teacherAssignmentWrapper {
    margin-top: 50px;

    .description {
      margin-bottom: 30px;
      .main {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
      }
    }

    .teacherSelect {
      max-width: 300px;
    }
  }

  .actions {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;

    .proceedBtn {
      max-width: 110px;
      margin: initial;
    }
  }

  .profileBadgeClassName {
    min-width: 90%;
  }
}
