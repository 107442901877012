@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding);

  // border-radius: $standard-padding;
  // padding: $standard-padding;
  // padding-bottom: $standard-padding + 17;
  // background-color: #fcf7f7;
  // box-shadow: 5px 5px 10px 0px #e7e7e7;
  // min-height: 200px;
  // max-width: 370px;
}

.header {
  display: flex;
  flex-direction: column;

  padding: 6px;
  border: 0.695212px solid #f3f3f3;
  border-radius: 6.95212px;

  &.active {
    border: 1.39042px solid $colorBlue3;
    .date {
      font-weight: 700;
    }
  }

  .date {
    display: flex;
    justify-content: center;
    font-size: 10px;
    font-weight: 400;
    color: #343434;
  }
}

.body {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding);

  .badgeWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .timeButton {
    display: flex;
    flex: 1;
    justify-content: center;
    min-width: auto;
    height: auto;
    padding: $standard-padding 0;
    border: none;
    border-radius: $standard-padding;
    background-color: #d6f3e3;

    font-size: 9px;
    font-weight: 600;
    color: $colorBlue3;

    &.active,
    &:active,
    &:hover,
    &.selected {
      color: #f8f9fd;
      background-color: #25be69;
    }
    &:disabled {
      color: $black;
      background-color: $mid-grey;
    }
  }

  .noAvailability {
    display: flex;
    justify-content: center;
    font-size: 9px;
    font-weight: 600;
    color: $colorBlue3;

    padding: $standard-padding 0;
  }
}
