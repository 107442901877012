@import 'assets/theme';

.coupleInfoContainer {
  display: flex;
  flex-direction: column;
  @include rowGap(40px);
}

.contactDetailsWrapper {
  @extend .coupleInfoContainer;
  flex-direction: row;
  min-width: 400px;
  @include columnGap(20%);
}
