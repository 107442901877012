@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .tagSelectContainer {
    display: flex;
    flex-direction: column;
    color: #414449;
    margin-bottom: 8px;

    .label {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
    }

    .selectContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      max-width: 300px;
    }

    .tagSearchInputWrapper {
      display: flex;
      align-items: center;
      font-size: 16px;

      .tagSearchInput {
        display: flex;
        flex: 1;
        padding: 8px 32px 8px 2px;
        border: none;
        border-bottom: 1px solid #000;
      }

      .arrowIconWrapper {
        display: flex;
        align-items: center;
        position: relative;
        user-select: none;
        cursor: pointer;

        .arrowIcon {
          position: absolute;
          right: 0;
        }
      }
    }

    .tagListWrapper {
      width: 100%;

      position: absolute;
      border-radius: 4px;
      border: 1px solid #eee;
      box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
      transition: transform 0.2s ease;
      background: #fff;
      z-index: 1;
      max-height: 223px;
      overflow: auto;
      font-size: 16px;

      &::-webkit-scrollbar {
        display: none;
      }

      .tagValue {
        padding: 4px 8px;
        cursor: pointer;

        &:hover {
          background-color: $colorBlue3;
          color: #fff;
        }
      }

      .noTagOption {
        display: flex;
        justify-content: center;
        padding: 4px 8px;
      }
    }
  }

  .tagValueWrapper {
    display: flex;
    flex-wrap: wrap;
    @include columnGap(12px);

    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      padding: 6px 8px;
      background: $colorBlue3;
      border-radius: 40px;
      color: #fff;
      margin-bottom: 8px;

      .closeIcon {
        cursor: pointer;
        font-size: 14px;
        margin-left: 6px;
      }
    }
  }
}
