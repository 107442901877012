@import 'assets/theme';

.container {
  display: flex;
  @include columnGap(8px);

  .customField {
    display: flex;
    max-width: 100px;
    margin-top: 18px;
  }
}

.customText {
  font-size: 14px;
  font-weight: 600;
  color: $colorTextualGray;
}

.selectClass {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.labelClass {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 11px;
  color: $colorTextualGray;
}

:export {
  greyColor: $colorLineGray;
}
