@import 'assets/theme';

.radio {
  display: flex;
  flex-wrap: wrap;
  @include columnGap($standard-padding * 2);

  &.vertical {
    flex-direction: column;

    .button {
      width: 100%;
    }
  }
  &.nowrap {
    flex-wrap: nowrap;
  }
}

.default {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
  margin-top: 8px;

  &.error {
    border: 1px solid $main-red;
    margin-bottom: 0;
  }

  &.noSpacing {
    margin-bottom: 0;
  }

  input {
    &:checked + label,
    &:active + label {
      color: $colorBlue1 !important;
      font-weight: 600;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  label {
    background-color: transparent;
    color: #343434;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    transition: all 0.2s cubic-bezier(0.35, 0.1, 0.25, 1);
    margin-left: 16px;
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: center;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: $medium-screen-min-width - 1) {
    label {
      flex: 1 0 auto;
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: $standard-padding;
  margin-bottom: $standard-padding;

  @media only screen and (max-width: $medium-screen-min-width) {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    margin-right: 24px;

    @media only screen and (max-width: $medium-screen-min-width) {
      margin-right: 16px;
    }
  }

  &.error {
    border: 1px solid $main-red;
    border-radius: $standard-padding * 2;
    margin-bottom: 0;
  }

  &.noSpacing {
    margin-bottom: 0;
  }

  input {
    display: none;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;

    &:checked + label {
      background-color: $mhh-dark-blue;
      color: $white;

      &:hover {
        opacity: 0.8;
      }
    }

    &:active + label {
      color: $white;
      background-color: #{$mhh-dark-blue}cc;
    }
  }

  label {
    background-color: #{$mhh-yellow}30;
    color: #343434;
    font-size: 12px;
    min-width: 72px;
    max-width: 480px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    padding: 10px 12px;
    border-radius: 24px;
    transition: all 0.2s cubic-bezier(0.35, 0.1, 0.25, 1);
    margin-right: 40px;
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;

    span {
      font-size: 11px;
      font-weight: 700;
      padding: 3px;
      color: $white;
      background-color: $mhh-yellow;
      border-radius: 100%;
      margin-left: 8px;
    }

    &:hover {
      cursor: pointer;
      background-color: $mhh-light-yellow;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.label {
  display: flex;
  flex: 1;
  font-size: 14px;
  line-height: 18px;
  color: $colorTextualBlack;
  margin: 8px 0;
  cursor: pointer;

  .subLabel {
    display: flex;
    align-items: center;
    margin-left: 4px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #343434;
  }

  .labelWord {
    display: flex;
    align-items: center;
    min-height: 22px;
  }

  .labelActive {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $mhh-dark-blue;
  }

  .labelWordDisable {
    @extend .labelWord;
    opacity: 0.7;
  }
}

.disabled {
  cursor: not-allowed;
}

.radioBtn {
  display: flex;
  align-items: center;
  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border: 1px solid #414449;
    border-radius: 50%;
    margin-right: 16px;

    &:before {
      content: '';
      width: 20px;
      min-width: 20px;
      height: 20px;
      border-radius: 50%;
      transition: all 250ms ease;
    }

    &:checked {
      &:before {
        background-color: $mhh-yellow;
        box-shadow: inset 0 0 0 4px $white;
        border: 1px solid $mhh-yellow;
      }
      &:disabled {
        &:before {
          background: darken($white, 25%);
          border-color: darken($white, 25%);
        }
      }
    }

    &:focus {
      outline: none;
      border-color: $colorBlue1;
    }

    &:disabled {
      cursor: not-allowed;
      border-color: darken($white, 25%);
    }
  }
}

.tooltip {
  max-width: 230px;
  border-radius: 8px;
  line-height: 16px;
  font-size: 14px;
  padding: 12px;
  opacity: 0.95 !important;
}

:global(.mhh-assessment-question) {
  .button {
    label {
      background-color: $mhh-light-yellow;
      color: $mhh-light-black;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      .checkedIcon {
        display: none;
      }
    }

    input {
      display: none;

      &:checked + label {
        background-color: $mhh-yellow;
        font-weight: 600;
        color: #1b365d;
        font-size: 16px;
        line-height: 19px;
      }

      &:active + label {
        background-color: $mhh-yellow;
        color: #1b365d;
      }
    }
  }
}
