@import 'assets/theme';

.loading {
  display: flex;
  flex-direction: column;
  padding: 16px;
  @include rowGap(8px);

  .skeleton {
    width: 144px;
    border-radius: 4px;
    height: 20px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  @include rowGap(8px);
  margin: 4px 0;

  .name {
    padding: 8px 12px;
    font-weight: 700;
    font-size: 12px;
    color: #949aa2;
    line-height: 18px;
    text-transform: uppercase;
  }

  .email,
  .mobileNumber,
  .noContactDetails {
    position: relative;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #414449;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background-color: #f5f6ff;
    }

    &:active {
      background-color: #e5e6ef;
    }

    .icon {
      position: absolute;
      font-size: 16px;
      color: $colorBlue3;
    }

    .value {
      margin: 0 28px;
    }

    .iconLeft {
      @extend .icon;
      left: 12px;
    }

    .iconRight {
      @extend .icon;
      right: 12px;
    }
  }

  .communicationPreferenceLabel {
    padding: 8px 12px 0;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    text-transform: uppercase;
    color: #b5b8bd;
  }

  .communicationPreference {
    padding: 8px 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #414449;
  }
}
