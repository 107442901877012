@use 'sass:math';
@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 2);

  padding: $standard-padding * 3;

  .title {
    font-weight: 600;
    font-size: 24px;
  }

  .toggleContainer {
    .label {
      font-size: 10px;
      color: #818e98;
    }

    .toggle > div {
      display: flex;
      flex-direction: column;
      width: 200px;
      margin-top: 8px;
    }
  }

  .profileNameContainer {
    margin-top: 16px;

    .label {
      font-size: 10px;
      color: #818e98;
    }

    .profileName {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .footerContainer {
    display: flex;
    justify-content: space-between;
    .link {
      display: flex;
      align-items: center;
    }

    .freeBusyDisclaimer {
      width: 400px;
    }
  }
}
