@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  .roomsContainer {
    display: flex;
    flex-direction: column;
    @include rowGap(24px);

    .loading {
      display: flex;
      min-height: 250px;
      align-items: center;
      justify-content: center;
    }
  }
}

.roomContainer {
  display: flex;
  @include columnGap(20px);

  .infoContainer {
    flex: 1;
  }

  .nameAndOccupancyContainer {
    display: flex;
    @include columnGap(24px);
    align-items: center;
    padding-bottom: 8px;

    .name {
      font-size: 18px;
      font-weight: 600;
    }

    .occupancy {
      display: flex;
      align-items: center;
      columns: 4px;

      span {
        font-size: 16px;
      }
    }
  }

  .weekdayPillsContainer {
    display: flex;
    align-items: center;
    @include columnGap(48px);

    .editButton {
      border: none;
      background-color: transparent;

      display: flex;
      align-items: center;
      @include columnGap(12px);

      span {
        color: $colorBlue1;
        font-size: 30px;
      }

      &:active {
        background-color: #f8f9fd;
      }
    }
  }
}

.buttonContainer {
  display: flex;
  padding-top: 40px;

  .addButton {
    min-width: 200px;
  }
}
