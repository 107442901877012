@import 'assets/theme';

$maxWidthContent: 1100px;

.containerLoading {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  @include rowGap(12px);

  .paginationLoading {
    display: flex;
    justify-content: space-between;

    .perPageLoading {
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .showLoading {
      max-width: 250px;
      width: 100%;
      height: 25px;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .listContainer {
    display: flex;
    flex-direction: column;
    z-index: 1;
    padding-bottom: 8px;
  }

  .header {
    background: #f5f6ff;
    border-top: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;

    .actionHeader {
      display: flex;
      background-color: #f5f6ff;
    }
  }

  .loadingWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include rowGap(8px);
    padding: 16px 0;

    .loading {
      width: 100%;
      border-radius: 4px;
      height: 20px;
    }
  }

  .listItem {
    border-bottom: 0.5px solid #ecedef;
    align-items: center;
    height: 100%;

    .dateJoined {
      font-size: 14px;
      font-weight: 400;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .noValue {
      display: flex;
      padding: 0 8px;
      justify-content: center;
    }

    .stage {
      display: flex;

      .stagePill {
        display: flex;
        align-items: center;
        text-align: center;
        background: $colorBlue3;
        border-radius: 50px;
        color: #fff;
        padding: 6px 8px;
        word-break: normal;
        overflow-wrap: anywhere;
      }
    }

    .arrowWrapper {
      display: flex;
      user-select: none;
      background-color: #fff;
    }

    .icon {
      border: 2px solid $colorBlue3;
      color: $colorBlue3;
      padding: 4px;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .noMemberFoundContainer {
    display: flex;
    padding: 24px 16px;
    border-bottom: 0.5px solid #ecedef;
    justify-content: center;
  }

  .paginationWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0 16px;

    .recordPerPageWrapper {
      display: flex;
      align-items: center;
      color: #64748b;

      .label {
        margin-left: 4px;
      }
    }
  }
}
