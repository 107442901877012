@import '../../../../assets/theme/index';

.container {
  display: flex;
  align-items: center;
  padding: $standard-padding * 2 0;
  justify-content: space-between;

  .leftSection {
    display: flex;
    align-items: flex-end;

    .titleWrapper {
      display: flex;
      flex-direction: column;
      padding-right: 24px;
      .title {
        display: flex;
        flex-direction: row;
        font-weight: 600;
        font-size: 22px;
        letter-spacing: 0.01em;
        color: $colorTextualBlack;

        .videoBtn {
          display: flex;
          align-items: center;
          margin-left: 32px;
          font-size: 16px;
          color: $mhh-yellow;
          cursor: pointer;

          .label {
            text-decoration: underline;
          }

          .icon {
            text-decoration: unset;
            font-size: 32px;
            margin-right: 12px;
          }
        }
      }

      .backBtnWrapper {
        display: flex;
        padding-top: 8px;

        .backBtn {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          line-height: 30px;
          color: $mhh-dark-blue;

          .icon {
            font-size: 30px;
            width: 20px;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .rightSection {
    display: flex;
    flex-direction: row;
    flex: 30%;
    justify-content: flex-end;
    align-items: center;
    .updateLabel {
      font-size: 12px;
      color: #7c7c7e;
      margin-right: 16px;
    }
    .saveBtnWrapper {
      display: flex;
      align-items: center;
      border: 1px solid $colorBlue1;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 13px;
      text-align: center;
      letter-spacing: 0.01em;
      color: $white;
      background: $colorBlue1;
      box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
      border-radius: 4px;
      cursor: pointer;
      padding: 10px 32px;
      user-select: none;
    }
  }
}
