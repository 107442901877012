@import 'assets/theme';

.container {
  min-width: 720px;
  background: #fff;
  box-shadow: 0 2px 16px #0000001a;
  border-radius: 8px;
  font-weight: 600;

  .header {
    padding: 16px 24px;
    border-bottom: 1px solid #ebeff2;

    .title {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 28px;
      color: $colorTextualBlack;
    }

    .description {
      font-size: 13px;
      letter-spacing: 0.1px;
      line-height: 20px;
      color: #323c4780;
    }
  }

  .form {
    padding-top: 24px;
  }

  .photoList {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .photoListItem {
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 4px;

      .removePhotoBtn {
        color: #f15944;
        border: none;
        background: none;
        margin-left: 28px;

        &:hover {
          opacity: 0.8;
        }
      }

      img {
        height: 100px;
        width: 100px;
        object-fit: cover;
        border-radius: 8px;
        border: dashed 1px $colorBlue1;

        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
  }

  .bioContainer {
    position: relative;

    .bioInput {
      width: 100%;
      margin-top: 12px;
      padding: 16px 16px 32px;
      resize: none;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434e0;
      border: 1px solid #c4c4c4;
      box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
    }

    .characterCount {
      position: absolute;
      left: 1px;
      bottom: 8px;
      border-radius: 16px;
      font-size: 12px;
      color: #90a0b7;
      padding: 6px 16px;
      background-color: $white;
      width: calc(100% - 20px);
    }
  }

  .section {
    padding: 0 24px 48px;

    .toggle {
      .toggleSwitch {
        display: flex;
        flex-direction: column;
        width: 200px;
      }
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 4px;
    color: $colorTextualBlack;
  }

  .description {
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    color: #949aa2;
  }

  .uploadButton {
    background-color: transparent;
    border: solid 2px $colorBlue1;
    padding: 8px 16px;
    outline: none;
    font-size: 16px;
    border-radius: 8px;
    width: fit-content;
    color: $colorBlue1;
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;

    i {
      margin-right: 10px;
    }
  }

  .deliveryCheckbox {
    display: flex;

    .deliveryDetail {
      flex: 1;

      &.disable {
        .checkBoxExtra {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      .checkBoxLabel {
        color: $colorBlue1;
      }
      margin-bottom: 20px;
    }

    .locationInputs {
      display: flex;
      gap: 16px;
      align-items: flex-end;

      .locationSearch {
        flex: 1;
      }

      .locationName {
        flex: 1;
      }
    }
  }

  .helmInput {
    padding-left: 16px !important;
  }

  .label {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding-left: 16px;
    color: $colorTextualGray;
    margin-top: 20px;
  }

  .worksDetail {
    width: 100%;
    max-width: 530px;

    @media (max-width: $medium-small-screen-min-width) {
      width: 100%;
    }

    .row {
      display: flex;
      align-items: center;
      gap: 2px;
      margin-bottom: 32px;

      .checkboxDetail {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .col1 {
          width: 180px;
          color: $colorTextualBlack;
          font-weight: 400;
          font-size: 16px;
        }

        .col2 {
          flex: 1;
          min-width: 200px;

          .label {
            margin-top: 0;
          }
        }
      }
    }

    .checkBoxRow {
      @extend .row;
      margin-top: -16px;
    }
  }

  .map {
    margin-top: 8px;
    width: 342px;
    height: 224px;
    background-color: lightgray;
    border-radius: 10px;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 32px;

    .saveButton {
      display: flex;
      justify-content: center;
      box-shadow: none;
    }
  }
}

:export {
  greyColor: $colorLineGray;
  blueColor: $colorBlue3;
  whiteColor: $white;
  removeColor: $main-red;
}
