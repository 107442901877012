@import 'assets/theme';

.button {
  padding: 8px 16px;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  transition: all 0.1s linear;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.clinician-primary {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 16px 32px;
  border: 1px solid $colorBlue1;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: $white;
  background: $colorBlue1;
  box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
  }
}

.primary {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  color: $mhh-dark-blue;
  line-height: 18px;
  outline: none;
  align-self: center;
  margin: 16px 0;
  width: 100%;
  background-color: $mhh-yellow;
  border: 1px solid $mhh-yellow;
  font-size: 14px;

  &:active {
    background-color: $mhh-yellow;
    opacity: 0.9;
  }
}

.secondary {
  background-color: transparent;
  border: 1px solid $mhh-yellow;
  color: $mhh-dark-blue;
  font-weight: 400;

  &:active {
    background-color: #f8f9fd;
  }
}

.danger {
  background-color: #ff3434;
  color: $white;

  &:active {
    background-color: #ff8080;
  }
}
