@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  .headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }

    .saveBtn {
      box-shadow: none;
    }
  }

  .listHeader {
    background: #f5f6ff;
    border-top: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;
    padding: 16px 0;
  }

  .loadingWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include rowGap(8px);

    .loading {
      width: 100%;
      border-radius: 4px;
      padding: 0 16px;
      height: 20px;
    }
  }

  .listItem {
    padding: 24px 0;
    border-bottom: 0.5px solid #ecedef;
    align-items: center;

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .noValue {
      display: flex;
      padding: 0 8px;
      justify-content: center;
    }

    .invitedCard {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 19px;
      color: $colorBlue3;
    }
  }

  .noMemberFoundContainer {
    display: flex;
    padding: 24px 16px;
    border-bottom: 0.5px solid #ecedef;
    justify-content: center;
  }
}
