.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .error {
    color: #ff1900;
    font-size: 12px;
    padding-top: 4px;
  }
}
