@import 'assets/theme';

.content {
  height: 25px;
}

.title {
  color: $colorTextualGray;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.selectedContainer {
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
  padding-left: 5px;
  height: 29px;
  display: flex;
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  border-bottom: solid 1px $colorLineGray;
  align-items: flex-end;

  .controller {
    color: #1941da;
  }
}

.optionsContainer {
  top: 1px;
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 4px;
  background: $white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.18);
  display: flex;
  flex: 1 1;
  z-index: 1;

  .leftContainer {
    width: 51%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0px 10px 3px;
  }

  .rightContainer {
    border-left: solid 1px #c4c4c4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0px 30px 6px;
  }

  .optionItem {
    display: flex;
    user-select: none;
    cursor: pointer;
  }

  .optionLabel {
    margin-left: 6px;
    display: flex;
    flex-direction: column;
  }

  .mainLabel {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #343434;
  }

  .optionSubLabel {
    font-style: italic;
    font-weight: normal;
    font-size: 8px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorBlue3;
  }

  .checkbox {
    appearance: none;
    margin: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 2px solid #d5d5d5;
    border-radius: 4px;
    color: #d5d5d5;
    cursor: pointer;
    outline: none;
    transition: all 0.1s linear;

    &:after {
      content: '\2713';
      display: none;
      justify-content: center;
      align-items: center;
      width: 10px;
    }

    &:checked {
      font-size: 12px;
      line-height: 12px;
      font-weight: 800;
      color: #fff;
      background-color: $colorBlue1;
      border: 2px solid $colorBlue1;

      &:after {
        display: flex;
      }

      ~ .optionLabel .mainLabel {
        color: $colorBlue1;
      }
    }
  }
}
