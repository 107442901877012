@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $standard-padding * 2;
  margin-bottom: $standard-padding * 3;
  border-bottom: 1px solid #dce2f4;
  user-select: none;
  max-width: 1110px;
  width: 100%;
  position: relative;
}

.goBack {
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $colorBlue1;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  .icon {
    font-size: 20px;
  }
}

.info {
  display: flex;
  justify-content: center;

  .names {
    margin-left: 20px;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.02em;
  }
}
