.container {
  width: 840px;
  position: relative;
  transition: all 0.2s ease;
  min-height: 500px;
}

.resizeWrapper {
  border: 2px solid #fff;
  overflow: hidden;
}

.canDropResizeWrapper {
  border: 2px dashed #95a0ff;
  overflow: hidden;
}

.widgetWrapper {
  height: max-content;
}
