.container {
  .widgetThumb {
    display: flex;
    align-items: center;
    background: #f5f6ff;
    border: 1px solid #dadbde;
    box-sizing: border-box;
    border-radius: 4px;
    color: #414449;
    padding: 10px 12px;
    transition: all 0.2s ease;

    &:hover {
      background: #fbfbfb;
      border: 1px solid #e6e9f2;
      box-sizing: border-box;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .inputHeadingWidget {
    @extend .widgetThumb;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }

  .inputBodyWidget {
    @extend .widgetThumb;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  .inputNotesWidget {
    @extend .widgetThumb;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
  }
}
