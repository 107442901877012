@import 'assets/theme';

.day-time-selection-time-picker {
  align-items: flex-end;
  height: 26px !important;
  margin: 0 !important;

  .ant-picker-input {
    border-bottom: 1px solid $colorLineGray;

    input {
      text-align: left;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #323c47;
      opacity: 1;
    }

    .ant-picker-suffix {
      display: block;
      color: #aeaeae;
    }
  }
}

.ant-picker-focused .ant-picker-input .ant-picker-suffix {
  color: $colorBlue1;
}

.day-time-selection-time-picker-popup {
  .ant-picker-ranges {
    display: none;
  }

  .ant-picker-time-panel-column {
    @media (hover: none) {
      overflow-y: auto;
    }
  }
}
