@import 'assets/theme';

.header {
  display: flex;
  padding: 30px 30px 10px;
  border-bottom: solid 1px #ebeff2;

  .title {
    flex: 1 1;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #323c47;
  }

  .closeButton {
    font-size: 18px;
    color: #c2cfe0;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 40px 25px;
  @include rowGap(20px);

  .selectedReferralTypesContainer {
    display: flex;
    flex-wrap: wrap;
    @include rowGap(10px);
    @include columnGap(10px);

    .pill {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      min-width: 100px;
      padding: 0 20px;
      background: $colorBlue3;
      border-radius: 20px;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      color: $white;
    }
  }

  .rsDropdown {
    max-width: 260px;
  }

  .dateContainer {
    margin-top: 20px;
    max-width: 260px;

    &.error,
    .error {
      font-size: 12px;
      color: #ff4d4f;

      input {
        border-bottom: 1px solid #ff4d4f !important;
      }
    }

    .inputContainer {
      position: relative;

      .tooltip {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .detailContainer {
    margin-top: 20px;
    width: 403px;
  }

  .saveButton {
    width: 240px;
    align-self: flex-end;
    margin-top: 30px;
  }
}
