@import 'assets/theme';

.multipleSelectField {
  width: 46%;

  .error {
    margin-bottom: 0;
  }
}

.multipleChoice {
  .select {
    height: 40px;
  }
}

.headingField {
  width: 30%;
  padding-top: 16px;
  position: relative;

  &:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 16px;
  }
}

:export {
  greyColor: $colorLineGray;
}
