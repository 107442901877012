@import 'assets/theme';

.columnWrapper {
  display: flex;

  .columnStyles {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #414449;
  }

  .sortActive {
    font-weight: 600;
    color: $mhh-yellow;
  }

  .groupName {
    @extend .columnStyles;
    padding-left: 10px;
    min-width: 120px;
    max-width: 20%;
    padding-right: 8px;
    flex: 1 5;
  }

  .groupTag {
    @extend .columnStyles;
    min-width: 120px;
    width: 25%;
    max-width: 160px;
    padding-right: 12px;
    flex: 1 5;
  }

  .groupLead {
    @extend .columnStyles;
    min-width: 150px;
    max-width: 15%;
    padding-right: 8px;
    flex: 1 5;
  }

  .joinedDate {
    @extend .columnStyles;
    min-width: 100px;
    max-width: 20%;
    padding-right: 8px;
    flex: 1 5;
  }

  .memberStatus {
    @extend .columnStyles;
    min-width: 120px;
    width: 20%;
    max-width: 180px;
    padding-right: 8px;
    flex: 1 5;
  }

  .groupStage {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 20%;
    padding-right: 12px;
    flex: 1 5;
  }

  .action {
    @extend .columnStyles;
    justify-content: flex-end;
    min-width: 50px;
    max-width: 5%;
    padding-right: 8px;
    flex-grow: 1 5 10%;
  }
}
