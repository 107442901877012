@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.scaleBarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.labelContainer {
  position: relative;

  .labelBox {
    position: absolute;
    width: 100px;
    height: 400px;
    right: 35px;
    bottom: -14px;
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.1px;
    text-align: right;
    text-transform: uppercase;
    color: $colorBlue3;
  }
}
.sliderContainer {
  display: flex;
  justify-content: center;
  transform: rotate(-90deg);
  position: relative;
  z-index: 99;

  .pointerContainer {
    position: relative;
    z-index: 1;

    @for $i from 0 through 37 {
      &[data-value='#{$i}'] .circleContainer {
        left: percentage(calc($i / 37));
      }
    }

    .label {
      position: absolute;
      left: -7px;
      top: 34px;

      .sliderLine {
        position: relative;
        top: 12px;
        left: 6px;
        width: 370px;
        height: 14px;
        border-radius: 8px;
        background: #e6ebef;
      }

      .circleContainer {
        position: relative;
        width: 370px;

        .pointerCircle {
          height: 30px;
          width: 30px;
          background: #5f9cca;
          box-shadow: 0 1px 5px #848484;
          position: relative;
          left: -13px;
          bottom: 10px;
          z-index: 22;
          border-radius: 16px;
        }

        .triangle {
          width: 0;
          height: 0;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          border-bottom: 16px solid #e6ebef;
          position: relative;
          left: -7px;
        }

        .circle {
          width: 58px;
          height: 58px;
          position: relative;
          left: -27px;
          bottom: 7px;

          .profileWrapper {
            transform: rotate(90deg);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 58px;
            width: 58px;
            border-radius: 50%;
            background-color: #e6ebef;

            .profile {
              width: 52px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

.slider {
  position: absolute;
  top: 36px;
  left: 14px;
  z-index: 2;

  @supports (-moz-appearance: none) {
    bottom: -100px;
    left: 6px;
  }

  .range {
    touch-action: pan-x;
    transform: rotate(0deg);
  }

  input[type='range'] {
    -webkit-appearance: none;
    margin: 0 0;
    width: 380px;
    height: 110px;
    background-color: transparent;

    @supports (-moz-appearance: none) {
      margin: 6px 0;
    }
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    box-shadow: 0 0 0 $black, 0 0 0 #0d0d0d;
    background: transparent;
    height: 110px;
    border: 0 solid #000101;
  }

  input[type='range']::-webkit-slider-thumb {
    border: 0 solid $black;
    height: 110px;
    width: 20px;
    background: transparent;
    cursor: pointer;
    -webkit-appearance: none;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: transparent;
    outline: none;
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 110px;
    cursor: pointer;
    box-shadow: 0 0 0 $black, 0 0 0 #0d0d0d;
    background: transparent;
    border: 0 solid #000101;
  }

  input[type='range']::-moz-range-thumb {
    border: 0 solid $black;
    height: 110px;
    width: 40px;
    background: transparent;
    cursor: pointer;
    -webkit-appearance: none;
  }

  input[type='range']::-ms-track {
    width: 100%;
    height: 110px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 39px 0;
    color: transparent;
  }

  input[type='range']::-ms-fill-lower {
    background: transparent;
    border: 0 solid #000101;
    border-radius: 50px;
    box-shadow: 0 0 0 $black, 0 0 0 #0d0d0d;
  }

  input[type='range']::-ms-fill-upper {
    background: transparent;
    border: 0 solid #000101;
    border-radius: 50px;
    box-shadow: 0 0 0 $black, 0 0 0 #0d0d0d;
  }

  input[type='range']::-ms-thumb {
    box-shadow: 0 0 0 $black, 0 0 0 #0d0d0d;
    border: 0 solid $black;
    height: 110px;
    width: 50px;
    border-radius: 7px;
    background: #57a756;
    box-sizing: content-box;
    border-radius: 80% 0 55% 50% / 55% 0 80% 50%;
    cursor: pointer;
    margin-top: -3.6px;
    position: relative;
    top: 50px;
    -webkit-transform: rotateZ(315deg);
    transform: rotateZ(315deg);
  }

  input[type='range']:focus::-ms-fill-lower {
    background: transparent;
  }

  input[type='range']:focus::-ms-fill-upper {
    background: transparent;
  }
}
