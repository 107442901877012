@import 'assets/theme';

.container {
  border-top: 1px solid #ebeff2;

  .notShareableContainer {
    padding: 20px;
    display: flex;
    align-items: center;
    @include columnGap(20px);

    .message {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.hidden {
  display: none;
}
