.container {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 24px 16px;
  transition: all 0.5s ease;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .caseLoadContainer {
    display: flex;
    align-items: center;
    align-items: center;
  }

  .contentValueWrapper {
    display: flex;
    align-items: center;

    .value {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .copyBtn {
    display: flex;
    justify-content: center;
    padding-left: 6px;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      color: #2f42ef;
    }

    .icon {
      font-size: 18px;
    }
  }
}
