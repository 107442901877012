@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0;

  .iconContainer {
    width: fit-content;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    border: 2px solid #fff;

    .icon {
      width: 36px;
    }
  }

  .comments {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: auto;
    @include rowGap(10px);
    scrollbar-width: thin;
    scrollbar-color: #c4cae1 transparent;

    &::-webkit-scrollbar {
      width: 21px;
    }

    &::-webkit-scrollbar-track {
      background: #f0eeee;
      border-left: 9px solid #f5f6ff;
      border-right: 9px solid #f5f6ff;
    }

    &::-webkit-scrollbar-thumb {
      background: #c4cae1;
      border-radius: 9px;
      border-left: 7px solid #f5f6ff;
      border-right: 7px solid #f5f6ff;
    }

    & > *:not(:last-child) {
      border-bottom: 1px solid #ebeff2;
    }

    .commentWrapper {
      display: flex;
      flex-direction: column;
      @include rowGap(10px);
      padding-bottom: 14px;

      .topSection {
        display: flex;
        @include columnGap(10px);

        .clinicianContainer {
          display: flex;
          align-items: center;
          @include columnGap(10px);

          .avatarContainer {
            width: 32px;
            height: 32px;

            .avatar {
              max-width: 100%;
              max-height: 100%;
              border-radius: 50%;
            }
          }
          .name {
            font-size: 16px;
            font-weight: 600;
          }
        }

        .createdDate {
          font-weight: 600;
          font-size: 10px;
          color: #949aa2;
          align-self: center;
        }
      }

      .comment {
        font-size: 14px;
        font-weight: 600;
        color: $colorTextualBlack;
      }
    }
  }

  .commentContainer {
    display: flex;
    flex-direction: column;
    padding: 18px 26px;
    background: #f5f6ff;
    border: 1px solid #dadbde;
    box-sizing: border-box;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: absolute;
    top: 52px;
    width: 350px;
    cursor: auto;
    @include rowGap(10px);
    z-index: 100;

    .newComment {
      display: flex;
      @include columnGap(10px);
      width: 100%;

      .avatar {
        width: 32px;
        border-radius: 50%;
      }

      .inputField {
        display: flex;
        width: 100%;
        border-radius: 8px;
        padding: 0 10px;
        background-color: #fff;
        justify-content: space-between;
        align-items: center;

        .input {
          border: none;
          width: 100%;
        }

        .sendButton {
          display: flex;

          .sendIcon {
            color: $colorBlue1;
            cursor: pointer;
          }
        }
        .submitting {
          opacity: 0.6;
          background-color: #fff;
        }
      }
    }
  }

  .rightAligned {
    right: 0;
  }
}
