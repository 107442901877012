@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  padding-top: 16px;
  position: relative;

  .label {
    padding: 0 8px;
  }

  .selectField {
    width: 100%;
    max-width: 280px;
    font-size: 16px;
  }
}

:export {
  greyColor: $colorLineGray;
}
