.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #ebeff2;

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      color: #323c47;
    }

    .icon {
      color: #c2cfe0;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 20px 32px;

    .editorWrapper {
      padding-top: 16px;
    }

    .buttonWrapper {
      display: flex;
      justify-content: flex-end;

      .button {
        align-self: center;
        margin-top: 24px;
        border-radius: 4px;
        box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
        font-size: 14px;
      }
    }
  }

  .error {
    color: #ff1900;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 8px;
  }
}
