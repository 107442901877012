.container {
  padding: 0 16px 24px;
  margin-top: 16px;

  .checkBoxContainer {
    padding: 16px 0;
  }

  .noteContainer {
    transition: all 0.3s ease;
    max-height: 500px;
    opacity: 1;

    .error {
      margin-top: 4px;
    }
  }

  .noteContainerHidden {
    @extend .noteContainer;
    max-height: 0;
    transition: all 0.3s ease;
    overflow: hidden;
    opacity: 0;
  }
}

.highlightContainer {
  @extend .container;
  background-color: #f2f9ff;
}
