@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  @include columnGap(16px);

  .avatarContainer {
    width: 40px;

    .avatar {
      max-width: 100%;
      border-radius: 50%;
    }

    .initials {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #8aa8d3;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      font-weight: 600;
      font-size: 16px;
      color: #fff;
    }
  }

  .nameContainer {
    font-weight: 600;
    font-size: 16px;
  }
}
