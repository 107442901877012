@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  @include rowGap($standard-padding);

  .toggleContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: row-reverse;
  }

  .toggle > div {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-top: 8px;
    order: 3;
  }
}

.header {
  display: flex;
  @include columnGap($standard-padding * 4);
  align-items: center;

  .title {
    font-size: 20px;
    font-weight: 700;
    color: #323c47;
  }

  .button {
    font-size: 12px;
    font-weight: 600;
  }

  .appointmentNameContainer {
    display: flex;
    flex-direction: column;

    .label {
      font-size: 11px;
      font-weight: 500;
      color: $colorTextualGray;
    }

    .text {
      font-size: 18px;
      font-weight: 600;
      color: #343434;
    }
  }

  .customise {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: $colorBlue1;
    cursor: pointer;
    margin-left: 16px;

    i {
      font-size: 18px;
      margin-left: 4px;
    }
  }
}

.noAppointmentTypes {
  font-size: 16px;
  font-weight: bold;
  color: #343434;
}

.appointmentTypeLink {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $standard-padding;
  color: $colorBlue1;
  border: 1px solid $colorBlue1;
  border-radius: 0.3125rem;

  .icon {
    margin-right: 4px;
  }
}

.weeksContainer {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 2);
  margin: $standard-padding 0 4px;

  .week {
    font-size: 13px;
    font-weight: 600;
    color: #343434;

    &:hover,
    &.active {
      border-bottom: 2px solid $colorBlue1;
    }
  }
}

.daysContainer {
  display: grid;
  grid-template-columns: repeat(7, 14.25%);
  @include columnGap($standard-padding * 2);

  & > div {
    flex: 1;
  }
}

.navContainer {
  display: flex;
  padding-top: 4px;
  justify-content: space-between;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
}

.navContainerEdit {
  display: flex;
  position: relative;
  height: 0;
  top: 23px;
  width: 105%;
  left: -20px;
  justify-content: space-between;

  .leftControl,
  .rightControl {
    width: 29px;
    font-weight: 600;
    font-size: 7px;
    line-height: 10px;
    text-align: center;
    color: $colorBlue3;

    span {
      height: 29px;
      width: 29px;
      border-radius: 50%;
      color: $colorBlue2;
      background: #fff;
      border: 0.58px solid $colorBlue3;
      box-sizing: border-box;
      box-shadow: 0px 2.32px 2.32px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-bottom: 3px;
    }
  }

  .leftControl {
    span {
      padding-right: 2px;
    }
  }

  .rightControl {
    span {
      padding-left: 2px;
    }
  }

  .disabled span {
    cursor: not-allowed;
    opacity: 0.3;
  }
}
