@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 20px 16px;
  @include rowGap(20px);

  .firstSection {
    display: flex;
    flex-direction: column;
    @include rowGap(20px);
  }

  .statusContainer {
    display: flex;
    @include columnGap(16px);
    align-items: center;

    .statusList {
      display: flex;
      flex-wrap: wrap;
      row-gap: 8px;
      column-gap: 8px;
    }
  }

  .sentForReviewContainer {
    display: flex;
    flex-direction: column;

    .sentForReviewTime {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .detailsContainer {
    display: flex;
    flex-direction: column;
    @include rowGap(8px);
  }
}
