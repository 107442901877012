.modal {
  & > div {
    background-color: transparent;
    box-shadow: none;
  }
}

.container {
  border-radius: 25px;
  background-color: #fff;
}
