@import 'assets/theme';

.send-invoice-date-picker {
  align-items: flex-end;
  height: 26px !important;
  margin: 0 16px 0 48px !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  width: auto;

  .ant-picker-input {
    border-bottom: 1px solid $colorLineGray;

    input {
      text-align: left;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #323c47;
      opacity: 1;
    }

    .ant-picker-suffix {
      display: block;
      color: #aeaeae;
    }
  }
}

.ant-picker-focused .ant-picker-input .ant-picker-suffix {
  color: $colorBlue1;
}
