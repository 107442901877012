.container {
  .title {
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #343434;
  }

  .changeNoteContainer {
    position: relative;

    .changeNote {
      width: 100%;
      margin-top: 12px;
      padding: 16px;
      padding-bottom: 32px;
      resize: none;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434e0;
      border: 1px solid #c4c4c4;
      box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
    }

    .characterCount {
      position: absolute;
      left: 16px;
      bottom: 16px;
      font-size: 12px;
      color: #90a0b7;
    }
  }
}
