@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  width: 100%;

  &:not(:last-child) {
    margin-right: 40px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      @include columnGap(10px);
      font-weight: 600;
      font-size: 15px;
      line-height: 23px;
      color: #323c47;

      .editOffIcon {
        font-size: 20px;
        color: #808080;
      }
    }
  }

  .content {
    padding-top: 16px;

    .cNewCardContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba($mhh-dark-blue-30, 0.2);
      border: 1px dashed $mhh-dark-blue-30;
      box-sizing: border-box;
      border-radius: 4px;
      height: 126px;

      .addNewBtnWrapper {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.01em;
        color: $mhh-dark-blue;
        border: 1px solid $mhh-yellow;
        box-sizing: border-box;
        border-radius: 4px;
        /* box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24); */
        cursor: pointer;
        user-select: none;

        .icon {
          padding-right: 6px;
        }
      }

      .menuWrapper {
        position: relative;

        .menuBoxHide {
          position: absolute;
          background: #fff;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          visibility: hidden;
          opacity: 0;
          transform: translateY(0px);
          transition: all 0.3s;

          .listBox {
            padding: 16px;
            cursor: pointer;
            color: #707683;
            transition: all 0.2s ease;
            position: relative;

            &:not(:last-child) {
              border-bottom: 1px solid #e7e4e4;
            }

            &:hover {
              color: $colorBlue1;
            }

            .listTitle {
              font-weight: 600;
              font-size: 13px;
              line-height: 16px;
              padding-bottom: 4px;
            }

            .listDesc {
              font-size: 10px;
              line-height: 12px;
            }

            .comingSoon {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              background-color: #ffffff80;
              z-index: 1;
              cursor: default;
            }

            .tooltip {
              max-width: 200px;
              border-radius: 8px;
              line-height: 16px;
              font-size: 14px;
            }
          }
        }

        .menuBoxShow {
          @extend .menuBoxHide;
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}
