@import 'assets/theme';

.container {
  border-top: 1px solid #ebeff2;

  .commentSection {
    display: flex;
    flex-direction: column;

    .commentContainer {
      padding: 10px 16px;
      border-bottom: 1px solid #ebeff2;
    }

    .emptyCommentContainer {
      display: flex;
      padding: 20px;
      flex-direction: column;
      justify-content: center;
      @include rowGap(8px);

      .emptyCommentMessage {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}

.hidden {
  display: none;
}
