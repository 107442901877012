@import 'assets/theme';

.container {
  display: flex;
  padding-bottom: $standard-padding * 4;
  height: 100%;
  margin-bottom: 100px;

  .sideMenuContainer {
    background: #fff;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    height: 100%;

    .menuCard {
      display: flex;
      padding: 12px 24px;
      min-width: 300px;
      border-bottom: 1px solid #ebeff2;
      cursor: pointer;
      transition: all 0.1s ease;
      box-sizing: border-box;

      @media (max-width: $large-screen-min-width) {
        min-width: 250px;
      }

      @media (max-width: $medium-large_screen-min-width) {
        display: flex;
        flex-direction: column;
        min-width: 200px;
        max-width: 200px;
      }

      &:hover {
        .menuIcon {
          transition: all 0.3s ease;
          color: $mhh-yellow;
        }

        .labelWrapper {
          width: 100%;

          .label {
            transition: all 0.3s ease;
            color: $mhh-dark-blue;
          }
          .desc {
            transition: all 0.3s ease;
            color: $mhh-dark-blue-50;
          }
        }
      }

      .menuIcon {
        margin-right: 10px;
        font-size: 24px;
        color: $mhh-noActive-yellow;
      }

      .labelWrapper {
        display: flex;
        flex-direction: column;

        .label {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: $mhh-dark-blue;

          @media (max-width: $medium-large_screen-min-width) {
            padding-top: 4px;
          }
        }

        .desc {
          padding-top: 4px;
          font-size: 12px;
          line-height: 16px;
          color: $mhh-dark-blue-50;
        }
      }
    }

    .menuCardActive {
      @extend .menuCard;
      border-right: 4px solid $mhh-yellow;
      padding: 16px 24px;

      .menuIcon {
        color: $mhh-yellow;
      }

      .labelWrapper {
        width: 100%;

        .label {
          color: $mhh-dark-blue;
        }

        .desc {
          color: $mhh-dark-blue-50;
        }
      }
    }
  }

  .contentContainer {
    margin-left: 24px;
    width: calc(100% - 324px);

    @media (max-width: $large-screen-min-width) {
      margin-left: 16px;
    }
  }
}
