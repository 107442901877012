.container {
  display: flex;
  flex-direction: column;
  max-width: 220px;

  .loading {
    justify-content: center;
    width: 100%;
  }
}
