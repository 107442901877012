@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #414449;
    margin-bottom: 16px;
  }

  .content {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 20px;

    .subTitle {
      display: flex;
      color: #414449;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 20px;
    }

    .stageContainer {
      display: flex;
      color: #6a707e;
      @include columnGap(20px);

      .stageWrapper {
        display: flex;
        flex-direction: column;
        color: #6a707e;
        flex: 1 2;

        .labelWrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          .stageLabel {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: #414449;
          }

          .desc {
            font-size: 14px;
            line-height: 21px;
            color: #414449;
          }
        }
      }
    }
  }
}
