@import 'assets/theme';

.container {
  display: flex;
  padding-bottom: 12px;
  height: 100%;

  .sideMenuContainer {
    background: #fff;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    height: max-content;

    .menuCard {
      display: flex;
      align-items: center;
      padding: 24px 16px;
      min-width: 160px;
      max-width: 160px;
      border-bottom: 1px solid #ebeff2;
      cursor: pointer;
      transition: all 0.1s ease;
      box-sizing: border-box;

      @media (max-width: $medium-large_screen-min-width) {
        display: flex;
        flex-direction: column;
      }

      &:hover {
        .labelWrapper {
          width: 100%;

          .label {
            transition: all 0.3s ease;
            color: $colorBlue1;
          }
          .desc {
            transition: all 0.3s ease;
            color: #343434;
          }
        }
      }

      .labelWrapper {
        display: flex;
        flex-direction: column;

        .label {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: $colorTextualBlack;

          @media (max-width: $medium-large_screen-min-width) {
            padding-top: 4px;
          }
        }

        .desc {
          font-size: 12px;
          line-height: 16px;
          color: #90a0b7;
        }
      }
    }

    .menuCardActive {
      @extend .menuCard;
      border-right: 4px solid $colorBlue1;

      .labelWrapper {
        width: 100%;
        .label {
          color: $colorBlue1;
        }

        .desc {
          color: #343434;
        }
      }
    }

    .requestReportWrapper {
      margin: 40px 8px 16px;
      display: flex;

      .requestReportBtn {
        display: flex;
        width: 100%;
        max-width: 140px;
        min-width: 140px;
        padding: 8px;
      }
    }
  }

  .contentContainer {
    margin-left: 16px;
    width: 100%;
    height: 100%;
    background-color: $white;

    @media (max-width: $large-screen-min-width) {
      margin-left: 16px;
      width: 100%;
    }

    .contentBox {
      width: 100%;
      height: 100%;
    }

    .fullScreenBtnWrapper {
      position: relative;

      .fullScreenBtn {
        position: absolute;
        right: 0;
        top: -30px;
        color: $grey-darker;
        transition: all 1s ease;

        &:hover {
          color: $colorBlue1;
        }
      }

      .tooltip {
        max-width: 230px;
        border-radius: 8px;
        line-height: 16px;
        font-size: 14px;
        padding: 8px;
        opacity: 0.7 !important;
      }
    }
  }
}
