@import 'assets/theme';

.container {
  width: 100%;

  @media (max-width: $large-screen-min-width) {
    margin-left: 16px;
    width: calc(100% - 216px);
  }
}

.loading {
  @extend .container;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 320px;
  background-color: $white;
  box-shadow: 0 2px 16px #0000001a;
  border-radius: 8px;
}
