@import 'assets/theme';

.modalContainer {
  min-width: 800px;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #323c47;

    .selector {
      min-width: 200px;
      margin-left: 16px;
    }
  }
}
