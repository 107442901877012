.filterStyles {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #6a707e;
}

.GroupNameSection {
  @extend .filterStyles;
  max-width: 160px;
  padding-right: 20px;
  flex: 1 5;
  padding-left: 10px;
}

.GSinceSection {
  @extend .filterStyles;
  max-width: 160px;
  padding-right: 20px;
  flex: 1 5;
}

.GroupLeadSection {
  @extend .filterStyles;
  max-width: 160px;
  padding-right: 20px;
  flex: 1 5;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.buttonSection {
  @extend .filterStyles;
  align-items: flex-end;
  flex: 1 5;
}
