@import 'assets/theme';

.activityTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.date {
  word-break: break-word;
}

.activityContent {
  height: auto;
  padding: 16px;
  background: #fbfbfb;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  p,
  ol,
  ul {
    margin-bottom: 0;
  }
}

.activityIcon {
  color: $mhh-yellow;
  padding: 4px;
  border: 1px solid $mhh-yellow;
  border-radius: 50%;
  margin-right: 16px;

  svg {
    width: 24px;
    height: 24px;
  }
}
