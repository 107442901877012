@import 'assets/theme';

.filterStyles {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #6a707e;
}

.sortHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;

  &.classSection {
    justify-content: flex-start;
  }

  &.nameSection {
    justify-content: flex-start;
    align-items: center;
  }
}

.sortHeaderActive {
  color: $mhh-yellow !important;
}

.nameSection {
  @extend .filterStyles;
  align-items: flex-start;
  min-width: 120px;
  max-width: 200px;
  padding: 0 16px 0 4px;
  flex: 1 5 10%;
  color: inherit;
  text-decoration: inherit;
}

.classSection {
  @extend .filterStyles;
  min-width: 100px;
  max-width: 100px;
  padding: 0 8px;
  flex: 1 5 5%;
}

.tAssignedSection {
  @extend .filterStyles;
  min-width: 100px;
  max-width: 100px;
  padding-right: 8px;
  flex: 1 5 10%;
}

.groupsSection {
  @extend .filterStyles;
  min-width: 120px;
  max-width: 200px;
  padding-right: 8px;
  flex: 1 1 18%; //15%;
}

.interventionSection {
  @extend .filterStyles;
  min-width: 120px;
  max-width: 200px;
  padding-right: 16px;
  flex: 1 5 18%; //15%;
}

.actionPlanSection {
  @extend .filterStyles;
  align-items: center;
  max-width: 200px;
  padding-right: 22px;
  flex: 1 5 20%;
}

.lastScoreSection {
  @extend .filterStyles;
  align-items: center;
  max-width: 250px;
  padding-right: 22px;
  flex: 1 5 25%; //20%;
}

.nextAssessmentDueSection {
  @extend .filterStyles;
  max-width: 300px;
  padding-right: 22px;
  flex: 1 5 15%;
}

.buttonSection {
  @extend .filterStyles;
  align-items: flex-end;
  padding-right: 16px;
  max-width: 50px;
  flex: 1 5 5%;
}

.studentCheckBoxSection {
  @extend .filterStyles;
  display: flex;
  align-items: center;
  min-width: 55px;
}
