@import 'assets/theme';

.container {
  .label {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 4px;
    color: $colorTextualGray;
  }

  .fieldContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 32px;
    max-width: 500px;

    .emailField {
      border: 0;
      border-bottom: 1px solid #c4c4c4;
      color: $colorTextualBlack;
      outline: none;
      transition: 0.2s ease-in-out;
      box-sizing: border-box;
      width: 100%;
      height: 30px;
      font-size: 16px;

      &::placeholder {
        font-size: 14px;
        color: $mid-grey;
      }

      &:valid {
        border-bottom: 1px solid #c4c4c4;
        + {
          .label {
            color: $colorTextualGray;
            font-size: 11px;
            font-weight: 600;
            left: 2px;
            bottom: 22px;
            pointer-events: none;
          }
        }
      }
      &:focus {
        border-bottom: 1px solid #c4c4c4;
        + {
          .label {
            color: $colorTextualGray;
            font-size: 11px;
            font-weight: 600;
            left: 2px;
            bottom: 22px;
            pointer-events: none;
          }
        }
      }
    }

    &.fieldError,
    .fieldError {
      font-size: 12px;
      color: #ff4d4f;

      input {
        border-bottom: 1px solid #ff4d4f !important;
      }
    }
  }

  .ecWrapper {
    display: flex;
    flex-direction: column;

    .ecTitle {
      font-size: 16px;
      color: $colorTextualBlack;
      padding-bottom: 8px;
      font-weight: 600;
    }

    .ecList {
      display: flex;
      flex-wrap: wrap;

      .emailItem {
        background-color: $colorBlue3;
        color: #fff;
        height: 40px;
        padding: 12px 20px;
        font-size: 14px;
        border-radius: 40px;
        padding: 12px 20px;
        display: inline-flex;
        align-items: center;
        margin: 0 8px 8px 0;

        .deleteBtn {
          background-color: transparent;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          border: none;
          cursor: pointer;
          font: inherit;
          margin-left: 10px;
          font-weight: bold;
          padding: 0;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s ease all;

          &:hover {
            background-color: transparent;
            color: #ff8282;
          }
        }
      }
    }
  }
}

.roleContainer {
  margin-bottom: 20px;

  .practionerRoleTitle {
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $colorTextualBlack;
    margin-bottom: 15px;
  }
}
