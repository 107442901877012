@import 'assets/theme';

.listingWrapper {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 24px;
  transition: all 0.5s ease;
  justify-content: space-between;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover {
    background: #fbfbfb;
  }

  .contentWrapper {
    display: flex;
    flex: 1;

    .authorSection {
      display: flex;
      align-items: center;
      flex-direction: column;
      min-width: 90px;
      max-width: 170px;
      padding-right: 24px;
      flex: 1 5 15%;
    }

    .profileSection {
      display: flex;
      align-items: center;
      flex-direction: column;
      min-width: 90px;
      max-width: 170px;
      padding-right: 40px;
      flex: 1 5 15%;
    }

    .reportNameSection {
      display: flex;
      align-items: center;
      min-width: 120px;
      max-width: 250px;
      padding-right: 16px;
      flex: 1 5 20%;
      font-weight: 600;
      font-size: 15px;
      color: #343434;

      .link {
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          color: $colorBlue1;
          opacity: 0.75;
        }
      }
    }

    .statusSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 90px;
      max-width: 130px;
      padding-right: 8px;
      flex: 1 5 10%;
      color: #6575b1;

      .badge {
        display: flex;
        justify-content: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        width: 100%;
        margin: 0;
        padding: 4px;
        text-align: center;

        &:not(:last-child) {
          margin: 0 0 8px;
        }
      }

      .published {
        @extend .badge;
        background-color: #29794a;
      }

      .draft {
        @extend .badge;
        background-color: #cbb600;
      }

      .inReview {
        @extend .badge;
        background-color: #f38f8f;
      }

      .approved {
        @extend .badge;
        background-color: #66cb91;
      }

      .unpublished {
        @extend .badge;
        background-color: #5ab6e2;
      }

      .shareBadge {
        @extend .badge;
        background-color: #426eb1;
      }

      .feedbackBadge {
        @extend .badge;
        background-color: #ff9d73;
      }

      .changesRequestedBadge {
        @extend .badge;
        background-color: #a51212;
      }

      .reviewerEditsBadge {
        @extend .badge;
        background-color: #949aa2;
      }

      .collaborateBadge {
        @extend .badge;
        background-color: #8c97ff;
      }
    }

    .day {
      text-transform: capitalize;
    }

    .createdSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 90px;
      max-width: 190px;
      padding-right: 16px;
      flex: 1 5 20%;
      font-weight: 600;
      font-size: 14px;
      color: #6575b1;
    }

    .lastAccessedSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 90px;
      max-width: 150px;
      padding-right: 16px;
      flex: 1 5 20%;
      font-weight: 600;
      font-size: 14px;
      color: #6575b1;
    }

    .shareDetailSection {
      display: flex;
      align-items: center;
      min-width: 120px;
      max-width: 230px;
      flex: 1 20 20%;
      font-weight: 600;
      font-size: 14px;
      color: #6575b1;

      .shareIcon {
        padding-right: 16px;
      }

      .shareNameContainer {
        display: flex;
        flex-direction: column;

        .shareName {
          line-height: 16px;
          font-size: 10px;
        }
      }
    }
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 50px;
    flex: 1 5 15%;

    .icon {
      border: 2px solid #6575b1;
      color: #6575b1;
      padding: 6px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
