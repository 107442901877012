@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

:global {
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }
}
