.container {
  width: 100%;
  background: #fff;
  padding: 20px;
  border: 1px solid #ecedef;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .loadingWrapper {
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
