@import 'assets/theme';

.modalContainer {
  min-width: 800px;

  .title {
    display: flex;
    align-items: center;

    .trainingLink {
      display: flex;
      align-items: center;
      max-width: 320px;
      margin-left: $standard-padding * 4;
      padding: $standard-padding * 2 $standard-padding * 2.5;
      font-weight: 600;
      font-size: 18px;
      color: $mhh-dark-blue;
      word-break: break-word;
      border: 1px solid $mhh-yellow;
      border-radius: 4px;

      .icon {
        margin-left: $standard-padding * 1.5;
      }
    }
  }
}

.grayTitle {
  color: $mhh-blurb-grey-100;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: $standard-padding;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  @include rowGap(32px);

  .detailSection {
    display: flex;
    flex-direction: column;

    .descTitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
    }

    .desc {
      font-size: 14px;
      line-height: 16px;
      padding-top: 4px;
    }
  }
}

.selectionSection {
  display: flex;
  flex-direction: column;

  .deliveryTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
  }

  .deliveryContainer {
    display: flex;
    flex-direction: column;
    .optionsWrapper {
      font-size: 14px;
      line-height: 28px;

      padding-right: 16px;
      @include rowGap(4px);

      .activeRadioLabel {
        font-weight: 700;
        font-size: 14px;
        color: $colorBlue1;
      }
    }

    .deliveryMode {
      margin-bottom: $standard-padding * 2;
      width: 50%;
    }

    .teacherListContainer {
      display: flex;
      flex: 1;
      margin-bottom: $standard-padding * 2;
    }

    .externalPartyInput {
      display: flex;
      flex-direction: column;
      max-width: 220px;
      margin-bottom: $standard-padding * 2;

      .button {
        color: $colorBlue1;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: $standard-padding;
        cursor: pointer;

        .icon {
          font-size: 20px;
        }
      }

      .externalItem {
        display: flex;
        flex-direction: row;
        height: fit-content;
        margin-bottom: $standard-padding * 2;
      }

      .input {
        min-height: 50px;
        padding-top: $standard-padding;
      }

      .addSection {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

.saveChanges {
  display: flex;
  justify-content: flex-end;
}
