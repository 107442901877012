@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .stageWrapper {
    .stageLabel {
      font-size: 10px;
      line-height: 15px;
      color: #b5b8bd;
      padding: 8px 12px 0;
      text-transform: uppercase;
    }
  }
}
