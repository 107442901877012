@import 'assets/theme';

.modalContainer {
  min-width: 800px;

  .container {
    padding: 0 0 16px;
  }
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;
}
