@import 'assets/theme';

.container {
  display: flex;

  width: 100%;
  padding: 10px 20px;
  background: #fbfbfb;

  .form {
    width: 100%;
    margin: 0 $standard-padding 0 $standard-padding * 2;
    & > div:not(:last-child) {
      margin-bottom: $standard-padding * 2;
    }

    .firstRow {
      display: flex;
      align-items: flex-end;

      & > div:not(:last-child) {
        margin-right: $standard-padding * 2;
      }

      .typeContainer {
        width: 200px;
      }

      .addBtnWrapper {
        margin-top: $standard-padding;
        border: none;
        background: none;
        align-self: center;
        display: flex;
        align-items: center;
        color: $colorBlue1;
        cursor: pointer;

        .plusIcon {
          padding-right: 2px;
          font-size: 22px;
        }

        &.disabled {
          color: grey;
          cursor: not-allowed;
        }
      }
    }

    .label {
      color: $colorTextualGray;
      font-size: 12px;
    }

    .secondRow {
      display: flex;

      & > div:not(:last-child) {
        margin-right: $standard-padding * 2;
      }

      .tip {
        font-size: 10px;
        color: $colorTextualGray;
        font-family: 'Popppins';
      }

      .switch {
        margin-top: $standard-padding;

        &.checked {
          label {
            background-color: rgba(63, 82, 255, 0.33);
          }
        }

        input:checked + span {
          background-color: $colorBlue1;
        }
      }
    }
  }
}

.textFieldContainer {
  width: 100%;
}

.textField {
  margin: 0;
  border: 0;
  border-bottom: 1px solid $colorTextualGray;
  color: $colorTextualBlack;
  outline: none;
  transition: 0.2s ease-in-out;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  font-size: 16px;
  background-color: #fbfbfb;

  &::placeholder {
    font-size: 14px;
    color: #a2a6aa;
  }
}

.checkbox {
  appearance: none;
  margin: 0;
  margin-top: $standard-padding * 4;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #d5d5d5;
  border-radius: 4px;
  color: #d5d5d5;
  cursor: pointer;
  outline: none;

  &:checked {
    font-size: 12px;
    line-height: 16px;
    font-weight: 800;
    color: #fff;
    background-color: $mhh-yellow;
    border: 2px solid $mhh-yellow;

    &:after {
      content: '\2713';
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
    }
  }
}
