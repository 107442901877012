@import 'assets/theme';

.modalContainer {
  min-width: 800px;
  border-top: solid $white;
  border-radius: 8px;
  overflow: auto;

  & > div {
    border-radius: 8px;
    box-shadow: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 24px;

    .textAreaField {
      border: 1px solid #c4c4c4;
      border-radius: 4px;
      box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);

      .textarea {
        width: 100%;
        border: none;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #343434;
        resize: none;
        border-radius: 4px;
        padding: 8px;
      }

      .wordCount {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 4px 16px 8px;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #838080;

        .count {
          font-size: 18px;
          padding-right: 4px;
        }
      }
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
  }

  .submitButtonContainer {
    display: flex;
    justify-content: flex-end;
  }
}
