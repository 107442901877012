@import 'assets/theme';

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;

  .loadingContainer {
    margin-right: 8px;
  }

  .draftButton {
    margin-right: 16px;
    background-color: $white;
    color: $colorBlue1;
    border: 1px solid $colorBlue1;

    &.active,
    &.finished {
      background-color: #2ecc71;
      color: $white;
      border: none;
    }
  }
}

.formContainer {
  display: none;

  &.visible {
    display: block;
  }
}
