.loading {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  background: #fff;
  padding: 16px;
  flex: 1;

  .activityContainer {
  }
}
