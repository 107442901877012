@import 'assets/theme';

.loadingContainer {
  background-color: $white;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px;
}

.loading {
  display: flex;
  justify-content: center;
}

.actionPlanList {
  display: inline-flex;
  margin-bottom: $standard-padding;
  overflow-x: auto;
  width: 100%;
  .actionPlanNameBox {
    padding: 0px 8px;
    border-left: solid 1px #c4c4c4;
    font-size: 20px;
    cursor: pointer;

    &:first-child {
      border-left: none;
    }
    .actionPlanName {
      white-space: nowrap;
      &.selectedPlan {
        font-weight: 600;
        color: $mhh-dark-blue;
        border-bottom: solid 1px $mhh-dark-blue;
      }
    }
  }
}

.boxContainer {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px;

  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    .title {
      padding: 0px 16px 16px 0px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: $mhh-blurb-grey-100;
    }
    .secondaryButton {
      padding: 12px 16px;
      color: $colorTextualBlack;
      background-color: $white;
      box-shadow: none;
      max-height: 40px;
      border-radius: 4px;
      border: 1px solid $mhh-yellow;
      font-size: 16px;
      font-weight: 600;
      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
    .headerSection {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      .badge {
        min-width: 88px;
        justify-content: center;
      }
    }

    .actionPlanWrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: $standard-padding * 4;
      .fieldContent {
        font-size: 14px;
        font-weight: 600;
        color: $mhh-light-black;
        margin-top: 20px;
        .planName {
          font-size: 18px;
          font-weight: 700;
        }
        .description {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
        }
      }

      .statusSection {
        display: flex;
        flex-direction: column;
        width: 60%;
        margin-right: $standard-padding * 2;
      }
      .ownerSection {
        display: flex;
        flex-direction: column;
        width: 40%;
        .avatarContainer {
          width: fit-content;
          align-items: flex-start;
        }
        .clinicianName {
          font-weight: 600;
          font-size: 18px;
          color: #414449;
          padding-top: 4px;
          align-self: center;
        }
        .attachmentsSection {
          margin-top: 24px;
          .title {
            padding-bottom: 0;
          }
        }
      }
    }

    .editButton {
      margin: $standard-padding * 4 0;
    }

    .statusUpdateWrapper {
      margin-top: 24px;
    }
  }
}
