.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffd0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
}

.container {
  display: flex;
  flex-direction: column;

  .signUpContainer {
    max-width: 850px;
  }

  .content {
    //align-self: center;
    display: flex;
    flex-direction: column;
    //max-width: 720px;
  }
}
