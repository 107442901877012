@import 'assets/theme';

.container {
  display: flex;
  justify-content: center;
  padding-top: 4px;
  align-items: flex-end;
  flex-grow: 1;

  .progress {
    width: 100%;
    background: #ccc;
    border-radius: 6px;
    transition: width 1s ease;
    height: 4px;
  }

  .bar {
    height: 4px;
    border-radius: 6px;
    background: $colorBlue3;
    transition: width 1s ease;
  }

  .percent {
    line-height: 20px;
    color: #fff;
    margin-left: 10px;
    font-size: 10px;
  }
}
