@import 'assets/theme';

.container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 4px;

  .totalDone {
    @extend .outstanding;
    color: $mhh-yellow;
  }

  .outstanding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 16px;

    .value {
      font-weight: bold;
      font-size: 24px;
      padding-bottom: 8px;
    }

    .label {
      font-weight: 600;
      font-size: 11px;
    }
  }
}
