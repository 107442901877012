@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $colorBlue1;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  .arrowIcon {
    font-size: 12px;
    margin-left: 8px;
  }

  .groupLabelWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @include columnGap(20px);

    .groupLabel {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #202225;
    }

    .noGroupLabel {
      font-style: italic;
      font-size: 16px;
      line-height: 24px;
      color: #61666e;
    }
  }

  .groupDetailWrapper {
    display: flex;
    background-color: #f9f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid #ecedef;

    .leftContent {
      display: flex;
      flex-direction: column;
      @include rowGap(12px);
      width: 60%;

      .groupTitleWrapper {
        display: flex;
        align-items: center;
        @include columnGap(16px);

        .groupTitle {
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #414449;
          max-width: 60%;
        }
      }

      .groupValue {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #414449;
      }
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      width: 40%;
      @include rowGap(12px);

      .assignedLabel {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #949aa2;
        margin-bottom: 8px;
      }

      .facilitatorCard {
        display: flex;
        align-items: center;
        @include columnGap(8px);

        .facilitatorAvatar {
          border-radius: 50%;
          width: 32px;
        }

        .facilitatorName {
          font-size: 16px;
          line-height: 24px;
          color: #414449;
        }
      }
    }
  }
}
