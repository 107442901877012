.selectGroup {
  display: none;
}

.active {
  visibility: visible;
  opacity: 1;
  height: 100%;
  transition: opacity 500ms;
}

.inactive {
  visibility: hidden;
  opacity: 0;
  height: 0;
  display: none;
  transition: opacity 500ms;
}
