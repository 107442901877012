@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: #f5f6ff;

  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;
    cursor: pointer;

    .title {
      font-weight: 600;
      font-size: 16px;
      color: #414449;

      .highlight {
        color: $colorBlue1;
      }
    }

    .icon {
      color: #414449;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .rowContainer {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    height: 70px;
    border-bottom: 1px solid #ebeff2;
    color: $colorBlue3;

    .label {
      display: flex;
      align-items: center;
      flex: 1.5;
      font-size: 16px;
      font-weight: 600;
    }

    .mapsTo {
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 14px;
    }

    .checkboxContainer {
      display: flex;
      align-items: center;
      flex: 0.3;

      .checkbox {
        width: 18px;
        height: 18px;
      }
    }

    .hidden {
      opacity: 0%;
    }

    .selectionContainer {
      display: flex;
      flex: 2;
      font-weight: 600;
      font-size: 16px;
      color: #414449;
      align-items: center;
    }
  }

  .fields {
    max-height: 752px;
    overflow: hidden;
    transition: all 0.8s ease;
  }

  .hiddenFields {
    @extend .fields;
    max-height: 0;
  }
}
