@import 'assets/theme';

.container {
  flex: 1 0 48%;
  max-width: 48%;
  margin-bottom: 16px;
  border: 2px solid #e6e9f2;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border 0.2s;
  position: relative;
  user-select: none;

  &.selectable {
    cursor: pointer;

    &:hover {
      border: 2px dashed #3f68ff;
    }
  }

  &.selected {
    border: 2px solid #3f68ff;

    &:hover {
      border: 2px solid #3f68ff;
    }

    .tick {
      opacity: 1;
    }
  }

  .tick {
    position: absolute;
    top: -10px;
    right: -10px;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #dff0f8;
    border: 1px solid $colorBlue1;
    transition: opacity 0.1s;

    .icon {
      color: $colorBlue1;
    }
  }

  .card {
    padding: 24px;
    background: #f8f9fd;
    border-radius: 8px;
  }

  .date {
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
    line-height: 20px;
    color: #696969;
  }

  .title {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .desc {
    font-size: 13px;
    line-height: 16px;
    color: $colorBlue3;
  }

  .emotionWrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;

    .badge {
      padding: 8px 12px;
      border-radius: 20px;
      background-color: #bae3a3;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #343434;
      margin-bottom: 8px;
      margin-right: 8px;
    }

    .badgeNegative {
      @extend .badge;
      background-color: #d5e9f3;
    }
  }
}
