@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  color: #414449;
  width: 60%;

  .dateInfo {
    display: flex;
    align-items: center;

    .time {
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      padding: 8px 16px 8px 0;
    }

    .appointmentDate {
      font-weight: 600;
      font-size: 14px;
      line-height: 23px;
      border-left: 1px solid #c7c9cd;
      padding: 8px 16px;
    }

    .editBtn {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $colorBlue1;
      padding: 8px 16px;

      .icon {
        margin-right: 4px;
        font-size: 16px;
      }
    }
  }

  .detailInfo {
    display: flex;
    flex-direction: column;
    margin: 28px 0;
    @include rowGap(16px);

    .detailCard {
      display: flex;

      .detailIcon {
        margin-right: 16px;
        font-size: 24px;
        color: $colorBlue3;
      }

      .descWrapper {
        display: flex;
        flex-direction: column;

        .detailTitle {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }

        .detailDesc {
          font-style: italic;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
}
