@import 'assets/theme';

.container {
  flex: 1 0 calc(33% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: max-content;
  max-width: calc(33% - 64px);
  margin: 16px 32px;
  background: #fbfbfb;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  overflow: hidden;

  .previewContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    width: 100%;
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    overflow: hidden;

    .image {
      width: 100%;
    }

    .fileType {
      font-size: 32px;
      font-weight: bold;
    }
  }

  .titleButton {
    flex: none;
    width: max-content;
    margin: 8px 16px;
    padding: 0;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.1px;
    word-wrap: break-word;
    color: $colorBlue1;
    border: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .date {
    padding: 0 16px;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: #343434;
  }

  .linked {
    padding: 8px 16px;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: #343434;

    .link {
      font-weight: bold;
    }
  }

  .deleteButton {
    align-self: flex-end;
    width: auto;
    margin-right: 8px;
    margin-bottom: 4px;
    padding: 0;
    border: none;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $main-red;
  }

  .lockIcon {
    align-self: flex-end;
    width: auto;
    margin-right: 8px;
    margin-bottom: 4px;
    padding: 0;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
  }
}
