@import 'assets/theme';

.container {
  padding: 15px;
  letter-spacing: 0.1px;
  &:not(:first-child) {
    margin-top: 23px;
  }

  &.cancelled {
    background-color: #fff0f0;
  }

  &.tagged {
    background-color: #faf7f7;
  }
}

.header {
  display: flex;

  .title {
    flex: 1 1;
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
    color: $colorTextualBlack;
  }

  .clearAlertBtn {
    display: flex;
    align-items: center;
    padding: 0;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #ff0000;
    background-color: transparent;
    border: none;
  }

  .clearAlertBtnBusy {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @include columnGap(20px);

  @media (max-width: $medium-screen-min-width) {
    flex-direction: column;
  }

  .clientInfoWrapper {
    display: flex;
    flex: 1 2 40%;
    margin-right: 24px;
    @include columnGap(20px);

    @media (max-width: $medium-screen-min-width) {
      margin-right: 0;
      flex: 1;
      margin-bottom: 24px;
    }

    .avatarAndName {
      display: flex;
      min-width: 100px;
      max-width: 100px;

      @media (max-width: $medium-screen-min-width) {
        min-width: 140px;
        max-width: 140px;
      }

      .containerClassName {
        align-items: flex-start;
      }

      .name {
        font-size: 13px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0.1px;
        color: #323c47;
        padding: 4px 0 0;
      }
    }
  }

  .clientAppointmentWrapper {
    display: flex;
    flex: 1 2 60%;
    @include columnGap(20px);

    @media (max-width: $medium-screen-min-width) {
      justify-content: space-between;
      margin-right: 0;
      flex: 1;
    }
  }

  .dateTime {
    width: 25%;
    display: flex;
    flex-direction: column;
    line-height: 23px;
    letter-spacing: 0.1px;
    min-width: 154px;
    @include rowGap(10px);

    .title {
      font-size: 11px;
      line-height: 15px;
      color: #6575b1;
    }

    .date {
      font-size: 13px;
      color: $colorTextualBlack;
    }

    .time {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: $colorBlue3;
    }
  }

  .appointmentTime {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: $colorTextualBlack;
    max-width: 140px;
    min-width: 140px;
    @include rowGap(4px);

    @media (max-width: $medium-screen-min-width) {
      max-width: 100px;
      min-width: 100px;
    }

    .Status {
      font-size: 11px;
      line-height: 15px;
      color: #6575b1;
    }
  }

  .cancelledTime {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: $colorBlue3;
  }

  .actions {
    display: flex;
    align-items: center;

    .button {
      display: flex;
      align-items: center;
      padding: 0;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $colorBlue1;
      background-color: transparent;

      .icon {
        margin-left: 2px;
        font-size: 18px;
      }
    }
  }
}
