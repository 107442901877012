@import '../../assets/theme/index';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: $large-screen-min-width;
  margin: 0 auto;
  padding: 0 16px;

  @media (max-width: $medium-screen-min-width) {
    overflow-x: auto;
  }
}

.background {
  background-color: $mhh-lightest-yellow-20;

  &.whiteBg {
    background-color: $white;
  }

  &.transparentBg {
    background-color: transparent;
  }
}
