.mbsc-segmented {
  max-width: 300px;
  margin: 0 auto;
}

.cal-header-picker {
  flex: 1 0 auto;
}

.cal-header-nav {
  width: 200px;
}

/* material header order */

.mbsc-material .cal-header-today {
  order: 1;
}

.mbsc-material.cal-header-prev {
  order: 2;
}

.mbsc-material.cal-header-next {
  order: 3;
}

.mbsc-material.cal-header-nav {
  order: 4;
}

.mbsc-material .cal-header-picker {
  order: 5;
}
