@import 'assets/theme';

.text {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.1px;
  color: $black;
}

.button {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 10px 16px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: $mhh-dark-blue;
  background-color: #fff;
  border: 1px solid $mhh-yellow;
  border-radius: 4px;

  .icon {
    margin-right: 12px;
  }
}
