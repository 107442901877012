@import 'assets/theme';

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #323c47;
  padding-bottom: 20px;
}

.content {
  border: 1px solid #ecedef;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  .assignedButton {
    position: absolute;
    top: 16px;
    right: 8px;
    .button {
      height: fit-content;
      padding: 8px;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      border-radius: 4px;
      box-shadow: none;
      &disabled {
        cursor: pointer;
        border: 1px solid $mid-grey-dark;
        color: $mid-grey-dark;
      }
      .icon {
        margin-right: 4px;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  .header {
    padding: 20px;
  }

  .loadingWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include rowGap(8px);

    .loading {
      width: 100%;
      border-radius: 4px;
      padding: 0 16px;
      height: 20px;
    }
  }

  .groupList {
    @include rowGap(20px);
    .grayOutLabel {
      text-align: center;
      color: $colorTextualGray;
    }
    .listItem {
      height: 80px;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
      border-radius: 16px;

      padding: 24px;
      align-items: center;
      color: #414449;

      .groupName {
        font-size: 16px;
        font-weight: bold;
      }

      .groupTag {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .tagPill {
          display: flex;
          align-items: center;
          height: 27px;
          background: $colorBlue3;
          border-radius: 43px;
          color: #fff;
          padding: 6px 8px;
          white-space: nowrap;
          font-size: 10px;
        }
      }

      .groupLead {
        font-weight: 600;
        font-size: 14px;
      }

      .joinedDate {
        display: block;
        font-weight: 600;
        font-size: 12px;

        .date {
          color: #6575b1;
        }

        .joinedSince {
          color: #949aa2;
        }
      }

      .status {
        display: flex;
        align-items: center;
        height: 24px;
        border-radius: 16px;
        padding: 3px 12px;
        font-weight: 600;
        font-size: 12px;
        color: #fff;
      }

      .groupStage {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .stagePill {
          display: flex;
          align-items: center;
          height: 27px;
          background: $colorBlue3;
          border-radius: 43px;
          color: #fff;
          padding: 6px 8px;
          white-space: nowrap;
          font-size: 10px;
        }
      }

      .active {
        background: #66cb91;
      }

      .completed {
        background: #426eb1;
      }

      .archived {
        background: #ff9d73;
      }

      .icon {
        cursor: pointer;
      }
    }
  }
}

.containerLoading {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  @include rowGap(12px);

  .paginationLoading {
    display: flex;
    justify-content: space-between;

    .perPageLoading {
      max-width: 150px;
      width: 100%;
      height: 25px;
    }

    .showLoading {
      max-width: 250px;
      width: 100%;
      height: 25px;
    }
  }
}

.noGroupFound {
  display: flex;
  padding: 24px 16px;
  border-bottom: 0.5px solid #ecedef;
  justify-content: center;
}
