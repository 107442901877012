@import 'assets/theme';

.container {
  .labelContainer {
    padding-bottom: 24px;

    .label {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: $colorBlue1;
    }
  }

  .fieldContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 32px;
    max-width: 380px;

    &.fieldError,
    .fieldError {
      font-size: 12px;
      color: #ff4d4f;

      input {
        border-bottom: 1px solid #ff4d4f !important;
      }
    }
  }
}

.containerWithBox {
  @extend .container;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: -8px -16px 24px;
  padding: 16px;
}
