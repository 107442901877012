.container {
  display: flex;
  flex-direction: column;
  background: #f8f9fd;
  box-sizing: border-box;
  padding: 8px 12px;

  & > * {
    cursor: move;
  }

  .title {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
    padding-bottom: 4px;
  }

  .desc {
    font-size: 13px;
    line-height: 16px;
    color: #343434;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.containerPreview {
  @extend .container;

  & > * {
    cursor: auto;
  }
}