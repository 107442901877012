@import 'assets/theme';

.select {
  width: 100%;
  border-radius: 8px;

  &.error {
    margin-bottom: 0;
  }

  &.noSpacing {
    margin-bottom: 0;
  }
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: $colorTextualGray;
  margin-bottom: 4px;
}

.icon {
  line-height: 18px;
  color: #aeaeae;
  font-size: 20px;
}

.loadingWrapper {
  display: flex;

  .loading {
    width: 100%;
  }
}

:export {
  greyColor: $colorLineGray;
  yellowColor: $mhh-yellow;
  lightYellowColor: $mhh-light-yellow;
  blueColor: $mhh-dark-blue;
  whiteColor: $white;
}
