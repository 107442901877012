.container {
  width: 100%;
  background: #fff;
  padding: 20px;
  border: 1px solid #ecedef;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }
}
