.content {
  overflow: hidden;
}

.hideContent {
  @extend .content;
  max-height: 0 !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.hideMinHeightContent {
  @extend .content;
  max-height: 130px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.showContent {
  @extend .content;
  max-height: 1500px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
