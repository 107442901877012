@import 'assets/theme';

.loading {
  display: flex;
  justify-content: center;
}

.boxContainer {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px;

  .container {
    display: flex;
    flex: 1;
    flex-direction: column;

    .title {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: #343434;
    }

    .desc {
      margin-bottom: 16px;
      font-size: 13px;
      line-height: 20px;
      color: #343434;
    }

    .selectLabel {
      font-size: 13px;
      line-height: 20px;
      color: #343434;
    }

    .selectTeacher {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .listWrapper {
      width: max-content;
      margin-bottom: 24px;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      .select {
        width: 280px;
      }

      .dropdownIcon {
        color: $mhh-yellow;
      }

      .checkboxContainer {
        margin-top: 16px;
        margin-left: 32px;

        .checkbox {
          margin-left: 16px;

          &:checked {
            background-color: $mhh-yellow;
            border-color: $mhh-yellow;

            + div {
              .label {
                color: $mhh-yellow;
              }
            }
          }
        }

        .label {
          margin-top: 1px;
          margin-bottom: 8px;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: $mhh-light-black;
          transition: all 0.1s linear;
        }

        .labelDesc {
          margin-bottom: 8px;
          font-size: 14px;
          line-height: 16px;
          color: $mhh-light-black;
        }
      }

      .isLinkedIcon {
        position: absolute;
        right: -32px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;
        color: $mhh-green;
      }
    }

    .buttonWrapper {
      display: flex;
      justify-content: flex-end;
    }

    .profileBadgeClassName {
      min-width: 90%;
    }
  }
}
