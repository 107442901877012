@import 'assets/theme';

.headerLoading {
  width: 60%;
  height: 30px !important;
  margin-bottom: 8px;
}

.descLoading {
  width: 90%;
  height: 16px !important;
}
