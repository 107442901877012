@import 'assets/theme';

.consentGroupSelect {
  border: 1px solid #ecedef;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .selectConsentGroupWrapper {
    padding: 12px 16px;

    .selectConsentGroupTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: $colorTextualBlack;
    }

    .selectConsentGroupSubtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #323c47;
      opacity: 0.5;
    }

    .consentFormItems {
      max-height: 400px;
      margin-top: 6px;
      padding-right: 4px;

      .labelClassName {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      .consentFormItem {
        display: flex;
        margin: 23px 0px;

        .consentItemDetail {
          margin-left: 15px;
          label {
            cursor: pointer;
          }
        }

        .consentFormItemName {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #343434;
        }
        .consentFormItemChecked {
          color: $colorBlue1;
        }
      }
    }
  }
}

.submitBtn {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
}
