@import 'assets/theme';

.container {
  @include rowGap(30px);

  .subTitle {
    display: flex;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $colorTextualGray;
  }
}

.loadingWrapper {
  margin: 32px 0 16px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 32px;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
}

.clientDetailTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $colorTextualGray;
}
