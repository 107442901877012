@import 'assets/theme/index';

.container {
  width: 100%;
  margin-bottom: 24px;
  background-color: #f8f9fd;

  .progressBar {
    display: flex;
    flex: 1;
    justify-content: space-between;
    max-width: 850px;
    margin: 0 auto;
    width: 100%;
    padding: 16px 0;
    text-align: center;

    .stage {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 65px;

      .icon {
        background-color: $white;
        height: 34px;
        width: 34px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #34343433;
        font-weight: bold;
        line-height: 18px;
        font-size: 13px;
        transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
        margin-bottom: 8px;

        &.isActive {
          background-color: $mhh-yellow;
          color: $mhh-dark-blue;
        }

        .checkmark {
          color: $mhh-green;
        }
      }

      .iconComplete {
        @extend .icon;
        box-sizing: border-box;
        border: 2px solid $mhh-green;
      }

      .label {
        display: flex;
        flex: 1;
        justify-content: center;
        font-size: 12px;
        line-height: 18px;
        color: #969698;
        transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
        max-width: 100px;

        @media (max-width: $medium-small-screen-min-width) {
          max-width: 65px;
        }

        &.isActive {
          font-weight: 600;
          color: #343434;
        }
      }
    }
  }
}
