@import 'assets/theme';

.container {
  display: flex;
  @include columnGap($standard-padding * 2);

  & > div:not(:first-child) {
    flex: 1;
  }
}
