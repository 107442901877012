@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(10px);

  .toggleMessageContainer {
    display: flex;
    justify-content: space-between;

    .toggleMessage {
      font-size: 16px;
      color: #414449;
    }
  }

  .description {
    font-style: italic;
    font-size: 14px;
    color: #426eb1;
  }

  .disabledDescription {
    font-style: italic;
    font-size: 14px;
    color: #eb4545;
  }
}
