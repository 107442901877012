@import 'assets/theme';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  padding: 28px 20px;
  background: $white;
  border-radius: 8px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  position: relative;

  .closeIcon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    padding: 8px;
    cursor: pointer;
    user-select: none;

    &:active {
      background-color: #f8f9fd;
    }

    .icon {
      color: #00000080;
    }
  }

  .logoContainer {
    width: 200px;
    height: 200px;

    .logo {
      width: 200px;
      height: 200px;
      background-color: #dbdbdb4c;
      border: 1px dotted $colorBlue1;
      cursor: pointer;
    }

    .uploadLogoPrompt {
      @extend .logo;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 18px;
      color: $colorBlue1;

      .icon {
        margin-bottom: 4px;
        font-size: 48px;
      }
    }
  }

  .detailsContainer {
    width: calc(100% - 224px);

    .nameInput,
    .mobileNumberInput {
      margin-top: 24px;
    }

    .nameInput {
      font-size: 24px;
      font-weight: 600;
    }

    .location {
      display: flex;
      align-items: flex-end;
      margin-top: 24px;

      .inputContainer {
        max-width: 320px;
      }

      .removeButton {
        display: flex;
        align-items: center;
        margin-left: 16px;
        padding: 0;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.1px;
        line-height: 20px;
        color: $mhh-dark-blue;
        border: none;
      }
    }
  }
}
