@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #323c47;
    padding-bottom: 20px;
  }

  .clientInfoSection {
    display: flex;
    flex-direction: column;
  }

  .docSection {
    display: flex;
    flex-direction: row;
    @include columnGap(120px);
  }

  .profileTags {
    margin-bottom: 50px;
  }
}
