@import 'assets/theme';

.container {
  display: flex;
  min-width: 800px;

  .loadingContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .loadingBox {
      display: flex;
      align-items: center;
      min-height: 30px;
      padding: 16px 24px;
      box-sizing: border-box;

      .loadingSearchbar {
        width: 100%;
      }

      .loadingName {
        width: 80%;
      }
    }
  }

  .submitButtonContainer {
    border: none !important;
    background-color: transparent !important;
    width: fit-content !important;
    padding: 0 !important;
  }

  .submitButton {
    width: 100%;
    max-height: 42px;
  }
}
