@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  margin-top: 40px;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);

  .saveRadioContainer {
    padding: 8px 16px;
  }

  .authorWrapper {
    padding: 8px 0 20px;
    margin: 0 16px;
    border-bottom: 1px solid #eee;

    .profileWrapper {
      display: flex;
      align-items: center;
      padding-top: 16px;

      .imgProfile {
        border-radius: 50%;
        width: 55px;
        height: 55px;
        margin-right: 12px;
      }

      .authorName {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #494949;
        text-align: center;
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: $colorTextualBlack;
  }

  .saveNShareContainer {
    padding: 16px;

    .badge {
      display: flex;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      min-width: 90px;
      margin: 0;
    }

    .statusWrapper {
      display: flex;
      justify-content: space-between;
      padding: 24px 0;

      .publishedBadge {
        @extend .badge;
        background-color: #219653;
      }

      .editBtn {
        display: flex;
        color: $colorBlue1;
        font-size: 13px;
        cursor: pointer;

        .icon {
          margin-right: 4px;
          font-size: 22px;
        }

        .label {
          display: flex;
          align-items: center;
        }
      }
    }

    .shareWrapper {
      display: flex;
      flex-direction: column;
      padding-top: 16px;

      .badgeWrapper {
        display: flex;
        padding-bottom: 16px;

        .shareBadge {
          @extend .badge;
          background-color: $colorBlue2;
        }
      }

      .recipientListWrapper {
        display: flex;
        flex-direction: column;

        .clientItem {
          padding: 16px 0 !important;
        }

        .recipientList {
          &:not(:last-child) {
            border-bottom: 1px solid #f2f2f2;
          }
        }

        .recipientItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 24px 0;

          .client {
            display: flex;
            align-items: center;

            .clientAvatar {
              width: 50px;
              height: 50px;
              margin-right: 4px;
            }
          }

          .recipientEmail {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #323c47;
          }

          .revokeBtn {
            font-weight: 600;
            font-size: 11px;
            line-height: 20px;
            color: #ff2500;
            cursor: pointer;
          }
        }
      }
    }

    .shareLink {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 13px;
      line-height: 24px;
      color: $colorBlue1;
      cursor: pointer;
      padding-top: 16px;

      .plusIcon {
        margin-right: 8px;
      }
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: center;

    .button {
      align-self: center;
      margin: 8px 0 16px;
      border-radius: 4px;
      box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
    }
  }
}
