@import 'assets/theme';

:global {
  .pac-container {
    z-index: 3000;
  }
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid #ebeff2;

    .title {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      color: #323c47;

      .tooltipIcon {
        margin-left: 8px;
        color: #fd8468;
        border-radius: 50%;
        cursor: pointer;
      }

      .tooltip {
        max-width: 200px;
        border-radius: 8px;
        line-height: 16px;
        font-size: 14px;
      }
    }

    .icon {
      color: #c2cfe0;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 16px;

    .profileInfo {
      display: flex;
      padding: 16px 24px;
      border-radius: 8px;
      box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      .image {
        height: 80px;
        width: 80px;
        border-radius: 16px;
      }

      .clinicianImage {
        @extend .image;
        border: 3px solid #0086be;
        padding: 3px;
        border-radius: 50%;
      }

      .detailsWrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-left: 8px;

        .detailsTitle {
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
          padding-left: 4px;
          color: #343434;
        }

        .detailInfoWrapper {
          display: flex;
          flex-direction: column;
          transition: all 0.2s ease;
          border-radius: 4px;
          padding: 2px 4px;

          .inputWrapper {
            display: flex;
            margin-top: 8px;

            &:not(:last-child) {
              margin-bottom: 8px;
            }

            .questionWrapper {
              display: flex;
              flex: 1;
              flex-direction: column;
            }

            .checkboxContainer {
              display: flex;
              align-items: flex-start;
              margin-right: 8px;
            }

            .checkbox {
              appearance: none;
              margin: 0;
              height: 20px;
              width: 20px;
              background-color: transparent;
              border: 2px solid #d5d5d5;
              border-radius: 4px;
              color: #d5d5d5;
              cursor: pointer;
              outline: none;
              transition: all 0.1s linear;

              &:after {
                content: '\2713';
                display: none;
                justify-content: center;
                align-items: center;
                width: 16px;
              }

              &:checked {
                font-size: 12px;
                line-height: 16px;
                font-weight: 800;
                color: #fff;
                background-color: $colorBlue1;
                border: 2px solid $colorBlue1;

                &:after {
                  display: flex;
                }
              }
            }
          }

          .detailInput {
            margin-top: 14px;
          }
        }
      }
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
  }
}
