@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  position: relative;

  .field {
    border: 0;
    border-bottom: 1px solid #c4c4c4;
    color: $colorTextualBlack;
    outline: none;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    font-size: 16px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.25);
      font-size: 14px;
    }

    &:valid {
      border-bottom: 1px solid #c4c4c4;
      + {
        .label {
          color: $colorTextualGray;
          font-size: 11px;
          font-weight: 600;
          left: 2px;
          bottom: 22px;
          pointer-events: none;
        }
      }
    }
    &:read-only {
      border-bottom: 1px solid #c4c4c4;
      + {
        .label {
          color: $colorTextualGray;
          font-size: 11px;
          font-weight: 600;
          left: 2px;
          bottom: 22px;
          pointer-events: none;
        }
      }
    }
    &:focus {
      border-bottom: 1px solid #c4c4c4;
      + {
        .label {
          color: $colorTextualGray;
          font-size: 11px;
          font-weight: 600;
          left: 2px;
          bottom: 22px;
          pointer-events: none;
        }
      }
    }
    &:disabled {
      border-bottom: 1px solid #c4c4c4;
      color: $colorTextualDisabled;
      background-color: $white;
    }
  }

  .fieldWithLoading {
    @extend .field;
    padding-right: 40px;
  }

  .label {
    bottom: 0;
    left: 0;
    color: $colorTextualGray;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 14px;
    cursor: text;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    height: 30px;
    line-height: 16px;
    letter-spacing: 0.2px;
  }

  .maximizeLabel {
    color: $colorTextualGray;
    font-size: 11px;
    font-weight: 600;
    left: 2px;
    bottom: 22px;
    pointer-events: none;
  }

  .lockIcon {
    border-bottom: 1px solid #c4c4c4;
    color: #949aa2;
    cursor: default;
  }

  .loadingWrapper {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .loadingBox {
      position: absolute;
      left: -26px;
    }
  }
}
