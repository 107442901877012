@import 'assets/theme';

.title {
  padding: 16px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #323c47;
}

.invoiceSummary {
  margin: 8px;
  padding: 16px;
  background-color: #fcf7f7;

  .titles {
    display: flex;
    margin-bottom: 16px;

    .item {
      padding: 0 4px;
      font-size: 12px;
      line-height: 16px;
      color: #34343480;

      &:first-child,
      &:nth-child(2) {
        width: 15%;
      }

      &:nth-child(3) {
        width: 27%;
      }

      &:nth-child(4) {
        width: 13%;
      }

      &:nth-child(5) {
        width: calc(15% - 16px);
        text-align: center;
      }

      &:last-child {
        width: 15%;
        text-align: center;
      }
    }
  }

  .values {
    display: flex;
    align-items: center;

    .invoiceId {
      max-height: 60px;
      width: 15%;
      padding: 0 4px;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-align: left;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
    }

    .issueDate {
      width: 15%;
      padding: 0 4px;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434;
    }

    .description {
      width: 27%;
      max-height: 60px;
      padding: 0 4px;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-overflow: ellipsis;
      word-wrap: break-word;
      color: #343434;
      overflow: hidden;
    }

    .invoiceAmount {
      width: 13%;
      padding: 0 4px;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-overflow: ellipsis;
      color: #343434;
      overflow: hidden;
    }

    .status {
      width: calc(15% - 16px);
      margin: 0 8px;
      padding: 4px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.01em;
      text-align: center;
      color: $white;
      border-radius: 4px;

      &.draft {
        color: #000000c0;
        border: 1px solid $colorBlue1;
      }

      &.issued {
        background-color: $colorBlue2;
      }

      &.overdue {
        background-color: $main-red;
      }

      &.markedPaid {
        color: #343434;
        background-color: #6fcf97;
      }

      &.confirmPaid {
        background-color: #219653;
      }

      &.closed {
        background-color: #f5ad58;
      }
    }

    .actionButtonContainer {
      display: flex;
      flex-direction: column;
      width: 15%;
      padding: 4px;
    }
  }
}

.invoiceView {
  padding: 8px;
}
