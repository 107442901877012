@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(16px);

  .button {
    width: 100%;
    box-shadow: none;
    font-size: 16px;
  }

  .redButton {
    display: flex;
    justify-content: center;
    padding: 8px;

    .label {
      color: #eb4545;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .loading {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
