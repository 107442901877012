@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;

  .heading {
    display: flex;
    padding: 16px;

    .title {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #414449;
      padding-right: 16px;
      text-transform: uppercase;
    }

    .total {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      padding: 4px;
      background-color: $colorBlue3;
      border-radius: 50%;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $white;
    }
  }

  .cardWrapper {
    display: flex;
    flex-direction: column;
    padding: 4px 16px 24px;
    @include rowGap(20px);

    .month {
      display: flex;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #414449;
    }
  }

  .loadingContainer {
    padding: 0 16px 24px;

    .loadingMonth {
      width: 80px;
      height: 21px;
      border-radius: 4px;
    }

    .loadingWrapper {
      display: flex;
      margin: 20px;

      .loadingContent {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-left: 16px;
        @include rowGap(4px);

        .headingLoading {
          display: flex;

          .titleLoading {
            width: 260px;
            border-radius: 4px;
            height: 21px;
          }

          .tags {
            width: 90px;
            border-radius: 4px;
            height: 21px;
            margin-left: 20px;
          }
        }

        .desc1 {
          width: 200px;
          border-radius: 4px;
          height: 21px;
        }

        .desc2 {
          width: 150px;
          border-radius: 4px;
          height: 21px;
        }
      }

      .selectTagsLoading {
        width: 180px;
        border-radius: 4px;
        height: 52px;
      }
    }
  }
}
