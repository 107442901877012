@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 2);
}

.row {
  display: flex;
  @include columnGap($standard-padding * 2);

  & > span {
    color: $colorBlue3;
    font-size: 20px;
  }
}

.iconContainer {
  padding-top: $standard-padding;
}

.label {
  font-size: 11px;
  font-weight: 500;
  color: $colorTextualGray;
  padding-bottom: 6px;
}

.eventNoteContainer {
  flex: 1;

  .textarea {
    width: 100%;
    height: 148px;
    padding: 8px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    box-shadow: 0px 6px 18px 0px #0000000f;
    resize: none;
  }
}

.fieldError {
  font-size: 12px;
  color: #ff4d4f;
}
