@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
}

:export {
  blueColor: $colorBlue1;
}
