@import 'assets/theme';

.header {
  padding: 24px 32px 16px;
  border-bottom: 1px solid #ebeff2;

  .title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #323c47;
  }

  .description {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.sectionHeading {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 0.1px;
  color: $colorTextualBlack;
}

.formatTypeContainer {
  width: 100%;
  padding: 32px;
  border-bottom: 1px solid #ebeff2;

  .radioButtonContainer {
    padding: 16px 16px 0;
  }

  .templateFormatTypeContainer {
    display: flex;
    width: 100%;
    padding: 16px 16px 0;

    .templateRadioButton {
      padding-top: 10px;
    }

    .select {
      margin-left: 36px;
      width: calc(25% - 18px);
      max-width: 200px;

      .label {
        margin-bottom: -10px;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: $colorTextualGray;
      }
    }

    .newFormatName {
      display: flex;
      flex-direction: column;

      .formatName {
        margin-top: 24px;
      }
    }
  }
}

.fieldsContainer {
  padding: 32px;
  border-bottom: 1px solid #ebeff2;

  .lockEdits {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .unlockEditsButton {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $mhh-yellow;
      border-radius: 4px;

      .icon {
        margin-right: 8px;
        font-size: 28px;
      }
    }

    .text {
      margin-left: 16px;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434;
    }
  }

  .editContainer {
    position: relative;

    .disabled {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $white;
      opacity: 0.8;
    }
  }

  .description {
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;

    .icon {
      margin: 0 2px;
    }

    .starIcon {
      margin: 0 4px;
      color: $mhh-yellow;
    }
  }
}

.headingTemplateContainer {
  padding: 32px;
  position: relative;

  .disabled {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $white;
    opacity: 0.8;
    z-index: 1;
  }

  .existingTemplateContainer {
    padding: 16px;
    padding-bottom: 0;

    .select {
      margin-left: 36px;
      max-width: 200px;

      .label {
        margin-bottom: -10px;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: $colorTextualGray;
      }
    }
  }

  .newTemplateContainer {
    padding: 16px;

    .newTemplateEditor {
      margin-left: 36px;
      margin-top: 12px;

      .textEditorContainer {
        margin-top: 8px;
      }
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding: 16px;

  .submitButton {
    flex: none;
    display: flex;
    width: auto;
    min-width: 200px;
    border-radius: 4px;
    box-shadow: none;
  }
}
