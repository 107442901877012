@import 'assets/theme';
.header {
  background-color: #b2baff;
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;

  .name {
    padding: 20px 30px;
    display: flex;
    gap: 20px;

    .studentName {
      font-weight: 600;
      font-size: 20px;
    }

    .assessmentName {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      min-width: 153px;
      display: flex;
      align-items: center;

      .sameAssessmentIdCount {
        font-weight: 700;
        font-size: 20px;
        margin-right: 10px;
      }
    }
  }

  .assignment {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 18px;
    border: solid 1px #bbc3ce;
    border-radius: 4px;
    background-color: $white;
    max-width: 340px;
    flex: 1;

    .labels {
      display: flex;
      font-size: 10px;
      padding: 4px 8px;
    }

    .assignedByContainer {
      flex: 1 40%;
      min-width: 120px;
      max-width: 120px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .assignedOnContainer {
      flex: 1 60%;
    }

    .assessmentListOption {
      display: flex;
      color: black;
      font-weight: 600;
      font-size: 16px;
      margin-right: -10px;

      &.value {
        font-size: 18px;
        padding: 0 8px;
      }
    }
  }
}
