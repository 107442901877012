@import 'assets/theme';

.container {
  width: 100%;
  background: #fff;
  padding: 20px;
  border: 1px solid #ecedef;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.noPsychometrics {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  min-height: 500px;

  .image {
    max-width: 250px;
    width: 100%;
  }

  .prompt {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: 0.1px;
      text-align: center;
      color: #414449;
    }

    .button {
      display: flex;
      align-items: center;
      margin-top: 16px;
      padding: 10px 16px;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $mhh-dark-blue;
      background-color: #fff;
      border: 1px solid $mhh-yellow;
      border-radius: 4px;

      .icon {
        margin-right: 12px;
      }
    }
  }
}
