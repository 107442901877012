@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  &.filledArrow {
    position: relative;
  }

  .label {
    font-size: 11px;
    font-weight: 500;
    color: $colorTextualGray;
  }

  &:hover {
    .arrow {
      opacity: 0.8;
    }
  }

  .arrow {
    color: #3f52ff;
    display: flex;

    i {
      padding-right: 8px;
      font-size: 24px;
    }
  }
}

:export {
  greyColor: $colorLineGray;
  blueColor: $colorBlue3;
  whiteColor: $white;
  yellowColor: $mhh-yellow;
  lightYellowColor: $mhh-light-yellow;
}
