@import 'assets/theme';

.container {
  .checkboxContainer {
    padding: 16px 0;
    display: flex;
    cursor: pointer;

    .checkbox {
      appearance: none;
      margin: 0;
      height: 20px;
      width: 20px;
      min-width: 20px;
      background-color: transparent;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      color: #d5d5d5;
      cursor: pointer;
      outline: none;
      transition: all 0.1s linear;

      &:after {
        content: '\2713';
        display: none;
        justify-content: center;
        align-items: center;
        width: 16px;
      }

      &:checked {
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
        color: #fff;
        background-color: $colorBlue1;
        border: 2px solid $colorBlue1;

        &:after {
          display: flex;
        }
      }
    }

    .text {
      margin-left: 8px;

      .label {
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #343434;
        cursor: pointer;
      }

      .description {
        margin-top: 4px;
        font-size: 14px;
        line-height: 19px;
        color: #00000080;
        cursor: pointer;
      }
    }
  }

  .disabledCheckboxContainer {
    @extend .checkboxContainer;
    cursor: not-allowed;

    .checkbox {
      cursor: not-allowed;
      background-color: $mid-grey !important;
      border: 2px solid $mid-grey !important;
    }

    .text {
      .label {
        color: $mid-grey;
        cursor: not-allowed;
      }

      .description {
        color: $mid-grey;
        cursor: not-allowed;
      }
    }
  }
}
