.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .avatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }

  .avatarMultipleBox {
    display: flex;
    align-items: center;

    .showBalanceLabel {
      margin-left: 4px;
    }
  }

  .avatarMultipleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    .avatarMultipleWrapper {
      border-radius: 50%;
      border: 2px solid #fff;
      width: 50px;
      height: 50px;
      object-fit: cover;
      z-index: 6;

      &:nth-child(2) {
        position: absolute;
        left: 30px;
        z-index: 5;
      }

      &:nth-child(3) {
        position: absolute;
        left: 60px;
        z-index: 4;
      }

      &:nth-child(4) {
        position: absolute;
        left: 90px;
        z-index: 3;
      }

      &:nth-child(5) {
        position: absolute;
        left: 120px;
        z-index: 2;
      }

      &:nth-child(6) {
        position: absolute;
        left: 150px;
        z-index: 1;
      }
    }
  }

  .name {
    padding-top: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #494949;
    text-align: center;

    .description {
      font-weight: normal;
      font-size: 14px;
    }
  }

  .ellipsisName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.horizontalContainer {
  @extend .container;
  flex-direction: row;

  .name {
    padding-top: 0;
    padding-left: 10px;
    text-align: left;
  }

  .hideAvatar {
    padding-left: 0 !important;
  }
}
