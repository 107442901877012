@import 'assets/theme';

$height: 30px;
$thumb-height: 16px;
$track-height: 6px;

$upper-color: #cfcfcf;

$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat
  transparent;
$lower-background: linear-gradient(to bottom, $colorBlue1, $colorBlue1) 100% 50% / 100% $track-height no-repeat
  transparent;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 100%;
}

.title {
  width: 100%;
  margin-bottom: 16px;
  padding: 16px 32px;
  font-size: 24px;
  font-weight: bold;
  color: $black;
  border-bottom: 1px solid $mid-grey-light;
}

.body {
  //   padding: 16px 32px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: $medium-small-screen-min-width) {
    display: flex;
    flex-direction: column;

    :first-child {
      align-self: center;
      max-width: 100% !important;
      height: auto !important;
    }
  }
}

.scaleSliderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;

  .scale {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    color: $black;
  }

  .focusRangeBar {
    display: flex;
    appearance: none;
    width: 100%;
    margin: 4px 10px 0;
    height: $height;
    overflow: hidden;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  // Webkit
  .focusRangeBar::-webkit-slider-runnable-track {
    width: 100%;
    height: $height;
    background: $lower-background;
  }

  .focusRangeBar::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: $thumb-height;
    width: $thumb-height;
    background: $colorBlue1;
    border-radius: 100%;
    border: 0;
    top: 50%;
    margin-top: -8px;
    box-shadow: webkit-slider-thumb-shadow();
    transition: background-color 150ms;
  }

  // Firefox
  .focusRangeBar::-moz-range-track,
  .focusRangeBar::-moz-range-progress {
    width: 100%;
    height: $height;
    background: $upper-background;
  }

  .focusRangeBar::-moz-range-progress {
    background: $lower-background;
  }

  .focusRangeBar::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: $thumb-height;
    width: $thumb-height;
    background: $colorBlue1;
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  // Internet Exploder
  .focusRangeBar::-ms-track {
    width: 100%;
    height: $height;
    border: 0;
    // color needed to hide track marks
    color: transparent;
    background: transparent;
  }

  .focusRangeBar::-ms-fill-lower {
    background: $lower-background;
  }

  .focusRangeBar::-ms-fill-upper {
    background: $upper-background;
  }

  .focusRangeBar::-ms-thumb {
    appearance: none;
    height: $thumb-height;
    width: $thumb-height;
    background: $colorBlue1;
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    // IE Edge thinks it can support -webkit prefixes
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  .focusRangeBar:hover,
  .focusRangeBar:focus {
    &::-webkit-slider-thumb {
      background-color: $colorBlue1;
    }
    &::-moz-range-thumb {
      background-color: $colorBlue1;
    }
    &::-ms-thumb {
      background-color: $colorBlue1;
    }
  }
}

.resetBtn {
  display: inline-block;
  text-decoration: underline;
  border-radius: 4px;
  color: $colorBlue1;
  font-weight: 700;
  margin: 20px 0;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.removeBtn {
  color: #fd8468;
  background-color: $white;
  border: solid 2px #fd8468;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  width: 199px;
  margin: auto;
  margin-top: 10px;

  &:hover {
    opacity: 0.6;
  }
}

.uploadNewBtn {
  color: $colorBlue1;
  background-color: $white;
  border: solid 2px $colorBlue1;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  width: 199px;
  margin: auto;
  margin-top: 10px;

  &:hover {
    opacity: 0.6;
  }
}

.sizeLabel {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  color: white;
  background-color: rgba(62, 62, 62, 0.405);
}

.preview {
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 200px;
  overflow: hidden;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0;
  border: 1px solid #eee;
  border-radius: 8px;

  .removeBtn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 6px;
    background-color: rgba(62, 62, 62, 0.405);
    color: white;
    border: none;
    border-radius: 0;
  }

  .sizeLabel {
    display: block;
  }
}

.sizeContainer {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.1px;
  color: $black;
  display: flex;
  gap: 10px;

  .widthContainer,
  .heightContainer {
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .input {
      width: 100%;
      padding: 10px 20px;
      border: solid 2px $colorBlue1;
      border-radius: 12px;
    }
  }
}

.controls {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.editor {
  margin: auto;
  width: 100% !important;
  height: unset !important;
  transform: none !important;
  @media screen and (max-width: $medium-small-screen-min-width) {
    transform: none !important;
  }
}
