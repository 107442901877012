@import 'assets/theme';

.selectLabel {
  color: $colorTextualGray;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.2px;
}
.dropdown {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: $colorTextualBlack;
  // width: 163px;
  height: 29px;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px $colorLineGray;
  position: relative;
  align-items: flex-end;
}

.icon {
  position: absolute;
  right: 0;
  color: #1941da;
}

.selectedSortedBy,
.selectedSortedBy:hover {
  background-color: $colorBlue1;
  color: #fff;
}

.listOption {
  max-height: 164px;
  white-space: nowrap;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
