@import 'assets/theme';

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .navigation {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 16px;
      font-weight: 700;
      color: $colorTextualBlack;
      align-items: center;
      display: flex;
    }

    .sortContainer {
      display: flex;
      align-items: center;
      color: #6a707e;
      font-size: 12px;
      user-select: none;

      .sort {
        margin-left: $standard-padding;
        color: $mhh-yellow;
        cursor: pointer;
        display: flex;
        align-items: center;

        .arrow {
          margin: 0;
          color: #aeaeae;
        }
      }
    }
  }
}
