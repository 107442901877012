.container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .titleContainer {
    margin: 16px 0;
    width: 50% !important;

    .error {
      margin-bottom: 0;
    }
  }

  .safeguardingContainer {
    display: flex;
    flex-wrap: wrap;

    .safeguardingAlert {
      flex: 2;
    }

    .safeguardingReason {
      flex: 5;
      margin-top: 16px;
    }

    .error {
      margin-bottom: 0;
    }
  }

  .fieldContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  }
}
