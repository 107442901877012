@import 'assets/theme';

.container {
  display: flex;
  border-bottom: 1px solid $mhh-dark-blue-10;
  padding: 24px 0;
  transition: all 0.5s ease;

  &:hover {
    background: #fbfbfb;
  }

  .caseId {
    font-size: 12px;
  }

  .program {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #6575b1;
  }

  .icon {
    border: 2px solid #6575b1;
    color: #6575b1;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
  }

  .groupName {
    margin-right: 5px;
    margin-top: 8px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
  }

  .groups {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 19px;
  }

  .seeMore {
    text-decoration: underline;
    border: none;
    background-color: transparent;
    color: $mhh-dark-blue-50;
    padding: 0;
    margin-top: 8px;

    &:hover {
      opacity: 0.5;
    }
  }

  .tag {
    display: inline-block;
    width: fit-content;
    font-weight: 700;
    font-size: 12px;
    color: white;
    background-color: $mhh-dark-blue;
    border-radius: 24px;
    padding: 8px 12px;
    margin-right: 8px;
    margin-top: 8px;
  }

  .completedAssessment {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .completeButton {
    background-color: $mhh-yellow;
    box-shadow: none;
    color: $mhh-light-black;
    min-width: 100%;
  }
}
