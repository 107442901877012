.header {
  display: flex;
  flex-direction: column;

  .edgeTitle {
    font-size: 18px;
  }

  .subtitle {
    font-size: 16px;
  }
}

.plainEnglish {
  display: flex;

  .fullVersion {
    margin: 0;
    flex: 1;
  }

  .showPlainEnglish {
    flex: 1;
  }
}

.termsAndConditions {
  height: 300px;
  overflow-y: scroll;
  font-size: 13px;
  line-height: 18px;
  color: #00000080;
  background-color: #f8f9fd;
  border-radius: 8px;
  padding: 18px;
}

.emailCopy {
  display: flex;
  margin-top: 20px;
  font-size: 13px;
  color: #00000080;
}

.switch {
  margin-left: 12px;
}
