@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(20px);

  .widgetCard {
    display: flex;
    flex-direction: column;
    background: $white;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    .widgetGroup {
      padding: 16px 12px;
      align-items: center;

      &:not(:first-child) {
        border-top: 1px solid #ebeff2;
      }
    }

    .actionTab {
      display: flex;
      justify-content: center;
      padding: 12px 16px;
    }
  }
}
