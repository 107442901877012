@import 'assets/theme';

.container {
  display: flex;
  cursor: pointer;

  .checkbox {
    appearance: none;
    margin: 0;
    height: 20px;
    width: 20px;
    min-width: 20px;
    background-color: $white;
    border: 2px solid #d5d5d5;
    border-radius: 4px;
    color: #d5d5d5;
    cursor: pointer;
    outline: none;
    transition: all 0.1s linear;

    &:after {
      content: '\2713';
      display: none;
      justify-content: center;
      align-items: center;
      width: 16px;
    }

    &:checked {
      font-size: 12px;
      line-height: 16px;
      font-weight: 800;
      color: #fff;
      background-color: $mhh-yellow;
      border: 2px solid $mhh-yellow;

      &:after {
        display: flex;
      }
    }
  }

  .checkBoxLabel {
    margin-left: 8px;
    user-select: none;
  }
}

.disabledContainer {
  @extend .container;
  cursor: not-allowed;

  .checkbox {
    cursor: not-allowed;
    background-color: $mid-grey !important;
    border: 2px solid $mid-grey !important;
  }

  .checkBoxLabel {
    color: $mid-grey;
  }
}
