@import 'assets/theme';

.contentLayout {
  padding-top: $standard-padding * 3;
  width: 100%;
  max-width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 3);
}

.actionButton {
  min-width: 180px;
  position: fixed;
  bottom: 40px;
  right: 174px;
  border-radius: 55px !important;
  height: 55px;
  z-index: 1;

  @media (max-height: 740px) {
    bottom: 10px;
    right: 155px;
  }
}

.finderWizardButton {
  width: 203px;
  height: 50px;
  margin-left: auto;
  margin-right: 0;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgb(16 156 241 / 24%);

  span span {
    margin-right: 25px;
  }
}
