@import 'assets/theme';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
  background: url(~assets/images/CORDS/CORDS-Background.png) no-repeat;
  background-size: cover;
  overflow: hidden;

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    padding: $standard-padding * 4 $standard-padding * 3.5;
    background: $white;

    .logo {
      width: 240px;
    }

    .practiceName {
      margin-top: $standard-padding * 2;
      font-weight: bold;
      font-size: 36px;
      color: $mhh-dark-blue;
    }

    .expired {
      margin-top: $standard-padding * 2;
      font-weight: 600;
      font-size: 16px;
      color: $mhh-dark-blue;
    }

    .prompt {
      margin-top: $standard-padding * 2;
      font-weight: 600;
      font-size: 16px;
      color: $mhh-dark-blue;
    }

    .shortCodeContainer {
      display: flex;
      margin-top: $standard-padding * 4;
      @include columnGap($standard-padding * 3);

      div {
        width: 40px;

        input {
          height: 48px;
          margin-top: 0;
          padding-left: $standard-padding;
          padding-right: $standard-padding;
          border: 1px solid $mhh-blurb-grey-100;
          border-radius: 4px;
          text-align: center;
          transition: border 0.1s linear;

          &:active,
          &:focus {
            border-color: $mhh-dark-blue;
          }
        }
      }
    }

    .verifying {
      div {
        input {
          animation: verifying 0.7s ease-in infinite;
        }

        &:nth-of-type(2) {
          input {
            animation-delay: 0.1s;
          }
        }

        &:nth-of-type(3) {
          input {
            animation-delay: 0.2s;
          }
        }

        &:nth-of-type(4) {
          input {
            animation-delay: 0.3s;
          }
        }

        &:nth-of-type(5) {
          input {
            animation-delay: 0.4s;
          }
        }

        &:nth-of-type(6) {
          input {
            animation-delay: 0.5s;
          }
        }
      }
    }

    @keyframes verifying {
      0% {
        background-color: #f8f9fd;
      }
      50% {
        background-color: #efffd7;
      }
    }

    .verified {
      div {
        input {
          border: 1px solid $mhh-green;
        }
      }
    }

    .errored {
      div {
        input {
          border: 1px solid $mhh-red;
          animation: shake 0.15s;
        }
      }
    }

    @keyframes shake {
      0% {
        transform: translate(1px, 1px) rotate(0deg);
      }
      10% {
        transform: translate(-1px, -2px) rotate(-1deg);
      }
      20% {
        transform: translate(-3px, 0px) rotate(1deg);
      }
      30% {
        transform: translate(3px, 2px) rotate(0deg);
      }
      40% {
        transform: translate(1px, -1px) rotate(1deg);
      }
      50% {
        transform: translate(-1px, 2px) rotate(-1deg);
      }
      60% {
        transform: translate(-3px, 1px) rotate(0deg);
      }
      70% {
        transform: translate(3px, 1px) rotate(-1deg);
      }
      80% {
        transform: translate(-1px, -1px) rotate(1deg);
      }
      90% {
        transform: translate(1px, 2px) rotate(0deg);
      }
      100% {
        transform: translate(1px, -2px) rotate(-1deg);
      }
    }

    .error {
      width: 360px;
      margin-top: $standard-padding;
      text-align: center;
    }
  }
}
