.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  .titleWrapper {
    display: flex;
    align-items: center;

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #414449;
    }

    .buttonWrapper {
      display: flex;
      align-items: center;
      margin-left: 20px;

      .lockIcon {
        color: #414449;
      }
    }
  }

  .disabled {
    color: #bfbfbf;
  }
}
