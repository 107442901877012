@import 'assets/theme';

.container {
  display: flex;
  flex: 1;

  .card {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 350px;
    padding: 16px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-height: 350px;

    .cardTitle {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #323c47;
      padding-bottom: 8px;

      .highlight {
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $colorBlue1;
      }
    }

    .clientListWrapper {
      max-height: 300px;
      overflow: auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $grey-light;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 2px;
        background-color: $grey-light;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $mid-grey-dark;
      }
    }

    .clientListCard {
      display: flex;
      align-items: center;
      padding: 8px 0;

      .clientRecordWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 8px;
      }

      .initialName {
        font-size: 10px;
        user-select: none;
      }

      .avatar {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border: none;
      }

      .name {
        font-size: 12px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0.1px;
        color: #323c47;
        max-width: 130px !important;
        user-select: none;
        padding: 0 0 0 6px;
      }

      .addBtn {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $colorBlue1;
        padding-left: 8px;
        cursor: pointer;
      }

      .removeBtn {
        @extend .addBtn;
        color: #ff2500;
      }

      .noAllowTooltips {
        display: flex;
      }
    }

    .clientListCardDisable {
      @extend .clientListCard;
      background: #edf0f4;
      border-radius: 4px;
      cursor: not-allowed;
      user-select: none;

      .clientRecordWrapper {
        opacity: 0.5;
      }
    }
  }

  .clientListContainer {
    @extend .card;
    margin-right: 16px;
  }

  .clientCartContainer {
    @extend .card;
  }
}
