@import 'assets/theme';

.background {
  background-color: #f2f9ff;
}

.row {
  display: flex;
  align-items: center;
}

.dayColumn {
  display: flex;
  align-items: center;
  @include columnGap(36px);

  .checkbox {
    width: 16px;
    height: 16px;
    border: 2px solid #d5d5d5;
    box-sizing: border-box;
    border-radius: 4px;

    &:checked {
      background: $white;
      border: 0.342857px solid $white;
    }
  }
  .day {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

.inputs {
  flex-wrap: wrap;
  @include columnGap(12px);
}

.addButton {
  background: $white;
  border: none;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 8px;

  display: flex;
  align-items: center;

  &:hover {
    background-color: rgb(0, 0, 0, 0.25);
  }

  i {
    color: $colorBlue1;
    font-size: 18px;
  }
}

.errorText {
  color: red;
}

.container {
  padding: 11px;
  transition: all 0.1s linear;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  &.active {
    background-color: #f2f9ff;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;

    .checkbox {
      appearance: none;
      margin: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      color: #d5d5d5;
      cursor: pointer;
      outline: none;
      transition: all 0.1s linear;

      &:after {
        content: '\2713';
        display: none;
        justify-content: center;
        align-items: center;
        width: 16px;
      }

      &:checked {
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
        color: #fff;
        background-color: $colorBlue1;
        border: 2px solid $colorBlue1;

        &:after {
          display: flex;
        }
      }
    }

    .label {
      flex: 1;
      padding-left: 32px;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434;
      cursor: pointer;
    }
  }
}
