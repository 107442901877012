@import 'assets/theme';

.container {
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  padding: 16px 0px;
  border-radius: 8px;
  margin-bottom: 16px;

  .guideLineTitle {
    display: flex;
    margin-left: 20px;
    padding-bottom: 16px;

    .label {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: $colorTextualBlack;
    }

    .desc {
      display: flex;
      align-items: center;
      padding-left: 24px;
      font-size: 11px;
      line-height: 20px;
      color: #323c47;

      .icon {
        padding-right: 4px;
      }

      .iconDesc {
        opacity: 0.5;
      }
    }
  }

  .pointDesc {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 8px 20px;
    margin-bottom: 4px;
    .subQuestion {
      margin-top: 4px;
      margin-left: 28px;
      font-size: 13px;
      line-height: 20px;
      color: $mhh-dark-blue;
    }
    &.checked {
      background-color: $mhh-lightest-yellow;
    }
  }
}
