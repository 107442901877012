@import 'assets/theme';

.container {
  padding: 0px 24px 24px 24px;

  .header {
    display: flex;
    background: #f5f6ff;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-around;
    padding: 20px;
    color: $colorBlue3;
    border: 1px #e2e8f0;
    border-style: solid none;

    .headerItem {
      flex: 1;
    }
  }
}
