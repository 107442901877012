@import 'assets/theme';

.loadingWrapper {
  margin: 32px 0 16px;
}

.deleteReportIcon {
  float: left;
  margin-right: 16px;
  font-size: 24px;
  color: #ff7d45;
}

.container {
  display: flex;
  flex-direction: column;

  .headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 4px;

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #414449;
    }

    .manageBtn {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: $colorBlue1;
      cursor: pointer;
      user-select: none;

      .icon {
        font-size: 22px;
        margin-right: 4px;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    max-height: 400px;

    &::-webkit-scrollbar {
      width: 21px;
    }

    &::-webkit-scrollbar-track {
      background: #f0eeee;
      border-left: 9px solid #fff;
      border-right: 9px solid #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #c4cae1;
      border-radius: 9px;
      border-left: 6px solid #fff;
      border-right: 6px solid #fff;
    }

    & > *:not(:last-child) {
      border-bottom: 1px solid #ebeff2;
    }

    .newTemplateBtn {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $colorBlue1;
      padding: 12px 8px;
      cursor: pointer;
      user-select: none;

      .addIcon {
        margin-right: 6px;
        font-size: 18px;
      }
    }

    .noTemplateContainer {
      display: flex;
      align-items: center;

      .noTemplateImg {
        width: 80px;
      }

      .noTemplateDetails {
        display: flex;
        flex-direction: column;
        padding-left: 24px;

        .label {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #414449;
        }

        .noTemplateNewBtn {
          @extend .newTemplateBtn;
          padding: 8px 0 0 8px;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
}
