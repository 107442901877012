@import 'assets/theme';

.container {
  .contentContainer {
    display: flex;
    padding: 24px 0px;
    @include columnGap(14px);

    .icon {
      font-size: 24px;
      color: #ff7d45;
    }

    .messageContainer {
      .title {
        font-size: 18px;
      }
      .message {
        font-size: 14px;
        font-style: italic;
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    @include columnGap(8px);
  }
}
