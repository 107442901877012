.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .avatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;

    .imgProfile {
      width: 100%;
      border-radius: 50%;
    }
  }

  .unAssignAvatar {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 30px;
      border: 1px solid #eaeaea;
      color: #eaeaea;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }

  .name {
    padding-top: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #494949;
    text-align: center;
  }

  .unAssignLabel {
    @extend .name;
    color: #c4c4c4;
    font-size: 14px;
  }

  .ellipsisName {
    @extend .name;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
