@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0 24px;
  margin-bottom: $standard-padding * 3;
  border-bottom: 1px solid #dce2f4;
  user-select: none;
}

.backBtn {
  display: flex;
  flex: 1 3;
  align-items: center;
  cursor: pointer;
  color: $mhh-dark-blue;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  &:hover {
    color: $mhh-dark-blue;
  }

  .icon {
    font-size: 20px;
  }
}

.info {
  display: flex;
  flex: 1 3;
  justify-content: center;
  align-items: center;

  .names {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: -0.02em;
  }
}

.rightContent {
  flex: 1 3;
}
