@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(32px);
}

.timeAvailableContainer {
  padding-top: 20px;
}

.buttonContainer {
  align-self: flex-end;

  .saveButton {
    width: 200px;
  }
}

.dataErrorContainer {
  color: #ff1900;
  ul {
    padding-left: $standard-padding * 3;
  }
}
