@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 2);

  .subField {
    display: flex;
    flex-direction: column;
    @include rowGap($standard-padding);
    padding-left: $standard-padding * 2.5;
    margin-top: 8px;
    .label {
      color: $colorTextualGray;
      font-size: 12px;
      margin-bottom: 8px;
    }
    .textarea {
      width: 100%;
      border: 1px solid #c4c4c4;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      resize: none;
      border-radius: 4px;
      padding: 8px;
    }
  }
}

.noDeliveryOption {
  font-size: 12px;
  color: #a0a0a0;
  padding: 4px 0;
}
