.container {
  min-width: 200px;
}

.lastAppointmentDate {
  margin-top: 16px;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.1px;
  line-height: 16px;
  color: #343434;
}
