@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  height: 100%;
  margin-right: 24px;

  @media (max-width: $medium-screen-min-width) {
    flex-direction: row;
    overflow: auto;
    margin-right: 0;
    margin-bottom: 24px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: $grey-light;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 2px;
      background-color: $grey-light;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $mid-grey-dark;
    }
  }

  .menuCard {
    display: flex;
    padding: 16px 24px;
    min-width: 250px;
    max-width: 250px;
    border-bottom: 1px solid #ebeff2;
    cursor: pointer;
    transition: all 0.1s ease;
    box-sizing: border-box;
    align-items: center;

    @media (max-width: $medium-large_screen-min-width) {
      display: flex;
      flex-direction: column;
      min-width: 200px;
      max-width: 200px;
      align-items: flex-start;
    }

    @media (max-width: $medium-screen-min-width) {
      border-bottom: none;
      border-right: 1px solid #ebeff2;
      min-height: 120px;
    }

    &:hover {
      .menuIcon {
        transition: all 0.3s ease;
        color: $mhh-yellow;
      }

      .labelWrapper {
        width: 100%;

        .label {
          transition: all 0.3s ease;
          color: $mhh-dark-blue;
        }

        .desc {
          transition: all 0.3s ease;
          color: $mhh-dark-blue-50;
        }
      }
    }

    .menuIcon {
      margin-right: 10px;
      font-size: 24px;
      color: $mhh-noActive-yellow;
    }

    .labelWrapper {
      display: flex;
      flex-direction: column;

      .label {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $mhh-dark-blue;
        padding-bottom: 4px;

        @media (max-width: $medium-large_screen-min-width) {
          padding-top: 4px;
        }
      }

      .desc {
        font-size: 12px;
        line-height: 16px;
        color: $mhh-dark-blue-50;
      }
    }
  }

  .menuCardActive {
    @extend .menuCard;
    border-right: 4px solid $mhh-yellow;
    padding: 16px 24px;
    align-items: flex-start;

    .menuIcon {
      color: $mhh-yellow;
    }

    .labelWrapper {
      width: 100%;
      .label {
        color: $mhh-dark-blue;
      }

      .desc {
        color: $mhh-dark-blue-50;
      }
    }
  }
}
