@import 'assets/theme';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  border-radius: 4px;
  padding: 4px;
  max-width: 108px;
  min-width: 108px;
}

.fullContainer {
  @extend .container;
  background: #2e4672;
  color: #f2f2f2;
}

.fullInviteContainer {
  @extend .container;
  background: #a3adc7;
  color: #f2f2f2;
}

.recordContainer {
  @extend .container;
  background: #74b4dc;
  color: #f2f2f2;
}

.recordChildContainer {
  @extend .container;
  background: #dca4cc;
  color: #f2f2f2;
}

.fullCoupleContainer {
  @extend .container;
  background: #3765c5;
  color: #f2f2f2;
}

.recordCoupleContainer {
  @extend .container;
  background: #bda54d;
  color: #f2f2f2;
}

.prospectContainer {
  @extend .container;
  background: #d0ffd3;
  color: #7e7e7e;
}

.inviteLabel {
  padding-top: 4px;
  font-size: 11px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black;
  opacity: 0.5;
}

.inviteAction {
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }

  .label {
    @extend .inviteLabel;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    .tooltip {
      max-width: 230px;
      border-radius: 8px;
      line-height: 16px;
      font-size: 14px;
      padding: 12px;
      opacity: 0.95 !important;
    }
  }
  .menuWrapper {
    position: relative;
    width: 100%;
    z-index: 1;

    .menuBoxHide {
      position: absolute;
      width: 100%;
      background: #fff;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;
      width: max-content;
      left: 50%;
      transform: translateX(-50%);
      .listBox {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 16px 8px;
        color: #707683;
        transition: all 0.2s ease;
        cursor: pointer;
        position: relative;

        @media (max-width: $medium-large_screen-min-width) {
          padding: 12px 8px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #e7e4e4;
        }

        &:hover {
          color: $colorBlue1;
        }

        .listTitle {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;

          @media (max-width: $medium-large_screen-min-width) {
            font-size: 11px;
          }
        }

        .comingSoon {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: #ffffff80;
          z-index: 1;
          cursor: default;
        }

        .tooltip {
          max-width: 200px;
          border-radius: 8px;
          line-height: 16px;
          font-size: 14px;
        }
      }
    }

    .menuBoxShow {
      @extend .menuBoxHide;
      visibility: visible;
      opacity: 1;
    }
  }
}
