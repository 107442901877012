@import 'assets/theme';

:global {
  .consentFormEditor {
    .ql-toolbar {
      width: 80%;
    }
  }
}

.loadingPracticeWrapper {
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  @include rowGap(12px);

  .loadingPracticeInfo {
    max-width: 170px;
    height: 18px;
  }
}

.container {
  display: flex;
  padding-top: 8px;

  .leftContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px;
    max-width: 350px;
    margin-right: 24px;
    background: $white;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    justify-content: space-between;
    min-height: 400px;

    .content {
      display: flex;
      flex-direction: column;
      @include rowGap(24px);

      .nameWrapper {
        padding-top: 24px;

        .fieldContainer {
          display: flex;
          flex-direction: column;
          flex: 1;

          &.fieldError,
          .fieldError {
            font-size: 12px;
            color: #ff4d4f;

            input {
              border-bottom: 1px solid #ff4d4f !important;
            }
          }
        }
      }

      .statusWrapper {
        display: flex;
        padding: 40px 0;
      }

      .saveBtn {
        max-width: 230px;
      }
    }

    .deleteWrapper {
      display: flex;

      .deleteBtn {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: $mhh-red;
        cursor: pointer;
        user-select: none;
      }
    }
  }

  .rightContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 32px;
    background: #fff;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .practiceInfoWrapper {
      display: flex;

      .image {
        height: 80px;
        width: 80px;
        margin-right: 8px;
        border-radius: 16px;
      }

      .practiceDetails {
        display: flex;
        flex-direction: column;

        .name {
          font-weight: bold;
          font-size: 20px;
          line-height: 30px;
          display: flex;
          align-items: center;
          color: #343434;
        }

        .detail {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #343434;
          opacity: 0.5;
        }
      }
    }

    .errorMessage {
      font-size: 12px;
      color: #ff4d4f;
      padding-top: 4px;
    }

    .textEditorWrapper {
      display: flex;
      flex-direction: column;
      padding-top: 32px;

      .textEditorContainer {
        background-color: #fff;
        width: 100%;
      }
    }

    .signatureWrapper {
      display: flex;
      flex-direction: column;
      padding: 24px;
      background: #f8f9fd;
      border-radius: 8px;
      margin-top: 24px;
      max-width: 570px;

      .signatureTitle {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #5666a6;
        padding-bottom: 4px;
      }

      .signatureDesc {
        font-size: 14px;
        line-height: 19px;
        color: #7c7d7f;
      }

      .checkBoxWrapper {
        display: flex;
        flex-direction: column;
        @include rowGap(12px);
        padding: 16px 8px 0;
      }
    }
  }
}
