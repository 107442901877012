@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .btnWrapper {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: $mhh-dark-blue;
    border: 1px solid $mhh-yellow;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    width: 110px;

    &:hover {
      background: $mhh-light-yellow;
    }

    .icon {
      padding-right: 6px;
    }
  }

  .btnWrapperActive {
    @extend .btnWrapper;
    background: $mhh-light-yellow;
  }

  .menuWrapper {
    position: relative;
    z-index: 1051;

    .menuBoxHide {
      position: absolute;
      background: #fff;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      transform: translateY(0px);
      transition: all 0.3s;

      .listBox {
        padding: 16px;
        cursor: pointer;
        color: #707683;
        transition: all 0.2s ease;
        position: relative;

        &:not(:last-child) {
          border-bottom: 1px solid #e7e4e4;
        }

        &:hover {
          color: $mhh-yellow;
        }

        .title {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
        }
      }
    }

    .menuBoxShow {
      @extend .menuBoxHide;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      z-index: 1;
    }

    .displayToTop {
      bottom: 47px;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 32px;

    .saveButton {
      display: flex;
      justify-content: center;
      box-shadow: none;
    }
  }
}

.input {
  width: 100%;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: $mhh-dark-blue;
  border: 1px solid #e7e4e4;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 16px;

  &::placeholder {
    color: #707683;
  }
}
