@import 'assets/theme';

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
}

.selectedReferralTypessContainer {
  max-width: 380px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0;
  margin-bottom: 20px;
  @include columnGap(10px);

  .pill {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    min-width: 100px;
    padding: 0 20px;
    background: $colorBlue3;
    border-radius: 20px;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    color: $white;
  }
}

.dropdownContainer {
  margin-bottom: 32px;

  .rsDropdown {
    max-width: 380px;
  }
}
