@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #dce2f4;
  user-select: none;
  max-width: 1110px;
  width: 100%;
  position: relative;

  .btnBox {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      font-size: 30px;
      color: $mid-grey-medium;
    }

    .date {
      font-size: 11px;
      color: #343434;
      display: flex;

      .label {
        max-width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &.left {
      position: absolute;
      left: 0;
    }

    &.right {
      position: absolute;
      right: 0;
    }
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    line-height: 27px;

    .clientNameWrapper {
      display: flex;
      flex-direction: column;
      font-weight: 600;
      font-size: 18px;
      padding-left: 8px;
      color: #2b4670;
    }
  }
}
