@import 'assets/theme';

.container {
  background-color: #f8fdff;
  border-radius: 4px;
  padding: 12px;

  @media (max-width: $medium-screen-min-width) {
    padding: 16px 8px;
  }

  &:not(:first-child) {
    margin-top: 23px;
  }
}

.title {
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.1px;
  color: $colorTextualBlack;
}

.content {
  display: flex;
  margin-top: 20px;

  @media (max-width: $medium-screen-min-width) {
    flex-direction: column;
  }

  .clientInfoWrapper {
    display: flex;
    flex: 1 2 60%;
    @include columnGap(24px);
    margin-right: 24px;

    @media (max-width: $medium-screen-min-width) {
      justify-content: space-between;
      @include columnGap(16px);
      margin-right: 0;
      flex: 1;
    }
  }

  .actionWrapper {
    display: flex;
    flex: 1 2 30%;
    justify-content: space-between;

    @media (max-width: $medium-screen-min-width) {
      margin-top: 16px;
    }

    .statusWrapper {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: flex-start;

      @media (max-width: $medium-screen-min-width) {
        align-items: center;
      }
    }

    .acceptButton {
      height: 40px;
      min-width: 115px;
      letter-spacing: 0.1px;
      box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
    }

    .declineButtonContainer {
      display: flex;
      min-width: 115px;
      flex-direction: column;

      .declineButton {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 115px;
        letter-spacing: 0.1px;

        .declineIcon {
          margin-left: 0;
          width: 18px;
        }
      }

      .menuWrapper {
        position: relative;
        width: 100%;
        z-index: 1;

        .menuBoxHide {
          position: absolute;
          width: 100%;
          background: #fff;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          visibility: hidden;
          opacity: 0;
          transform: translateY(0px);
          transition: all 0.3s;

          .listBox {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 16px 0;
            color: #707683;
            transition: all 0.2s ease;
            cursor: pointer;
            position: relative;

            &:not(:last-child) {
              border-bottom: 1px solid #e7e4e4;
            }

            &:hover {
              color: $colorBlue1;
            }

            .listTitle {
              font-weight: 600;
              font-size: 13px;
              line-height: 16px;
              padding-bottom: 4px;
            }

            .listDesc {
              font-size: 10px;
              line-height: 12px;
            }

            .comingSoon {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              background-color: #ffffff80;
              z-index: 1;
              cursor: default;
            }

            .tooltip {
              max-width: 200px;
              border-radius: 8px;
              line-height: 16px;
              font-size: 14px;
            }
          }
        }

        .menuBoxShow {
          @extend .menuBoxHide;
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  .avatarAndName {
    display: flex;
    flex: 1 3 20%;
    flex-direction: column;

    @media (max-width: $medium-screen-min-width) {
      min-width: 145px;
      max-width: 145px;
    }

    @media (max-width: $medium-small-screen-min-width) {
      min-width: 90px;
      max-width: 90px;
    }

    .containerClassName {
      align-items: flex-start;
    }

    .name {
      font-size: 13px;
      font-weight: 600;
      line-height: 23px;
      text-align: start;
    }
  }

  .dateTimeWrapper {
    display: flex;
    flex: 1 3 40%;
    flex-direction: column;
    line-height: 23px;
    letter-spacing: 0.1px;
    min-width: 154px;
    @include rowGap(10px);

    @media (max-width: $medium-screen-min-width) {
      flex: 1 3 30%;
      min-width: 145px;
    }

    .date {
      font-size: 13px;
      color: $colorTextualBlack;
    }

    .time {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: $colorBlue3;
    }

    .dayScheduleBtn {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $colorBlue1;

      .link {
        line-height: 20px;
        letter-spacing: 0.1px;
        font-size: 13px;
      }

      .icon {
        margin-left: 2px;
        font-size: 18px;
      }
    }
  }

  .deliveryType {
    display: flex;
    flex: 1 3 20%;
    font-size: 14px;
    font-style: italic;
    line-height: 19px;
    color: #00000080;

    @media (max-width: $medium-screen-min-width) {
      flex: 1;
    }
  }

  .booked,
  .declined,
  .suggested {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.1px;
    color: #707683;

    .statusIcon {
      font-size: 36px;
      margin-right: 4px;
    }
  }

  .booked .statusIcon {
    color: #4abd03;
  }

  .declined .statusIcon {
    color: #ff3f3f;
  }

  .suggested .statusIcon {
    color: $colorBlue1;
  }
}

.submitButton {
  align-self: flex-end;
  width: auto;
  margin: 16px 0;
  border-radius: 4px;
  box-shadow: none;
}

.deliveryModeContainer {
  padding: 10px;
}
