@import 'assets/theme';

$base-duration: 1s;

// Colors
$color-1: #2c3e50;
$color-2: $mhh-dark-blue;
$color-3: $mhh-green;

// Breakpoints
$sm: new-breakpoint(min-width 320px);
$med: new-breakpoint(min-width 768px);
$lg: new-breakpoint(min-width 1024px);

*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

.hide {
  display: none;
}

.container {
  display: flex;
  min-width: 100px;
  max-height: 50px;
  height: 50px;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 20px;
  background-color: $mhh-yellow;
  color: $mhh-dark-blue;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  overflow: hidden;
  font-size: 16px;

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $color-3;
  }

  .submit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @include columnGap(8px);

    &.reverse {
      flex-direction: row-reverse;
    }
  }

  .icon {
    padding-right: 10px;
  }

  .reverse {
    .icon {
      padding-left: 10px;
    }
  }

  span {
    &:nth-of-type(2) {
      display: none;
    }

    &:nth-of-type(3) {
      display: none;
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
  }
}

.active {
  display: flex;
  background-color: $color-3;
  color: $white !important;
  max-height: 52px;
  height: 52px;

  &:before {
    width: 100%;
    transition: width 3s linear;
  }

  .submit {
    height: 0;
    overflow: hidden;
  }

  span {
    &:nth-of-type(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;

      svg {
        animation: loading $base-duration linear infinite;
      }
    }

    &:nth-of-type(3) {
      display: none;
    }
  }
}

.finished {
  display: flex;
  background-color: $color-3;
  color: $white !important;
  max-height: 52px;
  height: 52px;

  .submit {
    height: 0;
    overflow: hidden;
  }

  .loading {
    display: none;
  }

  .check {
    display: block !important;
    font-size: 20px;
    animation: scale 0.5s linear;

    svg {
      transform-origin: center center;
    }
  }
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: scale(10);
  }
  50% {
    transform: scale(0.2);
  }
  70% {
    transform: scale(1.2);
  }
  90% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

.secondary {
  box-shadow: none;
  background-color: transparent;
  border: 1px solid $mhh-yellow;
  color: $mhh-dark-blue;
  font-weight: 400;

  &:enabled:active {
    background-color: #f8f9fd;
  }
}

.primary {
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  color: $mhh-dark-blue;
  line-height: 18px;
  outline: none;
  align-self: center;
  margin: 16px 0;
  width: 100%;
  background-color: $mhh-yellow;
  border: 1px solid $mhh-yellow;
  font-size: 14px;

  &:enabled:active {
    background-color: #{$mhh-yellow}80;
  }
}

.link {
  border: none;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  height: auto;
  min-height: 0;
  min-width: 0;

  display: flex;
  justify-content: center;
  color: $mhh-dark-blue;

  &:enabled:active {
    background-color: #f8f9fd;
  }
}

.pill {
  border: none;
  border-radius: 20px;
  background-color: rgba(0, 133, 189, 0.19);
  box-shadow: none;
  padding: 0 20px;
  height: auto;
  min-height: 32px;
  min-width: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #343434;

  font-size: 14px;
  font-weight: 600;

  &:enabled:hover,
  &:enabled:active {
    background-color: $colorBlue3;
    color: $white;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 20px;
    background-color: $mhh-dark-blue;
    color: $white;
    width: 20px;
    height: 20px;
  }
}

.pill-active {
  @extend .pill;
  background-color: $colorBlue3;
  color: $white;
}
