@import 'assets/theme';

.dayTimeSelections {
  display: flex;
  flex-wrap: wrap;

  .dayTimeSelectionPill {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    border-radius: 20px;
    margin: 6px 12px 6px 0;

    i {
      padding: 4px;
      margin-left: 8px;
      font-size: 11px;
      font-weight: 700;
      color: $white;
      background-color: $colorBlue1;
      border-radius: 100%;
    }

    &.active {
      color: $white;
      background-color: $colorBlue3;
    }

    &.inactive {
      color: #343434;
      background-color: #0085bd30;

      i {
        display: none;
      }
    }
  }
}
