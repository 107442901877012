@import 'assets/theme';

.container {
  position: absolute;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    .closeBtnWrapper {
      opacity: 1;
    }

    .resizeHandle {
      opacity: 1;
    }
  }

  .box {
    height: 100%;
    transition: all 0.2s ease;
  }

  .closeBtnWrapper {
    display: flex;
    opacity: 0;
    position: relative;
    transition: all 0.2s ease;

    .btnFixedWrap {
      .closeButton {
        display: flex;
        position: absolute;
        top: -21px;
        left: 0;
        width: 130px;

        .closeIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          font-weight: bold;
          border-radius: 50%;
          background: #fc846880;
          color: #fff;
          padding: 3px;
          transition: all 0.2s ease;
          cursor: pointer;

          &:hover {
            background: $main-red;

            ~ .closeLabel {
              opacity: 1;
            }
          }
        }

        .closeLabel {
          font-size: 12px;
          padding-left: 4px;
          opacity: 0;
          transition: all 0.2s ease;
        }
      }
    }
  }

  .widgetContainer {
    height: 100%;

    .previewIcon {
      display: none;
    }
  }
}

.DraggableContainer {
  @extend .container;
  cursor: move;
}

.resizeHandle {
  position: absolute;
  transition: all 0.2s ease;
  top: 10%;
  left: 2px;
  cursor: ew-resize;
  border-right: 5px solid #607d8b;
  width: 4px;
  height: 25%;
  min-height: 20px;
  max-height: 40px;
  box-sizing: border-box;
  z-index: 1;
  opacity: 0;
}

.hideResizeHandle {
  @extend .resizeHandle;
  opacity: 0 !important;
}
