@import 'assets/theme';

.loadingContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 16px;

  .loadingBox {
    display: flex;
    align-items: center;
    min-height: 30px;
    padding: 16px 24px;
    @include columnGap(16px);
    border: 1px solid #ecedef;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 8px;

    .loadingName {
      width: 90%;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;
  margin-bottom: 16px;

  &::-webkit-scrollbar {
    width: 21px;
  }

  &::-webkit-scrollbar-track {
    background: #f0eeee;
    border-left: 9px solid #fff;
    border-right: 9px solid #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4cae1;
    border-radius: 9px;
    border-left: 6px solid #fff;
    border-right: 6px solid #fff;
  }

  .noFoundLabel {
    font-size: 14px;
    color: #b1b1b1;
    padding: 16px 24px;
  }

  .groupBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: $white;
    cursor: pointer;
    border: 1px solid #ecedef;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 8px;

    &.active {
      border: 1px solid $colorBlue1;
    }

    .groupInfoWrapper {
      display: flex;
      flex-direction: column;

      .name {
        display: flex;
        align-items: center;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #414449;
      }

      .activeMember {
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 14px;
        line-height: 21px;
        color: #414449;
      }
    }

    .selectedBadge {
      display: flex;
      align-items: center;

      span {
        font-size: $standard-padding * 4;
        color: $colorBlue1;
      }
    }
  }
}
