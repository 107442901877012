@import 'assets/theme';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;

  .header {
    position: absolute;
    top: -60px;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 44px;

    .attachmentsCount {
      padding: 0 12px;
      font-size: 16px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: #323c47;
    }

    .uploadButton {
      flex: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      min-width: 200px;
      padding: 10px 16px;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 13px;
      text-align: center;
      letter-spacing: 0.01em;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;

      &:active {
        background-color: #2f42ef;
        border-color: #2f42ef;
      }

      .icon {
        padding-right: 6px;
      }
    }

    .uploadInput {
      display: none;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 500px;
  }

  .content {
    .noAttachments {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 100%;
      min-height: 500px;

      .image {
        max-width: 250px;
        width: 100%;

        @media (max-width: $medium-large_screen-min-width) {
          max-width: 150px;
        }
      }

      .prompt {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 40%;

        .text {
          font-size: 18px;
          font-weight: 600;
          line-height: 25px;
          letter-spacing: 0.1px;
          text-align: center;
          color: $black;
        }

        .button {
          display: flex;
          align-items: center;
          margin-top: 16px;
          padding: 10px 16px;
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.1px;
          border-radius: 4px;

          .icon {
            margin-right: 12px;
          }
        }
      }
    }

    .attachmentsContainer {
      display: flex;
      flex-wrap: wrap;
      padding: 32px 0;
    }
  }
}
