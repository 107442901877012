@import 'assets/theme/index';

.taskContainer {
  position: relative;
  .task {
    margin-right: $standard-padding;
    &.viewTask {
      width: 59%;
    }
  }
  .taskDetail {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 60%;
    width: 40%;
  }
}

.addNewTaskButton {
  background-color: $mhh-yellow;
  color: $colorTextualBlack;
  box-shadow: none;
  max-height: 40px;
  i {
    font-size: 14px;
    margin-right: 2px;
  }
}
