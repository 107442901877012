@use 'sass:math';
@import 'assets/theme';

.contactsContainer {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding);
  height: 280px;
  overflow-y: auto;

  .contactContainer {
    display: flex;

    .nameAndTags {
      display: flex;
      align-items: center;
      @include columnGap($standard-padding);
      font-size: 11px;
      font-weight: 400;

      .initialName {
        font-weight: 600;
        font-size: 16px;
      }

      .clientName {
        font-weight: 600;
      }

      .pill {
        font-size: 10px;
        background-color: #f3f3f3;
        border-radius: 20px;
        padding: math.div($standard-padding, 2) $standard-padding;
      }

      .addButton {
        font-size: 13px;
      }
    }
  }
}
