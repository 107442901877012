@import 'assets/theme';

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 -16px 8px;
  padding: 16px;
  background-color: #fcf7f7;
  border-radius: 4px;

  .avatar {
    width: 64px;
    max-width: 10%;
  }

  .name {
    width: 20%;
    margin-left: 2%;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #343434;
  }

  .message {
    width: 26%;
    margin-left: 2%;

    .title {
      font-size: 11px;
      line-height: 15px;
      color: #34343480;
    }

    .text {
      margin-top: 8px;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1px;
      color: #343434;
    }
  }

  .scheduleAppointmentButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    margin-left: 2%;
    padding: 8px 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $white;
    background-color: $colorBlue1;
    box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
    border-radius: 4px;
  }

  .declineButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 20%;
    min-width: auto;
    margin-left: 4%;
    font-size: 14px;
    border: 1px solid $colorBlue1;
    box-sizing: border-box;
    font-weight: 600;
    color: $colorBlue1;
    background-color: $white;
    border-radius: 4px;
    box-shadow: none;

    .icon {
      margin-left: 4px;
    }
  }
}

.scheduleContainer {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.deliveryModeContainer {
  padding: 20px;
}

.roomOptionContainer {
  padding: 20px;
}

.submitButton {
  align-self: flex-end;
  width: auto;
  margin: 16px 0;
  border-radius: 4px;
  box-shadow: none;
}
