@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(40px);

  .contactWrapper {
    display: flex;
    flex-wrap: wrap;
    @include rowGap(40px);

    & > *:not(:last-child) {
      min-width: 360px;
    }
  }
}

.rowContainer {
  @extend .container;
  display: flex;
  flex-direction: row;
  @include columnGap(35%);
}
