@import 'assets/theme';
.cancelPopoverContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: $standard-padding;

  textarea {
    width: 100%;
    height: 100px;
    resize: none;
    padding: $standard-padding;
  }
}

.confirmModalAppointmentDescription {
  margin-bottom: 16px;
}

.confirmModalConfirmCancel {
  margin-bottom: 16px;
  font-weight: 600;
}

.confirmModalButtonContainer {
  display: flex;
  flex-direction: column;
  row-gap: $standard-padding;

  .modalButton {
    box-shadow: none;
  }

  .modalButtonBusy {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .modalCancelButton {
    color: #fc8367;
    background-color: $white;
    border: 1px solid #fc8367;
    box-shadow: none;
  }
}
