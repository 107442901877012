@import 'assets/theme';

.stripeDescription {
  margin-top: 24px;
  font-size: 12px;
}

.stripeSetupContainer {
  margin-top: 24px;
  background: #f2f5ff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 18%);
  display: flex;
  flex-direction: column;

  .stripeInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 400;

    .audit {
      align-self: center;

      .setUpEdit {
        display: flex;
        flex-direction: column;

        .setUpContainer {
          display: flex;
          flex-direction: row;

          .checkIcon {
            font-size: 30px;
            font-weight: bolder;
            padding: 4px;
            color: #4abd03;
            border: 4px solid #4abd04;
            border-radius: 50%;
            background-color: $white;
          }

          .setUp {
            margin-left: 15px;
            margin-top: 4px;
          }
        }

        .edit {
          margin-top: 24px;
          display: flex;
          flex-direction: column;
        }
      }
    }

    .account {
      display: flex;
      flex-direction: column;
      margin-right: 24px;

      .accountDetails {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 13px;
        font-weight: 400;

        .keys {
          color: #6575b1;
          display: flex;
          flex-direction: column;
          margin-right: 24px;

          span {
            margin-top: 8px;
          }
        }

        .values {
          display: flex;
          flex-direction: column;

          span {
            margin-top: 8px;
          }
        }
      }
    }
  }

  .editCTA {
    background: none;
    border: none;
    color: $colorBlue1;
    font-size: 13px;
    font-weight: 600;
    align-self: flex-end;
    display: flex;
    align-items: center;

    i {
      margin-left: 8px;
    }
  }

  .setupTitle {
    font-size: 14px;
    font-weight: 600;
  }

  .setupDesc {
    margin-top: 8px;
  }

  .setupCTA {
    margin-top: 16px;

    .inProgress {
      font-weight: 600;
    }

    button {
      margin-top: 8px;
      display: flex;
      align-items: center;
      padding: 16px;
      border-radius: 15px;

      i {
        margin-left: 16px;
      }
    }
  }

  .loading {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.subTitle {
  font-size: 12px;
  font-weight: 500;
  color: $colorTextualGray;
}
