@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .headerContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.selectAssessmentId {
  width: 280px;
  font-weight: 600;
  font-size: 16px;
}

.noItemsContainer {
  height: 500px;

  .sendAssessment {
    right: 0;
    margin-bottom: 16px;
  }
}

.loading {
  font-weight: 700;
  display: flex;
  min-height: 500px;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
}

.noItems {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);

  .noItemsText {
    display: flex;
    flex-direction: column;
    align-items: center;

    .noItemsTitle {
      font-weight: 700;
      font-size: 18px;
      color: $black;
      margin-bottom: 20px;
    }

    .noItemsSendAssessment {
      pointer-events: all !important;
    }
  }
}

.loadingBody {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
}

.content {
  position: relative;
  border-radius: 16px;
  background-color: $white;
  padding-bottom: 1px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);

  .totalScore {
    font-weight: 600;
    font-size: 36px;
    padding: 20px;
    height: 81px;
    width: 81px;
    border-radius: 81px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 32px;
    top: -32px;
    background-color: $colorBlue1;
    color: $white;
    z-index: 1;

    .label {
      font-weight: 600;
      font-size: 15.5px;
      border-radius: 100px;
      width: 139px;
      position: absolute;
      top: -4px;
      right: 100%;
      margin-right: -16px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 28px;

    .tabs {
      display: none;

      .tab {
        font-weight: 400;
        font-size: 16px;
        color: $mhh-dark-blue;
        padding: 0 16px;
        cursor: pointer;

        &:not(:last-of-type) {
          border-right: 2px solid #c2cfe0;
        }

        &:hover {
          opacity: 0.8;
        }

        &.active {
          color: $colorBlue1;
          font-weight: 600;
          padding: 0 30px;

          &::after {
            content: '';
            display: block;
            width: calc(100% + 28px);
            margin-left: -14px;
            height: 1px;
            background-color: $colorBlue1;
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 28px;

      .compare {
        font-weight: 600;
      }

      .expandAll {
        padding: 0 20px;
        i {
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
  }

  .data {
    padding: 12px 22px 0 22px;
  }

  .footer {
    margin: 0 22px 22px 22px;
    padding: 20px 18px;
    background: #ffffff;
    border: 1px solid #1941da;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      color: $mhh-blurb-grey-100;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      padding-right: 100px;
    }

    .buttons {
      display: flex;
      gap: 40px;
      align-items: center;

      .takeNoAction {
        padding: 0;
        color: $mhh-red;
      }
    }
  }
}
