@import 'assets/theme';

.title {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $colorTextualGray;
}

.baseColumn {
  display: flex;
  align-items: center;
}

.assessmentName {
  @extend .baseColumn;
  width: 200px;
}

.requestDate {
  @extend .baseColumn;
  width: 150px;
}

.status {
  @extend .baseColumn;
  width: 50px;
}

.button {
  @extend .baseColumn;
  width: 50px;
  position: relative;
}

.header {
  display: flex;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  color: $mhh-light-black;
  @include columnGap(8px);
}

.content {
  @include rowGap(4px);

  .loading {
    width: 100%;
  }
}
