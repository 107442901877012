@import 'assets/theme';

.container {
  padding: 24px 0;

  .title {
    font-weight: 600;
    font-size: 22px;
    letter-spacing: 0.01em;
    color: $colorTextualBlack;
  }
}
