@import 'assets/theme';

.container {
  display: flex;
  @include columnGap($standard-padding);
  align-items: center;

  input {
    &:checked,
    &:active {
      font-weight: 600;

      &::after {
        content: '';
        position: relative;
        display: block;
        top: 0;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background-color: $mhh-dark-blue;
        border: solid 2px $white;
        outline: solid 1px $mhh-dark-blue;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .label {
    font-size: 13px;
    font-weight: 400;
    color: #343434;

    & + input:checked,
    & + input:active {
      font-weight: 600;

      &::after {
        content: '';
        position: relative;
        display: block;
        top: 0;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background-color: $colorBlue1;
        border: solid 2px $white;
        outline: solid 1px $colorBlue1;
      }
    }
  }
}
