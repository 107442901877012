@import 'assets/theme';

.container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  @include rowGap(32px);

  .form {
    display: flex;
    flex-direction: column;
    @include columnGap(32px);
  }

  .row {
    display: grid;
    grid-template-columns: max-content auto auto;
    align-items: flex-end;
    @include columnGap(20px);
  }

  .scheduleTypeContainer {
    display: flex;
    align-items: flex-end;
    min-height: 80px;
  }
}
.footer {
  display: flex;
  justify-content: flex-end;
}
