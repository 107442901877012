@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 20px 16px;
  @include rowGap(34px);

  .templateDetails {
    @include rowGap(18px);

    .titleContainer {
      .titleInput {
        margin-top: 12px;
      }

      .error {
        color: #ff1900;
        font-size: 12px;
        padding-top: 4px;
      }
    }

    .statusContainer {
      display: flex;
      flex-direction: column;
      @include rowGap(4px);
    }
  }

  .widgets {
    display: flex;
    flex-direction: column;
    @include rowGap(46px);
  }

  .widgetListContainer {
    @include rowGap(24px);

    .backButton {
      display: flex;
      align-items: center;
      color: $colorBlue1;
      width: max-content;
      @include columnGap(8px);
      cursor: pointer;

      .backButtonLabel {
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }

  .widgetContainer {
    @include rowGap(18px);

    .titleWrapper {
      display: flex;
      justify-content: space-between;
    }

    .title {
      display: flex;
      font-weight: 600;
      font-size: 16px;
      color: $colorTextualBlack;

      .titleIcon {
        color: #c2cfe0;
        padding-right: 20px;
        font-size: 24px;
      }
    }

    .tooltips {
      padding: 8px;
      max-width: 250px;
    }
  }
}
