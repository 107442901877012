@import 'assets/theme';

.appointmentTypeCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include rowGap(10px);
  padding: 20px;
  background-color: $white;
  border-left-width: 3px;
  border-left-style: solid;
  border-radius: 8px;
  box-shadow: 0 4px 4px #00000040;
  position: relative;
  transition: all 0.1s linear;
  cursor: pointer;

  &.active {
    border-left-color: #6fcf97;
  }

  &.inactive {
    border-left-color: #f2c94c;
  }

  &:hover {
    background-color: #fefefe;
    box-shadow: 0 2px 16px #0000004d;
    border-radius: 8px;
  }

  .appointmentInfoCard {
    display: flex;
    @include columnGap(20px);

    .leftContent {
      display: flex;
      flex-direction: column;
      width: 70%;
      @include rowGap(24px);
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 30%;
      @include rowGap(24px);
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    .headerContent {
      display: flex;
      align-items: center;
      @include columnGap(30px);
      margin-bottom: 8px;

      .name {
        font-size: 18px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0.1px;
        color: $colorTextualBlack;
      }

      .activeLabel {
        padding: 4px 8px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;
        border-radius: 4px;

        &.active {
          color: $white;
          background-color: #219653;
        }

        &.inactive {
          color: #4f4f4f;
          background-color: #f2c94c;
        }
      }
    }

    .description {
      font-size: 14px;
      font-style: italic;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434;
    }
  }

  .footer {
    display: flex;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;
  }
}

.appointmentTypeCardView {
  @extend .appointmentTypeCard;
  cursor: auto;

  &:hover {
    box-shadow: 0 4px 4px #00000040;
  }
}
