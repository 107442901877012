.button {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  border: none;
  background-color: transparent;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
