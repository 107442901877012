@import 'assets/theme';

.container {
  min-width: 720px;
  background: #fff;
  box-shadow: 0 2px 16px #0000001a;
  border-radius: 8px;
  padding: 24px;

  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 320px;
  }

  .button {
    color: $colorTextualBlack;
    box-shadow: none;
    max-height: 40px;
  }

  .header {
    padding-bottom: $standard-padding * 2;
    display: flex;
    justify-content: space-between;

    .cancelButton {
      @extend .button;
      background-color: white;
      border: 1px $mhh-yellow solid;
    }
    .subHeader {
      .title {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.1px;
        color: $colorTextualBlack;
      }
      .description {
        font-size: 13px;
        letter-spacing: 0.1px;
        line-height: 20px;
        color: #323c4780;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    color: $mhh-light-black;
    font-size: 14px;
    @include rowGap($standard-padding * 2);

    .descriptionContainer {
      @include rowGap($standard-padding * 2);

      .statusContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        .badge {
          margin: 0px 0px 0px $standard-padding;
        }
      }

      .schoolContainer {
        display: flex;
        flex-direction: column;
        @include rowGap($standard-padding);
      }
      .connectionDetailsContainer {
        display: flex;
        flex-direction: column;
        @include rowGap($standard-padding * 1.5);
        .summary {
          line-height: 1.4;
        }
      }

      .autoSyncDetails {
        display: flex;
        flex-direction: column;
        margin-left: $standard-padding;
      }
    }

    .connectMisButton {
      @extend .button;
      background-color: $mhh-yellow;
      width: fit-content;
    }
  }
}
