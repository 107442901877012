@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: #f8f9fd;
  box-sizing: border-box;
  padding: 8px 12px;
  width: 100%;

  .date {
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
    line-height: 20px;
    color: #696969;
  }

  .title {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .desc {
    font-size: 13px;
    line-height: 16px;
    color: $colorBlue3;
  }

  .emotionWrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;

    .badge {
      padding: 8px 12px;
      border-radius: 20px;
      background-color: #bae3a3;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #343434;
      margin-bottom: 8px;
      margin-right: 8px;
    }

    .badgeNegative {
      @extend .badge;
      background-color: #d5e9f3;
    }
  }
}
