@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: $standard-padding * 3;
  background-color: #fff;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  .headerTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 16px;
  }

  .content {
    display: flex;

    .leftWrapper {
      padding-right: 32px;
      border-right: 2px solid #ebeff2;
      flex: 1 2 50%;
      .diffNameBox {
        .checkBox {
          margin-bottom: 16px;
        }
      }
      .titleBox {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
      }

      .descBox {
        margin-top: 16px;
        padding-bottom: 16px;
      }
      .tagBox {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        flex-wrap: wrap;
        .tagInput {
          margin-right: 16px;
          margin-bottom: 16px;
          width: 145px;
          .titleInput {
            font-size: 13px;
          }
        }
        .tagItem {
          display: flex;
          align-items: center;
          margin-right: 8px;
          margin-bottom: 16px;
          background: $colorBlue1;
          width: fit-content;
          height: fit-content;
          padding: 4px 8px;
          border-radius: 4px;
          color: $white;
          font-size: 13px;
          .icon {
            font-size: 13px;
            margin-left: 8px;
            cursor: pointer;
          }
        }
        .addTagButton {
          display: flex;
          align-items: center;
          margin-top: 8px;
          padding: 8px;
          width: fit-content;
          font-size: 13px;
          color: $colorBlue1;
          border: dashed $colorTextualBlack 1.5px;
          cursor: pointer;
          border-radius: 8px;

          .icon {
            font-size: 18px;
            margin-right: 4px;
          }
        }
      }

      .noteBox {
        max-width: 500px;
        .noteTitle {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
          color: $colorTextualBlack;
        }

        .selectedBadge {
          display: flex;
          margin-bottom: 16px;
        }

        .clinicalListWrapper {
          max-height: 450px;
          overflow: auto;

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: $grey-light;
          }

          &::-webkit-scrollbar {
            width: 6px;
            background-color: $grey-light;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $mid-grey-dark;
          }
        }
      }
      .title {
        color: $colorTextualGray;
        font-size: 11px;
        font-weight: 600;
      }

      .value {
        font-weight: 600;
        font-size: 20px;
      }

      .clinicalBox {
        .clinicalTitle {
          font-weight: 600;
          font-size: 18px;
          line-height: 23px;
          color: $colorTextualBlack;
          padding-bottom: 4px;
        }

        .selectedBadge {
          display: flex;
          margin-bottom: 16px;
        }
      }
    }

    .rightWrapper {
      padding-left: 32px;
      flex: 1 2 50%;
    }
  }
}
