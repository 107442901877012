@import '../../../assets/theme/index';

.container {
  grid-area: header;
  position: fixed;
  flex: 1;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-light;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  height: 70px;

  .leftContent {
    display: flex;
    align-items: center;
    padding: 0 $standard-padding * 2;

    .logoWrapper {
      display: flex;

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 110px;
        }
      }

      .cordsLogo {
        @extend .logo;

        img {
          max-width: 310px;

          @media only screen and (max-width: $medium-large_screen-min-width) {
            max-width: 200px;
          }
        }
      }
    }

    .menuWrapper {
      flex: 1;
      display: flex;
      align-items: center;
      margin-right: 4px;

      .menu {
        display: none;

        @media only screen and (max-width: $medium-large_screen-min-width) {
          display: flex;
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
  }

  .buttonWrapper {
    display: flex;
    flex: 1;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 22px;
      cursor: pointer;
      background: #4abd03;
      border-radius: 20px;
      font-weight: 600;
      font-size: 12px;
      padding: 2px 8px;
      z-index: 31;
      color: $white;

      .arrowIcon {
        display: flex;
        align-items: center;
        font-size: 18px;
        padding-left: 2px;
      }
    }
  }

  .rightContent {
    display: flex;
    background: $mhh-lightest-yellow;
    flex: 1;
    max-width: 360px;
    height: 100%;
    border-left: 1px solid #6575b1;

    @media only screen and (max-width: $medium-small-screen-min-width) {
      max-width: 205px;
    }
  }
}
