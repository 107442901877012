@import 'assets/theme';

.modalContainer {
  height: 800px;
  min-width: 820px;
}

.container {
  height: 800px;
}

.header {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 18px 32px 10px 32px;
  justify-content: space-between;
  border-bottom: solid 2px #ebeff2;

  .title {
    flex: 1;

    .mainTitle {
      font-size: 20px;
      font-weight: 700;
      color: #323c47;
      line-height: 30px;
      letter-spacing: 0.1px;
    }

    .subTitle {
      font-size: 13px;
      font-weight: 400;
      color: #343434;
      line-height: 20px;
      letter-spacing: 0.1px;
    }
  }

  .closeButton {
    border: none;
    display: flex;
    font-size: 18px;
    color: #c2cfe0;
    align-items: center;
    background-color: transparent;
  }
}

.body {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 54px 32px;

  .bodyTitle {
    font-size: 18px;
    font-weight: 700;
    color: $colorTextualBlack;
    line-height: 23px;
    letter-spacing: 0.1px;
  }

  .filterSection {
    margin-top: 15px;
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    padding-right: 20px;

    .appointmentTypeFilter {
      width: 165px;
    }

    .occupancyFilter {
      width: 80px;
    }

    .durationFilter {
      width: 100px;
    }

    .dayOrTimeFilter {
      width: 267px;
    }

    .dayFilter {
      width: 200px;
    }
  }
}

.result {
  margin-top: 40px;

  .resultFilter {
    height: 29px;
    display: flex;
    justify-content: space-between;
  }

  .sortedBy {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #6a707e;
    letter-spacing: 0.1px;

    .dropdownText {
      display: flex;
      align-items: center;
      margin-left: 8px;
      color: $colorBlue1;
      user-select: none;
      cursor: pointer;

      .sortedByIcon {
        color: #aeaeae;
        font-size: 16px;
      }
    }
  }

  .selectedSortedBy,
  .selectedSortedBy:hover {
    background-color: $colorBlue1;
    color: #fff;
  }

  .timeRangeControlContainer {
    height: 29px;
    width: 438px;
    display: flex;
    justify-content: space-between;

    .timeRange {
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      overflow: hidden;

      .timeRangeSlider {
        display: flex;
        width: max-content;
        transition: transform 1.5s cubic-bezier(0, 1, 0.5, 1);
      }

      .time {
        width: 40px;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        color: $colorBlue3;
      }
    }

    .leftControl,
    .rightControl {
      width: 29px;
      font-weight: 600;
      font-size: 7px;
      line-height: 10px;
      text-align: center;
      color: $colorBlue3;

      span {
        height: 29px;
        width: 29px;
        border-radius: 50%;
        color: $colorBlue2;
        background: #fff;
        border: 0.58px solid $colorBlue3;
        box-sizing: border-box;
        box-shadow: 0px 2.32px 2.32px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 3px;
      }
    }

    .leftControl {
      span {
        padding-right: 2px;
      }
    }

    .rightControl {
      span {
        padding-left: 2px;
      }
    }

    .disabled span {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
}

.resultTable {
  height: 450px;
  margin-top: 20px;
  overflow: hidden auto;
}
