.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.loading {
  display: flex;
  height: 500px;
  justify-content: center;
  align-items: center;
}
