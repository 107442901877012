@import 'assets/theme';

.container {
  height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding);
  padding: $standard-padding $standard-padding * 2;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.title {
  font-size: 13px;
  font-weight: 400;
  .numberOfAttendees {
    font-size: 18px;
    font-weight: 700;
    color: $colorBlue1;
  }
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-top: $standard-padding * 2;
}

.attendeeContainer {
  display: flex;
  @include columnGap($standard-padding);
  align-items: center;
  font-size: 15px;
  font-weight: 400;

  .profileNameContainer {
    max-width: unset;
    font-size: 15px;
    font-weight: 400;
  }

  .emailIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colorBlue3;
    width: 36px;
    height: 36px;
    margin-right: $standard-padding * 2;
  }

  .removeButton {
    font-size: 13px;
    font-weight: 600;
    color: #ff2500;
  }
}
