@import 'assets/theme';

.container {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 24px 8px;
  cursor: pointer;
  position: relative;
  color: black;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover {
    background: #fbfbfb;

    .name {
      color: $mhh-yellow;
      text-decoration: underline;
    }
  }

  .assessor {
    flex-direction: row;
    align-items: center;

    .icon {
      margin-right: 4px;
      color: $mhh-yellow;
    }
  }

  .caseId {
    font-size: 12px;
  }

  .program {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #6575b1;
  }

  .button {
    .icon {
      border: 2px solid $mhh-dark-blue;
      color: $mhh-dark-blue;
      padding: 6px;
      border-radius: 50%;
      cursor: pointer;
      font-size: 18px;
    }
  }

  .actionPlans {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    overflow: auto;
  }

  .actionPlan {
    display: flex;
    align-items: center;
    gap: 12px;

    .right {
      font-weight: 600;
      font-size: 12px;
      color: $mhh-dark-blue;
      overflow-wrap: anywhere;

      .actionPlanOwnerName {
        font-weight: 600;
        font-size: 10px;
        color: $colorTextualGray;
      }
    }
  }

  .lastScore {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding-left: 20px;

    .left {
      font-weight: 700;
      font-size: 24px;
      color: $mhh-dark-blue;
      width: 100px;

      .assessmentScore {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
    }
  }

  .label {
    max-width: 60px;
  }

  .none {
    font-weight: 600;
    font-size: 12px;
    color: #8d9bae;
  }

  .checkBox {
    margin: 16px;

    &:checked {
      background-color: $mhh-purple;
      border-color: $mhh-purple;
    }
  }
}
