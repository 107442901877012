.baseStyle {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.checkBoxSection {
  @extend .baseStyle;
  align-items: center;
  width: 55px;
}

.requestDate {
  @extend .baseStyle;
  width: 200px;
}

.assessmentType {
  @extend .baseStyle;
  width: 200px;
}

.student {
  @extend .baseStyle;
  width: 200px;
}

.class {
  @extend .baseStyle;
  width: 200px;
}

.staffMember {
  @extend .baseStyle;
  width: 200px;
}

.flags {
  @extend .baseStyle;
  width: 200px;
}

.buttonSection {
  @extend .baseStyle;
  width: 100px;
  min-width: 100px;
}
