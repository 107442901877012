@import 'assets/theme';

.container {
  padding: $standard-padding * 1.5;
  background: #f8fdff;
  border-radius: 8px;

  .title {
    font-weight: 600;
    font-size: 15px;
    color: $colorTextualBlack;
  }

  .content {
    @include rowGap($standard-padding * 2);

    .rescheduleDetails {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include columnGap($standard-padding * 2);

      .avatars {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 20%;
        @include columnGap($standard-padding * 2);

        .heading {
          font-weight: 600;
          font-size: 12px;
          color: #949aa2;
        }

        .containerClassName {
          align-items: flex-start;
        }

        .name {
          font-size: 13px;
          font-weight: 600;
          line-height: 23px;
          text-align: start;
        }
      }

      .currentAppointment,
      .requestedAppointment {
        width: 25%;

        .heading {
          font-weight: 600;
          font-size: 14px;
          color: $colorTextualBlack;
        }

        .text {
          font-style: italic;
          font-weight: 300;
          font-size: 14px;
          color: $black;
          opacity: 0.5;
        }
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 30%;
        @include columnGap($standard-padding * 1.5);

        .acceptButton {
          flex: none;
          width: 80px;
          min-width: auto;
          height: 40px;
          padding: $standard-padding $standard-padding * 2;
          font-weight: 600;
          font-size: 14px;
          border: none;
          border-radius: 4px;
          box-shadow: none;
        }

        .rejectButton {
          width: 80px;
          min-width: auto;
          height: 40px;
          padding: $standard-padding $standard-padding * 2;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .rejectWithNotesButton {
      flex: none;
      width: max-content;
      min-width: auto;
      height: 40px;
      padding: $standard-padding $standard-padding * 2;
      font-weight: 600;
      font-size: 14px;
      border: none;
      border-radius: 4px;
      box-shadow: none;
    }
  }
}
