@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #c7c9cd;
  padding: 8px;
  min-width: 260px;
  max-width: 810px;
  color: #414449;
  font-weight: 600;

  .titleContainer {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 18px;
    }

    .actionContainer {
      display: flex;
      align-items: center;
      margin-left: 20px;

      .iconsContainer {
        display: flex;
        @include columnGap(8px);

        .icon {
          font-size: 20px;
          cursor: pointer;
        }

        .activeDefault {
          color: #f3da00;
        }

        .inactiveDefault {
          color: #e6e6e6;
        }

        .spin {
          animation: spin-animation 0.5s infinite;
        }

        @keyframes spin-animation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(359deg);
          }
        }
      }
      .menuContainer {
        display: flex;
        justify-content: flex-end;
        position: relative;
        z-index: 7;

        .hideMenu {
          background: #fff;
          visibility: hidden;
          opacity: 0;
          transform: scaleY(0);
          transform-origin: top;
          transition: all 0.2s ease;
          position: absolute;
          width: 100%;
          min-width: 130px;
          border: 1px solid #ecedef;
          box-sizing: border-box;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 8px;

          .label {
            margin-left: 8px;
            width: 115px;
          }
        }

        .showMenu {
          @extend .hideMenu;
          visibility: visible;
          opacity: 1;
          transform: scaleY(1);
          transform-origin: top;
          transition: transform 0.2s ease;
        }

        .item {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          cursor: pointer;
          transition: background 0.3s ease;

          &:hover {
            background-color: #f5f6ff;
          }

          .itemIcon {
            font-size: 16px;
            color: #61666e;
          }

          .label {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #414449;
          }
        }

        .disabledItem {
          @extend .item;
          .itemIcon {
            color: #b5b8bd;
          }
          .label {
            color: #b5b8bd;
          }
        }
      }
    }
  }

  .detailContainer {
    display: flex;

    .labelContainer {
      padding-right: 16px;

      .label {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 25px;
      }
    }

    .valueContainer {
      .valueRow {
        display: flex;
        line-height: 25px;
        @include columnGap(16px);

        .value {
          font-size: 16px;
          cursor: pointer;
        }

        .icon {
          font-size: 18px;
          align-self: center;
          cursor: pointer;
        }
      }
    }
  }
}
