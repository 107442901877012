@import 'assets/theme';

.container {
  font-size: 12px;
  height: 100%;
  background: #fff;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px 10px 10px 4px;
  border-left-width: 3px;
  border-left-style: solid;
}

.group-1 {
  border-left-color: #ffbdad;
  background: #ffbdad;
}

.group0 {
  border-left-color: #ffbdad;
  background: #ffbdad;
}

.group1 {
  border-left-color: #25be69;
  background: #25be69;
}

.group2 {
  border-left-color: #a144f6;
  background: #a144f6;
}

.group3 {
  border-left-color: #de3d83;
  background: #de3d83;
}

.body {
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  box-sizing: border-box;
  padding: 2px 4px;
  border-radius: 4px 10px 10px 4px;
  transition: background 0.15s ease-in-out;
  overflow: hidden;
  color: initial;
}

.details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .initialName {
    font-weight: 600;
    font-size: 14px;
  }

  .clientName {
    font-weight: 500;
    font-size: 12px;
  }
}

.groupCard {
  display: flex;
  flex-direction: column;

  .groupCardHeading {
    display: flex;
    align-items: center;
    @include columnGap(4px);

    .groupIcon {
      font-size: 20px;
    }

    .groupName {
      font-weight: 600;
      font-size: 10px;
      line-height: 20px;
      color: #414449;
    }
  }
}

.c100Top {
  display: flex;
  flex-grow: 1;
}

.c100ProfileName {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.c100FloatImage {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.c50Bottom {
  display: flex;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ellipsis2 {
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.imageContainer {
  display: flex;
}

.avatarL {
  margin-right: 2px;
  border-radius: 7px;
  width: 14px;
  height: 14px;
}

.avatarXL {
  border-radius: 16px;
  width: 32px;
  height: 32px;
  background-color: $white;
}

.initial {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.initialXL {
  @extend .avatarXL;
  @extend .initial;
  font-size: 12px;
}

.initialL {
  @extend .avatarL;
  @extend .initial;
  font-size: 10px;
}

.free {
  background-color: transparent;
  border-left: dashed;
  border-left-color: #2a4870;
}

.busy {
  background-color: #5ab7e250;
  border-left-color: #2a4870;
}

.reserved {
  background-color: #777777;
  border-left-color: #303030;
}
