.fieldContainer {
  .textarea {
    width: 100%;
    border: 1px solid #c4c4c4;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    resize: none;
    border-radius: 4px;
    padding: 8px;
  }
  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
}
