@import 'src/assets/theme/palette/colours.scss';

// edge super user plan
$edgeSUViolet: $mhh-purple;
$edgeSUSideBtn: rgba(255, 255, 255, 0.5);
$edgeSUSideActiveBtn: #fff;

// default package
$defaultPlanBlue: $colorBlue3;
$defaultPlanSideBtn: #7487a2;
$defaultPlanSideActiveBtn: #fff;
