@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 24px;

  .filterWrapper {
    display: flex;
    align-items: center;

    .filterWrapperTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: $mhh-blurb-grey-100;
    }

    .filterWrapperTags {
      display: flex;
      margin-left: 16px;

      .tagWrapper {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .searchBarWrapper {
    display: flex;
    justify-content: flex-end;

    .searchBarCard {
      min-width: 400px;
    }
  }
}
