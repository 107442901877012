@import 'assets/theme';

.materialSelect {
  padding: 16px 20px 4px;
  max-width: 250px;
  width: 100%;

  .label {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: $colorTextualGray;
  }

  .valueWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $colorLineGray;
    cursor: pointer;
    user-select: none;

    .valueBox {
      padding: 2px 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: $colorTextualBlack;
    }

    .icon {
      color: #1941da;
    }
  }

  .listWrapperHide {
    background-color: #fff;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    position: absolute;
    max-width: 270px;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-5px);
    transition: all 0.2s;
    max-height: 200px;
    overflow: auto;

    .listBox {
      padding: 8px 12px;
      cursor: pointer;
      transition: all 0.2s ease;
      font-weight: 600;

      &:hover {
        background-color: #eee;
      }
    }

    .customListBox {
      display: flex;
      align-items: center;
      @extend .listBox;
      cursor: auto;

      &:hover {
        background-color: transparent;
      }

      .searchInput {
        font-size: 14px;
        border-radius: 6px;
        line-height: 16px;
        padding: 8px;
        border: 1px solid #eee;
        background-color: #fff;
        outline: none;
        margin-right: 8px;
      }

      .addButton {
        display: flex;
        align-items: center;
        color: $colorBlue1;
        cursor: pointer;
      }
    }
  }

  .listWrapperShow {
    @extend .listWrapperHide;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    z-index: 1;
  }
}
