@import 'assets/theme';

.container {
  position: relative;
  @include rowGap(5px);

  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorTextualGray;
  }
}

.select {
  .controller {
    display: flex;
    border-bottom: 1px solid $colorLineGray;
    .searchBox {
      flex: 1;
      input {
        width: 100%;
        height: 30px;
        border: none;
        padding-bottom: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #323c47;
        &::placeholder {
          color: #323c47;
        }
      }
    }

    .controlIcon {
      color: #1941da;
      cursor: pointer;
      user-select: none;
    }

    .disabled {
      cursor: not-allowed;
      user-select: none;
      background: transparent;
    }
  }

  .options {
    width: 100%;
    position: absolute;
    border-radius: 4px;
    padding: 5px;
    border: 1px solid #eee;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    transition: transform 0.2s ease;
    background: #fff;
    z-index: 1;
    @include rowGap(4px);
    max-height: 223px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    .optionsListLabel {
      margin: 10px;
      color: #323c47;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }

    .optionItem {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      background-color: $colorBlue3;
      padding: 5px 10px 5px 5px;
      border-radius: 3px;
      color: #fff;
      font-weight: 700;
      min-height: 20px;

      &:hover {
        background-color: #0079bf;
      }

      .selectedMark {
        display: flex;
        align-items: center;
        .selected {
          font-size: 15px;
        }
      }
    }

    .noOption {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
