@import 'assets/theme';

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }

  .leadLabel {
    font-size: 11px;
    font-weight: 600;
    color: $colorTextualGray;
    line-height: 21px;
  }

  .label {
    font-size: 14px;
    font-weight: 600;
    color: #61666e;
    padding-bottom: 6px;
    line-height: 21px;
  }

  .textarea {
    width: 100%;
    height: 148px;
    padding: 8px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    box-shadow: 0px 6px 18px 0px #0000000f;
    resize: none;
  }
}

.dropdownContainer {
  margin-bottom: 32px;

  .rsDropdown {
    max-width: 380px;
  }
}

.inputSkeletonLoading {
  .inputSkeletonLoading {
    &::after {
      content: '';
      position: relative;
      display: block;
      margin-top: 36px;
      border-bottom: solid 1px #c4c4c4;
    }
  }
}
