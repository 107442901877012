@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 86px;

  .insightsContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 28px;
  }

  .graphWrapper {
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .graphContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 24px;

    &:not(:last-child) {
      margin-right: 24px;
    }

    .graphHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 13px;
        line-height: 23px;
        color: $colorTextualBlack;
      }

      .filter {
        font-size: 12px;
        line-height: 18px;
        color: #6a707e;
      }
    }
  }

  .dividerLine {
    border-bottom: 1px solid #dce2f4;
  }
}
