@import 'assets/theme';

$content-width: 716px;
$header-height: 400px;

.container {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #343434;
}

.headerBackground {
  position: absolute;
  top: 0;
  width: 100%;
  height: $header-height;
  opacity: 0.7;
}

.header {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  @include rowGap($standard-padding);
  padding-bottom: $standard-padding * 2;
  max-width: $content-width;
  width: $content-width;
  height: $header-height;

  .title {
    font-size: 32px;
    font-weight: 900;
    color: #1d1d20;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 400;
    color: #52525a;
  }

  .bottomLine {
    display: flex;
    align-items: center;
    @include columnGap($standard-padding);
    font-size: 14px;
    font-weight: 400;

    .broughtToYouByLogo {
      display: flex;
      height: 20px;

      img {
        max-height: 100%;
      }
    }

    .broughtToYouBy {
      font-weight: 600;
    }
  }
}

.body {
  z-index: 1;
  min-height: 100px;
  width: 100%;

  display: flex;
  justify-content: center;

  padding: $standard-padding * 2;

  .innerBody {
    display: flex;
    flex-direction: column;
    @include rowGap($standard-padding * 2);

    max-width: $content-width;

    font-size: 14px;

    .chapters {
      font-size: 24px;
      font-weight: 700;
    }

    .chapterDescription {
      font-size: 14px;
      font-weight: 400;
    }

    .chapterContainer {
      display: flex;
      @include columnGap($standard-padding * 2);

      &.incomplete {
        opacity: 0.5;
      }

      .chapterImageContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 150px;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #888888;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .chapterNumber {
        font-size: 18px;
        font-weight: 700;
        min-width: 32px;
        width: 32px;
        text-align: right;
      }

      .chapterDetails {
        .chapterTitle {
          @extend .chapterNumber;
          width: unset;
          text-align: left;
        }

        .description {
          font-size: 14px;
          font-weight: 400;
        }

        .actionContainer {
          margin-top: $standard-padding * 2;
          display: flex;

          .completeText {
            display: flex;
            align-items: center;

            font-size: 14px;
            color: #25be69;

            margin-right: $standard-padding * 4;

            .text {
              font-weight: 700;
            }
          }

          .startButton {
            width: unset;
            flex: unset;
          }
        }
      }
    }
  }
}

.footer {
  background-color: $white;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;

  padding-bottom: $standard-padding * 6;

  .innerContainer {
    max-width: $content-width;
    width: 100%;
    display: flex;
    @include columnGap($standard-padding * 2);

    .buttonContainer {
      text-align: left;
      border: none;
      padding: $standard-padding;
      width: 45%;
      color: $white;

      &.back {
        background-color: #ed64a6;
      }

      &.next {
        background-color: #4fd1c5;
      }

      .line1 {
        font-size: 16px;
        font-weight: 700;
      }

      .line2 {
        font-size: 12px;
        font-weight: 400;
      }

      .leftAlign {
        text-align: left;
      }

      .rightAlign {
        text-align: right;
      }
    }
  }
}
