@import '../../assets/theme/index';

.container {
  display: grid;
  grid-template-rows: 60px 1fr;
  grid-template-areas:
    'header'
    'main';
  height: 100vh;
  flex-direction: column;
  background-color: $mhh-dark-blue;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo {
      max-width: 120px;
    }
  }
}
