@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(8px);

  .initialName {
    font-size: 16px;
  }

  .clientName {
    font-size: 16px;
    font-weight: 400;
  }

  .error {
    color: #ff1900;
    font-size: 12px;
  }
}
