.container {
  height: 40px;
  width: 360px;
  border-radius: 3px;
  background: rgba(42, 70, 112, 0.1);
  overflow: hidden;

  .timeRangeList {
    height: 40px;
    display: inline-block;
    transition: transform 1.5s cubic-bezier(0, 1, 0.5, 1);
  }

  .range {
    height: 40px;
    position: absolute;
    background: #a1f3c6;
    border-radius: 3px;
    display: inline-block;
    cursor: pointer;
  }
}
