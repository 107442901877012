@import 'assets/theme/index';

.containerWrapper {
  height: 100%;

  .editContainer {
    background: #f8f9fd;
  }
}

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 8px 12px;
  cursor: move;
  border: 0.5px dashed #0085bd;
  border-radius: 4px;
  line-height: 22px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $grey-light;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: $grey-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $mid-grey-medium;
  }

  & > * {
    cursor: move;
  }

  input {
    cursor: move !important;
    max-width: 100%;
  }
}

.previewContainer {
  @extend .container;
  border: 0.5px solid transparent;
  cursor: auto;
  padding: 8px 12px;

  & > * {
    cursor: auto !important;
  }

  .label {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorTextualGray;
  }

  .value {
    font-size: 14px;
    line-height: 22px;
  }
}

.showMoveCursor {
  & > * {
    cursor: move !important;
  }

  input {
    cursor: move !important;
  }

  label {
    cursor: move !important;
  }
}
