.container {
  margin-top: 12px;
  border: 1px solid #f8f9fd;
  cursor: move;

  &:first-child {
    margin-top: 0;
  }
}

.draggingContainer {
  @extend .container;
  border: 1px solid #ffc107;
}
