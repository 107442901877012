@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(16px);

  .titleContainer {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 600;
      font-size: 18px;
      color: #414449;
    }

    .description {
      font-size: 14px;
      color: #414449;
    }
  }

  .locationsContainer {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 16px;
  }

  .button {
    margin: 0px;
    width: 170px;
    font-weight: 600;
    font-size: 16px;
    align-self: flex-start;
    box-shadow: none;
  }

  .loading {
    height: 100px;
  }
}
