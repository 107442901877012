@import 'assets/theme';

.container {
  padding: 16px;

  @media (max-width: $medium-screen-min-width) {
    padding: 8px 16px 16px;
  }

  .header {
    display: flex;
    flex-direction: column;

    .titleWrapper {
      display: flex;

      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 23px;
        color: #323c47;

        @media (max-width: $medium-screen-min-width) {
          font-size: 16px;
        }
      }
    }

    .filter {
      display: flex;
      align-items: center;
      font-size: 12px;
      letter-spacing: 0.1px;
      margin-top: 8px;

      .dropdownText {
        display: flex;
        align-items: center;
        margin-left: 8px;
        color: $colorBlue1;
        user-select: none;
        cursor: pointer;

        .icon {
          color: #aeaeae;
        }
      }
    }
  }

  .statusesContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    .statusContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 80px;
      max-width: 90px;

      @media (max-width: $medium-large_screen-min-width) {
        max-width: 80px;
      }

      .pill {
        width: 100%;
        padding: 4px;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.01em;
        text-align: center;
        color: $white;
        border-radius: 4px;
      }

      .issuedPill {
        @extend .pill;
        background-color: $colorBlue2;
      }

      .overduePill {
        @extend .pill;
        background-color: $main-red;
      }

      .markedPaidPill {
        @extend .pill;
        color: #343434;
        background-color: #6fcf97;
      }

      .confirmPaidPill {
        @extend .pill;
        background-color: #219653;
      }

      .closedPill {
        @extend .pill;
        background-color: #f5ad58;
      }

      .label {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #343434;
      }

      &:not(:last-child) {
        margin-right: 6px;
        margin-bottom: 6px;
      }
    }
  }
}
