@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap($large-padding);

  &.horizontal {
    @include rowGap($standard-padding);
  }
}

.title {
  font-size: 16px;
  font-weight: 700;
  color: #323c47;
}

.navContainer {
  display: flex;
  justify-content: space-between;
}

.aTBoxWrapper {
  border: 1px solid #f3f3f3;
  border-right: none;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  margin-bottom: 16px !important;
  border-radius: 8px;
  height: 500px;
  padding: 16px 12px 16px 16px;
  display: flex;
}

.aTBox {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 430px;
  flex: 1;
  @include rowGap(16px);

  & > * {
    margin-right: 8px;
  }

  &::-webkit-scrollbar {
    width: 21px;
  }

  &::-webkit-scrollbar-track {
    background: #f0eeee;
    border-left: 9px solid #fff;
    border-right: 9px solid #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4cae1;
    border-radius: 9px;
    border-left: 6px solid #fff;
    border-right: 6px solid #fff;
  }

  &.horizontal {
    border: none;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 31% 31% 31%;
    overflow-y: hidden;
    box-shadow: none;
    padding: $standard-padding;

    & > * {
      margin-right: 0;
      max-width: 100%;
      height: 100%;
    }
  }
}

.noATWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: $colorTextualGray;
  align-items: center;

  .newATBtn {
    padding-top: 16px;
  }
}

.loading {
  display: flex;
  justify-content: center;
  width: 100%;
}

.leftControl,
.rightControl {
  width: 29px;
  font-weight: 600;
  font-size: 7px;
  line-height: 10px;
  text-align: center;
  color: $colorBlue3;

  span {
    height: 29px;
    width: 29px;
    border-radius: 50%;
    color: $colorBlue2;
    background: #fff;
    border: 0.58px solid $colorBlue3;
    box-sizing: border-box;
    box-shadow: 0px 2.32px 2.32px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 3px;
  }
}

.leftControl {
  span {
    padding-right: 2px;
  }
}

.rightControl {
  span {
    padding-left: 2px;
  }
}

.disabled span {
  cursor: not-allowed;
  opacity: 0.3;
}
