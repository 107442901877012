@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  align-items: center;
  @include columnGap(20px);

  .label {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #202225;
  }

  .labelActive {
    @extend .label;
    color: $colorBlue1;
  }

  .labelLoading {
    @extend .label;
    opacity: 0.7;
  }

  .disabled {
    background-color: transparent !important;
    border: 2px solid #d5d5d5 !important;
    opacity: 0.5;
  }

  .disabledActive {
    background-color: $colorBlue1 !important;
    border: 2px solid $colorBlue1 !important;
    opacity: 0.5;
  }
}
