@import 'assets/theme';

.container {
  min-width: 720px;
  background: #fff;
  box-shadow: 0 2px 16px #0000001a;
  border-radius: 8px;

  .header {
    padding: 16px 24px;
    border-bottom: 1px solid #ebeff2;

    .title {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 28px;
      color: $colorTextualBlack;
    }

    .description {
      font-size: 13px;
      letter-spacing: 0.1px;
      line-height: 20px;
      color: #323c4780;
    }
  }

  .basicDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 36px 24px;

    .avatarContainer {
      width: 200px;
      height: 200px;

      .avatar {
        width: 200px;
        height: 200px;
        background-color: #dbdbdb4c;
        border: 1px dotted $colorBlue1;
        border-radius: 50%;
        cursor: pointer;
      }

      .uploadAvatarPrompt {
        @extend .avatar;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.1px;
        line-height: 18px;
        color: $colorBlue1;

        .icon {
          margin-bottom: 4px;
          font-size: 48px;
        }
      }
    }

    .label {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: $colorTextualGray;
    }

    .detailsInputContainer {
      width: calc(100% - 224px);

      .name {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.1px;
        line-height: 33px;
        color: #343434;
      }

      .salutationContainer {
        display: flex;
        padding-top: 8px;

        .salutationOptionContainer {
          width: 30%;

          .label {
            margin-bottom: -9px;
          }
        }

        .otherValueContainer {
          width: calc(70% - 24px);
          margin-left: 24px;
        }
      }

      .nameDetailsContainer {
        display: flex;
        padding-top: 16px;

        .nameContainer {
          width: 60%;

          .label {
            z-index: 1;
          }
        }

        .pronounsContainer {
          width: calc(40% - 24px);
          margin-left: 24px;
          margin-bottom: -5px;

          .label {
            margin-bottom: -9px;
          }
        }
      }

      .emailContainer {
        display: flex;
        margin-top: 32px;

        .emailValue,
        .mobileNumberValue {
          width: 60%;
          user-select: none;

          .label {
            margin-top: -13px;
            margin-bottom: 5px;
          }

          .value {
            border-bottom: 1px solid #c4c4c4;
            cursor: not-allowed;
          }
        }

        .inputContainer {
          width: 60%;
        }

        .unlockButton {
          display: flex;
          align-items: center;
          max-width: calc(40% - 24px);
          margin-left: 24px;
          padding: 0;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0.1px;
          line-height: 20px;
          color: $mhh-dark-blue;
          border: none;

          .icon {
            margin-right: 8px;
          }
        }
      }

      .mobileNumberContainer {
        @extend .emailContainer;
        display: flex;
        margin-top: 32px;

        .mobileFieldWrapper {
          display: flex;
          flex-direction: column;
          width: 60%;
          margin-top: -8px;
        }
      }
    }
  }

  .professionalSummaryContainer {
    padding: 16px 24px;

    .title {
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 23px;
      color: $colorTextualBlack;
    }

    .bioContainer {
      position: relative;

      .bioInput {
        width: 100%;
        margin-top: 12px;
        padding: 16px;
        padding-bottom: 32px;
        resize: none;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #343434e0;
        border: 1px solid #c4c4c4;
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
      }

      .characterCount {
        position: absolute;
        left: 16px;
        bottom: 7.5px;
        font-size: 12px;
        color: #90a0b7;
        height: 28px;
        width: 100%;
        background-color: $white;
        padding-top: 8px;
        width: 95%;
      }
    }
  }

  .specialisationsContainer {
    padding: 0 24px 32px;

    .specialisationsValueContainer {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 16px;

      .specialisation {
        display: flex;
        align-items: center;
        margin: 2px;
        padding: 8px 12px;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.1px;
        line-height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $white;
        background-color: rgb(42, 70, 112);
        border-radius: 24px;
        overflow: hidden;

        .iconContainer {
          display: flex;
          align-items: center;
          margin-left: 4px;
          padding: 4px;
          cursor: pointer;

          &:hover {
            background-color: #dddddd80;
          }

          .icon {
            font-size: 14px;
          }
        }
      }
    }

    .label {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: $colorTextualGray;
      margin-bottom: -4px;
    }
  }

  .locationsContainer {
    padding: 0 24px 32px;

    .title {
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 23px;
      color: $colorTextualBlack;
    }

    .location {
      display: flex;
      align-items: flex-end;
      margin-top: 24px;

      .inputContainer {
        display: flex;
        min-width: 240px;
        position: relative;

        .input {
          border: none;
          border-bottom: 1px solid #c4c4c4;
          box-shadow: none;
          padding: 0;
          color: $colorTextualBlack;
          outline: none;
          transition: 0.2s ease-in-out;
          box-sizing: border-box;
          height: 30px;
          font-size: 16px;

          &:valid {
            border-bottom: 1px solid #c4c4c4;
            + {
              .label {
                color: $colorTextualGray;
                font-size: 11px;
                font-weight: 600;
                left: 2px;
                bottom: 22px;
                pointer-events: none;
              }
            }
          }
          &:focus {
            border-bottom: 1px solid #c4c4c4;
            + {
              .label {
                color: $colorTextualGray;
                font-size: 11px;
                font-weight: 600;
                left: 2px;
                bottom: 22px;
                pointer-events: none;
              }
            }
          }
        }

        .label {
          bottom: 0;
          left: 0;
          color: $colorTextualGray;
          display: flex;
          align-items: center;
          position: absolute;
          font-size: 14px;
          cursor: text;
          transition: 0.2s ease-in-out;
          box-sizing: border-box;
          width: 100%;
          height: 30px;
          line-height: 16px;
          letter-spacing: 0.2px;
        }
      }

      .removeButton {
        display: flex;
        align-items: center;
        margin-left: 16px;
        padding: 0;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.1px;
        line-height: 20px;
        color: $colorBlue1;
        border: none;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 32px;

    .saveButton {
      display: flex;
      justify-content: center;
      box-shadow: none;
    }
  }
}
