@import 'assets/theme';

.actionButton {
  min-width: 180px;
  position: fixed;
  bottom: 40px;
  right: 38px;
  border-radius: 55px !important;
  height: 55px;
  z-index: 999;
}

.gapContainer {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  border-radius: 10px;
  background-color: #fdf5f3;

  .gapMakeup {
    width: 3px;
    background: $colorBlue3;
    border-bottom-left-radius: 10px;
  }

  .gapText {
    font-weight: 600;
    font-size: 8px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #90a0b7;
    display: flex;
    width: 100%;
    padding-left: 10px;
    align-items: center;
  }
}
.eventContainer {
  position: absolute;
  width: 100%;
  z-index: 2;
}
