@import 'assets/theme';

.columnWrapper {
  display: flex;

  .columnStyles {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #414449;
    flex-grow: 1;
    flex-shrink: 7;
  }

  .sortActive {
    font-weight: 600;
    color: $mhh-yellow;
  }

  .avatar {
    @extend .columnStyles;
    justify-content: center;
    min-width: 120px;
    max-width: 12%;
    padding-right: 8px;
  }

  .fullName {
    @extend .columnStyles;
    min-width: 120px;
    width: 25%;
    max-width: 250px;
    padding-right: 16px;
  }

  .profileType {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 15%;
    padding-right: 8px;
  }

  .invited {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 10%;
    padding-right: 8px;
  }

  .attended {
    @extend .columnStyles;
    min-width: 120px;
    width: 38%;
    max-width: 500px;
    padding-right: 8px;
  }
}
