.container {
  display: flex;
  justify-content: space-between;
  height: 42px;

  .personInfo {
    display: flex;
    align-items: center;

    .email {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .revokeButton {
    display: flex;
    align-items: center;

    .revokeButtonLabel {
      color: #eb4545;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.revoking {
  opacity: 0.6;
}
