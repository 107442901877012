@import 'assets/theme';

.filterContainer {
  display: flex;
  padding: 0 8px;
  margin-bottom: 16px;
  justify-content: space-between;
}

.paginationWrapper {
  padding-top: 16px;
}

.loading {
  display: flex;
  justify-content: center;
}

.bulkButtonWrapper {
  display: flex;
  position: absolute;
  z-index: 1;
  margin-top: -35px;
  margin-left: -20px;
  @include columnGap($standard-padding * 2);

  .bulkButton {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    &:enabled:active {
      background-color: #fcd88f;
    }
  }
}
