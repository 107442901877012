@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 2);
}

.row {
  display: flex;
  @include columnGap($standard-padding * 2);

  & > span {
    color: $colorBlue3;
    font-size: 20px;
  }
}

.iconContainer {
  padding-top: $standard-padding;
}

.label {
  font-size: 11px;
  font-weight: 500;
  color: $colorTextualGray;
  padding-bottom: 6px;
}

.locationDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  @include columnGap($standard-padding * 2);

  .locationField {
    max-width: 50%;
  }

  .roomContainer {
    display: flex;
    align-items: center;
    column-gap: $standard-padding * 2;
  }
}

.fieldError {
  font-size: 12px;
  color: #ff4d4f;
}
