@import 'assets/theme';

.container {
  height: 40px;
  border-radius: 20px;
  border: 0;
  padding: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding: 0 8px 0 4px;
  }

  .counter {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.info {
    border: 1px solid $colorBlue1;

    .label {
      color: $white;
    }

    .counter {
      color: $colorBlue1;
      background-color: transparent;
    }
  }

  &.normal {
    background-color: $mhh-dark-blue-10;

    .label {
      color: $mhh-dark-blue;
    }

    .counter {
      color: $white;
      background-color: $mhh-blurb-grey-100;
    }
  }

  &.active {
    background-color: $mhh-dark-blue;

    .label {
      color: $white;
    }

    .counter {
      color: $mhh-light-black;
      background-color: $mhh-yellow;
    }
  }
}
