@import 'assets/theme';

.container {
  @include rowGap(18px);

  .title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: $colorTextualBlack;
  }

  .widgetListing {
    @include rowGap(12px);
  }
}
