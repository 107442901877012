@import 'assets/theme';

.headerContainer {
  margin-top: 24px;

  .label {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorTextualGray;
  }

  .titleContainer {
    padding-bottom: 16px;

    .value {
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #323c47;
    }
  }

  .safeguardingContainer {
    display: flex;
    padding-bottom: 16px;

    .safeguarding {
      min-width: 28%;
    }

    .safeguardingReason {
      margin-left: 24px;
    }

    .value {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .fieldContainer {
    display: flex;
    flex-wrap: wrap;

    .fieldBox {
      display: flex;
      flex-direction: column;
      padding-bottom: 8px;
      min-width: 28%;

      &:not(:last-child) {
        margin-right: 24px;
      }

      .value {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

.body {
  margin: 24px 0 30px;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #343434;
  opacity: 0.9;

  .text {
    padding: 0;
    height: auto;

    > * {
      cursor: default;
    }
  }
}
