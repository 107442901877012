.sectionStyles {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #6a707e;
}

.profileSection {
  @extend .sectionStyles;
  min-width: 150px;
  max-width: 200px;
  align-items: center;
  padding-right: 24px;
  flex: 1 5 20%;
}

.roleSection {
  @extend .sectionStyles;
  min-width: 80px;
  max-width: 150px;
  padding-right: 16px;
  flex: 1 5 18%;
}

.mobileSection {
  @extend .sectionStyles;
  min-width: 140px;
  max-width: 200px;
  padding-right: 16px;
  flex: 1 5 15%;
}

.emailSection {
  @extend .sectionStyles;
  //max-width: 250px;
  min-width: 180px;
  padding-right: 16px;
  flex: 1 5 23%;
}

.scheduleSection {
  @extend .sectionStyles;
  max-width: 400px;
  padding-right: 22px;
  flex: 1 5 40%;
}

.caseLoadSection {
  @extend .sectionStyles;
  max-width: 180px;
  min-width: 180px;
  padding-right: 22px;
  flex: 1 5 15%;
}

.buttonSection {
  @extend .sectionStyles;
  align-items: center;
  max-width: 120px;
  flex: 1 5 10%;
}
