$mhh-lightest-yellow-20: #fdfbf8;
$mhh-lightest-yellow: #fff7e5;
$mhh-light-yellow: #fee7b3;
$mhh-noActive-yellow: #fdd880;
$mhh-yellow: #f9af05;
$mhh-light-black: #1d1d1f;
$mhh-dark-blue: #1b365c;
$mhh-dark-blue-10: #e8ebef;
$mhh-dark-blue-30: #bbc3ce;
$mhh-dark-blue-50: #8d9bae;
$mhh-blurb-grey-100: #898d8d;
$mhh-purple: #75559f;
$mhh-green: #4bc27d;
$mhh-tiffany-blue: #00bfb2;
$mhh-red: #e03e52;
$mhh-orange: #ee704c;

// grey series
$grey-darker: #424242;
$mid-grey-dark: #757575;
$mid-grey-medium: #8b8b8b;
$mid-grey: #bdbdbd;
$mid-grey-light: #e0e0e0;
$grey-light: #eeeeee;
$white: #ffffff;
$black: #000000;

// main color
$main-red: #fc8468;
$cta: #3f52fb;
$selected: #3f52fbc0;

// Colors
$colorBlue1: #3f52ff;
$colorBlue2: #2d9cdb;
$colorBlue3: #2a4670;
$colorLineGray: #d3d8dd; // Only use on line

$colorTextualGray: #818e9b; // Only use on textual
$colorTextualBlack: #192a3e; // Only use on textual
$colorTextualDisabled: #c2c2c2;
