@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
}

.formContainer {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 3);
  flex: 1;
  padding: $new-base-padding * 4 $new-base-padding * 5;
}

.label {
  font-size: 11px;
  font-weight: 500;
  color: $colorTextualGray;
}

.clientAppointmentTypeContainer {
  display: flex;
  flex: 1;
  @include columnGap(40px);
  max-height: 540px;

  & > div {
    flex: 1;
  }

  .selectPWrapper {
    display: flex;
    flex-direction: column;
    @include rowGap(16px);
  }
}
