@import 'assets/theme';

.container {
  font-weight: 400;

  .title {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 28px;
    color: $colorTextualBlack;
    margin-bottom: $standard-padding;
  }
  .header {
    display: flex;
    background: #f5f6ff;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-around;
    padding: 20px;
    color: $colorBlue3;
    border: 1px #e2e8f0;
    border-style: solid none;

    .headerItem {
      flex: 1;
    }
  }

  .histories {
    display: flex;
    flex-direction: column;

    .noField {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;
      padding: $standard-padding * 2 0px;
    }

    .historyRow {
      display: flex;
      flex-direction: row;
      padding: 10px 20px;
      border-bottom: 1px solid #dadbde;

      .field {
        display: flex;
        align-items: center;
        flex: 1;
        font-size: 14px;
      }

      .status {
        display: flex;
        align-items: center;
        flex: 1;
        @include columnGap(8px);

        .statusIconContainer {
          display: flex;
          align-items: center;

          .success {
            font-size: 16px;
            color: #4bc27d;
          }

          .processing {
            font-size: 16px;
            color: $mhh-dark-blue-50;
          }

          .failed {
            font-size: 16px;
            color: #eb4545;
          }
        }
      }
    }
  }

  .message {
    size: 18px;
    padding: 20px;
    text-align: center;
  }
}
