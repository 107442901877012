@import 'assets/theme';

.container {
  @include rowGap(30px);

  .subTitle {
    display: flex;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $colorTextualGray;
  }
}

.loadingWrapper {
  margin: 32px 0 16px;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
  .textarea {
    width: 100%;
    height: 148px;
    padding: 8px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    box-shadow: 0px 6px 18px 0px #0000000f;
    resize: none;
  }
}

.clientDetailTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $colorTextualGray;
}

.inputSkeletonLoading {
  .inputSkeletonLoading {
    &::after {
      content: '';
      position: relative;
      display: block;
      margin-top: 36px;
      border-bottom: solid 1px #c4c4c4;
    }
  }
}
