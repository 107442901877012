@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding);
  padding: $new-base-padding * 4 $new-base-padding * 5;
  background-color: #f8fdff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 25px 25px;

  .textAndButtonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include columnGap($standard-padding * 2);

    .blueTextLarge {
      font-weight: bold;
      font-size: 14px;
      color: $colorBlue1;
    }

    .descContainer {
      display: flex;
      flex: 1;
      align-items: center;

      .cProfileWrapper {
        display: flex;
        align-items: center;
        max-width: 25%;

        .cProfile {
          @include columnGap(12px);
        }

        .plusSign {
          margin: 0 12px;
          font-size: 12px;
          font-weight: 600;
          color: $colorTextualGray;
        }
      }

      .clientProfile {
        max-width: 25%;
      }

      .clientName {
        padding-left: 12px;
      }

      .initialName {
        font-size: 16px;
      }

      .divider {
        height: 100%;
        border-right: 1px solid #c4c4c4;
        margin: 0 16px;
      }

      .appointmentTypeWrapper {
        text-align: center;
      }

      .timeWrapper {
        max-width: 110px;
        text-align: center;
      }

      .label {
        font-size: 12px;
        font-weight: 600;
        color: $colorTextualGray;
        margin: 0 16px;
      }
    }

    .buttonContainer {
      display: flex;

      .button {
        margin: 0;
        min-width: 180px;
      }
    }
  }
}
