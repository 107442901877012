@import 'assets/theme';

.container {
  .labelContainer {
    padding-bottom: 24px;

    .label {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: $colorBlue1;
    }
  }

  .fieldContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 32px;
    max-width: 380px;

    &.fieldError,
    .fieldError {
      font-size: 12px;
      color: #ff4d4f;

      input {
        border-bottom: 1px solid #ff4d4f !important;
      }
    }

    .noneComms {
      font-size: 11px;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: #2eb886;
    }
  }

  .roleFieldContainer {
    @extend .fieldContainer;
    max-width: 240px;
  }

  .phoneContainer {
    @extend .fieldContainer;
    margin: -10px 0 24px;
  }

  .primaryFieldContainer {
    @extend .fieldContainer;
    max-width: fit-content;
    margin: 8px 0;
  }

  .preferenceContainer {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .cPreferenceHidden {
    @extend .fieldContainer;
    margin: 0;
    max-height: 0;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
  }
}

.containerWithBox {
  @extend .container;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: -8px -16px 24px;
  padding: 16px;
}

.twoQuestionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -16px;
}
