@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;

    .card {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
    }
  }
}

.buttonWrapper {
  display: flex;
  margin-top: 16px;
  @include columnGap(16px);
}
