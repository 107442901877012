.loading {
  height: 400px;
  margin-top: 24px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 512px;
  margin-top: 24px;
  padding-top: 12px;
  padding-right: 12px;
  overflow-y: auto;
}
