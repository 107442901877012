@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: $standard-padding * 2;

  .questionWrapper {
    display: flex;
    flex-direction: column;

    .answerWrapper {
      margin-top: 5px;
      font-weight: 400;
      color: $colorBlue3;
    }
  }
}
