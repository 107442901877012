@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  @include columnGap(24px);

  .search {
    max-width: 370px;
  }
}
