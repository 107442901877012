@import 'assets/theme';

.card {
  display: flex;
  flex-direction: column;
  border-radius: $standard-padding * 0.5;
  background-color: $mhh-lightest-yellow;
  border: 1px solid #bbc3ce;
  padding: $standard-padding * 1.5 $standard-padding * 2;
  font-size: 14px;

  .fieldName {
    font-size: 12px;
    color: $mhh-blurb-grey-100;
    margin-bottom: $standard-padding;

    b {
      color: $mhh-light-black;
    }

    .scoreContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @include rowGap($standard-padding * 0.5);
      @include columnGap($standard-padding);

      .focusArea {
        font-size: 16px;
        font-weight: 600;
        color: $mhh-light-black;
      }

      .badge {
        height: fit-content;
        width: fit-content;
        background-color: $mhh-yellow;
        color: $mhh-dark-blue;
        border-radius: 25px;
        padding: $standard-padding * 0.5 $standard-padding * 2;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .title {
    font-size: 18px;
    font-weight: 600;
  }

  .description {
    margin-bottom: $standard-padding;
  }

  .dueDate {
    font-weight: 600;
    margin-bottom: $standard-padding * 2;

    i {
      margin-right: $standard-padding * 0.5;
      vertical-align: bottom;
    }
  }

  .addButton {
    width: fit-content;
    max-height: 36px;
    font-size: 16px;
    background-color: $mhh-yellow;
    color: $colorTextualBlack;
    box-shadow: none;
    align-self: center;

    span {
      margin-right: $standard-padding * 0.5;
      font-size: 16px;
    }
  }
}
