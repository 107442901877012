@import 'assets/theme';

.modalContainer {
  min-width: 800px;

  .title {
    font-weight: bold;
    font-size: 24px;
    padding: 10px 0;

    .practitionerName {
      color: $mhh-yellow;
    }
  }

  .loadingContainer {
    padding: 40px 0;
  }

  table {
    width: 100%;
    margin-bottom: 20px;

    thead > tr > th {
      text-align: left;
    }

    th,
    td {
      padding: 10px 0;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
