.container {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px 32px;
  min-height: 300px;
  flex: 1;
  overflow: scroll;
}
