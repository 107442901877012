@import 'assets/theme';

.questionBox {
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;

  .header {
    display: flex;
    justify-content: space-between;

    .actionsWrapper {
      display: flex;
      align-items: center;
    }

    .removeQuestionBtn {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #ff2500;
      cursor: pointer;
      padding: 16px 16px 0 0;
    }

    .upDownWrapper {
      padding: 16px 16px 0 0;

      .upDownBtn {
        font-size: 28px;
        color: #5666a6;
        cursor: pointer;
      }
    }
  }
}

.questionBoxWithError {
  @extend .questionBox;
  border: 1px solid #fc8367;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;

  .moreQuestionBtn {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: $colorBlue1;
    //cursor: pointer;
    opacity: 0; // remove this when ready
    .arrowIcon {
      color: $colorBlue1;
    }
  }
}
