@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .insightsBack {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
    color: $colorBlue1;
    cursor: pointer;

    .backIcon {
      font-size: 30px;
      line-height: 20px;
      width: 28px;
      left: -4px;
      position: relative;
    }
  }

  .insightsTitle {
    display: flex;
    padding: 20px 12px;
    font-weight: 600;
    font-size: 18px;
    color: #414449;
  }

  .insightsMenuContent {
    display: flex;
    flex-direction: column;
    max-height: 800px;
    overflow-y: auto;
  }
}
