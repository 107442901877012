@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px;

  &.active {
    background-color: #f2f9ff;
  }
}

.label {
  display: flex;
  align-items: center;
  @include columnGap(12px);

  .descGroup {
    margin-left: 8px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;

    .desc {
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;
    }

    .subDesc {
      color: $colorTextualGray;
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.checkbox {
  appearance: none;
  margin: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #d5d5d5;
  border-radius: 4px;
  color: #d5d5d5;
  cursor: pointer;
  outline: none;
  transition: all 0.1s linear;
  align-self: baseline;

  &:after {
    content: '\2713';
    display: none;
    justify-content: center;
    align-items: center;
    width: 16px;
  }

  &:checked {
    font-size: 12px;
    line-height: 16px;
    font-weight: 800;
    color: #fff;
    background-color: $colorBlue1;
    border: 2px solid $colorBlue1;

    &:after {
      display: flex;
    }
  }
}

.select {
  align-self: end;
  min-width: 180px;
}

:export {
  greyColor: $colorLineGray;
}
