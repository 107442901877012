@import 'assets/theme';

.modalContainer {
  min-width: 600px;

  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #323c47;
  }
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;

  .headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;

    .label {
      font-size: 15px;
      line-height: 23px;
      color: $colorTextualBlack;
    }

    .saveBtn {
      display: flex;
      max-height: 45px;
      min-width: 120px;
    }
  }

  .noClientContainer {
    display: flex;
    justify-content: space-between;
  }

  .clientList {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow: auto;
    border: 1px solid #f3f3f3;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: $grey-light;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 2px;
      background-color: $grey-light;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $mid-grey;
    }
  }
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  padding: 20px 10px 30px;
}
