@import 'assets/theme';

.modalContainer {
  min-width: 800px;

  .title {
    font-weight: 600;
    font-size: 24px;
    color: #414449;
    padding: 8px 0px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(32px);

  .formContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 600px;
    @include rowGap(44px);

    .inputContainer {
      width: 280px;
    }

    .inputwithErrorContainer {
      @extend .inputContainer;
      margin-bottom: 26px;
    }

    .locationContainer {
      @extend .inputContainer;
      display: flex;
      width: 100%;

      .locationInputContainer {
        flex: none;
        width: 280px;
      }

      .locationDisplayContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-weight: 600;
        @include columnGap(16px);

        .label {
          font-size: 14px;
        }
        .value {
          font-size: 16px;
        }
      }
    }
  }

  .hiddenError {
    opacity: 0;
    color: #ff1900;
    font-size: 12px;
    font-weight: 600;
  }
  .showError {
    @extend .hiddenError;
    opacity: 1;
  }
  .footer {
    display: flex;
    justify-content: flex-end;

    .button {
      box-shadow: none;
    }
  }
}
