@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  @include rowGap(16px);

  .card {
    border: 1px solid #dadbde;
    box-sizing: border-box;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 12px;
  }
}
