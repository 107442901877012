@import 'assets/theme';

.loadingContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 16px;
  overflow: hidden;

  .loadingBox {
    display: flex;
    align-items: center;
    min-height: 30px;
    padding: 16px 24px;
    @include columnGap(16px);
    border: 1px solid #ecedef;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 8px;

    .loadingName {
      width: 80%;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 21px;
  }

  &::-webkit-scrollbar-track {
    background: #f0eeee;
    border-left: 9px solid #fff;
    border-right: 9px solid #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: $mhh-light-yellow;
    border-radius: 9px;
    border-left: 6px solid #fff;
    border-right: 6px solid #fff;
  }

  .noFoundLabel {
    font-size: 14px;
    color: #b1b1b1;
    padding: 16px 24px;
  }

  .clientBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: $white;
    border: 1px solid #ecedef;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 8px;
    margin-right: 4px;

    &:not(:last-child) {
      border-bottom: #ebeff2 1px solid;
    }

    &:hover {
      background: #fbfbfb;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    &.active {
      border: 1px solid $mhh-yellow;
    }

    .initialName {
      font-weight: 600;
      font-size: 16px;
    }

    .clientName {
      font-weight: 600;
    }

    .selectedBadge {
      display: flex;
      align-items: center;

      span {
        font-size: $standard-padding * 4;
        color: $mhh-yellow;
      }
    }

    .pill {
      padding: 0 4px;
      background-color: #ff0000;
      border-radius: 4px;
      font-style: italic;
      color: $white;

      &.active {
        background-color: #25be69;
      }
    }
  }
}

.searchBar {
  position: relative;
  font-weight: 600;
  font-size: 14px;

  .input {
    border: 1px solid #dadbde;
    border-radius: 4px;
    padding: 6px 12px;
    padding-left: 36px;
    width: 100%;
    color: $colorTextualBlack;

    &:focus {
      border: 1px solid $mhh-yellow;
    }

    &::placeholder {
      color: $colorTextualGray;
    }
  }

  .searchBarIcon {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 10px;

    i {
      color: $colorTextualGray;
      font-size: 20px;
    }
  }
}

.noItems {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: $colorTextualGray;
    font-size: 20px;
    margin-right: 6px;
  }
}

.compactContainer {
  display: flex;
  flex-direction: column;

  .selectedBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 8px 10px;
    border-radius: 8px;
    user-select: none;
    cursor: pointer;

    .icon {
      color: $colorBlue1;
    }
  }

  .loadingBox {
    @extend .selectedBox;
    min-height: 54px;
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    .listHide {
      background: #fff;
      border: 1px solid #eee;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.2s ease;
      position: absolute;
      width: 100%;

      .searchBox {
        display: flex;
        align-items: center;
        padding: 8px 10px;

        .searchIcon {
          position: absolute;
          left: 18px;
          color: #b1b1b1;
          font-size: 24px;
        }

        .searchInput {
          display: flex;
          flex: 1 1;
          font-size: 16px;
          line-height: 16px;
          padding: 8px 16px 8px 40px;
          border-radius: 6px;
          border: 1px solid #eee;
          background-color: #fff;
          outline: none;
          transition: all 0.2s ease;
        }
      }

      .itemWrapper {
        max-height: 200px;
        overflow: auto;

        .item {
          padding: 8px 10px;
          cursor: pointer;

          &:hover {
            color: rgba(0, 0, 0, 0.85);
            font-weight: 600;
            background-color: #f5f5f5;
            transition: background 0.3s ease;
          }
        }
      }

      .itemSelected {
        padding: 8px 10px;
        cursor: pointer;
        background-color: #e6f7ff;
      }
    }

    .listShow {
      @extend .listHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }
}
