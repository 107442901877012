@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px;

  .practiceInfoWrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;

    .practiceCard {
      display: flex;
      max-width: 50%;

      .practiceImgWrapper {
        padding-right: 10px;

        .img {
          border-radius: 50%;
          max-width: 100px;
          border: 4px solid #0086be;
          padding: 2px;
        }

        .practiceImg {
          border-radius: 16px;
          max-width: 100px;
          height: 100px;
          width: 100px;
        }
      }

      .contactDetailInfo {
        display: flex;
        flex-direction: column;
        padding-top: 2px;
        cursor: pointer;

        .name {
          font-weight: bold;
          font-size: 20px;
          line-height: 30px;
          display: flex;
          align-items: center;
          color: #343434;
        }

        .infoWrapper {
          display: flex;
          flex-direction: column;
          transition: all 0.2s ease;
          border-radius: 4px;
          padding: 2px 4px;

          &:hover {
            background: #eee;

            .editBadge {
              display: flex;
              justify-content: flex-end;

              .editIconWrapper {
                position: absolute;

                .icon {
                  position: relative;
                  left: 14px;
                  bottom: 7px;
                  padding: 4px;
                  border-radius: 50%;
                  background: $grey-light;
                  font-size: 14px;
                  transition: all 0.1s ease;

                  &:hover {
                    color: $colorBlue1;
                  }
                }
              }
            }
          }

          .detailInfo {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #343434;
            opacity: 0.5;
            padding: 1px 0;
          }

          .editBadge {
            display: none;
          }
        }

        .infoWrapperNoHover {
          @extend .infoWrapper;

          &:hover {
            background: none;

            .editBadge {
              display: none;
            }
          }
        }
      }

      .contactDetailInfoPreview {
        @extend .contactDetailInfo;
        cursor: auto;

        .infoPreviewWrapper {
          display: flex;
          flex-direction: column;

          .detailInfo {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #343434;
            opacity: 0.5;
            padding: 1px 0;
          }
        }
      }
    }

    .practitionerCard {
      @extend .practiceCard;
      padding-right: 8px;
    }
  }

  .patientInfoWrapper {
    display: flex;
    justify-content: space-between;

    .infoWrap {
      display: flex;

      .infoTitle {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        color: #343434;
        padding-right: 4px;
      }
    }
  }
}
