@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 3);
  flex: 1;
  padding: $new-base-padding * 4 $new-base-padding * 5;
  overflow-x: hidden;

  .timeSelectWrapper {
    padding: 0;
  }
}

.deliveryModeContainer {
  padding-top: $standard-padding * 2;
  padding-left: $standard-padding * 3;
  max-width: 50%;
}
