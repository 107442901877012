@import 'assets/theme';

.container {
  background: #f5f6ff;
  border: 1px solid #ecedef;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 12px 16px;
  margin-bottom: 28px;

  .loadingContainer {
    display: flex;
    @include columnGap(16px);
    margin-top: 12px;

    .loading {
      width: 100%;
      border-radius: 4px;
      min-height: 60px;
    }
  }

  .title {
    display: flex;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #323c47;
    margin-bottom: 4px;

    .editBtn {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $colorBlue1;
      margin-left: 16px;

      .icon {
        font-size: 18px;
        line-height: 17px;
        padding-right: 2px;
      }
    }
  }

  .summaryDetails {
    display: flex;
    flex-wrap: wrap;

    .summaryCard {
      display: flex;
      flex: 20%;
      max-width: 20%;
      flex-direction: column;
      align-items: center;
      padding: 12px 12px 0 0;

      .tag {
        width: 100%;
        margin: 0;
        min-width: 100px;
        justify-content: center;
      }

      .count {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #414449;
        margin-top: 4px;
      }
    }
  }
}
