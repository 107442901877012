@import 'assets/theme';

.card {
  display: flex;
  padding: 24px 16px;

  .calendarIconWrapper {
    display: flex;
    align-items: center;
    padding: 0 16px;

    .calendarIcon {
      color: $colorBlue1;
      border: 2px solid $colorBlue1;
      border-radius: 50%;
      padding: 4px;
      background: #fff;
    }
  }

  .appointmentDetailWrapper {
    display: flex;
    flex-direction: column;
    width: 80%;

    .nextLabel {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: $colorBlue1;
      padding-bottom: 4px;
    }

    .appointmentDetail {
      display: flex;
      font-size: 15px;
      line-height: 20px;
      color: #696969;

      .appointmentTime {
        font-weight: bold;
        color: #343434;
      }
    }

    .bookedBy {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #414449;
      padding-top: 4px;
    }
  }

  .actionContainer {
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: flex-end;
    padding-right: 16px;

    .editBtn {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $colorBlue1;
      margin-left: 16px;

      .icon {
        font-size: 18px;
        line-height: 17px;
        padding-right: 2px;
      }
    }
  }
}

.cardNext {
  @extend .card;
  background-color: #f2f9ff;
  padding: 16px;
}
