@import 'assets/theme';

.container {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 24px 16px;
  transition: all 0.5s ease;
  position: relative;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .interventionName {
    font-size: 16px;
    line-height: 22px;
    color: #414449;
  }

  .createdDate {
    font-size: 14px;
    line-height: 18px;
    color: $colorBlue3;
  }

  .member {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $colorBlue3;
  }

  .lead {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $colorBlue3;
  }

  .tag {
    background: $colorBlue3;
    border-radius: 20px;
    color: #fff;
    padding: 4px 12px;
    margin: 8px 4px 8px 0;
    max-width: -moz-fit-content;
    max-width: fit-content;
    font-size: 10px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .noneText {
    color: $colorBlue3;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
  }

  .description {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
  }

  .icon {
    border: 2px solid $mhh-dark-blue;
    color: $mhh-dark-blue;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 18px;
  }
}
