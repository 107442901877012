@import 'assets/theme';

.modalContainer {
  min-width: 800px;

  .title {
    font-weight: 600;
    font-size: 24px;
    color: #414449;
    padding: 8px 0px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(32px);

  .formContainer {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 600px;
    @include rowGap(30px);

    .locationNameInput {
      width: 280px;
    }
  }

  .error {
    color: #ff1900;
    font-size: 12px;
    font-weight: 600;
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    .button {
      box-shadow: none;
    }
  }
}
