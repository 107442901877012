@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: $mhh-light-black;
  @include columnGap(8px);

  .outstandingIcon {
    font-size: 20px;
    color: $mhh-blurb-grey-100;
  }

  .completedIcon {
    font-size: 20px;
    color: $mhh-green;
  }

  .actionButton {
    display: flex;
    padding: 8px;
    background: none;
    line-height: normal;
    border-radius: 50%;

    &:active {
      background-color: $mhh-lightest-yellow;
    }

    .icon {
      font-size: 20px;
      color: $mhh-dark-blue;
    }
  }

  .menuWrapper {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 7;

    .menuHide {
      background: #fff;
      visibility: hidden;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: all 0.2s ease;
      position: absolute;
      width: max-content;
      border: 1px solid #ecedef;
      box-sizing: border-box;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      .label {
        margin-left: 8px;
        width: 115px;
      }
    }

    .menuShow {
      @extend .menuHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
      transform-origin: top;
      transition: transform 0.2s ease;
    }

    .itemCard {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #414449;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background-color: #f5f6ff;
      }

      &:disabled {
        cursor: not-allowed;
      }

      .loading {
        margin-left: 4px;
      }
    }
  }
}
