@import 'assets/theme';

.container {
  width: 100%;
  height: 70px;

  .profileWrapper {
    display: flex;
    height: 100%;
    padding: 0 24px 0 16px;
    cursor: pointer;
    justify-content: space-between;

    .userInfo {
      display: flex;
      align-items: center;
      @include columnGap(16px);

      .userDetails {
        display: flex;
        flex-direction: column;
        @include rowGap(4px);
      }
    }

    .profileAvatar {
      display: flex;
      align-items: center;

      .profileImg {
        width: 55px;
        height: 55px;
        border-radius: 60px;
        border: 2px solid $mhh-yellow;

        @media only screen and (max-width: $medium-large_screen-min-width) {
          width: 40px;
          height: 40px;
        }
      }

      .defaultLogo {
        @extend .profileImg;
        font-size: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #c4c4c4;
        border: 2px solid #c4c4c4;
        user-select: none;
        background-color: #fff;
      }
    }

    .profileName {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 150px;

      @media only screen and (max-width: $medium-small-screen-min-width) {
        display: none;
      }

      .name {
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .practiceName {
        font-size: 13px;
        line-height: 16px;
        color: $colorBlue3;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .actionBtnWrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include columnGap(16px);
      flex: 1;
    }

    .notification {
      display: flex;
      align-items: center;
    }

    .settings {
      display: flex;
      align-items: center;

      .settingsBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid $mhh-yellow;
        color: $colorBlue3;
        background-color: #fff;
        transition: 0.2s ease-in-out;

        &:hover {
          background-color: $mhh-light-yellow;
          border: 1px solid $mhh-yellow;
          color: $mhh-dark-blue;
        }
      }

      .settingsBtnActive {
        @extend .settingsBtn;
        background-color: $mhh-light-yellow;
        border: 1px solid $mhh-yellow;
        color: $mhh-dark-blue;
      }
    }
  }

  .dropdownHide {
    position: relative;
    top: -1px;
    right: 0;
    height: auto;
    max-width: 359px;
    z-index: 1;
    background-color: $mhh-lightest-yellow;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.3s;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

    .dropdownList {
      margin: 0;
      padding: 0;
      list-style-type: none;

      .dropdownItem {
        padding: 16px;
        color: $mid-grey-medium;
        border-radius: 4px;
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          transition: all 0.3s;
          background: $grey-light;
        }

        .itemBtn {
          display: flex;
          align-items: center;
          @include columnGap(8px);

          .text {
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            color: $colorTextualBlack;
          }

          .rightIcon {
            line-height: 23px;
            font-size: 20px;
          }

          .exitIcon {
            line-height: 23px;
            font-size: 24px;
          }
        }
      }
    }
  }

  .dropdownShow {
    @extend .dropdownHide;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

.analyticLogoutContainer {
  opacity: 0;
  top: -1000px;
}

.loading {
  min-width: 360px;
  min-height: 70px;
}
