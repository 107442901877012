@import 'assets/theme';

.tags {
  display: flex;
  flex-wrap: wrap;

  .tag {
    font-weight: 700;
    font-size: 12px;
    color: white;
    background-color: $mhh-dark-blue;
    border-radius: 24px;
    padding: 8px 12px;
    margin-right: 8px;
    margin-top: 8px;
  }
}
