@import 'assets/theme';

.sectionLabel {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  color: #414449;

  .icon {
    margin-left: 8px;
    color: #fd8468;
    border-radius: 50%;
    cursor: pointer;
  }

  .tooltip {
    max-width: 200px;
    border-radius: 8px;
    line-height: 16px;
    font-size: 14px;
  }
}

.item {
  display: flex;
  margin: 0 -12px 40px;
  padding: $standard-padding * 2;

  &.includeDiscount {
    justify-content: space-between;
    align-items: center;
    min-height: 78px;

    .checkboxContainer {
      width: 32%;
    }
  }

  &.active {
    background-color: #f2f9ff;

    .discountContainer {
      display: flex;
    }

    .automatedInvoicingContainer {
      display: block;
    }
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    margin-right: 6%;

    .checkbox {
      appearance: none;
      margin: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      color: #d5d5d5;
      cursor: pointer;
      outline: none;
      transition: all 0.1s linear;

      &:after {
        content: '\2713';
        display: none;
        justify-content: center;
        align-items: center;
        width: 16px;
      }

      &:checked {
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
        color: $white;
        background-color: $colorBlue1;
        border: 2px solid $colorBlue1;

        &:after {
          display: flex;
        }

        + .checkboxLabel {
          color: $colorBlue1;
        }
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #2b4670;
        border: 2px solid #2b4670;
      }
    }

    .checkboxLabel {
      display: flex;
      align-items: center;
      flex: 1;
      padding-left: $standard-padding * 1.5;
      font-size: 16px;
      font-weight: 600;
      color: #414449;
      cursor: pointer;
    }
  }

  .discountContainer {
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 33%;

    .switchToggle {
      position: relative;
      display: flex;
      align-items: center;
      height: 14px;
      width: 31px;
      margin-left: 48px;
      border-radius: 25px;
      box-shadow: 0px 2px 4px #{$black}40;
      background: #{$colorBlue1}54;

      cursor: pointer;

      input {
        display: none;

        &:checked + span {
          left: 50%;
        }
      }

      .indicator {
        position: absolute;
        border-radius: 50%;
        background-color: #dfdfdf;
        transition: 0.1s ease;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
        width: 20px;
        height: 20px;
        top: -3px;
        left: -3px;
      }

      .switchLabel {
        position: absolute;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        color: #5666a6;
        user-select: none;

        &:first-of-type {
          left: -24px;
        }

        &:last-of-type {
          right: -24px;
        }
      }
    }

    .discountInput {
      max-width: calc(50% - 52px);
      margin-left: 52px;
      background-color: transparent;

      input {
        background-color: transparent;
        border-bottom-color: $colorBlue1;
      }
    }
  }
}
