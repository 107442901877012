@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  margin-top: -8px;
  justify-content: space-between;

  .leftSection {
    display: flex;
    align-items: flex-end;

    .titleWrapper {
      display: flex;
      flex-direction: column;
      padding-right: 24px;

      .title {
        font-weight: 600;
        font-size: 22px;
        letter-spacing: 0.01em;
        color: $colorTextualBlack;
      }

      .date {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: flex-end;
        color: $black;
        opacity: 0.5;
      }
    }
  }

  .rightSection {
    display: flex;

    .addNewBtnWrapper {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px 16px;
      border: 1px solid $mhh-dark-blue;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.01em;
      color: $white;
      background: $mhh-dark-blue;
      box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
      border-radius: 4px;

      &:hover {
        opacity: 0.8;
      }

      .icon {
        padding-right: 6px;
      }
    }

    .configureBtnWrapper {
      @extend .addNewBtnWrapper;
      border: 1px solid $mhh-dark-blue;
      color: $mhh-dark-blue;
      background: #fff;
      box-shadow: none;
      margin-left: 24px;

      .icon {
        padding-right: 6px;
      }
    }
  }
}
