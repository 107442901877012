@import 'assets/theme';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 16px;
}

.activeBadge {
  @extend .container;
  background: #4bc27d;
  color: #fff;
}

.closedBadge {
  @extend .container;
  background: #f38f8f;
  color: #fff;
}
