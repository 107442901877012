@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 840px + 32px;

  .content {
    border: 1px solid #414549;
    box-sizing: border-box;
    overflow: hidden;
  }
}
