@import 'assets/theme';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #ffffffd0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
}

.container {
  display: flex;
  flex-direction: column;

  .signUpContainer {
    max-width: 850px;
  }

  .content {
    //align-self: center;
    display: flex;
    flex-direction: column;
    //max-width: 720px;
  }
}

.expiredAlert {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  user-select: none;

  .expiredContainer {
    width: 750px;
    height: 240px;
    margin: auto;
    border-radius: 5px;
    border: solid 1px $colorTextualGray;
    background-color: #fff;
    box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
  }

  .expiredTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #323c47;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
  }

  .expiredMsg {
    padding: 30px 24px 24px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: $colorTextualBlack;
  }

  .supportEmail {
    color: #0019ff;
    text-decoration: underline;
    font-weight: 400;
    font-size: 16px;
  }
}
