@import 'assets/theme';

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .leftWrapper {
    display: flex;
    flex-direction: column;

    .loadingWrapper {
      display: flex;
      flex-direction: column;

      .loading {
        width: 200px;
        height: 40px;
      }
    }

    .title {
      font-weight: bold;
      font-size: 28px;
      line-height: 40px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      color: #414449;

      @media (max-width: $medium-screen-min-width) {
        font-size: 24px;
      }
    }

    .backBtn {
      margin: 8px 0;
      align-items: flex-start;
      width: max-content;

      .backIcon {
        margin-right: 4px;
        width: 20px;
      }
    }
  }

  .rightWrapper {
    display: flex;

    .icon {
      margin-right: 4px;
    }
  }
}
