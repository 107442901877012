@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding);

  .title {
    font-size: 11px;
    font-weight: 500;
    color: $colorTextualGray;
  }

  .nav {
    display: flex;
    align-items: center;
  }
}
