@import 'assets/theme';

.surveyGroupSelect {
  border: 1px solid #ecedef;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .selectSurveyGroupWrapper {
    padding: 12px 16px;

    .selectSurveyGroupTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: $colorTextualBlack;
    }

    .selectSurveyGroupSubtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #323c47;
      opacity: 0.5;
    }

    .surveyFormItems {
      max-height: 400px;
      margin-top: 6px;
      padding-right: 4px;

      .loadingContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        .loadingBox {
          display: flex;
          align-items: center;
          padding: 12px 0;
          box-sizing: border-box;

          .loadingName {
            width: 100%;
            height: 20px;
          }
        }
      }

      .surveyFormItem {
        display: flex;
        margin: 23px 0;

        .surveyItemDetail {
          margin-left: 15px;
          label {
            cursor: pointer;
          }
        }

        .surveyFormItemName {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #343434;
        }
        .surveyFormItemChecked {
          color: $mhh-yellow;
        }
      }
    }
  }
}

.submitBtn {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
}
