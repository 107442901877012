@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .contentBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 0px 20px;
  }
  .headerWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: #323c47;
      margin-bottom: 4px;
    }

    .subTitle {
      font-size: 13px;
      line-height: 20px;
    }
  }

  .listContainer {
    display: flex;
    flex-direction: column;
    margin: 24px 0 28px;
  }

  .noContact {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $black;
    opacity: 0.5;
    margin: 20px 0;
  }

  .addRemoveButton {
    display: flex;

    .button {
      display: flex;
      border-radius: 4px;
      border: 1px solid $mhh-yellow;

      & i {
        margin-right: 6px;
      }
    }
  }

  .addRemoveContact {
    margin-bottom: 40px;
  }
}
