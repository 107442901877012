@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(12px);

  .itemWrapper {
    display: flex;
    background: #f5f6ff;
    border: 1px solid #dadbde;
    border-radius: 4px;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .item {
      display: flex;
      @include columnGap(12px);
      align-items: center;

      .label {
        color: #414449;
        font-size: 16px;
        font-weight: 600;
      }

      .count {
        background-color: $colorBlue1;
        border-radius: 25px;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        line-height: 16px;
      }
    }

    .arrowIcon {
      color: $colorBlue1;
    }
  }
}
