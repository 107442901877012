@import 'assets/theme';

.filterWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 10px;

  .filterTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: $mhh-blurb-grey-100;
    margin: 0px !important;
  }
}
