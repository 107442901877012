@import 'assets/theme';

.container {
  min-width: 720px;
  background: #fff;
  box-shadow: 0 2px 16px #0000001a;
  border-radius: 8px;
  padding: 20px;

  .backBtnContainer {
    display: flex;

    .backBtn {
      display: flex;
      align-items: center;
      @include columnGap(8px);
      color: $colorBlue1;
      cursor: pointer;
    }
  }

  .contentsContainer {
    padding: 24px 0px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    @include rowGap(24px);

    .statusContainer {
      display: flex;
      @include columnGap(16px);
      font-weight: 600;
    }

    .detailsContainer {
      display: flex;
      flex-direction: column;
      @include rowGap(16px);
      font-weight: 600;

      .nameContainer {
        font-size: 18px;
      }

      .summaryContainer {
        display: flex;
        flex-direction: column;
        @include rowGap(4px);

        .summary {
          max-width: 512px;
        }
      }
    }
  }
}
