@import 'assets/theme';

.headingField {
  width: 30%;
  padding-top: 16px;
  position: relative;

  &:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 16px;
  }

  .error {
    margin-bottom: 0;
  }
}

:export {
  greyColor: $colorLineGray;
}
