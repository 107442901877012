@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  @include columnGap(16px);

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    min-width: 36px;

    .defaultLogo {
      font-size: 20px;
      color: #c4c4c4;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #c4c4c4;
      border-radius: 50%;
    }
  }

  .name {
    font-size: 18px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }
}
