@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  @include rowGap(4px);
  cursor: pointer;
  user-select: none;

  .sortIcon {
    color: $mhh-blurb-grey-100;
    font-size: 18px;
    margin: -4px 0;
  }

  .sortIconActive {
    @extend .sortIcon;
    color: $mhh-yellow;
  }
}
