@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #414449;
    margin-right: 24px;
  }

  .editLabelWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .editBtn {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $mhh-dark-blue;
      cursor: pointer;
      .icon {
        margin-right: 4px;
        font-size: 16px;
      }
    }
  }

  .activityWrapper {
    margin-top: 32px;
  }
}
