@import 'assets/theme';

.title {
  padding: 20px 32px;
  font-size: 20px;
  font-weight: bold;
  color: #343434;
  border-bottom: 1px solid #ebeff2;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px 15px;

  .noRequests {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 128px;
    font-size: 16px;
    color: #343434;
  }

  .appointmentBookingRequestsContainer {
    @include rowGap($standard-padding * 3);

    .appointmentBookingRequestItem {
      @include rowGap($standard-padding * 3);
    }
  }

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #343434;

    .button {
      display: flex;
      align-items: center;
      margin-left: 8px;
      padding: 0;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $colorBlue1;
      background-color: transparent;

      .icon {
        margin-left: 2px;
      }
    }
  }

  .appointmentBookingRequests {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
}
