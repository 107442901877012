@import 'assets/theme';

.addNoteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  width: calc(100% - 24px);
  margin: 12px 12px 8px;
  padding: 10px 16px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 18px;
  color: $mhh-dark-blue;
  border-radius: 4px;
  box-shadow: 0 4px 10px #34343459;

  .icon {
    margin-right: 12px;
  }
}

.warningMessage {
  display: flex;
  justify-content: center;
  margin: 8px 12px;
  font-style: italic;
  color: #153978;
  font-size: 11px;
}

.list {
  max-height: 70vh;
  border-top: 1px solid #ebeff2;
  overflow: hidden auto;
  border-bottom-left-radius: 4px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $grey-light;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
    background-color: $grey-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $mid-grey-dark;
  }

  .noValue {
    display: flex;
    text-align: center;
    padding: 16px;
    color: $colorTextualGray;
  }
}
