@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: $standard-padding * 3;

  .loading {
    display: flex;
    justify-content: center;
  }

  .contentContainer {
    display: flex;

    .descriptionBox {
      display: flex;
      flex-direction: column;
      border-right: 2px solid #ebeff2;
      padding-right: $standard-padding * 3;
      flex: 1 2 35%;
    }

    .title {
      font-weight: bold;
      font-size: 14px;
      color: $black;
      padding-bottom: 12px;
    }

    .descWrapper {
      display: flex;
      flex-direction: column;
      padding-bottom: $standard-padding * 3;

      .desc {
        font-size: 12px;
        color: $black;
      }
    }
  }
}
