@import 'assets/theme';

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: $mhh-light-black;
}

.desc {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  color: $mhh-dark-blue-50;
}
