@import 'assets/theme';

.columnWrapper {
  display: flex;

  .columnStyles {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #414449;
    flex-grow: 1;
    flex-shrink: 7;
    padding: 24px 0;
  }

  .sortActive {
    font-weight: 600;
    color: $mhh-yellow;
  }

  .dateAdded {
    @extend .columnStyles;
    min-width: calc(0.15 * (100% - 90px));
    max-width: calc(0.15 * (100% - 90px));
    padding: 0 8px;
  }

  .avatar {
    @extend .columnStyles;
    justify-content: center;
    min-width: calc(0.13 * (100% - 90px));
    max-width: calc(0.13 * (100% - 90px));
    padding-right: 8px;
  }

  .fullName {
    @extend .columnStyles;
    min-width: calc(0.35 * (100% - 90px));
    max-width: calc(0.35 * (100% - 90px));
    padding-right: 16px;
  }

  .profileType {
    @extend .columnStyles;
    min-width: calc(0.17 * (100% - 90px));
    max-width: calc(0.17 * (100% - 90px));
    padding-right: 8px;
  }

  .status {
    @extend .columnStyles;
    min-width: calc(0.15 * (100% - 90px));
    max-width: calc(0.15 * (100% - 90px));
    padding-right: 16px;
  }

  .stage {
    @extend .columnStyles;
    min-width: calc(0.15 * (100% - 90px));
    max-width: calc(0.15 * (100% - 90px));
    padding-right: 8px;
  }

  .dropdown {
    @extend .columnStyles;
    min-width: 40px;
    max-width: 40px;
    justify-content: center;
  }

  .action {
    @extend .columnStyles;
    justify-content: center;
    min-width: 50px;
    max-width: 50px;

    &:empty {
      content: '';
      display: inline-flex;

      &:before {
        content: '';
        display: inline-flex;
      }
    }
  }
}

.columnWrapperHeader {
  @extend .columnWrapper;

  .columnStyles {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
