@import 'assets/theme';

.title {
  padding: 24px 32px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #323c47;
  border-bottom: 1px solid #ebeff2;
}

.content {
  padding: 24px 32px;

  .heading {
    margin-bottom: 24px;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;
  }

  .paymentMethod {
    padding: 16px 24px 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .label {
      display: flex;
      align-items: center;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: $colorTextualGray;
    }

    .instructionsLabel {
      margin-top: 24px;
    }

    .paymentType {
      width: 33%;
      margin-top: -10px;
    }

    .disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    .bankName {
      width: 50%;
      margin-top: 40px;
      margin-bottom: 24px;
    }

    .accountName {
      width: 50%;
      margin-bottom: 40px;
    }

    .accountNumberAndCode {
      display: flex;
      justify-content: space-between;
      width: 50%;

      .accountNumber,
      .code {
        width: 40%;
      }
    }

    .email {
      width: 50%;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .paypalMeLink {
      width: 50%;
    }

    .instructions {
      width: 60%;
    }
  }

  .buttonRow {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .addButton {
      flex: none;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 8px;
      }
    }

    .saveButton {
      width: 30%;
    }
  }
}
