.container {
  z-index: 6;
}

.button {
  display: flex;
  align-items: center;
}

.arrowWrapper {
  display: flex;

  .expandIcon {
    font-size: 24px;
    padding-left: 4px;
  }
}

.adminButton {
  display: flex;
  padding: 4px 12px;

  &:focus {
    background-color: inherit;
    color: inherit;
  }
}

.nonAdminButton {
  display: flex;
  padding: 4px 12px;

  &:focus {
    background-color: inherit;
    color: inherit;
  }
}

.tooltips {
  opacity: 1 !important;
}

.disabledButtonWrapper {
  display: inline-block;
}

.disabledButton {
  pointer-events: none;
}

.selectedItemsCount {
  border-radius: 50%;
  color: white;
  display: inline-block;
  margin-left: 0.5rem;
  width: 21px;
  height: 21px;

  &.adminOpen {
    background-color: white;
  }

  &.nonAdminOpen {
    background-color: white;
    color: blue;
  }
}

.nonAdminColor {
  background-color: blue;
}

.buttonLabelItemsColor {
  padding: 4px;
  border-radius: 4px;
  display: inline;
  font-size: 12px;
  line-height: 20px;
  margin-left: 2px;
  margin-right: 2px;

  &:nth-last-child(2) {
    margin-right: 8px;
  }
}

.clearButton {
  padding: 0;
  background: none;
  border-radius: 50%;
  height: 22px;
  width: 28px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  .clearIcon {
    font-size: 18px;
    padding-left: 6px;
  }
}
