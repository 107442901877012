.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0 24px;

    .filterWrapper {
      display: flex;
      align-items: center;

      .filterWrapperTitle {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #6a707e;
      }

      .filterWrapperTags {
        display: flex;
        margin-left: 16px;

        .tagWrapper {
          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }

    .searchBarWrapper {
      display: flex;
      justify-content: flex-end;

      .searchBarCard {
        min-width: 400px;
      }
    }
  }

  .cardContainer {
    padding: 24px;
    background: #fff;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }
}
