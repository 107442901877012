.container {
  width: 100%;
  background: #fff;
  padding: 20px;
  border: 1px solid #ecedef;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .header {
    display: flex;
    align-items: center;
    padding: 8px 0 24px;
    border-bottom: 1px solid #dce2f4;
    min-height: 80px;

    .headerLoading {
      width: 100%;
      height: 26px;
    }
  }
}
