.sectionStyles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #6a707e;
}

.groupNameSection {
  @extend .sectionStyles;
  max-width: 220px;
  padding-right: 20px;
  flex: 1 5;
  padding-left: 10px;
}

.typeSection {
  @extend .sectionStyles;
  max-width: 110px;
  padding-right: 20px;
  flex: 1 5;
}

.createdSection {
  @extend .sectionStyles;
  max-width: 150px;
  padding-right: 20px;
  flex: 1 5;
}

.groupTagSection {
  @extend .sectionStyles;
  max-width: 180px;
  padding-right: 20px;
  flex: 1 5;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.groupMemberSection {
  @extend .sectionStyles;
  max-width: 150px;
  padding-right: 20px;
  flex: 1 5;
}

.groupLeadSection {
  @extend .sectionStyles;
  max-width: 180px;
  padding-right: 20px;
  flex: 1 5;
}

.descriptionSection {
  @extend .sectionStyles;
  max-width: 400px;
  padding-right: 20px;
  flex: 1 5;
}

.buttonSection {
  @extend .sectionStyles;
  align-items: center;
  max-width: 120px;
  flex: 1 5 10%;
}
