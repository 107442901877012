@import 'assets/theme';

.title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 0.1px;
  color: $colorTextualBlack;
  padding-bottom: 16px;
}

.radioContainer {
  padding: 0 8px;

  .customTimeError {
    margin-left: 48px;
    margin-top: 8px;
  }
}
