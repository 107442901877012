@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  @include rowGap(20px);
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #323c47;
  letter-spacing: 0.1px;
}

.referralInformationDetail {
  display: flex;
  @include columnGap(20px);

  .referralTypes,
  .referralDate,
  .referralDetails {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorTextualGray;
  }

  .referralTypes {
    max-width: 70%;
  }

  .types {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 10px;

    .pill {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      min-width: 70px;
      min-height: 30px;
      background: $colorBlue3;
      border-radius: 20px;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #fff;
    }
  }

  .dateHeader {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 4px;
      font-size: 16px;
    }
  }

  .date,
  .detail {
    margin-top: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #323c47;
  }
}

.editButton {
  display: flex;
  align-items: flex-start;
  color: $colorBlue1;
  cursor: pointer;
  padding-left: 8px;

  .editIcon {
    padding-right: 4px;
    padding-top: 3px;
    font-size: 16px;
  }
}

.referralEdit {
  padding-top: 30px;
}
