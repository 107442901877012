@import 'assets/theme';

.attachmentsContainer {
  margin-top: 16px;

  .attachmentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $black;
    justify-content: space-between;
    align-content: center;

    .fileName {
      overflow: hidden;
      display: flex;
      align-items: flex;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 4px;
      cursor: pointer;
      &:hover {
        color: #0085bd;
        text-decoration: underline;
      }
    }
    .icon {
      margin-right: 2px;
      font-size: 18px;
      color: #0085bd;
    }
    span {
      color: #e03e52;
      margin-left: 8px;
      cursor: pointer;
    }
  }

  .addAttachmentsButton {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-left: 12px;
    padding: 0;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $colorTextualBlack;
    font-weight: 600;
    border: none;
    cursor: pointer;

    .icon {
      font-size: 18px;
      margin-right: 4px;
    }
  }

  .uploadingLabel {
    @extend .addAttachmentsButton;
    cursor: auto;
  }

  .addAttachmentInput {
    display: none;
  }
}
