@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: $standard-padding * 2;

  .questionWrapper {
    display: flex;
    flex-direction: column;

    .answerWrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -2px;

      .answerBtn {
        flex: 1;
        background: #cfe8f3;
        border-radius: 20px;
        padding: 4px 16px;
        margin: 8px 2px;
        box-sizing: border-box;
        width: 100%;
        max-width: 140px;
        min-width: 100px;
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        color: #343434;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }

      .answerBtnChecked {
        @extend .answerBtn;
        background: #5666a6;
        color: #fff;
      }

      .answerOtherValue {
        margin-top: 5px;
        font-weight: 400;
        color: $colorBlue3;
      }
    }
  }
}

:global(.cords-assessment-question) {
  .questionWrapper {
    font-weight: 600;
    font-size: 18px;

    .answerWrapper {
      .answerBtn {
        background: $mhh-light-yellow;
        color: $mhh-light-black;
        font-weight: 400;
        padding: 8px 12px;
        border-radius: 32px;
        font-size: 16px;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }

      .answerBtnChecked {
        background: $mhh-yellow !important;
        color: $mhh-light-black !important;
        font-weight: 600 !important;
      }
    }
  }
}
