@import 'assets/theme';

.modalContainer {
  min-width: 540px;
}

.container {
  .contentContainer {
    display: flex;
    padding: 24px 0px;
    color: #414449;
    @include columnGap(14px);

    .icon {
      font-size: 30px;
      color: #ff7d45;
    }

    .messageContainer {
      @include rowGap(8px);

      .title {
        font-size: 18px;
        font-weight: 600;
      }

      .descriptionContainer {
        font-size: 14px;
        font-style: italic;
        @include rowGap(16px);

        .descriptionList {
          padding-left: 20px;
        }
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    @include columnGap(8px);
  }
}
