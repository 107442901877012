@import 'assets/theme';

.sectionLabel {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  color: #414449;

  .icon {
    margin-left: 8px;
    color: #fd8468;
    border-radius: 50%;
    cursor: pointer;
  }

  .tooltip {
    max-width: 200px;
    border-radius: 8px;
    line-height: 16px;
    font-size: 14px;
  }
}

.item {
  display: flex;
  margin: 0 -12px 40px;
  padding: $standard-padding * 2;

  &.automatedInvoicing {
    flex-direction: column;
    position: relative;

    .paymentMethodsNotReady {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 16px;
      background-color: $white;
      z-index: 1;

      .text {
        font-weight: 600;
        font-size: 15px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.1px;
        color: $black;
      }

      .link {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.01em;
        color: $colorBlue1;
        border-radius: 4px;
        border: 1px solid $colorBlue1;
        box-shadow: none;

        .icon {
          padding-right: 6px;
        }

        &:active {
          background-color: #f8f9fd;
        }
      }
    }
  }

  &.active {
    background-color: #f2f9ff;

    .discountContainer {
      display: flex;
    }

    .automatedInvoicingContainer {
      display: block;
    }
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    margin-right: 6%;

    .checkbox {
      appearance: none;
      margin: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      color: #d5d5d5;
      cursor: pointer;
      outline: none;
      transition: all 0.1s linear;

      &:after {
        content: '\2713';
        display: none;
        justify-content: center;
        align-items: center;
        width: 16px;
      }

      &:checked {
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
        color: $white;
        background-color: $colorBlue1;
        border: 2px solid $colorBlue1;

        &:after {
          display: flex;
        }

        + .checkboxLabel {
          color: $colorBlue1;
        }
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #2b4670;
        border: 2px solid #2b4670;
      }
    }

    .checkboxLabel {
      display: flex;
      align-items: center;
      flex: 1;
      padding-left: $standard-padding * 1.5;
      font-size: 16px;
      font-weight: 600;
      color: #414449;
      cursor: pointer;
    }
  }

  .automatedInvoicingContainer {
    display: none;
    padding-top: $standard-padding * 2.5;
    padding-left: $standard-padding * 4;

    .heading {
      font-size: 15px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: $colorTextualBlack;
    }

    .radio {
      width: 50%;
      cursor: pointer;

      .radioLabel {
        font-weight: 600;
        font-size: 16px;
        color: #414449;
      }
    }

    .sendAtConfig {
      display: flex;
      margin-top: 12px;

      &.appointment {
        justify-content: flex-start;
      }

      &.month {
        justify-content: flex-end;
      }

      .sendAtItem {
        display: block;
        width: 50%;
        padding-left: 36px;
        padding-right: 20px;

        .includeMedicareDetails {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: $standard-padding * 2;

          .switchContainer {
            padding: 0;
          }
        }

        .label {
          display: flex;
          align-items: center;
          margin-bottom: -10px;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: $colorTextualGray;

          .icon {
            margin-left: 8px;
            color: #fd8468;
            border-radius: 50%;
            cursor: pointer;
          }

          .tooltip {
            max-width: 200px;
            border-radius: 8px;
            line-height: 16px;
            font-size: 14px;
          }
        }

        .select {
          margin-bottom: 20px;
        }

        .checkboxContainer {
          margin-bottom: 20px;

          .checkboxLabel {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #323c47;
          }
        }

        .flatFeeContainer {
          display: flex;
          margin-top: 28px;
          margin-bottom: 20px;

          .moneySymbol {
            margin-top: 4px;
          }

          .input {
            margin-left: 4px;

            input {
              background-color: transparent;
            }
          }
        }
      }
    }

    .sendToContainer {
      margin-right: 52px;

      .checkboxContainer {
        margin-bottom: 20px;
        width: max-content;
      }

      .inputContainer {
        margin-top: 32px;
        margin-left: 48px;
        margin-right: 16px;

        input {
          background-color: transparent;
        }
      }
    }

    .includeNoteContainer {
      margin-top: $standard-padding * 2.5;
      margin-right: 52px;
      position: relative;

      .textarea {
        width: 100%;
        padding: 8px;
        padding-bottom: 24px;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #343434;
        border: 1px solid #c4c4c4;
        border-radius: 4px;
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
        resize: none;
      }

      .wordCount {
        position: absolute;
        left: 8px;
        bottom: 8px;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #90a0b7;
      }
    }
  }
}
