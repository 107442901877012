@import 'assets/theme';

.loading {
  padding-top: 8px;
}

.container {
  max-height: 120px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $grey-light;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
    background-color: $grey-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $mid-grey-dark;
  }

  .link {
    display: flex;
    align-items: center;
    padding: $standard-padding;

    .icon {
      margin-right: 4px;
      margin-bottom: 1px;
      color: $mhh-yellow;
      font-size: 12px;
    }

    .label {
      display: flex;
      justify-content: center;
      font-size: 12px;
      line-height: 18px;
      color: #61666e;

      .name {
        margin-right: $standard-padding * 0.5;
        font-weight: bold;
        word-wrap: break-word;
      }

      .count {
        background-color: $colorBlue1;
        border-radius: 50%;
        padding: 0 4px;
        min-width: 18px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}
