@import 'assets/theme';

.container {
  color: $colorBlue1;
  text-decoration: underline;
  cursor: pointer;

  .tooltip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    background: #61666e;
    border-radius: 4px;

    .detailsWrapper {
      display: flex;
      flex-direction: column;
      font-size: 12px;

      .value {
        &:not(:last-child) {
          padding-bottom: 4px;
        }
      }
    }
  }
}
