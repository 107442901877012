@import 'assets/theme';

.container {
  padding: 15px;
  letter-spacing: 0.1px;
  background-color: #f5f6ff;
  @include rowGap(8px);

  &:not(:first-child) {
    margin-top: 23px;
  }
}

.header {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 600;
    font-size: 16px;
    font-weight: 600;
    color: #202225;
  }

  .clearAlertBtn {
    display: flex;
    align-items: center;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #eb4545;
    background-color: transparent;
    border: none;
  }

  .clearAlertBtnBusy {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.content {
  display: flex;
  flex-direction: column;
  @include rowGap(6px);

  @media (max-width: $medium-screen-min-width) {
    flex-direction: column;
  }

  .label {
    font-size: 12px;
    color: #949aa2;
  }

  .labelContainer {
    display: flex;
  }

  .detailsContainer {
    display: flex;
    justify-content: space-between;
  }

  .avatarAndName {
    flex: 1;

    .avatarContainer {
      align-items: flex-start;
    }

    .clinicianName {
      font-weight: 600;
      font-size: 13px;
      color: #414449;
      padding-top: 4px;
    }
  }

  .reportNameContainer {
    display: flex;
    flex: 1;

    .reportName {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      color: #414449;
    }
  }

  .commentAndDatetime {
    flex: 1.5;
    display: flex;

    .statusUpdated {
      display: flex;
      align-items: center;
      font-style: italic;
      font-size: 14px;
      color: #414449;
    }
  }

  .actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .button {
      padding: 8px 14px;
    }
  }
}

.commentContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 8px;

  .commentTitle {
    font-weight: 600;
    font-size: 12px;
    color: #949aa2;
  }

  .comment {
    white-space: pre-wrap;
    font-weight: 600;
    font-size: 14px;
    color: #414449;
    border-left: 2px solid #dadbde;
    padding-left: 8px;
  }
}
