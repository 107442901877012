@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #fbfbfb;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  .questionBox {
    display: flex;
    align-items: center;

    .checkbox {
      appearance: none;
      margin: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      color: #d5d5d5;
      cursor: pointer;
      outline: none;
      transition: all 0.3s ease;

      &:checked {
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
        color: #fff;
        background-color: $colorBlue1;
        border: 2px solid $colorBlue1;

        &:after {
          content: '\2713';
          display: flex;
          justify-content: center;
          align-items: center;
          width: 16px;
        }
      }
    }
  }

  .textField {
    margin: 0 16px;
    border: 0;
    border-bottom: 2px solid $colorTextualGray;
    color: $colorTextualBlack;
    outline: none;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    font-size: 16px;
    max-width: 350px;
    background-color: #fbfbfb;

    &::placeholder {
      font-size: 14px;
      color: #a2a6aa;
    }
  }

  .addBtnWrapper {
    display: flex;
    align-items: center;
    color: $colorBlue1;
    cursor: pointer;

    .plusIcon {
      padding-right: 2px;
      font-size: 22px;
    }
  }
}
