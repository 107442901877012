@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #ebeff2;
  box-shadow: 0 6px 18px 0 rgb(0 0 0 / 6%);
  flex-direction: column;

  .coupleLabel {
    width: 100%;
    padding: 12px 18px;

    span {
      color: #fff;
      font-size: 10px;
      font-weight: 400;
      padding: 5px 5px;
      background-color: $colorBlue1;
      border-radius: 3px;
    }
  }

  .notAvailable {
    font-size: 15px;
    font-style: italic;
    opacity: 0.5;
  }

  .clientCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 12px 24px;
    width: 100%;

    &.disabled {
      background: #edf0f4;
      border-radius: 4px;
      cursor: not-allowed;
      user-select: none;

      .profile,
      .name {
        opacity: 0.5;
      }

      .list {
        display: none;
      }
    }

    .detail {
      display: flex;
      align-items: center;
    }

    .profile {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      border-radius: 50%;
    }

    .name {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #323c47;
    }

    .list {
      width: 36%;
      display: flex;
      position: relative;
    }
  }

  .clientCardDisable {
    @extend .clientCard;
    background: #edf0f4;

    .profile,
    .name {
      opacity: 0.5;
    }
  }
}
