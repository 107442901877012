@import 'assets/theme';

.container {
  display: flex;

  .infoWrapper {
    display: flex;
    width: 30%;
    min-width: 320px;
    padding-right: 20px;
    box-sizing: border-box;

    .calendarIconWrapper {
      display: flex;
      align-items: flex-start;
      padding: 4px 16px;

      .calendarIcon {
        color: $colorBlue1;
        border: 2px solid $colorBlue1;
        border-radius: 50%;
        padding: 4px;
        background: #fff;
      }
    }

    .appointmentDetailWrapper {
      display: flex;
      flex-direction: column;

      .sessionName {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #343434;
        padding-bottom: 4px;
      }

      .appointmentDetail {
        font-size: 14px;
        line-height: 20px;
        color: #343434;
      }
    }
  }

  .tagContainer {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    width: 50%;
    padding-right: 20px;

    .markedStatus {
      display: flex;
      align-items: center;
      margin: 4px 8px 0 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $white;
      border-radius: 4px;
      overflow: hidden;
      background: #294670;
      font-size: 12px;
      line-height: 16px;
      padding: 4px 6px 4px 12px;
      text-transform: uppercase;

      .iconContainer {
        display: flex;
        align-items: center;
        margin-left: 4px;
        padding: 4px;
        cursor: pointer;

        &:hover {
          background-color: #dddddd80;
        }

        .icon {
          font-size: 14px;
        }
      }
    }
  }

  .actionContainer {
    display: flex;
    width: 20%;
  }
}
