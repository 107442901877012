@import 'assets/theme';

.container {
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
  border-bottom: 1px solid #ebeff2;
  cursor: pointer;

  &:hover {
    background: #fbfbfb;
  }

  .connectionName {
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    align-items: center;

    color: #414449;
  }

  .connectedSince {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    .date {
      font-size: 12px;
      font-weight: 400;
      color: $colorBlue3;
    }
    .dateDiff {
      font-size: 10px;
      font-weight: 400;
      color: #95a2b8;
    }
  }

  .status {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .action {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    .icon {
      border: 2px solid #6575b1;
      color: #6575b1;
      padding: 6px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
