@use 'sass:math';
@import 'assets/theme';

.loadingContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.practitionerAttendeesContainer {
  @include rowGap($standard-padding);

  .practitioner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .practitionerName {
      display: flex;
      overflow: hidden;
      flex-wrap: wrap;
      font-weight: 600;

      .addButton {
        font-size: 13px;
      }
    }
  }
}
