$container-height: 300px;
$inner-padding: 16px;

.container {
  position: relative;
  height: $container-height;
  overflow-y: scroll;
  font-size: 13px;
  line-height: 18px;
  color: #00000080;
  scroll-snap-type: y mandatory;
  scroll-padding: 0 0 $inner-padding;

  &:before {
    content: '';
    position: sticky;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    background-color: #f8f9fd;
    top: 0;
    left: 0;
    display: block;
    margin-top: -$container-height;
    transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
  }

  .content {
    position: relative;
    z-index: 1;
    padding: $inner-padding;

    .row {
      display: flex;
      align-items: flex-start;

      .text {
        width: 100%;
        transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
      }

      .plainText {
        width: 0%;
        display: none;
        margin-left: $inner-padding;
        padding: 8px;
        transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
        position: sticky;
        top: $inner-padding;
        border: 1px solid #f9c50d;
        border-radius: 16px;
      }
    }
  }

  &.showPlainEnglish {
    &:before {
      width: 50%;
    }

    .content {
      .scrollContainer {
        .text {
          width: 50%;
          padding-right: $inner-padding;
        }

        .plainText {
          width: 50%;
          display: block;
        }
      }
    }
  }
}

.policyContainer {
  @extend .container;
  overflow-y: hidden;

  .content {
    padding: 0;
  }
}
