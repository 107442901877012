@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  flex: 1 2 65%;

  .section {
    margin-bottom: $standard-padding * 3;
  }
}
