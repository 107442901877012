@import 'assets/theme';

.menuWrapper {
  display: flex;
  flex-direction: column;
  padding: 16px 12px;

  &:not(:first-child) {
    border-top: 1px solid #ebeff2;
  }

  .widgetTitle {
    display: flex;
    flex-direction: column;
    color: #414449;
    padding-bottom: 8px;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      .titleIcon {
        color: #b1c5e2;
        padding-right: 20px;
        position: relative;
        font-size: 24px;
      }
    }
  }

  .selectClientLabel {
    padding: 16px 4px 0;
    color: $mid-grey-medium;
  }
}
