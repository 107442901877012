@import 'assets/theme';

.toggleSwitch {
  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:checked {
    ~ .wrapper::before {
      font-weight: 700;
      color: $white;
    }

    ~ .wrapper::after {
      font-weight: 600;
      color: $mhh-dark-blue;
    }

    ~ .wrapper .switch {
      transform: translate3d(6px, 0, 0);
    }
  }

  .switch {
    top: 4px;
    z-index: 1;
    width: calc((100% - 12px) / 2);
    height: 40px;
    border-radius: 25px;
    position: relative;
    display: inline-block;
    background-color: $mhh-dark-blue;
    transform: translate3d(calc(100% + 6px), 0, 0);
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s cubic-bezier(0, 1, 0.5, 1);
  }

  .wrapper {
    height: 50px;
    cursor: pointer;
    position: relative;
    border-radius: 25px;
    display: inline-block;
    background-color: #f3f3f3;

    &::before,
    &::after {
      width: 50%;
      z-index: 2;
      font-size: 13px;
      line-height: 50px;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
    }

    &::before {
      left: 0;
      font-weight: 600;
      color: $mhh-dark-blue;
      content: attr(data-checked);
    }
    &::after {
      right: 0;
      color: $white;
      font-weight: 700;
      content: attr(data-unchecked);
    }
  }
}

.disabled {
  opacity: 0.3;

  .wrapper {
    cursor: progress;
  }

  input[type='checkbox']:checked {
    ~ .wrapper::before {
      font-weight: 700;
      color: grey;
    }

    ~ .wrapper::after {
      font-weight: 600;
      color: grey;
    }

    ~ .wrapper .switch {
      transform: translate3d(6px, 0, 0);
    }
  }
}
