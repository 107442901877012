@import 'assets/theme';

.container {
  display: flex;
  padding: 8px 0 24px;

  .label {
    font-size: 12px;
    color: $colorTextualGray;
    line-height: 16px;
    letter-spacing: 0.2px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .leftContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px;
    max-width: 350px;
    margin-right: 24px;
    background: $white;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    justify-content: space-between;
    min-height: 400px;

    .content {
      display: flex;
      flex-direction: column;
      @include rowGap(24px);
      .title {
        font-weight: 600;
      }
      .nameWrapper {
        padding-top: 16px;

        .fieldContainer {
          display: flex;
          flex-direction: column;
          flex: 1;

          &.fieldError,
          .fieldError {
            font-size: 12px;
            color: #ff4d4f;

            input {
              border-bottom: 1px solid #ff4d4f !important;
            }
          }
        }
      }

      .statusWrapper {
        display: flex;
        flex-direction: column;
        width: 80%;
        padding-top: 16px;
      }

      .assessmentsWrapper {
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        .smallCaretDownClass {
          color: $colorBlue1;
          padding-right: 8px;
          font-size: 24px;
        }
        span {
          font-size: 16px;
        }
      }

      .descriptionWrapper {
        .textarea {
          width: 100%;
          height: 148px;
          padding: 8px;
          border: 1px solid #c4c4c4;
          border-radius: 4px;
          box-shadow: 0px 6px 18px 0px #0000000f;
          resize: none;
        }
      }

      .dueDateWrapper {
        .dateInput {
          width: 100%;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          padding: 8px 12px;
          input {
            font-weight: 600;
          }
        }
      }

      .saveBtn {
        max-width: 200px;
      }
    }

    .deleteWrapper {
      display: flex;

      .deleteBtn {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #ff2500;
        cursor: pointer;
        user-select: none;
      }
    }
  }

  .rightContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 32px;
    background: #fff;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    .interventionListWrapper {
      margin-bottom: $standard-padding * 2;
    }
    .errorMessage {
      font-size: 12px;
      color: #ff4d4f;
      padding-top: 4px;
    }
  }
}
:export {
  greyColor: $colorLineGray;
  blueColor: $mhh-dark-blue;
  whiteColor: $white;
}
