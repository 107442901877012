@import 'assets/theme';

span {
  &.title {
    font-size: 20px;
    font-weight: 800;
    line-height: 27px;
    color: #343434;
    margin-bottom: 16px;
  }

  &.heading {
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #4b718e;
  }

  &.description {
    font-size: 13px;
    color: #34343480;
  }

  &.sectionHeader {
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #343434;
    margin-bottom: 4px;
  }
}

a {
  text-decoration: none;
  font-size: 13px;
  line-height: 18px;
  color: $colorBlue3;
}

.divider {
  height: 0;
  width: 100%;
  border: 1px solid #f3f3f3;
  margin-top: 26px;
  margin-bottom: 20px;
}

.fullWidth {
  width: 100%;
  position: relative;
}
