@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  display: flex;
}

.header {
  .backButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    font-size: 16px;
    background-color: transparent;
    border: none;
    color: $mhh-dark-blue;
    padding: 16px 0;

    i {
      font-size: 14px;
    }
  }
}

.sideBar {
  flex: 1;
  font-size: 16px;

  .title {
    margin-top: 8px;
    font-size: 14px;
  }

  .subTitle {
    margin-top: 24px;
    font-size: 14px;
  }

  .name {
    font-weight: 600;
    font-size: 18px;
  }

  .group {
    display: flex;
  }

  .name,
  .class,
  .group,
  .tags {
    margin-top: 16px;
  }
}

.tag {
  border-radius: 24px;
  font-weight: 700;
  font-size: 12px;
  padding: 8px 12px;
  width: fit-content;
  display: inline-block;
  margin-top: 4px;
  margin-right: 4px;
  color: white;
  background-color: $mhh-dark-blue;
}

.content {
  width: 71%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  margin-bottom: 100px;
  padding: 24px;
}

.noItems {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $colorTextualGray;
  font-weight: 600;
  font-size: 16px;
  padding: 20px;
}

.assessmentTitle {
  font-weight: 600;
  font-size: 20px;
  padding: 0 24px 28px 0;
}

.continueButtonWrapper {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  width: fit-content !important;
  padding: 0 !important;
}

.continueButton {
  border-radius: 32px;
  background-color: $mhh-yellow;
  color: $mhh-light-black;
  font-weight: 600;
  font-size: 18px;
  border: none;
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .contIcon {
    margin-left: 8px;
  }
}

.modalTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.modalButton {
  box-shadow: none;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 18px;
}
