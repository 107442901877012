@import 'assets/theme';

.container {
  display: flex;
  @include columnGap($standard-padding * 2);
}

.recurringDetails {
  display: flex;
  padding-left: 32px;
  @include columnGap($standard-padding * 3);

  .recurringTimesContainer {
    display: flex;
    flex-direction: column;
    @include rowGap($standard-padding * 2);

    flex: 1;

    .estimateLastAppointment {
      font-size: 12px;
      font-weight: 400;
      font-style: italic;
      color: #343434;
    }
  }
}
