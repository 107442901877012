@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .statuses {
    display: flex;
    padding-top: 8px;
    border-bottom: 1px solid #ebeff2;

    .appointmentStatus {
      width: 45%;
      border-right: 1px solid #ebeff2;
    }

    .paymentStatus {
      width: 55%;
    }
  }

  .listing {
    padding: 32px;

    @media (max-width: $large-screen-min-width) {
      padding: 24px;
    }

    @media (max-width: $medium-large_screen-min-width) {
      padding: 16px;
    }
  }
}
