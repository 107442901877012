@import 'assets/theme';

.container {
  padding: 16px;
  border-radius: 6px;
  margin: 20px 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.18);

  .date {
    font-size: 11px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.1px;
    color: #696969;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: $black;
    margin-top: 16px;
  }

  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: $colorBlue3;
    margin-top: 26px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .polarity {
      border-radius: 20px;
      padding: 4px 16px;
      margin: 0 6px 12px 0;
    }

    span {
      margin: 8px;
      color: #343434;
    }
  }
}
