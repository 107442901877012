.container {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 24px;
  cursor: pointer;
  transition: all 0.5s ease;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover {
    background: #fbfbfb;
  }

  .icon {
    border: 2px solid #6575b1;
    color: #6575b1;
    padding: 6px;
    border-radius: 50%;
    cursor: pointer;
  }
}
