@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .label {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    text-align: left;
    color: $colorTextualGray;
    margin-bottom: 8px;
  }

  .bookablePractitioners {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: left;
  }
}
