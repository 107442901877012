@import 'assets/theme';

.noteItem {
  padding: 16px 12px;
  border-bottom: 1px solid #ebeff2;
  cursor: pointer;

  &.selected {
    background-color: $mhh-lightest-yellow;
  }

  .noteTitle {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 20px;
    color: $colorTextualBlack;
    display: flex;
    .icon {
      font-size: 16px;
      margin-left: auto;
    }
  }

  .noteContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    .noteDate {
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #90a0b7;
    }

    .noteAttachmentsCount {
      display: flex;
      align-items: center;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: #90a0b7;

      .icon {
        margin-top: -4px;
        font-size: 12px;
      }
    }

    .safeguardingAlert {
      display: flex;
      align-items: center;
      line-height: 16px;

      .safeguardingAlertIcon {
        margin-top: -4px;
        font-size: 16px;
        color: #ff2500;
      }
    }
  }
}

.disabled {
  cursor: not-allowed;
}
