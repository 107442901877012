@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  @include columnGap(16px);

  .avatarContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    min-width: 36px;

    .avatarImg {
      max-height: 100%;
      max-width: 100%;
    }

    .initials {
      font-size: 18px;
      font-weight: 700;
      color: #343434;
    }

    .defaultLogo {
      font-size: 20px;
      color: #c4c4c4;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #c4c4c4;
      border-radius: 50%;
    }
  }

  .name {
    font-size: 18px;
    font-weight: 600;
    overflow: hidden;
  }

  .ellipsisName {
    @extend .name;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    white-space: nowrap;
  }
}
