.container {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.noQuestionContainer {
  display: flex;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #adb1b4;
}
