@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .contactItem {
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #323c47;
    padding: 8px 0;
  }
}

.deleteButton {
  font-size: 30px;
  cursor: pointer;
  color: #eb4545;
  user-select: none;

  &.disabled {
    color: #f3b7b7;
    cursor: not-allowed;
  }
}
