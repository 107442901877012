@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  position: relative;

  .locationInput {
    border: 0;
    padding: 1px 2px;
    background: none;
    border-radius: unset;
    border-bottom: 1px solid #c4c4c4;
    color: $colorTextualBlack;
    outline: none;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    font-size: 16px;
    z-index: 1;

    &:focus {
      box-shadow: none;
    }

    &:valid {
      border-bottom: 1px solid #c4c4c4;

      + {
        .label {
          color: $colorTextualGray;
          font-size: 11px;
          font-weight: 600;
          left: 2px;
          bottom: 22px;
          pointer-events: none;
        }
      }
    }

    &:focus {
      border-bottom: 1px solid #c4c4c4;

      + {
        .label {
          color: $colorTextualGray;
          font-size: 11px;
          font-weight: 600;
          left: 2px;
          bottom: 22px;
          pointer-events: none;
        }
      }
    }
  }

  .label {
    bottom: 0;
    left: 0;
    color: $colorTextualGray;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 14px;
    cursor: text;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    line-height: 16px;
    letter-spacing: 0.2px;
  }
}
