@use 'sass:math';
@import 'assets/theme';

.loadingContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.clientAttendeesContainer {
  @include rowGap($standard-padding);

  .client {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .clientName {
      display: flex;
      overflow: hidden;
      flex-wrap: wrap;
      font-weight: 600;
    }

    .initialName {
      font-weight: 600;
      font-size: 16px;
    }

    .addButton {
      font-size: 13px;
    }

    .contactButton {
      color: #90a0b7;
      font-size: 13px;
    }
  }
}
