@import 'assets/theme';

.note {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  .noteSection {
    width: 60%;
    .noteHeader {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    .subHeader {
      color: $colorTextualGray;
      b {
        color: $colorTextualBlack;
      }
    }
    .content {
      width: 100%;
      padding: 16px;
      overflow: hidden;
      overflow-wrap: break-word;
      background: #fbfbfb;
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      p,
      ol,
      ul {
        margin-bottom: 0;
      }
    }
  }

  .icon {
    color: $colorBlue1;
    padding: 4px;
    border: 1px solid $colorBlue1;
    border-radius: 50%;
    margin-right: 16px;
    height: fit-content;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.date {
  word-break: break-word;
}
