@import 'assets/theme';

.statusContainer {
  padding: 4px 0;

  .toggle {
    .toggleSwitch {
      display: flex;
      flex-direction: column;
      width: 200px;
    }
  }
}
