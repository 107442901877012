@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 80px;

  .header {
    position: absolute;
    top: -60px;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    height: 44px;

    .caseNotesCount {
      padding: 0 12px;
      font-size: 16px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: #323c47;
    }

    .inputContainer {
      input {
        background-color: transparent;
      }
    }

    .noteFormatButton {
      display: flex;
      align-items: center;
      border-radius: 4px;

      .icon {
        margin-right: 12px;
      }
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 500px;
  }

  .content {
    display: flex;
    min-height: 500px;

    .noteListing {
      width: 20%;
      border-right: 1px solid #c2cfe0;
      position: relative;

      .addNoteButton {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 0;
        width: calc(100% - 24px);
        margin: 12px 12px 8px;
        padding: 10px 16px;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.01em;
        line-height: 18px;
        border-radius: 4px;
        box-shadow: 0 4px 10px #34343459;

        .icon {
          margin-right: 12px;
        }
      }
      .warningMessage {
        display: flex;
        justify-content: center;
        margin: 8px 12px;
        font-style: italic;
        color: #153978;
        font-size: 11px;
      }
    }

    .noteDetailsContainer {
      width: 80%;
      position: relative;

      .noCaseNotes,
      .noNoteFound,
      .noNoteSelected {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .text {
          font-size: 18px;
          font-weight: 600;
          line-height: 25px;
          letter-spacing: 0.1px;
          color: $black;
        }

        .button {
          display: flex;
          align-items: center;
          margin-top: 16px;
          padding: 10px 16px;
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: $mhh-dark-blue;
          background-color: #fff;
          border: 1px solid $mhh-yellow;
          border-radius: 4px;

          .icon {
            margin-right: 12px;
          }
        }
      }
    }
  }
}
