@import 'assets/theme';

:global {
  .intro-note-editor {
    .quill {
      display: flex;
      flex-direction: column-reverse;
    }
    .ql-toolbar {
      border: none;
    }

    .ql-editor {
      min-height: 200px;
      .mention {
        display: inline-flex;
        width: auto;
        height: auto;
        background-color: #c9e3f1;
        margin: 0;
        font-size: 13px;
      }
    }

    .ql-container {
      display: flex;
      flex-direction: column-reverse;
      border: none;
    }

    .ql-mention-list .selected {
      color: #fff;
      background-color: #5666a6;
    }
  }
}

.container {
  background: $white;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  position: relative;
  .wordCount {
    color: #90a0b7;
    position: absolute;
    right: 8px;
    bottom: 8px;
  }
}
.containerToolbar {
  @extend .container;
  background: none;
  border: 0.5px dashed #0085bd;
  border-radius: 4px;
}

.mentionListContainer {
  background-color: #e6e9f2;
  border-radius: 6px;
  z-index: 1;
}

.mentionList {
  padding: 4px 6px;
  font-size: 12px;
  cursor: pointer;
}

:export {
  blackColor: $black;
}
