@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .label {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    text-align: left;
    color: $colorTextualGray;
    margin-bottom: 8px;
  }

  .participation {
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #414449;

    .icon {
      color: $colorBlue1;
      font-size: 22px;
      margin-right: 8px;
    }
  }
}
