@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(24px);

  .infoWrapper {
    display: flex;
    flex-direction: column;

    .pITitleWrapper {
      display: flex;
      align-items: center;
      @include columnGap(12px);
    }

    .subTitle {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $colorTextualGray;
      text-transform: uppercase;
    }

    .details {
      display: flex;
      align-items: center;
      padding-top: 8px;

      .fieldBox {
        display: grid;
        @include rowGap(12px);
        font-size: 13px;

        .fieldValue {
          display: flex;
          align-items: center;

          .widthValue {
            max-width: 180px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .icon {
          font-size: 16px;
          margin-left: 8px;
          cursor: pointer;
        }

        .facUContainer {
          display: flex;
          align-items: center;
          cursor: pointer;

          .qrCodeBtn {
            color: #7b84dc;
            border: 1px solid #7b84dc;
            border-radius: 6px;
          }
        }
      }

      .fieldLabel {
        @extend .fieldBox;
        color: #6575b1;
        min-width: 75px;
      }

      .verticalLine {
        margin: 0 16px;
        height: 100%;
        border-right: 1px solid #c4c4c4;
      }
    }
  }
}
