@import 'assets/theme';

$content-width: 716px;

.container {
  margin-top: $standard-padding * 10;
  padding: $standard-padding;
  @media (min-width: $medium-screen-min-width) {
    padding: $standard-padding * 2 $standard-padding * 6 $standard-padding * 6;
  }
  background: rgba(240, 244, 249, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #343434;
}

.gradientBackground {
  position: absolute;
  top: 0;
  width: 100%;
  height: 400px;
  background: linear-gradient(180deg, rgb(246, 246, 246) 22.92%, rgba(240, 244, 249, 1) 100%);
}

.header {
  z-index: 1;
  display: flex;
  @include columnGap($standard-padding * 2);
  margin-bottom: $standard-padding * 2;
  max-width: $content-width;

  .logoContainer {
    display: none;
    @media (min-width: $medium-screen-min-width) {
      display: flex;
    }
    align-items: center;
    justify-content: center;

    min-width: 200px;
    width: 200px;
    height: 200px;
    border-radius: 50%;

    padding: $standard-padding * 4;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .detailsContainer {
    padding: $standard-padding 0;

    .title {
      font-size: 24px;
      font-weight: 700;
    }

    .subtitle {
      font-size: 20px;
      font-weight: 600;
    }

    .description {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: $standard-padding;
    }

    .broughtToYouBy {
      font-size: 14px;
      font-weight: 600;
      color: $colorBlue1;
    }

    .broughtToYouByLogoContainer {
      height: 25px;

      img {
        height: 100%;
      }
    }
  }
}

.body {
  z-index: 1;
  min-height: 100px;
  width: 100%;
  max-width: 1200px;

  background-color: $white;
  border-radius: 100px 100px 0 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: $standard-padding * 2;

  @media (min-width: $medium-screen-min-width) {
    display: flex;
    justify-content: center;
  }

  .innerBody {
    display: flex;
    flex-direction: column;
    @include rowGap($standard-padding * 2);

    max-width: $content-width;
    @media (min-width: $medium-screen-min-width) {
      padding: $standard-padding * 6 $standard-padding $standard-padding * 12;
    }

    .chapters {
      font-size: 24px;
      font-weight: 700;
      text-align: center;

      @media (min-width: $medium-screen-min-width) {
        text-align: left;
      }
    }

    .chapterDescription {
      font-size: 14px;
      font-weight: 400;
      text-align: center;

      @media (min-width: $medium-screen-min-width) {
        text-align: left;
      }
    }

    .chapterContainer {
      display: flex;

      @include columnGap($standard-padding);
      @media (min-width: $medium-screen-min-width) {
        @include columnGap($standard-padding * 2);
      }

      &.incomplete {
        opacity: 0.5;
      }

      .chapterImageContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 50px;
        width: 50px;
        height: 50px;
        overflow: hidden;

        @media (min-width: $medium-screen-min-width) {
          min-width: 150px;
          width: 150px;
          height: 150px;
        }
        border-radius: 50%;
        background-color: #888888;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .chapterNumber {
        font-size: 18px;
        font-weight: 700;
        min-width: 32px;
        width: 32px;
        text-align: right;
      }

      .chapterDetails {
        width: 100%;

        .chapterTitle {
          @extend .chapterNumber;
          width: unset;
          text-align: left;
        }

        .description {
          font-size: 14px;
          font-weight: 400;
        }

        .mobileCompleteText {
          font-size: 14px;
          color: #4abd03;
          display: block;
          float: right;

          @media (min-width: $medium-screen-min-width) {
            display: none;
          }
        }

        .actionContainer {
          margin-top: $standard-padding * 2;
          display: flex;

          .completeText {
            display: none;

            @media (min-width: $medium-screen-min-width) {
              display: flex;
            }
            align-items: center;

            font-size: 14px;
            color: #4abd03;

            margin-right: $standard-padding * 4;

            .text {
              font-weight: 700;
            }
          }

          .startButton {
            width: unset;
            flex: unset;
          }
        }
      }
    }
  }
}

.footer {
  background-color: $white;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;

  padding-bottom: $standard-padding * 6;

  padding: 0 $standard-padding $standard-padding * 6;

  .innerContainer {
    max-width: $content-width;

    .faqContainer {
      margin-bottom: $standard-padding * 4;
      .title {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        margin: $standard-padding * 2 0;
      }

      .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .faq {
          width: 100%;
          @media (min-width: $medium-screen-min-width) {
            width: 45%;
          }
          margin-bottom: $standard-padding * 2;

          .question {
            font-size: 18px;
            font-weight: 600;
          }

          .answer {
            font-size: 14px;
            font-weight: 400;
            color: $colorBlue3;
          }
        }
      }
    }

    .contactUsContainer {
      display: flex;
      flex-direction: column;
      @media (min-width: $medium-screen-min-width) {
        flex-direction: row;
      }

      justify-content: space-between;
      align-items: center;

      border-top: 1px solid #c4c4c4;
      border-bottom: 1px solid #c4c4c4;

      padding: $standard-padding 0;

      .text {
        font-size: 16px;
        font-weight: 400;
        color: $colorBlue3;
      }

      .contactUsButton {
        flex: unset;
        width: unset;
      }
    }
  }
}

:export {
  blueColor: $colorBlue3;
}
