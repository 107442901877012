@import 'assets/theme';

$spacing: 16px;

.container {
  position: relative;
}

.input:focus,
.input:not(:focus):valid {
  ~ .label {
    top: 0;
    font-size: 11px;
    color: $colorTextualGray;
    opacity: 1;
    font-weight: 600;
  }
}

.input:focus {
  color: $colorTextualBlack;
  border-color: $colorTextualGray;

  ~ .label {
    color: $mhh-dark-blue;
  }
}

.input {
  padding: 0;
  border: none;
  border-bottom: 1px solid $colorLineGray;
  outline: none;
  height: 38px;
  color: #343434;
  font-size: 13px;
  line-height: 18px;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 16px;
  box-sizing: border-box;
  border-radius: 0;

  &.error {
    border-bottom: 1px solid $mhh-red;
    margin-bottom: 0;

    ~ .label {
      color: $mhh-red;
    }
  }

  &.noSpacing {
    margin-bottom: 0;
  }
}

.label {
  position: absolute;
  pointer-events: none;
  font-size: 16px;
  top: 20px;
  color: $colorTextualGray;
  font-weight: 600;
  transition: 0.2s ease all;
}
