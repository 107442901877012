@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 2);

  padding: $standard-padding * 3;
}

.header {
  display: flex;
  align-items: center;

  & > div {
    flex: 1;
  }

  .appointmentTime {
    font-size: 13px;
    font-weight: 600;
  }

  .deliveryType {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    color: $black;
  }
}

.deliveryModeContainer {
  max-width: 300px;
}

.changeNoteContainer {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding);

  .label {
    font-size: 15;
    font-weight: 400;
    color: $colorTextualBlack;
  }

  .textarea {
    width: 100%;
    height: 148px;
    padding: 8px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    box-shadow: 0px 6px 18px 0px #0000000f;
    resize: none;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #f8fdff !important;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 25px 25px;

  padding: $standard-padding * 2;

  .label {
    font-size: 11px;
    font-weight: 500;
    color: $colorTextualGray;
  }

  .data {
    font-size: 14px;
    font-weight: 700;
    color: $colorBlue1;
    text-align: center;
  }

  .detailsContainer {
    display: flex;
    align-items: center;
    column-gap: $standard-padding;
  }

  .button {
    max-width: 280px;
  }
}
