@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  padding: $standard-padding * 2 0;
  justify-content: space-between;

  .leftSection {
    display: flex;
    align-items: flex-end;

    .titleWrapper {
      display: flex;
      flex-direction: column;
      padding-right: 24px;

      .title {
        font-weight: 600;
        font-size: 22px;
        letter-spacing: 0.01em;
        color: $colorTextualBlack;
      }

      .backBtnWrapper {
        display: flex;
        padding-top: 8px;

        .backBtn {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          line-height: 30px;
          color: $mhh-dark-blue;

          .icon {
            font-size: 30px;
            width: 20px;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    .avatarAndNameContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 330px;
      @include columnGap(20px);

      .avatarSection {
        display: flex;
        @include columnGap(10px);

        .avatar {
          max-width: 50px;
        }

        .initials {
          height: 50px;
          width: 50px;
        }
      }

      .nameSection {
        font-style: normal;
        font-size: 30px;
        line-height: 41px;
        letter-spacing: 0.01em;
        color: $colorTextualBlack;

        .profileName {
          font-weight: 700;
        }
      }
    }
  }

  .rightSection {
    display: flex;
    align-items: center;
    @include columnGap(8px);

    .addNewBtnWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      border: 1px solid $mhh-yellow;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 13px;
      text-align: center;
      letter-spacing: 0.01em;
      color: $mhh-dark-blue;
      background: $mhh-yellow;
      box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      min-width: 200px;
      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
      .icon {
        padding-right: 6px;
      }
    }

    .invoiceSettingsButton {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      padding: 12px;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.01em;
      border-radius: 4px;

      &:active {
        background-color: #f0f1f6;
      }

      .icon {
        margin-right: 12px;
      }
    }

    .createInvoiceButton {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: $white;
      background: $colorBlue1;
      border: 1px solid $colorBlue1;
      border-radius: 4px;
      box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
      .icon {
        margin-right: 12px;
      }
    }

    .createActionPlanButton {
      padding: 12px 16px;
      background-color: $mhh-yellow;
      color: $colorTextualBlack;
      box-shadow: none;
      max-height: 40px;
      border-radius: 4px;
      font-size: 13px;
      font-weight: 600;
      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
}
