@import 'assets/theme';

.container {
  @include rowGap(30px);

  .subTitle {
    display: flex;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $colorTextualGray;
  }
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
}

.guardianContainer {
  background: $white;
  box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
  border-radius: 8px;
  margin: 8px -12px 24px;
  padding: 12px 16px 16px;

  .guardianHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;

    .guardianTitle {
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      color: $colorBlue1;
    }

    .removeBtn {
      display: flex;
      font-size: 18px;
      font-weight: bold;
      position: relative;
      right: -6px;
      top: -4px;
      transition: all 0.5s ease;
      cursor: pointer;

      &:hover {
        color: #fd8468;
      }
    }
  }
}

.addGuardianBtn {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: rgba(42, 70, 112, 0.8);
  cursor: pointer;
  user-select: none;
  transition: all 0.5s ease;

  &:hover {
    color: rgba(73, 110, 164, 0.8);
  }

  .icon {
    color: $colorBlue1;
    border: 1px solid $colorBlue1;
    border-radius: 50%;
    padding: 4px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 32px;
}

.clientDetailTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $colorTextualGray;
}
