@import 'assets/theme';

.container {
  flex: 1 0 48%;
  max-width: 48%;
  margin-bottom: 16px;
  border: 2px solid #e6e9f2;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border 0.2s;
  position: relative;
  user-select: none;

  &.selectable {
    cursor: pointer;

    &:hover {
      border: 2px dashed #3f68ff;
    }
  }

  &.selected {
    border: 2px solid #3f68ff;

    &:hover {
      border: 2px solid #3f68ff;
    }

    .tick {
      opacity: 1;
    }
  }

  .tick {
    position: absolute;
    top: -10px;
    right: -10px;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #dff0f8;
    border: 1px solid $colorBlue1;
    transition: opacity 0.1s;

    .icon {
      color: $colorBlue1;
    }
  }

  .card {
    padding: 24px;
    background: #f8f9fd;
    border-radius: 8px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .title {
      padding-right: 12px;
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
    }

    .createdAt {
      font-style: italic;
      font-size: 13px;
      font-weight: 600;
      line-height: 23px;
      text-align: right;
    }
  }

  .body {
    padding: 0;

    > * {
      cursor: pointer;
    }

    ul {
      padding: 0;
    }
  }
}
