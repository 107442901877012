@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .label {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #949aa2;
  }

  .searchBarContainer {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover > .searchInput {
      border: 1px solid $colorBlue3;
    }

    .searchButton {
      position: relative;
      right: 1px;
      height: 40px;
      width: 40px;
      cursor: pointer;

      .iconWrapper {
        display: flex;
        height: 40px;
        justify-content: center;
        align-items: center;
        background-color: $mhh-dark-blue;
        border-radius: 0 6px 6px 0;

        .searchIcon {
          color: #fff;
          font-size: 14px;
          font-weight: lighter;
        }
      }
    }

    .searchInput {
      display: flex;
      flex: 1;
      font-size: 16px;
      line-height: 16px;
      padding: 8px 16px 8px 16px;
      border-radius: 6px;
      border: 1px solid #eee;
      background-color: #fff;
      outline: none;
      transition: all 0.2s ease;
      height: 40px;

      &:hover {
        border: 1px solid $colorBlue3;
      }

      &:focus {
        border: 1px solid $colorBlue3;
      }
    }

    .searchInputWithBtn {
      @extend .searchInput;
      border-radius: 6px 0 0 6px;
    }
  }
}
