@import '../../assets/theme/index';

.container {
  display: flex;
  align-items: center;
  background: $colorBlue3;
  border-radius: 4px;
  font-size: 11px;
  color: #fff;
  padding: 4px 12px;
  margin: 4px 8px 0 0;
}
