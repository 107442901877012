@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #fff;
  border-radius: 8px;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 0 0;
    border: 1px solid $black;
    margin: 0 -1px;
  }

  .loadingWrapper {
    width: 840px;
    height: 900px;
  }
}
