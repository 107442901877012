@import 'assets/theme';

.container {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 24px;
    margin-top: 24px;
  }

  &:last-child {
    padding-bottom: 200px;
  }

  .nameWrapper {
    display: flex;
    width: 15%;

    .nameLabel {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #414449;
    }
  }

  .invoiceId {
    max-height: 60px;
    width: 15%;
    padding: 0 4px;
    font-size: 14px;

    @media (max-width: $large-screen-min-width) {
      font-size: 12px;
      width: 13%;
    }

    .button {
      max-height: 60px;
      max-width: 100%;
      padding: 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      text-align: left;
      text-decoration-line: underline;
      color: $colorBlue3;
      text-overflow: ellipsis;
      word-wrap: break-word;
      background-color: transparent;
      overflow: hidden;

      @media (max-width: $large-screen-min-width) {
        font-size: 12px;
      }
    }
  }

  .issueDate {
    width: 15%;
    padding: 0 4px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #343434;

    @media (max-width: $large-screen-min-width) {
      font-size: 12px;
    }

    @media (max-width: $medium-screen-min-width) {
      font-size: 10px;
    }
  }

  .description {
    width: 20%;
    max-height: 60px;
    padding: 0 4px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    color: #343434;
    overflow: hidden;

    @media (max-width: $large-screen-min-width) {
      width: 22%;
    }

    @media (max-width: $medium-screen-min-width) {
      font-size: 10px;
    }
  }

  .invoiceAmount {
    width: 12%;
    padding: 0 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.1px;
    text-overflow: ellipsis;
    color: #343434;
    overflow: hidden;

    @media (max-width: $large-screen-min-width) {
      font-size: 14px;
    }

    @media (max-width: $medium-screen-min-width) {
      font-size: 12px;
    }
  }

  .statusWrapper {
    width: calc(13% - 8px);
    margin-right: 8px;

    @media (max-width: $large-screen-min-width) {
      width: calc(15% - 8px);
      margin-right: 8px;
    }
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: center;
    color: $white;
    border-radius: 4px;
    width: 90px;

    @media (max-width: $large-screen-min-width) {
      width: 100%;
      font-size: 8px;
    }

    &.draft {
      color: #000000c0;
      border: 1px solid $colorBlue1;
    }

    &.issued {
      background-color: $colorBlue2;
    }

    &.overdue {
      background-color: $main-red;
    }

    &.markedPaid {
      color: #343434;
      background-color: #6fcf97;
    }

    &.confirmPaid {
      background-color: #219653;
    }

    &.closed {
      background-color: #f5ad58;
    }
  }

  .actionButtonContainer {
    display: flex;
    flex-direction: column;
    width: 15%;
    padding: 4px;

    @media (max-width: $large-screen-min-width) {
      width: 15%;
      padding: 0;
    }
  }
}
