@import 'assets/theme';

.row {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 25px 30px;

  &.listTitle {
    padding: 15px 30px;
    background-color: #f8fafc;
    border-top: solid 1px #c4cae1;
    border-bottom: solid 1px #c4cae1;
  }

  .name {
    width: 12%;
    font-weight: 600;
    font-size: 16px;
    color: #343434;
  }

  .description {
    width: 25%;
    color: $mhh-dark-blue;
  }

  .suitableFor {
    width: 20%;

    .pills {
      .pill {
        display: inline-block;
        padding: 8px;
        width: fit-content;
        border-radius: 20px;
        color: white;
        background-color: $mhh-dark-blue;
        margin: 4px 0;
        margin-right: 8px;
        font-weight: 600;
        font-size: 12px;
      }
    }
  }

  .capability {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12%;
    text-align: center;
    padding-right: 20px;

    .checkIcon {
      background-color: #63c364;
      color: #fff;
      border-radius: 50%;
      font-size: 16px;
      font-weight: bold;
      padding: 4px;
      margin-right: 8px;
    }
  }

  .trainedStaff {
    width: 22%;
    font-weight: 700;
    font-size: 16px;
    gap: 18px;
    overflow: hidden;
  }

  .buttonCol {
    width: 6%;
  }

  .title {
    font-weight: 600;
    font-size: 14px;
    color: $mhh-blurb-grey-100;
    display: flex;
    gap: 10px;
    align-items: center;
    overflow: hidden;

    .sortTitleText {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.active {
      color: $colorBlue1;
    }
  }
}
