@import 'assets/theme';

.container {
  .header {
    display: flex;
    background: #f5f6ff;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-around;
    padding: 20px;
    color: $colorBlue3;
    border: 1px #e2e8f0;
    border-style: solid none;

    .headerItem {
      flex: 1;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    padding: 14px 20px;
    border-bottom: 1px solid #ebeff2;
    color: $colorBlue3;
    min-height: 70px;

    &:hover {
      background: #fbfbfb;
    }

    .item {
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 12px;
    }

    .nameItem {
      @extend .item;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
