@import 'assets/theme';

.container {
  border-top: 1px solid #ebeff2;
  padding: 20px;
  display: flex;
  align-items: center;
  @include columnGap(20px);

  .messageContainer {
    .message {
      font-size: 16px;
      font-weight: 600;

      .status {
        font-weight: 700;
      }
    }

    .backButton {
      display: flex;
      color: $colorBlue1;
      align-items: center;
      margin: 12px;
      cursor: pointer;
      @include columnGap(4px);

      .editIcon {
        font-size: 14px;
      }

      .backMessage {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
