@import 'assets/theme';

.monthGroup {
  margin-bottom: 16px;

  .monthGroupTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .monthGroupActivityItems {
    margin-top: 16px;
    margin-left: 24px;
  }

  .activityLinkContainer {
    display: flex;
    width: 100%;
  }

  .activityContainer {
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .activityContainer:nth-child(1) {
    margin-top: 0;
  }

  .activityContainer:nth-last-child(1) {
    margin-bottom: 0;
  }
}
