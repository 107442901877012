@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  text-align: center;

  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: $mhh-dark-blue;
  }

  .desc {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    padding-top: 16px;
    color: $mhh-dark-blue;
  }

  .backListing {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
