@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .content {
    overflow: hidden;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;
    opacity: 0.9;
    transition: all 0.5s ease;
  }

  .showHideController {
    user-select: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $colorBlue1;
    display: flex;
    align-items: center;
  }

  .emptyBody {
    font-size: 13px;
    opacity: 0.5;
    font-style: italic;
  }
}
