@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
}

.roomRequiredContainer {
  display: flex;
  align-items: center;
  padding-top: $standard-padding * 2;
  padding-left: $standard-padding * 3;
  @include columnGap($large-padding);
}

.roomNameSelect {
  min-width: 200px;
}

.roomNotRequiredContainer {
  padding: $standard-padding 0;
}

.roomRequiredLabel {
  font-size: 18px;
  font-weight: 700;
}
