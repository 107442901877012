@import 'assets/theme';

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 380px;

  .noneComms {
    font-size: 11px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #2eb886;
  }
}

.fieldError {
  font-size: 12px;
  color: #ff4d4f;

  input {
    border-bottom: 1px solid #ff4d4f !important;
  }
}

.phoneContainer {
  @extend .fieldContainer;
  margin: -10px 0 24px;
}

.preferenceContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.cPreferenceHidden {
  @extend .fieldContainer;
  margin: 0;
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}

.withCheckBoxContainer {
  display: flex;
  justify-content: space-between;
}

.disableMessageContainer {
  display: flex;
  padding: 0px 8px;

  .lockIcon {
    color: $colorBlue1;
    font-size: 24px;
  }
  .disabledMessage {
    padding-left: 8px;

    .helpIcon {
      position: relative;
      top: 4px;
      color: $main-red;
      font-size: 20px;
    }
  }
}
