.editor {
  margin: 24px 0 50px;
  padding: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  position: relative;

  .textEditorContainer {
    background-color: transparent;
    border: none;
  }
}

.saveButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  .saveButton {
    display: flex;
    box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
    position: fixed;
    bottom: 40px;
    border-radius: 55px !important;
    height: 55px;

    @media only screen and (max-height: 740px) {
      bottom: 11px;
      right: 165px;
    }
  }
}
