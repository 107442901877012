@import 'assets/theme';

.listHeader {
  display: flex;
  width: 100%;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $colorTextualGray;
  @include columnGap(8px);

  .firstName {
    width: 15%;
  }

  .lastName {
    width: 15%;
  }

  .mobileNumber {
    width: 18%;
  }

  .email {
    width: 27%;
    word-break: break-all;
  }

  .contactTags {
    width: 20%;
    display: flex;
    flex-wrap: wrap;
    @include columnGap(10px);

    letter-spacing: 0.02em;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }

  .actions {
    display: flex;
    justify-content: center;
    width: 5%;
  }
}
