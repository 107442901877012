@import 'assets/theme/index';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .closeMenuWrapper {
    display: flex;
    justify-content: flex-end;

    .closeMenu {
      display: none;

      @media only screen and (max-width: $medium-large_screen-min-width) {
        display: flex;
      }

      .backBtn {
        font-size: 24px;
        margin-right: 8px;
        cursor: pointer;

        &:hover {
          color: #fff;
          transition: all 0.5s ease;
        }
      }
    }
  }

  .sideMenuWrapper {
    padding: 8px 0;
    align-items: center;

    .gap {
      height: 80px;
    }
  }

  .btnWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    width: 100%;
    cursor: pointer;

    &:hover {
      color: #fff;
      transition: all 0.5s ease;
    }

    .icon {
      font-size: 20px;
    }

    .text {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      margin-top: 6px;
    }
  }

  .btnWrapperActive {
    @extend .btnWrapper;
    color: $white;

    .text {
      color: $mhh-yellow;
    }
  }
}

.edgeSUTheme {
  color: $edgeSUSideBtn;
}

.defaultTheme {
  color: $defaultPlanSideBtn;
}
