@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #fbfbfb;
  cursor: pointer;
  user-select: none;
  margin-bottom: 4px;
  &.checked {
    background-color: $mhh-lightest-yellow;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  .questionBox {
    display: flex;
    align-items: center;
  }

  .checkbox {
    appearance: none;
    margin: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid #d5d5d5;
    border-radius: 4px;
    color: #d5d5d5;
    cursor: pointer;
    outline: none;

    &:checked {
      font-size: 12px;
      line-height: 16px;
      font-weight: 800;
      color: #fff;
      background-color: $mhh-yellow;
      border: 2px solid $mhh-yellow;

      &:after {
        content: '\2713';
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
      }
    }
  }

  .labelWrapper {
    margin: 0 8px 0 16px;
    display: flex;
    flex: 1;

    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #343434;
    }
  }
}

.actionsContainer {
  display: flex;
}

.multipleChoiceLabel {
  margin-left: $standard-padding * 2;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.optionsContainer {
  display: flex;
  margin-left: $standard-padding * 2;
  flex-flow: wrap;
  .option {
    background-color: $colorBlue3;
    border-radius: 20px;
    padding: $standard-padding $standard-padding * 2;
    color: $white;
    font-size: 12px;
    margin-bottom: 4px;

    &:not(:last-child) {
      margin-right: $standard-padding * 2;
    }
  }
}
