@import 'assets/theme';

.container {
  display: flex;
  @include columnGap(40px);

  .symbolInfo {
    display: flex;
    align-items: center;
    @include columnGap(12px);

    i {
      color: $colorBlue3;
    }

    .value {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.10000000149011612px;
      text-align: left;
      color: #343434;
    }
    span {
      position: relative;
      top: -5px;
    }
  }
}
