@import 'assets/theme';

.selectLabel {
  color: $colorTextualGray;
  font-size: 11px;
  font-weight: 600;
  pointer-events: none;
  margin: 0 2px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:export {
  greyColor: $colorLineGray;
}
