@import 'assets/theme';

.loading:after {
  content: ' ';
  display: block;
  width: 16px;
  height: 16px;
  margin: 1px;
  border-radius: 50%;
  border: 3px solid $mid-grey-medium;
  border-color: $mid-grey-medium transparent $mid-grey-medium transparent;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
