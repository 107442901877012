@import 'assets/theme';

.selectAssessmentWrapper {
  padding: 20px 5px 12px 20px;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .loadingBox {
    display: flex;
    align-items: center;
    min-height: 30px;
    padding: 16px 24px;
    border-bottom: 1px solid #ecedef;
    box-sizing: border-box;

    .loadingName {
      width: 80%;
    }
  }
}
