@import 'assets/theme';

.title {
  font-weight: 600;
  font-size: 18px;
}

.label {
  font-weight: 600;
  font-size: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: $standard-padding * 1.5 $standard-padding * 2.5 $standard-padding * 3 $standard-padding * 2.5;
  @include rowGap($standard-padding * 1.5);

  .searchContainer {
    @include rowGap($standard-padding);

    .searchInputContainer {
      position: relative;
      display: flex;
      height: 32px;

      .searchIcon {
        position: absolute;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        padding: 0 10px;
      }
    }

    .searchBar {
      width: 100%;
      font-size: 14px;
      padding-left: 40px;
      padding-right: 8px;
      font-weight: 400;
      outline: none;
      border-radius: 4px;
      border: 1px solid #bfbfbf;
      &:focus {
        border: 1px $mhh-yellow solid;
      }
    }
  }

  .searchResultsContainer {
    max-height: 330px;
    overflow-y: auto;
    @include rowGap($standard-padding);

    .loadingContainer {
      @include rowGap($standard-padding);

      .loadingCard {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 65px;
        border-radius: 4px;
        border: solid 1px $mhh-dark-blue-10;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        padding: $standard-padding $standard-padding * 3;
      }

      .loadingTitle {
        width: 400px;
        height: 16px;
        border-radius: 4px;
      }
      .loadingDescription {
        width: 200px;
        height: 14px;
        border-radius: 4px;
      }
    }

    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      border: solid 1px $mhh-dark-blue-10;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
      padding: $standard-padding $standard-padding * 3;
      cursor: pointer;

      .checkIcon {
        visibility: hidden;
        font-size: 24px;
        visibility: hidden;
        color: $mhh-yellow;
      }

      &.selected {
        border: solid 1px $mhh-yellow;
        .checkIcon {
          visibility: visible;
        }
      }

      .detail {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .inputSection {
    @include rowGap($standard-padding * 3.5);
    margin-top: $standard-padding * 2;
    max-width: 380px;

    .fieldName {
      font-weight: 600 !important;
      font-size: 12px !important;
      line-height: 20px !important;
      color: $mhh-blurb-grey-100 !important;
    }
  }

  .descriptionContainer {
    @include rowGap($standard-padding * 2.5);

    .description {
      font-weight: 600;
      font-size: 14px;
      line-height: 1.2;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @include columnGap($standard-padding * 2);

    .button {
      color: $colorTextualBlack;
      box-shadow: none;
      max-height: 40px;
    }

    .cancelButton {
      @extend .button;
      background-color: white;
      border: 1px $mhh-yellow solid;
    }

    .nextButton {
      @extend .button;
      background-color: $mhh-yellow;
    }
  }
}
