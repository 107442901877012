@import 'assets/theme';

.container {
  min-width: 720px;
  background: #fff;
  box-shadow: 0 2px 16px #0000001a;
  border-radius: 8px;

  .loading {
    height: 320px;
  }

  .header {
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;

    .headerTitle {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 28px;
      color: $colorTextualBlack;
    }

    .headerDesc {
      font-size: 13px;
      letter-spacing: 0.1px;
      line-height: 20px;
      color: #323c4780;
    }

    .button {
      box-shadow: none !important;
    }
  }

  .integrationContainer {
    padding: 0px 24px 24px 24px;
    @include rowGap(16px);

    .integrationTitle {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
    }

    .integrationList {
      display: flex;
      flex-wrap: wrap;
      @include rowGap(24px);
      @include columnGap(24px);
      padding-bottom: 16px;
    }
  }
}
