@import 'assets/theme';

.loading {
  opacity: 0.45;
  pointer-events: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;

  .title {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: $colorTextualBlack;
  }

  .link {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: $white;
    background: $colorBlue1;
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);

    .icon {
      margin-right: 12px;
    }
  }
}

.content {
  display: flex;
  padding: 16px 0;

  .leftCol {
    height: max-content;
    width: 30%;
    margin-right: 24px;
    background: $white;
    border-radius: 4px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);

    @media (max-width: $large-screen-min-width) {
      width: 25%;
    }

    @media (max-width: $medium-large_screen-min-width) {
      margin-right: 16px;
    }
  }

  .rightCol {
    height: max-content;
    width: calc(70% - 24px);
    background: $white;
    border-radius: 4px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);

    @media (max-width: $large-screen-min-width) {
      width: calc(75% - 24px);
    }

    @media (max-width: $medium-large_screen-min-width) {
      width: calc(75% - 16px);
    }

    .statuses {
      display: flex;
      padding-top: 8px;
      border-bottom: 1px solid #ebeff2;

      .appointmentStatus {
        width: 45%;
        border-right: 1px solid #ebeff2;
      }

      .paymentStatus {
        width: 55%;
      }
    }

    .listing {
      padding: 32px;

      @media (max-width: $large-screen-min-width) {
        padding: 24px;
      }

      @media (max-width: $medium-large_screen-min-width) {
        padding: 16px;
      }
    }
  }
}
