@import 'assets/theme';

.container {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin-top: 16px;
  margin-right: 4px;
  padding-top: 40px;
  border-top: 1px dashed $colorBlue3;

  .totalLabel {
    width: 25%;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #5666a6;
  }

  .costTotal,
  .taxTotal {
    width: 25%;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;
  }

  .finalTotal {
    width: 25%;
    font-size: 24px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;
    position: relative;

    .label {
      position: absolute;
      top: -16px;
      left: 0;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      color: #343434;
    }
  }
}
