@import '../../assets/theme/index';

.container {
  display: grid;
  grid-template-columns: 150px calc(100% - 150px);
  grid-template-rows: 70px 1fr;
  grid-template-areas:
    'header header'
    'sidenav main';
  height: 100vh;

  @media only screen and (max-width: $medium-large_screen-min-width) {
    position: relative;
    grid-template-columns: 100%;
    grid-template-rows: 70px 1fr;
    grid-template-areas:
      'header'
      'main';
    overflow-x: hidden;
  }

  .sideDashboardWrapper {
    position: fixed;
    grid-area: sidenav;
    flex: 1;
    overflow-y: auto;
    width: 150px;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    z-index: 9;

    @media only screen and (max-width: $medium-large_screen-min-width) {
      transform: translateX(-150px);
    }
  }

  .sideDashboardActive {
    @extend .sideDashboardWrapper;
    @media only screen and (max-width: $medium-large_screen-min-width) {
      transform: translateX(0px);
    }
  }

  .edgeSUBackground {
    background: $edgeSUViolet;
  }

  .defaultBackground {
    background: $defaultPlanBlue;
  }

  .contentWrapper {
    grid-area: main;
    background: $mhh-lightest-yellow-20;
  }

  .teacherContentWrapper {
    @extend .contentWrapper;
    background-color: $white;
  }
}

.mentorContainer {
  @extend .container;
  grid-template-areas:
    'header'
    'main';
  grid-template-columns: 100%;
}
