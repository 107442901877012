@import 'assets/theme';

$height: 30px;
$thumb-height: 16px;
$track-height: 6px;

$upper-color: #cfcfcf;

$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat
  transparent;
$lower-background: linear-gradient(to bottom, $colorBlue1, $colorBlue1) 100% 50% / 100% $track-height no-repeat
  transparent;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.title {
  width: 100%;
  margin-bottom: 16px;
  padding: 16px 32px;
  font-size: 24px;
  font-weight: bold;
  color: $black;
  border-bottom: 1px solid $mid-grey-light;
}

.body {
  padding: 16px 32px;

  @media only screen and (max-width: $medium-small-screen-min-width) {
    display: flex;
    flex-direction: column;

    :first-child {
      align-self: center;
      max-width: 100% !important;
      height: auto !important;
    }
  }
}

.upload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 400px;
}

.uploadBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 400px;
  padding: 16px;
  overflow: hidden;

  .image {
    width: 100%;
  }
}

.scaleSliderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;

  .scale {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    color: $black;
  }

  .focusRangeBar {
    display: flex;
    appearance: none;
    width: 100%;
    margin: 4px 10px 0;
    height: $height;
    overflow: hidden;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  // Webkit
  .focusRangeBar::-webkit-slider-runnable-track {
    width: 100%;
    height: $height;
    background: $lower-background;
  }

  .focusRangeBar::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: $thumb-height;
    width: $thumb-height;
    background: $colorBlue1;
    border-radius: 100%;
    border: 0;
    top: 50%;
    margin-top: -8px;
    box-shadow: webkit-slider-thumb-shadow();
    transition: background-color 150ms;
  }

  // Firefox
  .focusRangeBar::-moz-range-track,
  .focusRangeBar::-moz-range-progress {
    width: 100%;
    height: $height;
    background: $upper-background;
  }

  .focusRangeBar::-moz-range-progress {
    background: $lower-background;
  }

  .focusRangeBar::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: $thumb-height;
    width: $thumb-height;
    background: $colorBlue1;
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  // Internet Exploder
  .focusRangeBar::-ms-track {
    width: 100%;
    height: $height;
    border: 0;
    // color needed to hide track marks
    color: transparent;
    background: transparent;
  }

  .focusRangeBar::-ms-fill-lower {
    background: $lower-background;
  }

  .focusRangeBar::-ms-fill-upper {
    background: $upper-background;
  }

  .focusRangeBar::-ms-thumb {
    appearance: none;
    height: $thumb-height;
    width: $thumb-height;
    background: $colorBlue1;
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    // IE Edge thinks it can support -webkit prefixes
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  .focusRangeBar:hover,
  .focusRangeBar:focus {
    &::-webkit-slider-thumb {
      background-color: $colorBlue1;
    }
    &::-moz-range-thumb {
      background-color: $colorBlue1;
    }
    &::-ms-thumb {
      background-color: $colorBlue1;
    }
  }
}

.rotationButtonsContainer {
  display: flex;
  border: 1px solid $black;
  border-radius: 4px;
  overflow: hidden;

  .rotationButton {
    flex: 1;
    font-weight: normal;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: $black;
    background-color: transparent;
    border-radius: 0;

    &:first-of-type {
      border-right: 1px solid $black;
    }

    &:last-of-type {
      border-left: 1px solid $black;
    }
  }
}

.orDivider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  position: relative;

  .orText {
    padding: 4px;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.1px;
    background-color: $white;
    z-index: 1;
  }

  &:before {
    content: '';
    width: 100%;
    border: 1px solid #{$black}30;
    position: absolute;
  }
}

.submitButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .submitButton {
    min-width: 240px;
    padding: 16px;
    font-weight: normal;
    border-radius: 20px;
  }

  .saveButton {
    margin-bottom: 8px;
    color: $white;
    background-color: $colorBlue1;
    border: 1px inset $colorBlue1;

    &:active {
      background-color: #2f42ef;
    }
  }

  .uploadButton {
    color: $colorBlue1;
    background-color: $white;
    border: 1px inset $colorBlue1;

    &:active {
      background-color: $grey-light;
    }
  }

  .cancelButton {
    margin-top: 8px;
    color: #fd8468;
    background-color: $white;
    border: 1px inset #fd8468;

    &:active {
      background-color: $grey-light;
    }
  }
}
