@import 'assets/theme';

.container {
  display: flex;
  background: $white;
  box-shadow: 0 2px 16px rgb(0, 0, 0, 10%);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 32px;
  min-width: 400px;

  .infoWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .contactDetailsWrapper {
      display: flex;
      flex-wrap: wrap;
      @include columnGap(20%);
      @include rowGap(40px);
    }
  }

  .subTitle {
    display: flex;
    padding-bottom: 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $colorTextualGray;

    .editBtn {
      display: flex;
      align-items: center;
      color: $mhh-dark-blue;
      cursor: pointer;
      padding-left: 16px;

      .editIcon {
        padding-right: 4px;
        font-size: 16px;
      }
    }
  }

  .detailsContainer {
    display: flex;
    @include columnGap(12px);

    .card {
      width: 50%;
      padding: 12px;
      background: #ffffff;
      box-shadow: 0px 2px 16px 0px #0000001a;
      border-radius: 8px;
    }
  }
}
