@import 'assets/theme';

.list {
  .title {
    font-weight: 600;
    font-size: 14px;
  }

  .item {
    display: flex;
    margin: 10px 0;
    align-items: flex-start;

    .radio {
      margin-top: 5px;
      margin-right: 6px;
      cursor: pointer;

      input {
        &:checked:after {
          width: 12px;
          height: 12px;
          border-radius: 12px;
          top: -3px;
          left: 0;
          position: relative;
          background-color: $mhh-yellow;
          outline: solid 1px $mhh-yellow;
          box-shadow: 0 0 0 2px white;
          content: '';
          display: inline-block;
          visibility: visible;
          border: 2px solid white;
        }
      }
    }

    .optionDetails {
      flex: 1;
      font-weight: 600;
      font-size: 14px;

      .title {
        color: #414449;
        cursor: pointer;
      }

      .description {
        margin-top: 4px;
        font-weight: 400;
        line-height: 16px;
        height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.2s;
        cursor: pointer;
      }

      .arrowIcon {
        margin-right: 4px;
        color: $mhh-dark-blue;
        cursor: pointer;
      }

      .readMore {
        color: $mhh-dark-blue;
        margin-top: 8px;
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      &.readAllClicked {
        .description {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          height: unset;
          margin-bottom: 8px;
        }
      }
    }

    &.active {
      .optionDetails {
        .title {
          color: $mhh-yellow;
        }
      }
    }
  }

  .nextButton {
    display: flex;
    justify-content: flex-end;
  }
}
