@import 'assets/theme';

.gpCardContainer {
  width: 512px;
  background: $white;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 15px 25px;

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: $colorTextualGray;
    }
    .editButton {
      display: flex;
      align-items: center;
      color: $colorBlue1;
      cursor: pointer;
      padding-left: 8px;

      .editIcon {
        padding-right: 4px;
        font-size: 16px;
      }
    }
  }

  .noGPDetail {
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .doctorAvatar {
      width: 88px;
      height: 88px;
    }

    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      font-size: 15px;
      line-height: 20px;
      color: $black;
      @include rowGap(20px);
    }

    .addButton {
      color: $colorBlue1;
      margin-top: 20px;
      user-select: none;
      cursor: pointer;
    }
  }

  .cardContent {
    display: flex;

    .leftContent {
      width: 40%;
      display: flex;
      padding-right: 30px;
      padding-bottom: 2px;
      font-size: 13px;
      line-height: 28px;
      letter-spacing: 0.1px;
      color: #6575b1;

      &.medicareProviderNumber {
        align-items: center;
      }
    }

    .divider {
      border-left: solid 1px #c4c4c4;
    }

    .rightContent {
      width: 100%;
      height: 100%;
      padding-bottom: 2px;
      padding-left: 30px;

      &.medicareProviderNumber {
        align-self: center;
      }

      &.gpName {
        font-size: 15px;
        font-weight: 600;
      }

      &.mobileNumber,
      &.email,
      &.fax {
        display: flex;

        .copyBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 6px;
          font-size: 24px;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            color: #2f42ef;
          }

          .icon {
            font-size: 18px;
          }
        }
      }
    }
  }
}
