@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 800px;
  margin-top: -5%;

  .content {
    display: flex;
    flex-direction: column;
    color: #414449;

    .searchContainer {
      display: flex;
      justify-content: flex-end;

      .search {
        max-width: 370px;
      }
    }

    @media (max-width: $medium-small-screen-min-width + 100) {
      flex-direction: column;
    }
  }

  .actionWrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
  }
}
