@import 'assets/theme';

.columnWrapper {
  display: flex;

  .columnStyles {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #414449;
    flex-grow: 1;
    flex-shrink: 7;
  }

  .sortActive {
    font-weight: 600;
    color: $mhh-yellow;
  }

  .checkBox {
    @extend .columnStyles;
    justify-content: center;
    min-width: 50px;
    max-width: 12%;
  }

  .avatar {
    @extend .columnStyles;
    justify-content: center;
    min-width: 120px;
    max-width: 120px;
    padding-right: 8px;
  }

  .fullName {
    @extend .columnStyles;
    min-width: 120px;
    width: 25%;
    max-width: 180px;
    padding-right: 12px;
  }

  .profileType {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 18%;
    padding-right: 8px;
  }

  .mobile {
    @extend .columnStyles;
    min-width: 120px;
    max-width: 18%;
    padding-right: 8px;
  }

  .email {
    @extend .columnStyles;
    min-width: 120px;
    width: 19%;
    max-width: 200px;
  }
}
