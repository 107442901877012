@import 'assets/theme';

$spacing: 20px;

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .titleHeading {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: $mhh-yellow;
    padding-bottom: 12px;
  }

  .progressIndicatorContainer {
    display: flex;

    .progressIndicator {
      height: 8px;
      width: 8px;
      background-color: #{$colorBlue3}80;
      border-radius: 16px;
      transition: all 0.3s ease-in-out;

      &:not(:last-child) {
        margin-right: 4px;
      }

      &.complete {
        width: 24px;
        background-color: #{$mhh-yellow}80;
      }

      &.current {
        width: 24px;
        background-color: $mhh-yellow;
      }
    }
  }
}

.suggestion {
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  transition: 0.2s ease all;

  .checkIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: 2px solid transparent;
    color: #7f90a9;
    padding: 2px;
    border-radius: 50%;
    margin-right: 8px;
    font-size: 14px;
    font-weight: bold;
    transition: 0.2s ease all;
  }

  .label {
    line-height: 24px;
  }

  .passwordStrengthWrapper {
    display: flex;

    .passwordStrengthBox {
      padding: 4px 0 0 16px;
    }
  }

  .strong {
    font-weight: bold;
  }
}

.suggestionSuccess {
  @extend .suggestion;
  color: $mhh-green;

  .checkIcon {
    color: $mhh-green;
    border: 2px solid $mhh-green;
    background-color: #{$mhh-green}50;
  }
}

.sendCodeButtonWrapper {
  display: flex;
  justify-content: center;

  .sendCodeBtn {
    max-width: 284px;
    margin: 32px 0;
    display: flex;
    flex: 1;
    box-shadow: none;
    font-weight: bold;
    font-size: 14px;
    border-radius: 8px;
  }
}

.collapse {
  overflow: hidden;

  .passwordSection {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    transition: margin-top 0.7s ease-in-out;

    .heading {
      margin-top: -24px;
      margin-bottom: 32px;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      color: $mhh-dark-blue;
    }

    .password {
      position: relative;
      margin-top: 20px;
      width: 100%;
    }

    .passwordContainer {
      display: flex;
    }

    .passwordStrengthIndicatorContainer {
      align-self: flex-end;
      display: flex;

      &.valid .passwordStrengthIndicator {
        background-color: $mhh-green !important;
      }

      .passwordStrengthIndicator {
        height: 16px;
        width: 16px;
        border-radius: 100%;
        background-color: #e1e1e1;

        &:not(:last-child) {
          margin-right: 4px;
        }

        &.active {
          background-color: $mhh-yellow;
        }
      }
    }

    .passwordStrengthBar {
      div {
        display: none;
      }
    }

    .passwordDesc {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: $mhh-dark-blue-50;

      .highlight {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;

        color: $colorBlue3;
      }
    }

    .validatePassword {
      top: 43px;
      position: relative;
      width: 34%;
      height: 48px;
      border: 1px solid $mhh-green;
      border-radius: 16px;
      margin-top: 8px;
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: $mhh-green;
      box-sizing: border-box;
    }

    &.validated {
      margin-top: -100%;
    }
  }

  .mobileNumberSection {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    transition: margin-top 0.7s ease-in-out;

    .title {
      display: flex;
      justify-content: space-between;

      .whatsThis {
        display: flex;
        cursor: pointer;

        .icon {
          margin-left: 4px;
          color: #fd8468;
        }
      }
    }

    .tooltip {
      max-width: 200px;
      border-radius: 8px;
      line-height: 16px;
      font-size: 14px;
    }

    .mobileNumberContainer {
      display: flex;
      flex-direction: column;
    }

    .mobileNumber {
      margin: 24px 0 16px;

      .label {
        font-weight: 500;
        font-size: 11px;
        letter-spacing: 0.1px;
        color: $mhh-dark-blue;
      }
    }

    .mobileNumberInput {
      width: calc(100% - 11px) !important;
      height: 52px !important;
      padding-left: 52px !important;
      background-color: $white !important;
      border: 1px solid $white !important;
      border-bottom-color: $colorLineGray !important;
      border-radius: 8px !important;
      outline: none !important;
      color: #343434 !important;
    }

    .countrySelect {
      background-color: $white !important;
      border: 1px solid $white !important;
      border-bottom-color: $colorLineGray !important;
      border-right: none !important;
      border-radius: 8px 0 0 8px !important;

      div,
      div:hover {
        background-color: $white !important;
      }
    }

    .countryDropdown {
      max-height: 100px;

      li {
        margin-top: 0 !important;
      }
    }

    .validateMobileNumber {
      width: 34%;
      height: 52px;
      border: 1px solid $mhh-green;
      border-radius: 16px;
      margin-top: 8px;
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: $mhh-green;
      box-sizing: border-box;

      .innerContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        .successIcon {
          font-size: 20px;
          margin-right: 4px;
        }

        .loadingIcon {
          font-size: 20px;
          margin-right: 4px;
          animation: loading 1s linear infinite;
        }
      }

      &.loading {
        background-color: #ebebe4;
        color: #34343480;
        border-color: #ebebe4;
        cursor: default;
      }

      &.success {
        background-color: #00bf9c;
        color: $white;
        border-color: #00bf9c;
        cursor: default;
      }
    }

    &.codeRequested {
      margin-top: -100%;
    }
  }

  .twoFASection {
    display: flex;
    flex-direction: column;
    margin-top: -100%;
    transition: margin-top 0.7s ease-in-out;

    .noCode {
      font-size: 13px;
      line-height: 18px;
      color: #343434;
    }

    .tryAgain {
      font-size: 13px;
      line-height: 18px;
      color: $colorBlue3;
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
    }

    &.codeRequested {
      margin-top: 32px;
    }
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
