@import 'assets/theme';

.modalContainer {
  min-width: 800px;

  .title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #323c47;
    margin-bottom: 4px;
  }

  .formContainer {
    padding: 16px 0;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding-top: 32px;
  }
}
