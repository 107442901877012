@import 'assets/theme';

.headingWrapper {
  display: flex;
  flex-direction: column;

  .headingBtn {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: $mhh-dark-blue;
    cursor: pointer;

    .headingIcon {
      font-size: 16px;
    }
  }

  .menuWrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    top: 4px;

    .menuBoxHide {
      position: absolute;
      background: #fff;
      border: 1px solid #eee;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      transform: translateY(0px);
      transition: all 0.3s;
      min-width: 210px;
      max-width: 210px;

      .listWrapper {
        display: flex;
        justify-content: space-between;
        color: #707683;
        font-size: 13px;

        &:not(:last-child) {
          border-bottom: 1px solid #e7e4e4;
        }

        .list {
          display: flex;
          flex: 1;
          align-items: center;
          padding: 12px;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            color: $mhh-dark-blue;
          }
        }

        .removeBtnWrapper {
          display: flex;
          align-items: center;
          height: auto;
          padding: 6px;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            background: #eee;
          }

          .icon {
            color: #fc6868;
            font-size: 16px;
          }
        }
      }

      .createListBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 6px 12px 12px;
        cursor: pointer;
        color: #707683;
        font-size: 13px;
        max-width: 250px;
        font-weight: bold;
      }
    }

    .menuBoxShow {
      @extend .menuBoxHide;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
