@import 'assets/theme';

.container {
  display: flex;
  padding: 12px;
  flex-direction: column;
  background: #f8f9fd;
  border: 1px solid #e6e9f2;
  border-radius: 8px;
  cursor: pointer;
  @include rowGap(10px);

  .titleContainer {
    display: flex;
    @include columnGap(10px);

    .authorContainer {
      display: flex;
      align-items: center;
      @include columnGap(10px);

      .avatarContainer {
        width: 32px;
        height: 32px;

        .avatar {
          max-height: 100%;
          border-radius: 50%;
        }
      }
      .authorName {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .createdDate {
      font-weight: 600;
      font-size: 10px;
      color: #949aa2;
      align-self: center;
    }
  }

  .message {
    font-size: 14px;
    font-weight: 600;
    color: $colorTextualBlack;
  }

  .replies {
    font-size: 12px;
    color: $colorBlue1;
  }
}
