@import 'assets/theme';

.container {
  padding: 16px;
  border: 1px solid #dadbde;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: all 0.2s ease;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .titleWrapper {
      display: flex;
      align-items: center;

      .sequenceWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;

        .sequenceBadge {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          background: $mhh-dark-blue;
          border-radius: 15px;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: #fff;
        }
      }

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #4c5862;
      }
    }
  }

  .desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #414449;
    margin-top: 8px;
  }

  .memberInStageWrapper {
    margin-top: 16px;

    .label {
      font-size: 12px;
      line-height: 18px;
      color: #949aa2;
    }

    .totalMember {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
