.container {
  padding: 4px 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.draft {
  background: #cbb600;
}

.published {
  background: #29794a;
}

.shared {
  background: #426eb1;
}

.inReview {
  background: #f38f8f;
}

.changesRequested {
  background: #a51212;
}

.feedback {
  background: #ff9d73;
}

.approved {
  background: #66cb91;
}

.unpublished {
  background: #5ab6e2;
}

.collaborate {
  background: #8c97ff;
}

.reviewersEdits {
  background: #949aa2;
}
