@import 'assets/theme';

.container {
  display: block;
  width: 50%;
  padding-left: 36px;
  padding-right: 20px;

  .includeMedicareDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $standard-padding * 2;

    .switchContainer {
      padding: 0;
    }
  }

  .tooltips {
    max-width: 280px;
  }

  .label {
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorTextualGray;
  }

  .select {
    margin-bottom: 20px;
  }
}
