@import '../../assets/theme/index';

.container {
  display: grid;
  grid-template-rows: 60px 1fr 50px;
  grid-template-areas:
    'header'
    'main'
    'footer';
  height: 100vh;
  flex-direction: column;
}
