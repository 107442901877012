@use 'sass:math';
@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(16px);

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $colorBlue1;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  .arrowIcon {
    font-size: 12px;
    margin-left: 8px;
  }

  .clientContainer {
    display: flex;
    align-items: flex-start;
    @include columnGap($standard-padding);

    .clientAvatarContainer {
      .initialName {
        font-size: 16px;
      }
    }

    .clientDetailsWrapper {
      display: flex;
      flex-direction: column;
      row-gap: $standard-padding;

      width: 100%;
      padding: math.div($standard-padding, 2) $standard-padding $standard-padding;
      background-color: #fbfdff;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      & > div {
        display: flex;
        align-items: center;
        column-gap: $standard-padding * 4;
      }

      .clientName {
        font-size: 16px;
        font-weight: 600;
        color: #343434;
      }
    }

    .clientDetailsContainer {
      display: flex;
      @include columnGap($standard-padding * 2);

      & > div {
        flex: 1;
      }

      .contactDetailsContainer {
        display: grid;
        grid-template-columns: 80px 1fr;
        @include columnGap($standard-padding * 2);

        .label {
          font-size: 13px;
          color: #6575b1;

          border-right: 1px solid #c4c4c4;
        }

        .data {
          display: flex;
          @include columnGap($standard-padding);
          align-items: center;

          .copyButton {
            color: #343434;
            span {
              font-size: 15px;
            }
          }
        }
      }

      .clientHistoryContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include rowGap($standard-padding);
        @include columnGap($standard-padding * 2);

        .label {
          font-size: 13px;
          font-weight: 600;
          color: #6a707e;
        }

        .appointmentLabel {
          @extend .label;
          justify-self: center;
        }

        .clientSinceContainer {
          display: flex;
          flex-direction: column;
          @include rowGap(4px);

          .clientSince {
            font-size: 14px;
            font-weight: 600;
            color: #6575b1;
          }

          .clientSinceDays {
            font-size: 11px;
            font-weight: 600;
          }
        }

        .appointmentsContainer {
          display: flex;

          .appointmentsCounterContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;

            .appointmentCompleteCount {
              font-size: 24px;
              font-weight: 700;
              color: $colorBlue1;
            }
            .appointmentScheduledCount {
              @extend .appointmentCompleteCount;
              color: $colorBlue3;
            }

            .appointmentStatus {
              font-size: 11px;
              font-weight: 600;
              color: #6575b1;
            }
          }
        }
      }
    }
  }
}
