.filterContainer {
  display: flex;
  padding: 0 16px;
  margin-bottom: 16px;
  cursor: auto;
}

.paginationWrapper {
  padding-top: 16px;
}
