@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 4px 4px #0000002e;
  position: relative;
  min-height: 66px;

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #{$white}80;
    z-index: 1;
  }

  .noImage {
    height: 50px;
    width: 50px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: #808080;
  }

  .noName {
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #323c47;
  }

  .name {
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #323c47;
  }

  .selectedGroupContainer {
    display: flex;
    flex-direction: column;
    color: #414449;

    .groupName {
      display: flex;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .groupCount {
      display: flex;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .button {
    right: 12px;
    padding: 0;
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: $colorBlue1;
    background-color: transparent;
    position: absolute;
  }
}
