@import 'assets/theme';

.container {
  width: 244px;
  height: 220px;
  background: #fff;
  box-shadow: 0 2px 10px #0000001a;
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;

  .iconContainer {
    display: flex;
    justify-content: space-between;
    position: relative;

    .iconWrapper {
      display: flex;

      .icon {
        height: 100px;
        width: 100%;
        padding: 8px 0;
      }
    }

    .statusContainer {
      position: absolute;
      right: 0px;
    }
  }

  .detail {
    @include rowGap(8px);
  }
  .name {
    font-size: 16px;
  }

  .desc {
    font-size: 12px;
    color: $mid-grey-dark;
  }
}

.loading {
  opacity: 0.6;
}
