@import 'assets/theme';

.loadingContainer {
  padding: 40px 24px;
}

.container {
  display: flex;
  flex-direction: column;

  .nextAssessmentRow {
    display: flex;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 16px;

    .label {
      font-weight: 400;
      width: 54px;
      display: inline-flex;
      justify-content: space-between;
      margin-right: 16px;
    }
  }
}

.tags {
  margin-top: 16px;

  .tag {
    border-radius: 24px;
    font-weight: 700;
    font-size: 12px;
    padding: 8px 12px;
    width: fit-content;
    display: inline-block;
    margin-top: 4px;
    margin-right: 4px;
    color: white;
    background-color: $mhh-dark-blue;
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
