@import 'assets/theme';

.container {
  &:not(:first-child) {
    margin-top: 16px;
  }

  .checkboxContainer {
    padding: 16px 0;
    display: flex;
    cursor: pointer;

    .checkbox {
      appearance: none;
      margin: 0;
      height: 20px;
      width: 20px;
      min-width: 20px;
      background-color: transparent;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      color: #d5d5d5;
      cursor: pointer;
      outline: none;
      transition: all 0.1s linear;

      &:after {
        content: '\2713';
        display: none;
        justify-content: center;
        align-items: center;
        width: 16px;
      }

      &:checked {
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
        color: #fff;
        background-color: $colorBlue1;
        border: 2px solid $colorBlue1;

        &:after {
          display: flex;
        }

        + .label {
          color: $colorBlue1;
        }
      }
    }

    .label {
      margin-left: 8px;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434;
      cursor: pointer;
    }
  }

  .inputContainer {
    margin-top: 4px;
    margin-left: 28px;
  }
}
