.fieldContainer {
  margin-bottom: 32px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
}
