@import 'assets/theme';

.container {
  border: none;
  text-align: left;
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding);
  position: relative;
  background-color: #fcf7f7;
  box-shadow: 0 3.50096px 3.50096px rgba(0, 0, 0, 0.25);
  border-radius: $standard-padding * 2;
  padding: $standard-padding * 2;
  margin-bottom: 8px;

  &.selected,
  &:hover {
    background-color: #e0f1fa;
  }
}

.name {
  font-size: 16px;
  font-weight: 700;
  color: #343434;
}

.description {
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #343434;
}

.durationAndRateContainer {
  display: flex;
  align-items: center;

  width: 100%;

  .iconLabel {
    display: flex;
    align-items: center;
    @include columnGap($standard-padding);

    flex: 1;

    span {
      font-size: 17px;
    }

    .label {
      font-size: 15px;
      font-weight: 400;
      color: #343434;
    }
  }
}

.label {
  font-size: 10px;
  font-weight: 500;
  color: $colorTextualGray;
}

.weekdaysContainer {
  display: flex;
  @include columnGap($standard-padding * 2);

  font-size: 16px;
  font-weight: 400;
  color: #c4c4c4;

  .active {
    font-weight: 700;
    color: #27ae60;
  }
}

.deliveryTypeContainer {
  display: flex;
  @include columnGap($standard-padding * 2);

  font-size: 20px;
  color: #c4c4c4;

  .active {
    color: #27ae60;
  }
}

.selectedBadge {
  position: absolute;
  top: $standard-padding;
  right: $standard-padding;

  span {
    font-size: $standard-padding * 4;
    color: $colorBlue1;
  }
}
