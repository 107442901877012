@import 'assets/theme';

.header {
  width: 100%;
  padding: 16px 32px;
  font-size: 24px;
  font-weight: bold;
  color: $black;
  border-bottom: 1px solid $mid-grey-light;
}

.verificationContainer {
  padding: 24px 32px 16px;
}

.confirmationContainer {
  padding: 16px 32px;

  .title {
    font-size: 14px;
    font-weight: bold;
    color: $black;
  }

  .description {
    margin-top: 8px;
    font-size: 14px;
    color: #{$black}c0;
  }
}

.buttonContainer {
  margin-top: 24px;

  .submitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-shadow: none;
  }
}
