@import 'assets/theme';

.loadingWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  margin: 0 8px;
}

.container {
  display: flex;
  align-items: center;

  .countLabel {
    display: flex;
    font-size: 14px;
    line-height: 21px;
    color: #949aa2;
    padding: 0 8px;

    span {
      font-weight: bold;
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    @include columnGap(8px);

    .btn {
      display: flex;
      align-items: center;
      border: 1px solid $mhh-yellow;
      color: #949aa2;
      padding: 8px 12px;
      transition: all 0.3s ease-in-out;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      cursor: pointer;
      user-select: none;

      .btnIconPrev {
        font-size: 18px;
        padding-right: 4px;
      }

      .btnIconNext {
        font-size: 18px;
        padding-left: 4px;
      }
    }

    .btnActive {
      @extend .btn;
      color: $mhh-dark-blue;
    }
  }
}
