@import 'assets/theme';

.container {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.1px;
  color: #343434;

  .selectedBox {
    height: 60px;
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 8px 10px 8px 27px;
    border-radius: 8px;
    cursor: pointer;

    .name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .closeIcon {
      color: $main-red;
    }

    .icon {
      color: $colorBlue1;
    }
  }

  .loadingBox {
    @extend .selectedBox;
    min-height: 54px;
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 7;
    max-height: 200px;
    max-width: 280px;
    overflow: auto;

    &.listHide {
      background: #fff;
      border: 1px solid #eee;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.2s ease;
      position: absolute;
      width: 100%;

      .item {
        padding: 8px 10px 8px 27px;
        cursor: pointer;

        &:hover {
          color: rgba(0, 0, 0, 0.85);
          font-weight: 600;
          background-color: #e0f1fa;
        }
      }

      .itemSelected {
        @extend .item;
        cursor: pointer;
        background-color: $selected;
      }
    }

    &.listShow {
      @extend .listHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }
}
