@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  padding: $new-base-padding 0 $new-base-padding * 5;
  margin-bottom: $new-base-padding * 5;
  border-bottom: 1px solid #dce2f4;
  user-select: none;
  justify-content: space-between;

  .btnBox {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $mhh-dark-blue;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    .icon {
      font-size: 20px;
    }

    .date {
      display: flex;
      align-items: center;

      .label {
        max-width: 200px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 4px;
      }
    }
  }

  .leftContent {
    flex: 1;

    .btnBox {
      @extend .btnBox;
      justify-content: flex-start;
    }
  }

  .middleContent {
    flex: 1;
    justify-content: center;
  }

  .rightContent {
    flex: 1;

    .btnBox {
      @extend .btnBox;
      justify-content: flex-end;
    }
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    flex-direction: column;

    .title {
      font-weight: bold;
      color: #414449;
    }

    .date {
      font-weight: 600;
      color: #414449;
    }
  }
}
