@import 'assets/theme';

.smallSize {
  .title {
    font-size: 12px;
  }

  .desc {
    font-size: 10px;
  }
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: $mhh-dark-blue;
}

.desc {
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  color: #7f7f7f;
}
