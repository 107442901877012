@import 'assets/theme';

.statusContainer {
  padding: 4px 0;

  .toggle {
    .toggleSwitch {
      display: flex;
      flex-direction: column;
      width: 200px;
    }
  }
}
.confirmModal {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 5px;
  }
  .warningIcon {
    color: $main-red;
    font-size: 20px;
    margin-right: 16px;
    align-self: baseline;
  }
}
.subTitle {
  font-size: 12px;
  font-style: italic;
}
.confirmModalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .footerBtn {
    min-width: 100px;
    height: 40px;
    margin-right: 8px;
    padding: 8px;
    width: fit-content;
    border-color: $mhh-yellow;
    background-color: $mhh-yellow;
    color: $mhh-dark-blue;
    text-align: center;
  }
  .cancelBtn {
    @extend .footerBtn;
    border-color: $mhh-yellow;
    color: $mhh-dark-blue;
    background-color: $white;
  }
}
