.filterContainer {
  display: flex;
  padding: 0 24px;
  margin-bottom: 16px;
  cursor: auto;
}

.paginationWrapper {
  padding-top: 16px;
}

.loading {
  display: flex;
  justify-content: center;
}
