@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 16px 8px;

  .signatureInput {
    display: flex;

    .card {
      display: flex;
      align-items: center;
      cursor: pointer;

      .label {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        padding-right: 8px;
      }

      .signBox {
        background: #f8f9fd;
        border-radius: 8px;
        font-size: 14px;
        line-height: 19px;
        color: #7c7c7e;
        padding: 12px 16px;
        width: 200px;
      }
    }
  }

  .signatureError {
    color: #ff1900;
    font-size: 12px;
    padding-top: 6px;
  }
}

.previewContainer {
  display: flex;
  padding: 16px 16px 4px;
  flex-direction: column;

  .signature {
    cursor: auto;
  }
}

.signLabel {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.signature {
  display: flex;
  align-items: center;
  font-family: Kaushan Script;
  font-size: 36px;
  cursor: pointer;
  color: $black;

  .signatureImg {
    max-width: 200px;
    max-height: 80px;
    margin: 8px 0;
  }
}

.extraDetailsWrapper {
  margin-top: 12px;
  font-size: 16px;
  color: #343434;
  cursor: pointer;

  .extraDetails {
    &:first-of-type {
      font-weight: bold;
    }

    &:not(:first-of-type) {
      font-size: 12px;
    }
  }
}

.extraDetailsWrapperPreview {
  @extend .extraDetailsWrapper;
  cursor: auto;
}
