@import 'assets/theme';

.cardContainer {
  display: flex;
  flex-direction: column;
}

.cardWrapper {
  margin-bottom: 24px;

  .active {
    border-left: 4px solid #6fcf97;
  }

  .onboarding {
    border-left: 4px solid $mhh-yellow;
  }

  .microCheckIn {
    border-left: 4px solid $mhh-tiffany-blue;
  }

  .consent {
    border-left: 4px solid $mhh-dark-blue;
  }
}

.container {
  padding: 16px 4px 16px 16px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f2f9ff;
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: #4c5862;
    }

    .badgeBox {
      display: flex;

      .activeBadge {
        background-color: #6fcf97;
      }

      .surveyBadge {
        background-color: $mhh-yellow;
      }

      .microCheckInBadge {
        background-color: $mhh-tiffany-blue;
      }

      .consentBadge {
        background-color: $mhh-dark-blue;
      }

      .caseNotesBadge {
        background-color: $mhh-purple;
      }
    }
  }

  .dateWrapper {
    padding: 4px 0;

    .date {
      font-size: 13px;
      line-height: 20px;
      color: #4c5862;
    }

    .dateLabel {
      @extend .date;
      opacity: 0.5;
    }
  }
}
