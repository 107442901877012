@import 'assets/theme';
$labelLineHeight: 25px;

.checkboxContainer {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  @include columnGap($standard-padding * 2);

  .inputContainer {
    display: flex;
    align-items: center;
    height: $labelLineHeight;

    .checkbox {
      width: 16px;
      height: 16px;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      cursor: pointer;

      &:checked {
        background: $white;
        border: 0.342857px solid $white;
      }
    }
  }

  .labelContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .label {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: $labelLineHeight;
    }

    .subLabel {
      font-size: 13px;
      font-weight: 400;
      color: #a0a0a0;
    }
  }
}
