@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap($standard-padding * 2);
  padding: $standard-padding * 3;

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $colorBlue1;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  .arrowIcon {
    font-size: 12px;
    margin-left: 8px;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.eventInformationContainer {
  display: grid;
  grid-template-columns: 40px 1fr;
  @include columnGap($standard-padding);
  align-items: center;

  .iconContainer {
    justify-self: flex-end;

    span {
      font-size: 20px;
      color: $colorBlue3;
    }
  }

  .description {
    display: flex;
    @include columnGap($standard-padding * 2);
    align-items: center;

    .descriptionText {
      font-size: 18px;
      font-weight: 600;
      color: $colorTextualBlack;
    }
  }

  .subInfoContainer {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    color: #343434;
  }
}

.markAppointmentSelectContainer {
  width: 100%;
}

.footer {
  display: flex;
  justify-content: flex-end;

  .button {
    max-width: 200px;
  }
}
