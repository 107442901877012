@import 'assets/theme';

.container {
  max-height: 120px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $grey-light;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
    background-color: $grey-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $mid-grey-dark;
  }

  .item {
    display: flex;
    align-items: center;
    max-width: 248px;
    padding: $standard-padding;
    cursor: default;

    &.link {
      cursor: pointer;
    }

    .icon {
      margin-right: $standard-padding * 0.5;
      color: $mhh-yellow;
      font-size: 11px;
      min-width: 11px;
    }

    .noIcon {
      margin-left: 12px;
    }

    .label {
      font-size: 11px;
      max-width: 200px;

      .name {
        margin-right: $standard-padding * 0.5;
        font-weight: bold;
        word-wrap: break-word;
      }
    }
  }
}
