@import 'assets/theme';

.modal {
  & > div {
    background-color: transparent;
    box-shadow: none;
  }
}

.container {
  border-radius: 25px;
  border-top: 10px solid $main-red;
  background-color: #f7f5f5;

  & > div {
    background-color: transparent;
  }

  &.group0 {
    border-top-color: #ffbdad;
  }

  &.group1 {
    border-top-color: #25be69;
  }

  &.group2 {
    border-top-color: #a144f6;
  }

  &.group3 {
    border-top-color: #de3d83;
  }

  &.bespoke {
    border-top-color: #1941da;
  }

  &.bespokeEdit {
    border-top-color: $white;
    background-color: $white;
  }

  &.reserved {
    border-top-color: $grey-darker;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  @include columnGap($standard-padding * 2);
  color: #343434;

  .divider {
    height: 30px;
    border-right: 1px solid #c2cfe0;
  }

  .changeDayOrTime {
    font-size: 14px;
  }
}

.editTitleContainer {
  @extend .titleContainer;
  font-size: 14px;
  font-weight: 700;

  .title {
    font-size: 18px;
    color: $colorBlue3;
  }
}

.bespokeTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    @include columnGap($standard-padding * 2);
    font-size: 24px;
    font-weight: 700;
    color: #343434;
  }
}

.noAppointment {
  padding: 20px 32px;
  font-size: 16px;
  font-weight: 600;
}

.confirmModalButtonContainer {
  display: flex;
  flex-direction: column;
  row-gap: $standard-padding;

  .modalButton {
    box-shadow: none;
  }

  .modalButtonBusy {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .modalCancelButton {
    color: #fc8367;
    background-color: $white;
    border: 1px solid #fc8367;
    box-shadow: none;
  }
}
