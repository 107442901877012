.header {
  padding: 24px 32px;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.1px;
  color: #323c47;
  border-bottom: 1px solid #ebeff2;
}

.content {
  padding: 24px 32px;

  .description {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    .button {
      flex: none;
      width: auto;
      box-shadow: none;
    }
  }
}
