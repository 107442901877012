@import 'assets/theme';

.photoList {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  .photoListItem {
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 4px;

    .removePhotoBtn {
      color: #f15944;
      border: none;
      background: none;
      margin-left: 28px;

      &:hover {
        opacity: 0.8;
      }
    }

    img {
      height: 100px;
      width: 100px;
      object-fit: cover;
      border-radius: 8px;
      border: dashed 1px $colorBlue1;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
}

.uploadButton {
  background-color: transparent;
  border: solid 2px $colorBlue1;
  padding: 8px 16px;
  outline: none;
  font-size: 16px;
  border-radius: 8px;
  width: fit-content;
  color: $colorBlue1;
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  box-shadow: none;

  i {
    margin-right: 10px;
  }
}
