@import 'assets/theme';

.content {
  padding: 24px 32px;

  .heading {
    margin-bottom: $standard-padding * 2.5;
    font-weight: 600;
    font-size: 14px;
    color: #414449;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;

    .saveButton {
      width: 30%;
      box-shadow: none;
    }
  }
}
