@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tags {
    display: flex;
    flex-wrap: wrap;
    @include columnGap(6px);
  }

  .tagPill {
    height: 22px;
    background: #f3f3f3;
    border-radius: 27px;
    color: #323c47;
    padding: 5px 12px;
    margin-bottom: 6px;
    white-space: nowrap;
  }
}
