@import 'assets/theme';

.container {
  margin-top: 48px;

  .title {
    margin-bottom: 12px;
    margin-left: 24px;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #5666a6;
  }

  .headingContainer {
    display: flex;
    justify-content: space-evenly;

    .heading {
      font-size: 12px;
      line-height: 16px;
      color: #34343480;

      &:first-child {
        width: 5%;
        margin-left: 40px;
      }

      &:nth-child(2) {
        width: calc(50% - 40px);
      }

      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 15%;
      }

      &:last-child {
        width: 15%;
        margin-right: 4px;
      }
    }
  }

  .itemContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;

    .index {
      width: 5%;
      margin-left: 40px;
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
      color: $main-red;
    }

    .label {
      width: calc(50% - 40px);
      word-wrap: break-word;

      .tags {
        display: flex;
        flex-wrap: wrap;
        margin-top: $standard-padding * 0.5;
        gap: $standard-padding * 0.5;

        .tag {
          display: flex;
          align-items: center;
          padding: $standard-padding * 0.5 $standard-padding;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: $white;
          background: $colorBlue3;
          border-radius: 43px;
        }
      }
    }

    .cost {
      width: 15%;
    }

    .tax {
      width: 15%;
    }

    .total {
      width: 15%;
      margin-right: 4px;
    }
  }

  .discountContainer {
    display: flex;
    margin-top: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #343434;

    .label {
      width: calc(50% - 40px);
      margin-left: calc(5% + 40px);
    }

    .discount {
      width: 15%;
    }
  }
}
