@import 'assets/theme';

.container {
  display: flex;
  flex: 1 1;
  border-bottom: solid 1px #ebeff2;
  padding-bottom: 21px;
  padding: 16px 0px;

  .connectDomain {
    flex: 1 5 35%;
    display: flex;

    .domainThumbnail {
      flex: 1 5 13%;
      max-width: 23px;
    }

    .domainInfoContainer {
      flex: 1 5 87%;
      padding-left: 10px;
      @include rowGap(4px);

      .domainInfo {
        display: flex;
        flex-direction: column;
        @include rowGap(4px);

        .email {
          font-size: 14px;
          line-height: 19.1px;
          font-weight: 600;
          color: #6575b1;
        }

        .domainName {
          font-size: 12px;
          line-height: 23px;
          font-weight: 400;
          color: #323c47;
        }
      }

      .unlinkButton {
        align-items: flex-start;
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: #ff2500;
        padding: 16px 0px 0px 0px;
      }

      .checkboxContainer {
        .connectSettingCheckbox {
          display: flex;
          // align-items: baseline;
          @include columnGap(12px);
          user-select: none;
          cursor: pointer;
          width: 90%;

          .connectSettingLabel {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            letter-spacing: 0.1px;
            width: 90%;
          }

          .checkbox {
            appearance: none;
            height: 16px;
            width: 16px;
            background-color: transparent;
            border: 2px solid #d5d5d5;
            border-radius: 4px;
            color: #d5d5d5;
            cursor: pointer;
            outline: none;
            transition: all 0.1s linear;
            margin-top: 4px;

            &:after {
              content: '\2713';
              display: none;
              width: 10px;
            }

            &:checked {
              font-size: 12px;
              line-height: 12px;
              font-weight: 800;
              color: #fff;
              background-color: $colorBlue1;
              border: 2px solid $colorBlue1;

              &:after {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  .calendarsContainer {
    flex: 2 5 65%;
  }
}
