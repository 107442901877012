@import 'assets/theme';

.modalContainer {
  min-width: 540px;
}

.container {
  .contentContainer {
    display: flex;
    padding: 24px 0px;
    color: #414449;
    @include columnGap(14px);

    .icon {
      font-size: 30px;
      color: #ff7d45;
    }

    .messageContainer {
      @include rowGap(8px);

      .title {
        font-size: 18px;
        font-weight: 600;
      }

      .description {
        font-size: 14px;
        font-style: italic;
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    @include columnGap(8px);

    .button {
      box-shadow: none;
    }
  }
}
