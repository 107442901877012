.container {
  display: flex;
  min-height: 50px;
  align-items: center;

  .titleContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding-right: 16px;

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      color: #323C47;
    }

    .desc {
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      color: #343434;
    }
  }
}
