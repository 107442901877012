@import 'assets/theme/index';

.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 0 -16px;

  .box {
    display: flex;
    flex-direction: column;

    background: #fff;
    border-radius: 8px;
    padding: 16px;

    @media only screen and (max-width: $medium-screen-min-width) {
      flex-direction: column;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 16px;

      .title {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #343434;
        padding-left: 24px;
      }

      .iconWrapper {
        display: flex;
        align-items: center;

        .icon {
          height: 50px;
          width: 50px;
          font-size: 13px;
          border-radius: 50%;
          color: $mhh-green;
          background-color: $white;
          font-weight: bold;
          line-height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .iconComplete {
          @extend .icon;
          box-sizing: border-box;
          border: 2px solid $mhh-green;
        }
      }
    }

    .desc {
      display: flex;
      flex-direction: column;
      padding: 24px 0 0 24px;

      @media only screen and (max-width: $medium-screen-min-width) {
        padding: 0;
      }

      .description {
        padding: 8px 0;
        line-height: 28px;
      }

      .linkWrapper {
        display: flex;
        color: $mhh-yellow;
        cursor: pointer;
      }

      .link {
        color: $mhh-yellow;
        cursor: pointer;
      }
    }

    .resendButton {
      display: flex;
      align-content: center;
    }
  }
}
