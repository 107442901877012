@import 'assets/theme';

:global {
  .widgetFullHeightEditor {
    .ql-toolbar {
      border: none;
    }

    .ql-editor {
      min-height: 250px;

      .mention {
        display: inline-flex;
        width: auto;
        height: auto;
        background-color: #c9e3f1;
        margin: 0;
        font-size: 13px;
      }
    }

    .ql-container {
      border: none;
    }

    .ql-mention-list .selected {
      color: #fff;
      background-color: #5666a6;
    }
  }

  .widgetEditor {
    @extend .widgetFullHeightEditor;

    .ql-toolbar {
      border: none;
    }

    .ql-editor {
      max-height: 200px;
      overflow-y: auto;
      overscroll-behavior: contain;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $grey-light;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: $grey-light;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $mid-grey-medium;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background: #f8f9fd;
  box-sizing: border-box;
  padding: 0;
  cursor: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $grey-light;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: $grey-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $mid-grey-medium;
  }

  div {
    font-family: 'Roboto', sans-serif !important;
  }
}

.containerToolbar {
  @extend .container;
  background: none;
  border: 0.5px dashed #0085bd;
  border-radius: 4px;
}

.mentionListContainer {
  background-color: #e6e9f2;
  border-radius: 6px;
  z-index: 1;
}

.mentionList {
  padding: 4px 6px;
  font-size: 12px;
  cursor: pointer;
}

:export {
  blackColor: $black;
  whiteColor: $white;
}
