@import 'assets/theme';

.container {
  padding: 16px 0;

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.1px;
    padding-bottom: 16px;
  }

  .desc {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;

    .link {
      align-items: center;
      color: #6575b1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .facilitatedContainer {
    display: flex;
    flex-direction: column;
    @include rowGap(24px);

    .facilitatedWrapper {
      display: flex;
      flex-direction: column;

      .clientName {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: $colorBlue1;
        padding-top: 20px;
      }
    }
  }

  .linkContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .linkWrapper {
    display: flex;
    max-width: 100%;
    align-items: center;

    .link {
      font-size: 18px;
      line-height: 25px;
      align-items: center;
      color: #6575b1;
      margin-right: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 450px;
    }

    .copyBtn {
      display: flex;
      font-size: 24px;
      cursor: pointer;
    }
  }

  .openLinkBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border: 1px solid $colorBlue1;
    box-sizing: border-box;
    border-radius: 4px;
    color: $colorBlue1;

    .label {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin-left: 8px;
    }
  }

  .qrCodeWrapper {
    display: flex;
    justify-content: center;
    padding-top: 16px;
  }

  .footerButtonWrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;

    .createNewClientBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $colorBlue1;
      cursor: pointer;

      .newClientLabel {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        margin-left: 8px;
      }
    }

    .singleLinkWrapper {
      .openLinkBtn {
        @extend .openLinkBtn;
        padding: 8px 16px;
      }
    }
  }

  .newClientBtnWrapper {
    @extend .footerButtonWrapper;
    justify-content: flex-start;
  }

  .appointmentBtnWrapper {
    display: flex;
    justify-content: center;
    padding: 40px 0 8px;
  }
}

:export {
  blackColor: $black;
  whiteColor: $white;
}
