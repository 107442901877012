@import 'assets/theme';

.groupWrapper {
  padding: 12px 16px;
  display: flex;
  background: $white;
  border: 1px solid #ecedef;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  .groupInformation {
    color: #414449;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .groupName {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .activeMembers {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.selected {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding-left: 32px;
  gap: 10px;

  .selectedPill {
    padding: 0px 8px;
    background-color: $colorBlue3;
    color: #fff;
    border-radius: 15px;
    height: 23px;
    line-height: 23px;
  }
}

.profileWrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  margin: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  @include columnGap(16px);

  .profile {
    width: 40px;
    height: 40px;

    .initialAvatar {
      font-size: 18px;
    }
  }
}
