@import 'assets/theme';

.container {
  margin-bottom: $standard-padding * 2;

  .stem {
    font-style: italic;
    font-size: 13px;
    font-weight: bold;
    color: #4b718e;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    color: #343434;
    padding-bottom: 8px;
  }
}

:global(.mhh-assessment-question) {
  .stem {
    font-weight: 600;
    font-size: 18px;
    font-style: normal;
    color: $mhh-light-black;
  }
}
