@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  padding: $standard-padding * 2 0;
  justify-content: space-between;

  .leftSection {
    display: flex;
    align-items: flex-end;

    .titleWrapper {
      display: flex;
      flex-direction: column;
      padding-right: 24px;

      .loading {
        min-width: 100px;
      }

      .title {
        font-weight: bold;
        font-size: 28px;
        letter-spacing: -0.02em;
        color: #414449;
      }
    }
  }

  .rightSection {
    display: flex;
    flex-direction: column;

    .addNewBtnWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 46px;
      padding: 10px 16px;
      box-sizing: border-box;
      font-weight: 600;
      text-align: center;
      letter-spacing: 0.02em;
      border: 1px solid $mhh-yellow;
      color: $mhh-dark-blue;
      background: $mhh-yellow;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      min-width: 200px;

      .icon {
        padding-right: 6px;
      }
    }
  }
}
