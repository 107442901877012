@import 'assets/theme';

.loadingContainer {
  display: flex;
  padding: 16px 32px;
}

.container {
  max-height: 200px;
  overflow-y: auto;
  margin-right: 24px;
  margin-top: 12px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $grey-light;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
    background-color: $grey-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $mid-grey-dark;
  }

  .link {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      padding-bottom: $standard-padding * 2;
    }

    .icon {
      color: $mhh-yellow;
      font-size: 16px;
      margin-right: 8px;
    }

    .label {
      font-size: 16px;
      max-width: 200px;
      font-weight: 400;

      .name {
        margin-right: $standard-padding * 1;
        font-weight: 600;
        word-wrap: break-word;
      }
    }
  }
}

.noItems {
  font-size: 12px;
  font-style: italic;
  padding: 20px 0;
}
