@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(8px);

  .titleContainer {
    display: flex;
    flex-direction: column;
    @include rowGap(4px);

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .desc {
      color: #414449;
    }
  }

  .filterContainer {
    display: flex;
    font-weight: 600;
    align-items: center;
    @include columnGap(20px);

    .dropdown {
      min-width: 100px;
    }

    .dropdownSelection {
      color: $colorBlue1;
      cursor: pointer;
    }
  }

  .loadingContainer {
    display: flex;
    flex-direction: column;

    .loading {
      min-height: 240px;
    }
  }

  .infoContainer {
    display: flex;
    padding: 20px;
    @include columnGap(30px);

    .iconContainer {
      .icon {
        width: 200px;
      }
    }
    .messageContainer {
      display: flex;
      flex-direction: column;
      @include rowGap(4px);
      justify-content: center;

      .message {
        font-weight: 600;
        size: 18px;
      }

      .description {
        size: 14px;
      }
    }
  }
}
