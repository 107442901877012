@import 'assets/theme';

.container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  @include rowGap(32px);
}

.telehealthForm {
  display: flex;
  flex-direction: column;
  @include rowGap(8px);
  padding-top: 28px;
}

.optionRow {
  display: grid;
  grid-template-columns: 210px 1fr;
  padding: 20px 12px 12px;
  @include columnGap(12px);

  &.active {
    background-color: #f2f9ff;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    @include columnGap(16px);

    .checkbox {
      width: 16px;
      height: 16px;
      border: 2px solid #d5d5d5;
      border-radius: 4px;

      &:checked {
        background: $white;
        border: 0.342857px solid $white;
      }
    }

    .label {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }

    &.telehealthCheckbox {
      align-self: flex-start;
    }
  }

  .faceToFaceLocation,
  .telehealthInstructions {
    width: 250px;
  }

  .inputClass {
    background-color: transparent;
  }

  .telehealth2ndColumn {
    @include columnGap(32px);

    .telehealthItem {
      display: flex;
      margin-top: 8px;
    }
    .label {
      color: $colorTextualGray;
    }
    .errorMessage {
      color: #ff4d4f;
      font-size: 12px;
      margin-bottom: 0px !important;
    }
  }
}

.radioClass > div {
  @include rowGap(20px);
}

.radioClass {
  padding-right: 30px;
  margin-bottom: 12px;
}

.radioLabel {
  margin: 0;
}

.roomRequiredForm {
  display: flex;
  align-items: flex-start;
  @include columnGap(36px);
}

.labelClass {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 11px;
  color: $colorTextualGray;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.draftButton {
  width: 200px;
}

.fieldError {
  color: #ff4d4f;
  font-size: 12px;
  padding-left: 12px;
}

:export {
  greyColor: $colorLineGray;
}
