@import 'assets/theme/index';

.container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 0 -16px;

  .box {
    display: flex;
    flex-direction: column;

    background: #fff;
    border-radius: 8px;
    padding: 16px;

    @media only screen and (max-width: $medium-screen-min-width) {
      flex-direction: column;
    }

    .imgWrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 16px;

      .img {
        max-width: 80px;
        height: 100%;
      }

      .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #343434;
        padding-bottom: 8px;
        padding-left: 24px;
      }
    }

    .desc {
      display: flex;
      flex-direction: column;
      padding: 24px 0 0 24px;

      @media only screen and (max-width: $medium-screen-min-width) {
        padding: 0;
      }

      .description {
        padding: 8px 0;
        line-height: 28px;
      }

      .linkWrapper {
        display: flex;
        color: $cta;
        cursor: pointer;
      }

      .link {
        color: $cta;
        cursor: pointer;
      }
    }

    .button {
      align-self: center;
      display: flex;
      align-items: center;
      margin: 32px 0;
      width: 284px;
      background-color: $cta;
      border: 1px solid $cta;
    }
  }
}
