@import 'assets/theme';

.attachmentsContainer {
  margin-top: 16px;

  .title {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorTextualGray;
  }

  .attachmentContainer {
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $black;

    .icon {
      margin-right: 8px;
      color: #0085bd;
    }
  }

  .addAttachmentsButton {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-left: 12px;
    padding: 0;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $mhh-dark-blue;
    border: none;
    cursor: pointer;

    .icon {
      font-size: 18px;
      margin-right: 4px;
    }
  }

  .uploadingLabel {
    @extend .addAttachmentsButton;
    cursor: auto;
  }

  .addAttachmentInput {
    display: none;
  }
}
