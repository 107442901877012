@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #f2f9ff;

  .header {
    display: flex;
    margin-top: 12px;

    .titleInput {
      font-size: 15px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0.1px;
      color: #343434;
      background-color: transparent;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .selectLabel {
      margin-bottom: -10px;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: $colorTextualGray;
    }

    .middleCol {
      .switchContainer {
        margin: 16px 0;
        user-select: none;

        .label {
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: $colorTextualGray;
        }

        .multipleCheckBox {
          display: flex;
          margin: 8px 0 0 16px;
        }
      }

      .label {
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: $colorTextualGray;
      }

      .customOptionsContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 16px;

        .customOptions {
          height: 38px;
          font-size: 13px;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: 0.1px;
          color: $colorTextualBlack;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          border-bottom: 1px solid $colorLineGray;
          cursor: text;
        }

        .customChoiceInputs {
          display: none;

          &.visible {
            display: block;
          }
        }

        .pillContainer {
          display: flex;
          align-items: center;

          .pill {
            margin: 4px 0;
            padding: 4px 8px;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.1px;
            color: $white;
            background: $colorBlue3;
            border-radius: 20px;
          }

          .removeIcon {
            color: #eb4545;
            padding-left: 4px;
            cursor: pointer;
          }
        }

        .addMoreButton {
          display: flex;
          align-items: center;
          margin-top: 8px;
          padding: 0;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: $colorBlue1;
          border: none;

          .icon {
            font-size: 18px;
            margin-right: 4px;
          }
        }

        .additionalCommonOptionsInput {
          margin-top: 24px;
          background-color: transparent;
        }

        .customOptionsInput {
          background-color: transparent;
        }

        .input {
          background-color: transparent;
        }

        .tip {
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: $colorTextualGray;
        }
      }

      .customOptionsContainer {
        .input {
          background-color: transparent;

          &:last-of-type {
            margin-top: 16px;
          }
        }

        .tip {
          font-size: 10px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: $colorTextualGray;
        }
      }
    }
  }
}
