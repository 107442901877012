.modalContainer {
  min-width: 800px;

  .headerTitle {
    font-size: 20px;
    line-height: 30px;
    color: #323c47;
    font-weight: 600;
  }

  .container {
    display: flex;

    .videoPlayer {
      display: flex;
      width: 100%;
      border-radius: 6px;
    }
  }
}
