@import 'assets/theme';

.container {
  min-width: 800px;

  .header {
    padding: 8px 0px;
    .title {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

.loading {
  height: 320px;
}

.body {
  display: flex;
  flex-direction: column;
  @include rowGap(16px);

  .typeToggleContainer {
    display: flex;
  }
}

.footer {
  display: flex;
  padding-top: 32px;
  justify-content: flex-end;

  .saveButton {
    box-shadow: none;
    padding: 12px 20px;
  }
}
