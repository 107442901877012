@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  transition: all 0.1s linear;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  &.active {
    background-color: #f2f9ff;

    .startTimeContainer,
    .endTimeContainer {
      display: block;
    }
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    width: 32%;
    margin-right: 6%;

    .checkbox {
      appearance: none;
      margin: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      color: #d5d5d5;
      cursor: pointer;
      outline: none;
      transition: all 0.1s linear;

      &:after {
        content: '\2713';
        display: none;
        justify-content: center;
        align-items: center;
        width: 16px;
      }

      &:checked {
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
        color: #fff;
        background-color: $colorBlue1;
        border: 2px solid $colorBlue1;

        &:after {
          display: flex;
        }
      }
    }

    .label {
      flex: 1;
      padding-left: 32px;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434;
      cursor: pointer;
    }
  }

  .rightContainer {
    width: 56%;
    display: flex;
    flex-direction: column;

    .timeContainer {
      display: flex;
    }

    .errorText {
      margin-left: 10px;
      margin-top: 5px;
      font-size: 11px;
      color: red;
    }
  }

  .startTimeContainer,
  .endTimeContainer {
    display: none;
    width: 44%;
    margin-right: 6%;
  }
}

.extraFooterTitle {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $colorTextualGray;
}

.extraFooterButtons {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;

  .button {
    padding: 0;
    text-decoration: underline;
    line-height: revert;
    border: none;
  }
}
