@import 'assets/theme';

.container {
  min-width: 720px;
  background: #fff;
  box-shadow: 0 2px 16px #0000001a;
  border-radius: 8px;

  .header {
    padding: 16px 24px;
    border-bottom: 1px solid #ebeff2;

    .title {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 28px;
      color: $colorTextualBlack;
    }

    .description {
      font-size: 13px;
      letter-spacing: 0.1px;
      line-height: 20px;
      color: #323c4780;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    padding: 24px;
    @include rowGap(30px);

    .dropdownLoading {
      width: 280px;
      height: 51px;
    }

    .cardLoading {
      height: 122px;
    }

    .providersContainer {
      display: flex;
      flex-wrap: wrap;
      row-gap: 8px;
      column-gap: 16px;
    }

    .button {
      margin: 0px;
      width: 240px;
      font-weight: 600;
      font-size: 16px;
      align-self: flex-start;
      box-shadow: none;
    }

    .tooltip {
      max-width: 230px;
      border-radius: 8px;
      line-height: 16px;
      font-size: 14px;
      padding: 12px;
      opacity: 0.95 !important;
    }
  }
}
