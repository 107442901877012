@import 'assets/theme';

.propfileTagsContainer {
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #323c47;
    margin-bottom: 20px;

    .subTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #323c47;
      opacity: 0.5;
      margin-top: 5px;
    }
  }

  .selectedTagsContainer {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
    @include rowGap(10px);
    @include columnGap(10px);

    .tagItem {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;
      height: 32px;
      background: $colorBlue3;
      border-radius: 20px;
      color: #fff;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      user-select: none;
    }
  }
}
