@import 'assets/theme';

.container {
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  @include rowGap(24px);

  .profileBox {
    display: flex;
    @include columnGap(24px);

    .statusWrapper {
      display: flex;
      flex-direction: column;

      .subTitle {
        display: flex;
        padding-bottom: 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $colorTextualGray;
      }
    }
  }

  .detailsBox {
    display: flex;
    flex-direction: column;
    padding-right: 16px;
    flex: 1 2 35%;

    .personalInfo {
      display: flex;
      flex-direction: column;
      padding-bottom: 8px;

      .name {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: $colorTextualBlack;
        padding-bottom: 8px;
      }
    }

    .detailWrap {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .label {
        font-size: 13px;
        color: $colorTextualGray;
        padding-right: 8px;
      }

      .info {
        font-weight: 600;
        font-size: 13px;
        color: $black;
      }
    }

    .facilitatedUrlWrapper {
      display: flex;
      margin-bottom: 8px;

      .label {
        font-size: 13px;
        color: $colorTextualGray;
        padding-right: 4px;
        padding-top: 2px;
      }

      .content {
        display: flex;
        flex-direction: column;
        @include columnGap(15px);

        .facilitatedLinkWrapper {
          display: flex;
          max-width: 280px;
          align-items: center;

          .link {
            font-size: 12px;
            line-height: 25px;
            align-items: center;
            color: #6575b1;
            margin-right: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .copyBtn {
            display: flex;
            cursor: pointer;

            .icon {
              font-size: 16px;
            }
          }
        }

        .qrCodeWrapper {
          display: flex;
          padding-top: 4px;

          .qrCodeBtn {
            border: 1px solid #6575b1;
            cursor: pointer;
            align-self: center;
            padding: 6px 8px;
            font-size: 13px;
            font-weight: 600;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #6575b1;
            background-color: $white;
            border-radius: 4px;

            &:active {
              background-color: #fbfbfb;
            }
          }
        }
      }
    }

    .gpCheckIcon {
      background-color: $colorBlue1;
      color: #fff;
      border-radius: 50%;
      font-size: 18px;
      font-weight: bold;
      padding: 4px;
      margin-left: 4px;
    }

    .gpCancelIcon {
      background-color: $main-red;
      color: #fff;
      border-radius: 50%;
      font-size: 18px;
      font-weight: bold;
      padding: 4px;
      margin-left: 4px;
    }

    .completeSessionIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid $colorBlue1;
      color: #343434;
      border-radius: 50%;
      font-size: 13px;
      font-weight: bold;
      width: 22px;
      height: 22px;
      padding: 12px;
      margin-left: 4px;
    }
  }
}
