@import 'assets/theme';

.detailsContainer {
  flex: 1;

  .selectionsContainer {
    display: flex;
    align-items: center;

    @media only screen and (max-width: $medium-screen-min-width) {
      flex-direction: column;
    }

    .timeContainer {
      display: flex;
      width: 66%;

      @media only screen and (max-width: $medium-screen-min-width) {
        width: 100%;
      }
    }

    .startTimeContainer,
    .endTimeContainer {
      width: 50%;
      padding: 12px 0 12px;

      @media only screen and (max-width: $medium-screen-min-width) {
        padding-left: 0;
        display: flex;
        flex-direction: column;
        @include rowGap(10px);
      }
    }
    .actionContainer {
      padding: 12px;
      width: 33%;

      @media only screen and (max-width: $medium-screen-min-width) {
        display: flex;
        justify-content: flex-end;
        padding: 0 24px;
        font-size: 13px;
        width: 100%;
      }
    }
  }

  .errorContainer {
    padding: 0 12px;
  }
}
