.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px 30px 20px;

  p {
    font-weight: 600;
    font-size: 29px;
    margin: 0;
  }

  .searchbarWrapper {
    width: 400px;
  }
}
