@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    user-select: none;

    .vertIcon {
      color: $colorBlue3;
    }
  }
}

.menuWrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;

  .menuHide {
    background: #fff;
    visibility: hidden;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.2s ease;
    position: absolute;
    width: 100%;
    min-width: 140px;
    border: 1px solid #ecedef;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .menuShow {
    @extend .menuHide;
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 0.2s ease;

    .label {
      margin-left: 8px;
    }
  }
}

.itemCard {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background-color: #f5f6ff;
  }

  .itemIcon {
    font-size: 16px;
    color: #61666e;
  }

  .subMenuControlIcon {
    @extend .itemIcon;
    margin-left: auto;
  }

  .label {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #414449;
  }
}

.itemCardActive {
  @extend .itemCard;
  background-color: #f5f6ff;
}

.menuItemSelected {
  padding: 2px 4px;
  cursor: pointer;
  background-color: #e6f7ff;
}
