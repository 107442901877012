@import 'assets/theme';

.titleWrapper {
  display: flex;
  font-weight: 600;
  font-size: 16px;
  color: $colorTextualBlack;
  padding-bottom: 18px;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    line-height: 24px;

    .titleIcon {
      color: #c2cfe0;
      padding-right: 20px;
      position: relative;
      font-size: 24px;
    }
  }

  .insertTemplateBtn {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: $colorBlue1;
    cursor: pointer;

    .plusIcon {
      font-size: 16px;
    }
  }
}
