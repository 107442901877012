@import 'assets/theme';

.container {
  padding-top: 16px;
}

.paymentRequired {
  display: flex;
  padding: 32px 16px;

  &.active {
    background-color: #f2f9ff;
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    padding-right: 8px;

    .checkbox {
      appearance: none;
      margin: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      color: #d5d5d5;
      cursor: pointer;
      outline: none;
      transition: all 0.1s linear;

      &:after {
        content: '\2713';
        display: none;
        justify-content: center;
        align-items: center;
        width: 16px;
      }

      &:checked {
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
        color: #fff;
        background-color: $colorBlue1;
        border: 2px solid $colorBlue1;

        &:after {
          display: flex;
        }
      }
    }

    .label {
      flex: 1;
      padding-left: 32px;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434;
      cursor: pointer;
    }
  }

  .radioContainer {
    width: 55%;
  }

  .radio {
    flex: 1;
  }
}
