@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  @include rowGap(8px);

  .sectionContainer {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    max-height: 500px;
    transition: all 0.5s ease;
    @include rowGap(8px);

    .error {
      color: #ff1900;
      font-size: 12px;
      padding: 0px 10px;
      margin: 0px;
    }

    .section {
      border-radius: 8px;
      padding: 12px 10px;
      background-color: $white;
      display: flex;
      color: #202225;
      min-height: 94px;
      @include columnGap(20px);

      .labelContainer {
        @include rowGap(8px);
        flex: 1;

        .title {
          font-weight: 600;
          font-size: 16px;
        }
        .description {
          font-size: 14px;
        }

        .label {
          display: flex;
          align-items: center;
          @include columnGap(16px);

          .descGroup {
            display: flex;
            flex-direction: column;
            cursor: pointer;

            .desc {
              font-size: 16px;
              font-weight: 600;
            }

            .subDesc {
              font-size: 14px;
            }
          }
        }

        .checkbox {
          appearance: none;
          margin-top: 4px;
          height: 20px;
          min-width: 20px;
          background-color: $white;
          border: 2px solid #d5d5d5;
          border-radius: 4px;
          color: #d5d5d5;
          cursor: pointer;
          outline: none;
          transition: all 0.1s linear;
          align-self: baseline;

          &:after {
            content: '\2713';
            display: none;
            justify-content: center;
            align-items: center;
            width: 16px;
          }

          &:checked {
            font-size: 12px;
            line-height: 16px;
            font-weight: 800;
            color: #fff;
            background-color: $colorBlue1;
            border: 2px solid $colorBlue1;

            &:after {
              display: flex;
            }
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            background-color: #2b4670;
            border: 2px solid #2b4670;
          }
        }
      }
      .selectionContainer {
        display: flex;
        flex: 1;
        align-items: center;
        opacity: 100;
        transition: all 0.3s ease;
        font-size: 16px;

        .radioLabel {
          font-weight: 600;
        }

        .timeRangeContainer {
          display: flex;
          flex: 1;
          align-items: center;
          @include columnGap(8px);

          .label {
            margin-top: 8px;
          }
          .select {
            display: flex;
            flex-direction: column;
          }

          .error {
            position: absolute;
            margin: 0px;
          }
        }
      }

      .hiddenSelectionContainer {
        @extend .selectionContainer;
        opacity: 0;
      }
    }

    .active {
      background-color: #f5f6ff;
    }
  }

  .hiddenSectionContainer {
    @extend .sectionContainer;
    max-height: 0;
    overflow: hidden;
  }
}
