@import 'assets/theme';

.pagination {
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  background: #fff;
  padding: 8px;
  border-radius: 50px;
  user-select: none;

  .active > a {
    background-color: $colorBlue3;
    border-color: $colorBlue3;
    color: #fff;
  }

  li > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      background-color: $colorBlue3;
      border-color: $colorBlue3;
      color: #fff;
    }
  }

  .active > a,
  .active > span,
  .active > a:hover,
  .active > span:hover,
  .active > a:focus,
  .active > span:focus {
    background-color: $colorBlue3;
    border-color: $colorBlue3;
    outline: none;
    border-radius: 50%;
  }

  li > a,
  li > span {
    color: $colorBlue3;
  }

  li:first-child > a,
  li:first-child > span,
  li:last-child > a,
  li:last-child > span {
    border-radius: 50%;
  }

  .btn > a {
    height: 35px;
    width: 35px;
    margin: 0 8px;

    .label {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .arrowLeft {
      font-size: 30px;
      margin-right: 4px;
    }

    .arrowRight {
      font-size: 30px;
      margin-left: 4px;
    }
  }
}
