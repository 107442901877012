@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $colorTextualGray;
  }

  .consentContent {
    display: flex;
    padding-top: 12px;
    flex-direction: column;
    @include rowGap(8px);
    .sendAdhocBtn {
      margin-top: 8px;
      height: 46px;
      box-shadow: none;
      border-radius: 4px;
      cursor: pointer;

      .sendAdhocBtnIcon {
        height: 20px;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
    .consentList {
      display: flex;
      flex-direction: column;

      .consentName {
        display: flex;
        align-items: center;
        @include columnGap(8px);
        font-weight: 600;

        .gpCheckIcon {
          background-color: $colorBlue1;
          color: #fff;
          border-radius: 50%;
          font-size: 16px;
          font-weight: bold;
          padding: 4px;
          margin-right: 8px;
        }
      }

      .consentDetails {
        display: flex;
        align-items: center;
        padding-left: 32px;
        @include columnGap(17px);

        .consentDate {
          font-weight: 600;
          font-size: 12px;
        }

        .consentViewBtn {
          display: flex;
          align-items: center;
          color: $colorBlue1;
          cursor: pointer;
          font-size: 13px;

          .viewIcon {
            color: #7b84dc;
            margin-left: 4px;
          }
        }
      }
    }
  }
}
