@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  margin-top: $standard-padding;
  position: relative;
  border-radius: 8px;

  .checkbox {
    display: none;

    &:checked {
      + .label {
        color: $white;
        background-color: #{$colorBlue1}c0;

        .fakeCheckbox {
          &:after {
            content: '\2713';
          }
        }
      }
    }
  }

  .label {
    display: flex;
    align-items: center;
    padding: $standard-padding * 1.5 $standard-padding * 2;
    background: #f8f9fd;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    color: #34343480;
    transition: all 0.2s cubic-bezier(0.35, 0.1, 0.25, 1);
    cursor: pointer;

    .fakeCheckbox {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      width: 20px;
      margin-right: $standard-padding * 1.5;
      background-color: transparent;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      font-size: 16px;
      line-height: 16px;
      font-weight: bold;
      color: $white;
    }

    &:first-of-type {
      border-radius: 8px 8px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 8px 8px;
    }
  }

  &.error {
    border: 1px solid $main-red;
    margin-bottom: 0;
  }
}
