@import 'assets/theme';

.modalContainer {
  min-width: 800px;

  .container {
    padding: 0 0 16px;

    .contentContainer {
      display: flex;
      flex-direction: column;
      transition: all 0.3s ease;
    }

    .contentLoadingContainer {
      @extend .contentContainer;
      transition: max-height 0.3s ease-out;
      overflow: hidden;

      .loadingContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 300px;
        justify-content: center;
        max-width: 500px;
        margin-top: -16px;
      }
    }
  }
}

.inviteFormWrapper {
  padding-top: 24px;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;
}
