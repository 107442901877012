@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #343434;
  }

  .titleSelected {
    @extend .title;
    font-weight: bold;
    color: $mhh-yellow;
  }

  .disabled {
    color: #bfbfbf;
  }

  .desc {
    padding-top: 4px;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
  }
}
