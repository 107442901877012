@import 'assets/theme';

.containerWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.container {
  display: flex;
  align-items: center;
  color: #414449;
  padding: 12px 4px;

  .settingsMenu {
    display: flex;
    transition: all 0.8s ease;
    max-width: 100px;
    overflow: hidden;

    .deleteBtn {
      display: flex;
      color: #eb4545;
      cursor: pointer;
      user-select: none;

      .deleteIcon {
        margin-right: 16px;
      }
    }

    .editBtn {
      display: flex;
      color: $colorBlue1;
      cursor: pointer;
      user-select: none;

      .editIcon {
        margin-right: 16px;
      }
    }
  }

  .settingsMenuHide {
    @extend .settingsMenu;
    max-width: 0;
  }

  .detailsWrapper {
    display: flex;
    flex: 1;
    align-items: center;

    .details {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-right: 8px;

      .label {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      .desc {
        font-size: 14px;
        line-height: 21px;
        color: #b5b8bd;
      }
    }

    .selectMenu {
      display: flex;
      transition: all 1s ease;
      max-width: 40px;
      overflow: hidden;

      .insertBtn {
        display: flex;
        color: $colorBlue1;
        cursor: pointer;
        user-select: none;
        border: 1px solid $colorBlue1;
        border-radius: 50%;
        padding: 6px;

        .arrowIcon {
          font-size: 20px;
        }
      }
    }

    .selectMenuHide {
      @extend .selectMenu;
      max-width: 0;
      transition: all 0.5s ease;
    }
  }

  .cursor {
    cursor: pointer;
  }
}

.overlayContainer {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 12px 0;

  .overlayContent {
    display: flex;
    align-items: center;
    background-color: #f3f3f3;
    opacity: 0.95;
    color: #414549;
    height: 100%;
    width: 100%;
    padding: 8px;
    border-radius: 4px;

    .loading {
      display: flex;
      align-items: center;
      padding-left: 4px;

      .circle {
        stroke: #414549;
      }
    }
  }
}
