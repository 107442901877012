.container {
  display: flex;
  flex-direction: column;
}

.loadingWrapper {
  display: flex;

  .sideBarLoading {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    background-color: #fff;
    padding: 16px;
  }

  .contentLoading {
    margin-left: 24px;
    width: calc(100% - 324px);
    background-color: #fff;
    padding: 16px;
  }
}
