@import 'assets/theme';

.panel {
  display: flex;
  flex-direction: column;
  @include rowGap(25px);
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  font-weight: 700;
}

.addButton {
  min-width: 248px;
}

.addIcon {
  margin-right: 20px;
}

.appointmentTypeList {
  display: flex;
  flex-direction: column;
  @include rowGap(40px);
}

.loading {
  display: flex;
  justify-content: center;
  min-height: 300px;
  align-items: center;
}
