@import 'assets/theme';

.firstName {
  width: 15%;
}

.lastName {
  width: 15%;
}

.mobileNumber {
  width: 20%;
}

.email {
  width: 20%;
}

.contactTags {
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  @include columnGap(10px);

  letter-spacing: 0.02em;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;

  .tagPill {
    height: 22px;
    background: #f3f3f3;
    border-radius: 27px;
    color: #323c47;
    padding: 5px 12px;
    margin-bottom: 4px;
    white-space: nowrap;
  }

  .tagNote {
    min-height: 12px;
    color: #414449;
    display: flex;
    justify-content: flex-end;
  }
}

.creating {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 30px 27px 16px 15px;
  border-radius: 8px;
  background-color: #f2f9ff;
  border-bottom: 1px solid #ebeff2;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  @include rowGap(20px);

  .fieldsContainer {
    display: flex;
  }

  .actionsContainer {
    display: flex;
    justify-content: flex-end;
  }

  .contactTags {
    margin-top: -17px;
    width: 30%;

    .tagSelect {
      width: 255px;
      font-size: 14px;

      .smallCaretDownClass {
        color: $mhh-dark-blue;
      }
    }
    .labelClass {
      margin-bottom: -3px;
      font-size: 11px;
    }
    .errorLabel {
      color: #fc5353;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-bottom: 21px;
  }
}

.input > div {
  width: 90%;

  & > input {
    background-color: transparent;
    font-weight: 600;
    font-size: 18px;
    color: #323c47;
  }
}

.mobileNumberContainer {
  margin-top: -19px;

  .label {
    font-size: 11px;
    letter-spacing: 0.1px;
    color: $colorTextualGray;
  }

  .mobileNumberInput {
    width: calc(100% - 11px) !important;
    height: 27px !important;
    background-color: transparent !important;
    border: none;
    border-bottom: 1px solid #c4c4c4 !important;
    border-radius: 0px !important;
    outline: none !important;
    color: #343434 !important;
  }

  .countrySelect {
    background-color: transparent !important;
    border: none;
    border-bottom: 1px solid #c4c4c4 !important;
    border-right: none !important;
    border-radius: 0 !important;

    div,
    div:hover {
      background-color: transparent !important;
    }
  }

  .countryDropdown {
    max-height: 100px;

    li {
      margin-top: 0 !important;
    }
  }
}

.errorMessage {
  margin: 0;
}

.saveButton {
  min-width: 80px;
  height: 35px;
  border-radius: 8px;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1px;
  user-select: none;
  box-shadow: none;
}

.cancelButton {
  @extend .saveButton;
  background: transparent;
  border: none;
  color: $mhh-dark-blue;
  align-self: center;
  margin-right: 8px;
}

:export {
  greyColor: $colorLineGray;
  blueColor: $colorBlue3;
  whiteColor: $white;
}
