.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 8px 12px;
  cursor: move;
  border: 0.5px dashed #0085bd;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;

  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #5666a6;
  }

  .input {
    border: none;
    font-weight: bold;
    font-size: 16px;
    padding: 0;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #5666a6;
  }
}

.editWrapper {
  height: 100%;

  .editContainer {
    @extend .container;
    overflow: hidden;
    background: #f8f9fd;
    cursor: auto;
  }
}

.previewContainer {
  @extend .container;
  border: 0.5px dashed transparent;
  cursor: auto;
}
