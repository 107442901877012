@import 'assets/theme';

.container {
  position: relative;
  padding-top: 16px;
}

.filterWrapper {
  display: flex;
  flex-direction: column;

  .filterLabel {
    font-size: 12px;
    line-height: 18px;
    color: #6a707e;
    padding-right: 4px;
  }

  .filter {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 30px;
    transition: all 100ms;
    box-sizing: border-box;
    border-bottom: 1px solid $colorLineGray;

    .value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .placeholder {
      color: #808080;
      margin-left: 2px;
      margin-right: 2px;
      box-sizing: border-box;
      font-size: 13px;
    }

    .icon {
      line-height: 18px;
      color: #aeaeae;
      font-size: 20px;
    }
  }
}

.dropDown {
  position: relative;
  left: 0;
  display: flex;

  .dropDownMenuHide {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 8px 0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    position: absolute;
    transition: all 0.2s;
    max-height: 200px;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    user-select: none;

    .listBox {
      display: flex;
      flex-direction: column;
      transition: all 0.2s ease;
      font-weight: 600;

      .listValue {
        display: flex;
        align-items: center;
        padding: 12px 12px;
        justify-content: space-between;

        .listLabel {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
        }

        .subLabel {
          font-size: 10px;
          line-height: 12px;
          color: #707683;
          padding-top: 2px;
        }
      }

      .listValueOther {
        @extend .listValue;
        padding-top: 24px;

        .addBtnWrapper {
          min-width: 54px;

          .addBtn {
            margin-left: 16px;
            display: flex;
            align-items: center;
            color: $colorBlue1;
            cursor: pointer;

            .icon {
              padding-right: 4px;
              font-size: 22px;
            }
          }
        }
      }
    }

    .listValueSelected {
      @extend .listBox;
      .listValue {
        .listLabel {
          color: $colorBlue1;
        }
        .subLabel {
          color: $colorBlue1;
        }
      }
    }
  }

  .dropDownMenuShow {
    @extend .dropDownMenuHide;
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
}
