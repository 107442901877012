@import 'assets/theme';

.removeButton {
  border: none;
  background-color: transparent;

  &:hover {
    color: $colorBlue1;
  }
}

.error {
  & > div {
    border-bottom: 1px solid red !important;
  }
}
