@import 'assets/theme';

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
}

.addEditClientGPModalContainer {
  .modalHeader {
    border-bottom: solid 1px #ebeff2;
    padding: 30px 30px 15px 30px;

    .header {
      display: flex;

      .title {
        flex: 1 1;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.1px;
        color: #323c47;
      }
    }

    .selectGP {
      display: flex;
      @include columnGap(20px);

      .selectGPTitle {
        display: flex;
        align-items: center;
        max-width: 175px;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #343434;
      }

      .dropdownIcon {
        color: $colorBlue1;
      }
    }
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    padding: 43px 35px 20px 30px;
    @include rowGap(30px);

    .fieldContainer {
      max-width: 400px;
    }

    .mobileNumberContainer {
      margin-top: -19px;
      .label {
        font-size: 11px;
        font-weight: 16px;
        letter-spacing: 0.1px;
        color: $colorTextualGray;
      }

      .mobileNumberInput {
        width: 100% !important;
        height: 27px !important;
        background-color: transparent !important;
        border: none;
        border-bottom: 1px solid #c4c4c4 !important;
        border-radius: 0px !important;
        outline: none !important;
        color: #343434 !important;
      }

      .countrySelect {
        background-color: transparent !important;
        border: none;
        border-bottom: 1px solid #c4c4c4 !important;
        border-right: none !important;
        border-radius: 0px !important;

        div,
        div:hover {
          background-color: transparent !important;
        }
      }

      .countryDropdown {
        max-height: 100px;

        li {
          margin-top: 0 !important;
        }
      }
    }

    .medicareProviderNumber {
      position: relative;

      .tooltip {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .saveButton {
      float: right;
      max-width: 240px;
      max-height: 48px;
      box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
      border-radius: 4px;
      margin: 0;
      align-self: flex-end;
      font-weight: 600;
      font-size: 13px;
    }
  }

  .existedGPs {
    font-size: 12px;
    line-height: 14px;
    color: #202225;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}

.loadingWrapper {
  min-height: 667px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unassigned {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ef6a6a;
}

.closeButton {
  font-size: 18px;
  color: #c2cfe0;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.confirmHeader {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px;
}

.confirmBody {
  padding: 0 32px 24px;
  display: flex;
  flex-direction: column;
  @include rowGap(40px);

  .confirmMessage {
    display: flex;
    @include columnGap(15px);
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;

    .infoIcon {
      color: #ffbc00;
    }
  }

  .actionsContainer {
    display: flex;
    justify-content: flex-end;

    .confirmNo {
      line-height: 1.5715;
      font-weight: 400;
      text-align: center;
      border: 1px solid transparent;
      box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
      cursor: pointer;
      user-select: none;
      height: 32px;
      padding: 4px 15px;
      font-size: 14px;
      border-radius: 2px;
      color: rgba(0, 0, 0, 0.85);
      background: #fff;
      border-color: #d9d9d9;
    }

    .confirmYes {
      @extend .confirmNo;
      margin-left: 10px;
      color: #fff;
      background: #1890ff;
      border-color: #1890ff;
      box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    }
  }
}
