@import 'assets/theme';

.container {
  margin-bottom: 0px !important;
  .header {
    display: flex;
    background: #f5f6ff;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-around;
    padding: 20px;
    color: $colorBlue3;
    border: 1px #e2e8f0;
    border-style: solid none;

    .checkboxField {
      flex: 0.3;
    }
    .labelField {
      flex: 1.5;
    }
    .mapsTo {
      flex: 1;
    }
    .integrationField {
      flex: 2;
    }
  }

  .subcategoryTitle {
    padding-left: 20px;
    padding-top: 10.5px;
    font-weight: 600;
    font-size: 18px;
    color: #414449;
  }

  .rowContainer {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    border-bottom: 1px solid #ebeff2;
    color: $colorBlue3;

    .label {
      display: flex;
      align-items: center;
      flex: 1.5;
      font-size: 16px;
      font-weight: 600;
    }

    .mapsTo {
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 14px;
    }

    .checkboxContainer {
      display: flex;
      align-items: center;
      flex: 0.3;

      .checkbox {
        width: 18px;
        height: 18px;
      }
    }

    .hidden {
      opacity: 0%;
    }

    .selectionContainer {
      display: flex;
      flex: 2;
      font-size: 12px;
      flex-direction: column;

      .label {
        margin-bottom: 0px;
        font-size: 12px;
        color: #61666e;
      }

      .dropdown {
        width: 280px;
        font-size: 16px;
      }

      .error {
        margin-top: 4px;
        margin-bottom: 0px;
      }
    }
  }
}
