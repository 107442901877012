@import 'assets/theme';

.countBtnWrap {
  display: flex;
  justify-content: center;

  .minusBtn {
    border: 1px solid #343434;
    font-size: 18px;
    border-radius: 50%;
    line-height: 22px;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }

  .value {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: $colorBlue1;
    width: 18px;
    height: 22px;
  }

  .plusBtn {
    @extend .minusBtn;
  }
}
