@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .selectedBox {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    border-bottom: 1px solid $colorLineGray;
    padding-bottom: 4px;

    .downIcon {
      color: $mhh-yellow;
      margin-top: -2px;
      width: 20px;
    }
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 7;

    .listHide {
      background: #fff;
      border: 1px solid #eee;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.2s ease;
      position: absolute;
      width: 100%;

      .listItem {
        padding: 2px 4px;
        cursor: pointer;

        &:hover {
          color: rgba(0, 0, 0, 0.85);
          font-weight: 600;
          background-color: #f5f5f5;
          transition: background 0.3s ease;
        }
      }

      .listItemSelected {
        padding: 2px 4px;
        cursor: pointer;
        background-color: #e6f7ff;
      }
    }

    .listShow {
      @extend .listHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }
}
