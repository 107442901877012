@import 'assets/theme';

.header {
  padding: 24px;
  border-bottom: 1px solid #ebeff2;

  .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #323c47;
  }

  .warningLabel {
    color: $main-red;
  }
}

.content {
  padding: 16px 24px;

  .error {
    color: #ff1900;
    font-size: 12px;
    margin-top: 4px;
  }

  .reportNameWrapper {
    padding: 16px 0 24px;

    .reportName {
      font-size: 24px;
      line-height: 20px;
      color: #323c47;
      font-weight: 600;
    }

    .note {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: #6a707e;
      padding-top: 4px;
    }
  }

  .secondHeader {
    padding-bottom: 16px;

    .selectClientLabel {
      font-size: 14px;
      line-height: 23px;
      color: $colorTextualBlack;
    }
  }

  .list {
    max-height: 370px;
    overflow-y: auto;
    box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: $grey-light;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 2px;
      background-color: $grey-light;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $mid-grey;
    }

    .clientListCard {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 12px 16px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 0 3px #e4e4e4;
      cursor: pointer;
      transition: all 0.3s ease;

      &:active {
        background-color: #f8f9fd;
      }

      &:hover {
        background: #fbfbfb;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }

      &:not(:first-child) {
        margin-top: 4px;
      }

      .clientList {
        display: flex;
        align-items: center;

        .clientListWrapper {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-right: 4px;
          }

          .avatarWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
          }
        }

        .name {
          font-size: 16px;
          font-weight: 600;
          line-height: 23px;
          letter-spacing: 0.1px;
          color: #323c47;
          margin-left: 8px;
        }
      }

      .checkIconWrapper {
        display: flex;

        .checkIcon {
          color: #4abd03;
          font-size: 32px;
          padding-right: 4px;
        }
      }
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    margin: 16px 0;

    .createReportBtn {
      align-self: center;
      margin-top: 24px;
      border-radius: 4px;
      box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
      font-size: 14px;
    }
  }
}
