@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: 16px 24px 16px 16px;
  @include rowGap(8px);

  @media only screen and (max-width: $medium-small-screen-min-width) {
    padding: 16px 16px 16px 11px;
  }

  .label {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: $colorTextualGray;
  }

  .profileWrapper {
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    cursor: pointer;
    padding: 10px 16px;
    background: #fcf9f9;
    color: #343434;
    border-radius: 6px;

    @media only screen and (max-width: $medium-small-screen-min-width) {
      padding: 5px 8px;
    }

    &:hover {
      background: #ededed;
    }

    .box {
      display: flex;
      align-items: center;
      @include columnGap(16px);

      .profileImg {
        background-color: #fff;
        max-width: 40px;
        border-radius: 50%;

        @media only screen and (max-width: $medium-small-screen-min-width) {
          margin-right: 5px;
        }
      }

      .defaultLogo {
        @extend .profileImg;
        height: 40px;
        width: 40px;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #c4c4c4;
        border: 2px solid #c4c4c4;
        user-select: none;
      }

      .infoBox {
        display: flex;
        flex-direction: column;
        @include rowGap(2px);
        min-width: 80px;

        @media only screen and (max-width: $medium-small-screen-min-width) {
          margin-right: 0;
        }

        .name {
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          @media only screen and (max-width: $medium-small-screen-min-width) {
            font-size: 12px;
          }
        }

        .practiceBoxContainer {
          display: flex;

          @media only screen and (max-width: $medium-small-screen-min-width) {
            display: none;
          }

          .practiceBox {
            font-size: 12px;
            line-height: 16px;
            background: #96929f;
            border-radius: 4px;
            padding: 4px 6px;
            box-sizing: border-box;
            color: #fff;
          }

          .practitionerBox {
            @extend .practiceBox;
            background: #294670;
          }
        }
      }

      .rightIcon {
        display: flex;
        justify-content: flex-end;
        flex: 1;

        @media only screen and (max-width: $medium-small-screen-min-width) {
          display: none;
        }
      }
    }
  }

  .practiceActive {
    @extend .profileWrapper;
    background: #5d576b;
    color: #fff;

    &:hover {
      background: #5d576b;
    }
  }

  .clinicianActive {
    @extend .profileWrapper;
    background: #7487a2;
    color: #fff;

    &:hover {
      background: #7487a2;
    }
  }
}
