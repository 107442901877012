.clinician-profile-avatar-editor .circular-upload {
  .ant-upload {
    display: block;
    height: 400px;
    width: 400px;
    background-color: #fafafa;
    border: 2px solid #c0c0c0;
    overflow: hidden;
    cursor: pointer;
  }
}
