@import 'assets/theme';

.card {
  background: $white;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 20px;

  &.expanded {
    padding-bottom: 20px;
  }

  .header {
    display: flex;
    align-items: center;
    padding: 27px 24px;
    cursor: pointer;

    .title {
      font-weight: 700;
      font-size: 16px;
      margin-right: 71px;
      flex: 1 20%;
    }

    .labelCol {
      flex: 1 23%;
      padding-right: 56px;
    }

    .number {
      flex: 1 4%;
      font-weight: 700;
      font-size: 16px;
      margin-right: 68px;
    }

    .barScore {
      flex: 1 39%;

      .bar {
        width: 100%;
        height: 8px;
        background: #dbdff1;
        border-radius: 10px;
        position: relative;

        .barThumb {
          height: 12px;
          position: absolute;
          left: 0;
          top: -2px;
          background-color: #1941da;
          border-radius: 20px;
        }

        .barNumberStart {
          position: absolute;
          left: -2px;
          bottom: -20px;
          font-weight: 600;
          font-size: 10px;
        }

        .barNumberEnd {
          position: absolute;
          right: -2px;
          bottom: -20px;
          font-weight: 600;
          font-size: 10px;
        }
      }
    }

    .icon {
      margin: auto 20px auto 44px;
    }
  }

  .body {
    margin-top: 8px;
  }

  .extendable {
    max-height: 800px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 21px;
    }

    &::-webkit-scrollbar-track {
      background: #f0eeee;
      border-left: 9px solid #fff;
      border-right: 9px solid #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: $mhh-light-yellow;
      border-radius: 9px;
      border-left: 6px solid #fff;
      border-right: 6px solid #fff;
    }
  }
}
