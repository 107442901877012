@import 'assets/theme';

.modalContainer {
  min-width: 800px;
  border-top: solid $white;
  border-radius: 8px;
  overflow: auto;

  & > div {
    border-radius: 8px;
    box-shadow: none;
  }

  .container {
    padding: 0 0 16px;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
  }

  .submitButtonContainer {
    display: flex;
    justify-content: flex-end;
  }
}
