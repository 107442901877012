@import 'assets/theme';

.loading {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .contentBox {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
    border-radius: 8px;
    padding: 20px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;

    .groupLead {
      display: flex;
      flex-direction: column;
    }
    .classLead {
      display: flex;
      flex-direction: column;
    }
  }
  .rowClassLead {
    @extend .row;
    align-items: center;
  }

  .title {
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #343434;
  }

  .desc {
    padding-bottom: 32px;
    font-size: 13px;
    line-height: 20px;
    color: #343434;
  }
  .leadListWrapper {
    display: flex;
    flex-direction: column;
    column-gap: 8px;
    width: 325px;
    padding-right: 24px;
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding-bottom: 24px;
    max-width: 360px;

    .practitionerItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .selectorWrapper {
        flex: auto;
        max-width: 325px;
      }

      .addBtn {
        max-width: 24px;
        flex: 1 auto;
        color: $mhh-yellow;
        margin-left: 8px;
        cursor: pointer;

        &.disabled {
          color: #c4c4c4;
          cursor: not-allowed;
        }
      }
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
  }
}
