@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .selectedBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 8px 16px;
    border-radius: 8px;
    user-select: none;
    cursor: pointer;

    .placeholder {
      color: #414449;
    }

    .icon {
      color: $colorBlue1;
    }
  }

  .selectedMaterialBox {
    @extend .selectedBox;
    border: none;
    border-bottom: 1px solid #b5b8bd;
    border-radius: 0;
    padding: 0 2px 4px 2px;
  }

  .loadingBox {
    @extend .selectedBox;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    .skeletonLoading {
      width: 100%;
    }
  }

  .materialLoadingBox {
    @extend .selectedMaterialBox;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    .skeletonLoading {
      width: 100%;
    }
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 7;

    .listHide {
      background: #fff;
      border: 1px solid #eee;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.2s ease;
      position: absolute;
      width: 100%;

      .itemWrapper {
        max-height: 300px;
        overflow: auto;

        .emptyBlank {
          display: flex;
          flex-direction: column;
          margin-top: 8px;
          margin-bottom: 12px;

          .item {
            cursor: pointer;
            color: #414449;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            padding: 8px 16px;

            &:hover {
              color: rgba(0, 0, 0, 0.85);
              font-weight: 600;
              background-color: #f5f5f5;
              transition: background 0.3s ease;
            }
          }
        }

        .anytimeStageWrapper {
          display: flex;
          flex-direction: column;

          .label {
            font-weight: 700;
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: #b5b8bd;
            padding: 0 16px;
          }

          .anytimeStageItem {
            @extend .item;
            padding: 8px 16px;
            cursor: pointer;

            &:hover {
              color: rgba(0, 0, 0, 0.85);
              font-weight: 600;
              background-color: #f5f5f5;
              transition: background 0.3s ease;
            }
          }
        }

        .stageTypeContainer {
          display: flex;
          flex-direction: column;

          .stageContainer {
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;

            .label {
              font-weight: 700;
              font-size: 10px;
              line-height: 15px;
              letter-spacing: 0.08em;
              text-transform: uppercase;
              color: #b5b8bd;
              padding: 0 16px;
            }

            .stageOptions {
              display: flex;
              flex-direction: column;

              .stageItem {
                font-weight: 600;
                font-size: 14px;
                color: #414449;
                cursor: pointer;
                padding: 8px 16px;

                &:hover {
                  color: rgba(0, 0, 0, 0.85);
                  font-weight: 600;
                  background-color: #f5f5f5;
                  transition: background 0.3s ease;
                }
              }
            }
          }
        }
      }

      .itemSelected {
        cursor: pointer;
        background-color: #e6f7ff;
        padding: 8px 16px;
        font-weight: 600;
      }
    }

    .listShow {
      @extend .listHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }
}
