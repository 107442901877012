@import 'assets/theme';

.loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 500px;
}

.boxContainer {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px;

  .container {
    display: flex;
    flex-direction: column;
    @include rowGap(48px);
    flex: 1;
    margin-bottom: 48px;
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: #323c47;
      padding-bottom: 20px;
    }

    .clientInfoBox {
      display: flex;
      flex-direction: column;

      .clientInfoWrapper {
        background: $white;
        box-shadow: 0 2px 16px rgb(0, 0, 0, 10%);
        padding: 16px;
        margin-bottom: 16px;
      }
    }
  }
}
