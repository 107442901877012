@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .widgetCard {
    display: flex;
    flex-direction: column;
    background: $white;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-top: 32px;

    .widgetGroup {
      padding: 16px 12px;
      align-items: center;

      &:not(:first-child) {
        border-top: 1px solid #ebeff2;
      }

      .widgetMenu {
        display: flex;
        flex-direction: column;
      }

      .widgetTitle {
        display: flex;
        font-weight: 600;
        font-size: 16px;
        color: $colorTextualBlack;
        padding-bottom: 8px;
        border-bottom: 1px solid #ebeff2;

        .titleIcon {
          color: #c2cfe0;
          padding-right: 4px;
          position: relative;
          font-size: 24px;
        }
      }
    }
  }
}

.widgetThumb {
  display: flex;
  align-items: center;
  background: #f8f9fd;
  border: 1px solid #e6e9f2;
  box-sizing: border-box;
  border-radius: 8px;
  color: #5666a6;
  padding: 10px 12px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #fbfbfb;
    border: 1px solid #e6e9f2;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.inputHeadingWidget {
  @extend .widgetThumb;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.inputBodyWidget {
  @extend .widgetThumb;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.inputNotesWidget {
  @extend .widgetThumb;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
}

.widgetWrapper {
  margin-top: 16px;
}
