@import 'assets/theme';

.label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: #343434;
  margin-bottom: 4px;
}

.container {
  width: calc(100% - 12px);
  display: flex;
  margin-bottom: 16px;

  div {
    width: 60px;
    margin-right: 4px;

    input {
      height: 60px;
      padding-left: 8px;
      padding-right: 8px;
      background: #f8f9fd;
      border: 1px solid #34343480;
      border-radius: 8px;
      text-align: center;
      transition: border 0.1s linear;

      &:active,
      &:focus {
        border-color: $mhh-dark-blue;
      }
    }
  }
}

.verifying {
  div {
    input {
      animation: verifying 0.7s ease-in infinite;
    }

    &:nth-of-type(2) {
      input {
        animation-delay: 0.1s;
      }
    }

    &:nth-of-type(3) {
      input {
        animation-delay: 0.2s;
      }
    }

    &:nth-of-type(4) {
      input {
        animation-delay: 0.3s;
      }
    }

    &:nth-of-type(5) {
      input {
        animation-delay: 0.4s;
      }
    }

    &:nth-of-type(6) {
      input {
        animation-delay: 0.5s;
      }
    }
  }
}

@keyframes verifying {
  0% {
    background-color: #f8f9fd;
  }
  50% {
    background-color: #efffd7;
  }
}

.verified {
  div {
    input {
      border: 1px solid $mhh-green;
    }
  }
}

.errored {
  div {
    input {
      border: 1px solid $mhh-red;
      animation: shake 0.15s;
    }
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.formattedMobileNumber {
  font-weight: bold;
  color: $mhh-yellow;
}
