.container {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  .descriptionContainer {
    margin: 24px 20px;

    .label {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #5666a6;
    }

    .description {
      margin-top: 8px;
      font-size: 14px;
      line-height: 19px;
      color: #00000080;
      word-wrap: break-word;
    }
  }
}
