@import 'assets/theme';

.container {
  display: flex;
  align-items: center;
  @include columnGap(12px);
}

.containerVertical {
  display: flex;
  flex-direction: column;
  @include rowGap(12px);
}

.costInputWrapper {
  display: flex;

  .costInput {
    height: 32px;
  }

  .verticalInput {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .rateTypeContainer {
    display: flex;
    position: relative;
    width: 130px;

    .rateTypeSession {
      margin-top: 21px;
      display: flex;
      width: 100%;
      position: absolute;
    }
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  @include columnGap(8px);

  .checkbox {
    appearance: none;
    margin: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid #d5d5d5;
    border-radius: 4px;
    color: #d5d5d5;
    cursor: pointer;
    outline: none;
    transition: all 0.1s linear;

    &:after {
      content: '\2713';
      display: none;
      justify-content: center;
      align-items: center;
      width: 16px;
    }

    &:checked {
      font-size: 12px;
      line-height: 16px;
      font-weight: 800;
      color: #fff;
      background-color: $colorBlue1;
      border: 2px solid $colorBlue1;

      &:after {
        display: flex;
      }
    }
  }
  .label {
    margin-left: 8px;
    font-size: 12px;
  }
}

:export {
  greyColor: $colorLineGray;
}
