@import 'assets/theme';

.dayTimeSelections {
  .header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 12px;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorTextualGray;

    span {
      &:first-child {
        width: 32%;
        margin-right: 6%;
      }

      &:nth-child(2),
      &:last-child {
        width: 22%;
        margin-right: 3%;
      }
    }
  }
}

.errorText {
  color: red;
  padding-left: 12px;
}
