@import 'assets/theme';

.loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 200px;
}

.modalContainer {
  width: 100% !important;
  max-width: 700px;

  .title {
    display: flex;
    align-items: flex-end;
    font-weight: bold;
    font-size: 18px;
    padding: 2px 0;
  }
}

.container {
  .secondHeaderTitle {
    font-size: 16px;
    line-height: 23px;
    color: $colorTextualBlack;
    font-weight: 700;
  }
  .itemWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 12px -12px;
    padding: 8px 12px;

    &.disabled {
      background: #edf0f4;
      border-radius: 4px;
      cursor: not-allowed;
      user-select: none;

      .avatar,
      .nameContainer {
        opacity: 0.5;
      }
    }

    .checkBox {
      flex: 50%;
      min-height: 50px;
      width: 100%;
      align-items: center;

      .checkBoxLabel {
        display: flex;
        flex-direction: row;
        align-items: center;

        .avatar {
          margin-left: 8px;
          height: 50px;
          width: 50px;
        }

        .clientLabel {
          flex: 1;
          display: flex;
          align-items: center;
          margin-left: 16px;

          .nameContainer {
            justify-content: center;
          }

          .note {
            padding-top: 2px;
            color: $colorTextualGray;
            font-size: 13px;
          }
        }
      }
    }

    .selectContainer {
      flex: 50%;

      .note {
        color: $colorTextualGray;
        font-size: 13px;
        font-style: italic;
      }

      .profileSelect {
        margin-top: 8px;
      }
    }
  }
}

.backBtn {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: $colorBlue1;
  cursor: pointer;

  .icon {
    font-size: 20px;
  }

  &.disabled {
    color: gray;
    cursor: not-allowed;
  }
}

.step2Container {
  padding-left: 16px;

  .profileWrapper {
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;

    .profileSurvey {
      display: flex;
      flex-direction: row;
      margin: 8px;
      margin-bottom: 16px;
      align-items: center;
      justify-content: left;

      .profile {
        flex: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .avatar {
          margin-left: 8px;
          height: 40px;
          width: 40px;
        }

        .name {
          flex: 1;
          margin-left: 16px;
        }
      }

      .surveyNames {
        flex: 50%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .howToComplete {
    .title {
      margin-bottom: 10px;
    }

    margin-bottom: 20px;
  }

  .addNoteTitle {
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: $colorTextualBlack;
    margin-top: 8px;
    padding-bottom: 8px;
    display: flex;
  }
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.isGetLinkSuccessful {
  p {
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.1px;
    color: #343434;
    margin-bottom: 50px;
    margin-top: 30px;
  }
}

.linkToSurvey {
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .name {
    text-transform: uppercase;
    color: $colorBlue1;
  }
}

.linkContainer {
  display: flex;
  align-items: center;
  @include columnGap(20px);

  .value {
    max-width: 380px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .copyBtn {
    display: flex;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      color: #2f42ef;
    }

    .icon {
      font-size: 18px;
    }
  }

  .openLinkInNewTab {
    min-width: 200px;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $colorBlue1;
    border: 1px solid $colorBlue1;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;

    .icon {
      padding-right: 6px;
    }
  }
}

.newSurveyBtn {
  display: flex;
  align-items: center;
  color: $colorBlue1;
  cursor: pointer;
  margin-top: 40px;

  .newSurveyLabel {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-left: 8px;
  }
}

.getLinkTitle {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #343434;
}
