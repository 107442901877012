@import 'assets/theme';

.container {
  padding: 24px 16px;
  border-bottom: 1px solid #ebeff2;

  @media (max-width: $medium-screen-min-width) {
    padding: 16px;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: $colorTextualBlack;

    @media (max-width: $medium-screen-min-width) {
      font-size: 16px;
    }
  }

  .paymentMethodsContainer {
    margin-top: 16px;

    @media (max-width: $medium-screen-min-width) {
      margin-top: 4px;
    }

    .paymentMethod {
      padding: 8px;
      padding-left: 36px;
      position: relative;

      .icon {
        position: absolute;
        left: 0;
        color: $colorBlue1;
      }

      .paymentType {
        font-size: 15px;
        line-height: 23px;
        letter-spacing: 0.1px;
        color: #343434;
      }

      .description {
        font-size: 14px;
        line-height: 19px;
        color: #00000080;
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    margin-top: 16px;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 14px;

    @media (max-width: $large-screen-min-width) {
      max-width: 270px;
      padding: 12px 16px;
    }

    @media (max-width: $medium-screen-min-width) {
      padding: 8px 12px;
      font-size: 12px;
      margin-top: 8px;
    }

    .icon {
      margin-right: 12px;
    }
  }
}
