.container {
  width: 300px;
}

.hideContainer {
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  left: -5000px;
  overflow: hidden;
}
