@import 'assets/theme';

.container {
  display: flex;
  flex: 1;
  position: relative;
  padding-top: 16px;
}

.textArea:focus,
.textArea:not(:focus):valid {
  ~ .label {
    top: 0;
    font-size: 11px;
    color: $colorTextualGray;
    opacity: 1;
    font-weight: 600;
  }
}

.textArea:focus {
  color: $colorTextualBlack;
  border-color: $colorTextualGray;

  ~ .label {
    color: $colorBlue1;
  }
}

.textArea {
  display: flex;
  width: 100%;
  color: #000;
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
  overflow-wrap: break-word;
  resize: none;
  padding: 0 0 4px;
  border-style: none;
  border-bottom: 1px solid $colorLineGray;
  outline: none;
  overflow: hidden;
  transition: box-shadow 0.1s ease-out 0s;
  -webkit-appearance: none;
  border-radius: 0;

  &.error {
    border-bottom: 1px solid #ff1900;
    margin-bottom: 0;

    ~ .label {
      color: #ff1900;
    }
  }
}

.label {
  position: absolute;
  pointer-events: none;
  font-size: 16px;
  top: 18px;
  color: $colorTextualGray;
  font-weight: 600;
  transition: 0.2s ease all;
}
