@import 'assets/theme';

.section {
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px;
  color: $black;
  padding-bottom: $standard-padding;
}

.question {
  font-weight: 600;
  font-size: 14px;
  color: #343434;
  padding-bottom: 4px;
}
