.container {
  min-height: 64px;
  width: 720px;
  display: flex;
  flex: 1 1;
  border-bottom: solid 1px #ebeff2;
}

.mainInfo {
  display: flex;
  flex: 1 1;
  align-items: center;
  max-width: 50%;
  padding-left: 15px;

  .avatar {
    width: 25px;
    height: 25px;
    max-width: 25px;
    border-radius: 50%;
    overflow: hidden;

    img,
    .roomAvatar {
      height: 100%;
      width: 100%;
    }
  }

  .name {
    width: 180px;
    padding-left: 15px;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.0719101px;
    color: #343434;
  }

  .date {
    width: 140px;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #343434;
  }
}

.availableSlots {
  padding-top: 17px;
  padding-bottom: 17px;
}

.availableTimeRange {
  display: flex;
  align-items: center;
}
