@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .emailSuccessWrapper {
    padding-bottom: 24px;
  }

  .label {
    font-size: 16px;
    color: $colorTextualBlack;
    padding-bottom: 8px;
    font-weight: 600;
  }

  .emailList {
    display: flex;
    flex-wrap: wrap;

    .emailSuccessItem {
      background-color: $colorBlue3;
      color: #fff;
      height: 40px;
      padding: 12px 20px;
      font-size: 14px;
      border-radius: 40px;
      padding: 12px 20px;
      display: inline-flex;
      align-items: center;
      margin: 0 8px 8px 0;
    }

    .emailFailItem {
      @extend .emailSuccessItem;
      background-color: #eee;
    }
  }

  .resetBtnWrapper {
    display: flex;
    margin: 16px 0;
    justify-content: flex-end;

    .createNewPractitionerBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $colorBlue1;
      cursor: pointer;
      padding: 8px;
      border: 1px solid $colorBlue1;
      border-radius: 6px;

      .newClientLabel {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        margin-left: 8px;
      }
    }
  }
}
