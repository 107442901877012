.container {
  padding: 2px 8px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.connected {
  background: #25be69;
}

.disconnected {
  background: #c7c9cd;
}

.pending {
  background: #ff9d73;
}
