@import 'assets/theme';

.container {
  min-width: 720px;
  background: #fff;
  box-shadow: 0 2px 16px #0000001a;
  border-radius: 8px;

  .header {
    padding: 16px 24px;
    border-bottom: 1px solid #ebeff2;

    .headerTitle {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.1px;
      line-height: 28px;
      color: $colorTextualBlack;
    }

    .headerDesc {
      font-size: 13px;
      letter-spacing: 0.1px;
      line-height: 20px;
      color: #323c4780;
    }
  }

  .content {
    padding: 16px 24px;

    .fieldBoxWrapper {
      display: flex;
      flex-direction: column;

      &:first-child {
        margin-bottom: 32px;
      }

      .labelWrapper {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;

        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
        }

        .desc {
          font-size: 13px;
          line-height: 20px;
          color: #90a0b7;
        }
      }

      .fieldContainer {
        display: flex;
        flex-direction: column;
        flex: 1;

        .fieldLabelWrapper {
          display: flex;
          align-items: center;
          padding-bottom: 4px;

          .fieldLabel {
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: $colorTextualGray;
          }
        }

        .caseLoadField {
          border: 0;
          border-bottom: 1px solid #c4c4c4;
          color: $colorTextualBlack;
          outline: none;
          transition: 0.2s ease-in-out;
          box-sizing: border-box;
          width: 100%;
          height: 30px;
          font-size: 16px;
          max-width: 200px;

          &::placeholder {
            font-size: 14px;
            color: $mid-grey;
          }

          &:valid {
            border-bottom: 1px solid #c4c4c4;
            + {
              .label {
                color: $colorTextualGray;
                font-size: 11px;
                font-weight: 600;
                left: 2px;
                bottom: 22px;
                pointer-events: none;
              }
            }
          }
          &:focus {
            border-bottom: 1px solid #c4c4c4;
            + {
              .label {
                color: $colorTextualGray;
                font-size: 11px;
                font-weight: 600;
                left: 2px;
                bottom: 22px;
                pointer-events: none;
              }
            }
          }
        }

        &.fieldError,
        .fieldError {
          font-size: 12px;
          color: #ff4d4f;
          padding-top: 2px;

          input {
            border-bottom: 1px solid #ff4d4f !important;
          }
        }
      }
    }

    .submitButtonContainer {
      display: flex;
      justify-content: flex-end;
      padding-top: 50px;
    }
  }
}
