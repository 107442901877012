@import 'assets/theme';

.noSurveyFoundContainer {
  display: flex;
  padding: 24px 16px;
  border-bottom: 0.5px solid #ecedef;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;

  .header {
    background: #f9f9ff;
    padding: 16px 0;
    border-bottom: solid 1px #e2e8f0;
    border-top: solid 1px #e2e8f0;
  }

  .loadingWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include rowGap(8px);

    .loading {
      width: 100%;
      border-radius: 4px;
      padding: 0 16px;
      height: 20px;
    }
  }

  .listItem {
    padding: 24px 0;
    border-bottom: 0.5px solid #ecedef;
    align-items: center;

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .isSentText,
    .isCompleteText {
      font-size: 14px;
      line-height: 21px;
      @include columnGap(12px);
    }

    .noValue {
      display: flex;
      padding: 0 8px;
      justify-content: center;
    }

    .remindIcon {
      color: $colorBlue3;
      padding: 4px;
      border-radius: 50%;
      cursor: pointer;
    }

    .detailIcon {
      @extend .remindIcon;
      border: 2px solid $colorBlue3;
    }
  }

  .paginationWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    .recordPerPageWrapper {
      display: flex;
      align-items: center;
      color: #64748b;

      .label {
        margin-left: 4px;
      }
    }
  }
}
