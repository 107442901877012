@import 'assets/theme/index';

.headerContainer {
  display: flex;
  align-items: center;
  padding: $standard-padding * 2 0;
  justify-content: space-between;

  .titleWrapper {
    display: flex;
    flex-direction: column;
    padding-right: 24px;
    line-height: 46px;

    .title {
      font-weight: 600;
      font-size: 22px;
      letter-spacing: 0.01em;
      color: $colorTextualBlack;
    }

    .backBtnWrapper {
      display: flex;

      .backBtn {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        line-height: 30px;
        color: $mhh-dark-blue;

        .icon {
          font-size: 30px;
          width: 20px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
