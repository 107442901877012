@import 'assets/theme';

.headerContainer {
  display: flex;
  flex: 1 1;
  font-size: 12px;
  color: $mid-grey-medium;
  font-weight: 400;

  .connectDomain {
    flex: 35%;
  }

  .connectSetting {
    flex: 35%;
  }

  .connectStatus {
    flex: 30%;
  }
}

.buttonContainer {
  display: flex;
  padding-top: 25px;
  justify-content: space-between;

  .saveButton {
    max-width: 250px;
    margin: 0px;
  }
}
