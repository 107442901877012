@import 'assets/theme';

.container {
  display: grid;
  grid-template-columns: 250px auto;
  align-items: stretch;
  @include rowGap(8px);
}

.cell {
  display: flex;
  align-items: flex-end;
}

.label {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.20000000298023224px;
  color: $colorTextualGray;
  padding: 12px;
}

.durationText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.10000000149011612px;
  color: $colorTextualBlack;
  padding: 0 12px;

  .duration {
    font-weight: 700;
  }

  .durationMinutes {
    font-weight: 600;
  }
}

.errorText {
  color: red;
  padding-left: 12px;
  width: 280px;
}
