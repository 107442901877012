.inputContainer {
  margin: 16px 0;

  .inputField {
    height: 31px;
  }
}

.headingField {
  width: 30%;

  &:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 16px;
  }

  .error {
    margin-bottom: 0;
  }
}
