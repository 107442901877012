@import 'assets/theme';

.container {
  display: flex;
  background: #fff;
  box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 20px;
  color: #414449;

  @media (max-width: $medium-small-screen-min-width + 100) {
    flex-direction: column;
  }

  .detailContainer {
    display: flex;
    flex-direction: column;
    flex: 1 2 60%;
    margin-right: 20px;

    @media (max-width: $medium-small-screen-min-width + 100) {
      margin-right: 0;
      margin-bottom: 48px;
    }
  }

  .detailWrapper {
    display: flex;
    margin-bottom: 16px;
  }

  .label {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin-right: 4px;
  }

  .value {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .statusWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .label {
      margin-right: 16px;
    }
  }

  .nameWrapper {
    @extend .detailWrapper;

    .label {
      font-size: 18px;
      line-height: 25px;
      font-weight: 400;
    }

    .value {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
    }
  }

  .idWrapper {
    @extend .detailWrapper;
  }

  .createdByWrapper {
    @extend .detailWrapper;
  }

  .interventionLibraryItemWrapper {
    @extend .detailWrapper;
  }

  .descWrapper {
    @extend .detailWrapper;
    flex-direction: column;
  }

  .tagsWrapper {
    margin-top: 20px;
  }
}
