@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  @include rowGap(20px);

  .titleContainer {
    display: flex;

    .title {
      font-weight: 600;
      font-size: 18px;
      color: #414449;
    }
  }

  .contentContainer {
    .loading {
      width: 100%;
      height: 200px;
    }

    .noProviderContainer {
      display: flex;
      align-items: center;
      @include columnGap(40px);

      .noProviderIcon {
        height: 100px;
      }

      .noProviderMessage {
        font-weight: 600;
        font-size: 15px;
        color: #414449;
      }
    }
  }
}
