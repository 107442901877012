@import 'assets/theme';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px;

  .contentContainer {
    display: flex;
    padding-top: 24px;

    .descriptionBox {
      display: flex;
      flex-direction: column;
      border-right: 2px solid #ebeff2;
      padding-right: 24px;
      flex: 1 2 35%;
    }

    .title {
      font-weight: bold;
      font-size: 14px;
      color: $black;
      padding-bottom: 12px;
    }

    .descWrapper {
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;

      .desc {
        font-size: 12px;
        color: $black;
      }
    }
  }
}
