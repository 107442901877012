@import 'assets/theme';

.containerWrapper {
  min-height: 100%;
}

.loadingTitle {
  max-width: 200px;
  padding: 8px 0 32px;
}

.loadingHeaderDesc {
  max-width: 280px;
  padding-bottom: 40px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 16px 0 120px;

  .title {
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;
    margin: 8px 0 32px;
    color: #202225;
    opacity: 0.75;
  }

  .headerDetails {
    display: flex;
    align-items: center;
    @include columnGap(20px);

    .date {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }

    .labelField {
      display: flex;
      align-items: center;
      @include columnGap(20px);

      .labelInput {
        max-width: 280px;
        background-color: transparent;
      }

      .labelSaveBtn {
        height: 30px;
        min-width: 102px;
        padding: 12px;
        box-shadow: none;
      }
    }
  }

  .backBtnWrapper {
    padding: 24px 0;
  }

  .sessionBoxWrapper {
    display: flex;
    @include columnGap(40px);
  }
}
