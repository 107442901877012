@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;

  .selectedBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 8px 10px;
    border-radius: 8px;
    user-select: none;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    .closeIcon {
      color: $main-red;
    }

    .selectLabel {
      color: #414449;
      font-size: 16px;
    }

    .icon {
      color: $colorBlue1;
    }
  }

  .selectedMaterialBox {
    @extend .selectedBox;
    border: none;
    border-bottom: 1px solid #b5b8bd;
    border-radius: 0;
    padding: 0 2px 4px 2px;
  }

  .loadingBox {
    @extend .selectedBox;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    .skeletonLoading {
      width: 100%;
    }
  }

  .materialLoadingBox {
    @extend .selectedMaterialBox;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    .skeletonLoading {
      width: 100%;
    }
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 7;

    .listHide {
      background: #fff;
      border: 1px solid #eee;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.2s ease;
      position: absolute;
      width: 100%;

      .itemWrapper {
        max-height: 200px;
        overflow: auto;

        .item {
          display: flex;
          padding: 12px 10px;
          padding-right: 14px;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          &:not(:first-child) {
            border-top: 1px solid #dadbde;
          }

          &:hover {
            color: rgba(0, 0, 0, 0.85);
            font-weight: 600;
            background-color: #f5f5f5;
            transition: background 0.3s ease;
          }

          .checkbox {
            width: 15px;
          }
        }
      }

      .itemSelected {
        padding: 8px 10px;
        cursor: pointer;
        background-color: #e6f7ff;
      }
    }

    .listShow {
      @extend .listHide;
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
  }
  .initialName {
    font-weight: 600;
    font-size: 16px;
  }

  .clientName {
    font-weight: 600;
  }

  .error {
    color: #ff1900;
    font-size: 12px;
    margin-top: 4px;
  }
}
