@import 'assets/theme';

.container {
  background-color: $mhh-dark-blue-10;
  padding: 0 12px;
  border-radius: 50px;

  .wrapper {
    display: flex;
    position: relative;
    min-width: 200px;
    height: 64px;

    input[type='radio'] {
      display: none;
    }

    .pillWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
      cursor: pointer;

      .label {
        display: flex;
        align-items: center;
        z-index: 1;
        font-weight: 600;
        color: $mhh-dark-blue;
        padding: 0 16px;
        margin: 0 6px;
        cursor: pointer;
        font-size: 16px;
        line-height: 27px;
        user-select: none;

        .count {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          font-weight: bold;
          font-size: 14px;
          background: $mhh-blurb-grey-100;
          border-radius: 40px;
          padding: 4px 8px;
          height: 24px;
          margin-left: 8px;
        }
      }

      .labelActive {
        @extend .label;
        color: $white;
        font-weight: bold;

        .count {
          background: $mhh-yellow;
          color: $mhh-dark-blue;
        }
      }
    }

    .pillWrapperDisable {
      @extend .pillWrapper;
      opacity: 0.8;
      z-index: 2;
      pointer-events: none;
    }

    .indicator {
      position: absolute;
      top: 6px;
      left: 0;
      padding: 0;
      background-color: $mhh-dark-blue;
      background-image: linear-gradient(rgba(255, 255, 255, 0.2), transparent);
      transition: all 0.2s ease-out;
      box-shadow: 0 4px 2px rgba(0, 0, 0, 0.1);
      border-radius: 25px;
      height: 50px;
    }
  }
}
