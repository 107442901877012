@import 'assets/theme';

.container {
  display: flex;
  column-gap: $standard-padding * 2;
}

.iconContainer {
  padding-top: $standard-padding;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  row-gap: $standard-padding * 2;
}

.field {
  display: flex;
  @include columnGap($standard-padding * 2);
  align-items: center;

  & > span {
    color: $colorBlue3;
    font-size: 20px;
  }
}

.timeField {
  display: flex;
  @include columnGap($standard-padding);
}

.recurringField {
  display: flex;
  align-items: baseline;
  @include columnGap($standard-padding * 2);

  label {
    align-self: center;
  }
}
