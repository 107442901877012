@import 'assets/theme';

.detailIcon {
  color: $colorBlue3;
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid $colorBlue3;
  user-select: none;
}

.disabledDetailIcon {
  @extend .detailIcon;
  opacity: 0.2;
  cursor: not-allowed;
  border: 2px solid $colorBlue3;
}

.menuWrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 7;
  top: 18px;

  .menuHide {
    background: #fff;
    visibility: hidden;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.2s ease;
    position: absolute;
    width: 100%;
    min-width: 180px;
    border: 1px solid #ecedef;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding-top: 8px;
    padding-bottom: 8px;

    .label {
      margin-left: 8px;
    }
  }

  .menuShow {
    @extend .menuHide;
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 0.2s ease;
  }
}

.itemCard {
  &.disabledSubMenu {
    cursor: not-allowed;
    opacity: 0.2;
  }
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  transition: background 0.3s ease;
  justify-content: space-between;

  &:hover {
    background-color: #f5f6ff;
  }

  .itemIcon {
    font-size: 16px;
    color: #61666e;
  }

  .label {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #414449;
  }
}
