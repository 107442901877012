@import 'assets/theme';

.filterWrapper {
  display: flex;
  align-items: center;
  padding: 4px 0 8px;

  .filterLabel {
    font-size: 12px;
    line-height: 18px;
    color: #6a707e;
    padding-right: 4px;
  }

  .filter {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: $mhh-yellow;
    cursor: pointer;
    user-select: none;

    .icon {
      line-height: 18px;
      color: #aeaeae;
      font-size: 20px;
    }
  }
}

.dropDown {
  position: relative;
  display: flex;
  left: 0;
  bottom: 6px;

  .dropDownMenuHide {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 8px;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    position: absolute;
    transition: all 0.2s;
    max-height: 200px;
    overflow: auto;
    visibility: hidden;
    opacity: 0;

    .listBox {
      display: flex;
      padding: 8px 12px;
      cursor: pointer;
      transition: all 0.2s ease;
      font-weight: 600;

      &:hover {
        background-color: #eee;
      }
    }
  }

  .dropDownMenuShow {
    @extend .dropDownMenuHide;
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
}
