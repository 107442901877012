@import 'assets/theme';

.container {
  padding: 4px 8px;
  display: flex;
  @include columnGap(4px);
  justify-content: center;

  background-color: $mhh-dark-blue-10;
  border-radius: 24px;
}

.button {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;

  padding: 12px 32px;
  border: 0;
  background-color: transparent;
  border-radius: 24px;

  &.active,
  &:hover {
    background-color: $mhh-dark-blue;
    color: $white;
  }

  &.compact {
    font-size: 12px;
    padding: $standard-padding $standard-padding * 2;
  }
}
