@import 'assets/theme';

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #414449;
  margin-bottom: 16px;
}

.fieldContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;

  .tagWrapper {
    display: flex;
    flex: 50%;
    padding: 0 8px 16px 0;

    .checkBoxWrapper {
      display: flex;
      align-items: center;
    }

    .checkBox {
      border: 2px solid #414449;

      &:checked {
        border: 2px solid $mhh-yellow;
      }
    }

    .label {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .activeLabel {
      @extend .label;
      color: $mhh-yellow;
    }
  }
}
