@import 'assets/theme';

.container {
  display: flex;

  .activeWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $mhh-yellow;
    margin-right: 16px;
  }

  .openSlotWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $mhh-dark-blue;
  }

  .value {
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 8px;
  }

  .label {
    font-weight: 600;
    font-size: 11px;
    color: $mhh-dark-blue-50;
  }
}
