@import 'assets/theme';
.gpDetailsContainer {
  display: flex;
  flex-direction: column;
  @include rowGap(20px);

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: #323c47;
  }

  .noGPTitle {
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $black;
    opacity: 0.5;
  }
}
