@import 'assets/theme';

.label {
  display: flex;
  align-items: center;
  position: absolute;
  top: 24px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 16px;
  color: $colorTextualGray;

  &.onFocus {
    top: 0;
    font-size: 11px;
    font-weight: 600;
    pointer-events: none;
    transition: 0.2s ease-in-out;
  }

  &.onBlur {
    top: 24px;
    pointer-events: none;
    transition: 0.2s ease-in-out;
  }
}

.icon {
  font-size: 20px;
  margin-left: 4px;
  line-height: 19px;
  pointer-events: auto;
}
