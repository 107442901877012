@import 'assets/theme';

.container {
  padding: 20px 24px 110px;
  margin: auto;
  min-height: 100vh;
}

.title {
  font-weight: 600;
  font-size: 28px;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 24px;

  .filterWrapper {
    display: flex;
    flex-direction: column;

    .filterWrapperTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 30px;
      letter-spacing: 0.1px;
      color: $mhh-blurb-grey-100;
    }

    .filterWrapperTags {
      display: flex;

      .tagWrapper {
        min-width: 120px;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  .searchBarWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .searchBarTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 30px;
      letter-spacing: 0.1px;
      color: $mhh-blurb-grey-100;
    }

    .searchBarCard {
      min-width: 400px;
    }
  }
}

.cardContainer {
  display: flex;
  flex-direction: column;

  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }

  .readMoreDescription {
    color: $mhh-dark-blue;
    font-weight: 400;
    font-size: 14px;
  }
}

.content {
  font-weight: 400;
  font-size: 14px;
  background-color: $white;
  padding-bottom: 1px;
  box-shadow: 0 6px 18px rgb(0 0 0 / 6%);
  border-radius: 8px;

  .headerContainer {
    font-weight: 600;
    font-size: 12px;
    padding: 24px;
  }

  .itemButton {
    height: 34px;
    width: 34px;
    border-radius: 34px;
    border: solid 2px $mhh-dark-blue;
    color: $mhh-dark-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
  }

  .checkIcon {
    background-color: $mhh-green;
    color: #fff;
    border-radius: 50%;
    font-size: 24px;
    font-weight: bold;
    padding: 4px;
    margin-right: 8px;
  }

  .noCheckIcon {
    @extend .checkIcon;
    background-color: $mhh-dark-blue-10;
  }

  .internalStaff {
    display: flex;
    align-items: center;
  }
}

.noData {
  padding: 24px;
}
