@import 'assets/theme/index';

.containerWrapper {
  height: 100%;

  .editContainer {
    background: #f8f9fd;

    :global {
      .ql-toolbar {
        padding: 8px 12px;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 8px 12px;
  cursor: move;
  border: 0.5px dashed #0085bd;
  border-radius: 4px;
  line-height: 22px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $grey-light;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: $grey-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $mid-grey-medium;
  }

  & > * {
    cursor: move;
  }

  img {
    cursor: move !important;
    max-width: 100%;
  }
}

.previewContainer {
  @extend .container;
  border: 0.5px solid transparent;
  cursor: auto;
  background: transparent;
  padding: 0;

  .wrapper {
    padding: 8px 12px;
  }

  & > * {
    cursor: auto;
  }

  img {
    cursor: auto !important;
    max-width: 100%;
  }
}
