@import 'assets/theme';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  border-radius: 16px;
  padding: 4px 12px;
}

.activeBadge {
  @extend .container;
  background: #66cb91;
  color: #fff;
}

.completeBadge {
  @extend .container;
  background: #426eb1;
  color: #fff;
}

.archiveBadge {
  @extend .container;
  background: #ff9d73;
  color: #fff;
}
