@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 33px;
}

.calendarLinkingsContainer {
  display: flex;
  flex-direction: column;
  @include rowGap(15px);

  .loading {
    display: flex;
    min-height: 250px;
    align-items: center;
    justify-content: center;
  }
}

.addButton {
  max-width: 250px;
}
