@import 'assets/theme';

.container {
  margin-bottom: 16px;

  .editContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 32px;
    background: #f8f9fd;
    box-shadow: 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-bottom: 16px;
    position: relative;

    .fieldContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-bottom: 24px;
      width: 50%;
      input {
        background: #f8f9fd;
      }

      &.fieldError,
      .fieldError {
        font-size: 12px;
        color: #ff4d4f;
        input {
          border-bottom: 1px solid #ff4d4f !important;
        }
      }
    }

    .dueDateWrapper {
      .label {
        font-size: 12px;
        color: $colorTextualGray;
        line-height: 16px;
        letter-spacing: 0.2px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .dateInput {
        width: 50%;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        padding: 8px 12px;
        background: #f8f9fd;
        input {
          font-weight: 600;
        }
      }
    }

    .deleteButton {
      position: absolute;
      color: #ff4d4f;
      cursor: pointer;
      top: 32px;
      right: 32px;
    }

    .addButton {
      position: absolute;
      min-width: 96px;
      max-height: 36px;
      bottom: 32px;
      right: 32px;
      background-color: $mhh-yellow;
      color: $colorTextualBlack;
      box-shadow: none;
    }

    .editModeError {
      position: absolute;
      bottom: 0;
      right: 32px;
    }
  }

  .highlightTask {
    padding: $standard-padding * 1.5 - 1 $standard-padding * 2 - 1;
    background-color: $mhh-lightest-yellow;
    border: 1px solid $mhh-lightest-yellow;

    .completedIcon {
      color: $mhh-dark-blue;
    }

    .chevronRightIcon {
      display: block;
    }
  }

  .viewContainer {
    display: flex;
    border-radius: $standard-padding * 0.5;
    border-color: $mhh-lightest-yellow;
    transition: all 0.1s ease-in-out;
    position: relative;
    cursor: pointer;

    &.selectedTask {
      @extend .highlightTask;
      border-color: #bbc3ce;
    }

    &:hover {
      @extend .highlightTask;
    }

    .icon {
      margin-top: 3px;
      margin-right: $standard-padding * 1.5;
      font-size: 20px;
    }

    .completedIcon {
      @extend .icon;
      color: $mhh-blurb-grey-100;
    }

    .notCompletedIcon {
      @extend .icon;
      color: $black;
    }

    .description {
      &.completed .title {
        color: $mhh-yellow;
        text-decoration: line-through;
      }

      .title {
        font-weight: 600;
        font-size: 16px;
        color: $mhh-light-black;
      }

      .dueDate {
        font-size: 14px;
        line-height: 16px;
        color: $mhh-blurb-grey-100;

        .completedLabel {
          color: #c4d600;
        }
      }
    }

    .chevronRightIcon {
      position: absolute;
      right: 0;
      align-self: center;
      display: none;
      font-size: 24px;
    }
  }
}
