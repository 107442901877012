@import 'assets/theme';

.createBtn {
  position: fixed;
  bottom: 40px;
  right: 174px;
  background: $colorBlue1;
  box-shadow: 0 4px 10px rgba(52, 52, 52, 0.35);
  border-radius: 55px !important;
  height: 55px;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: $white;

  min-height: 48px;
  min-width: 175px;

  @media (max-height: 740px) {
    bottom: 10px;
    right: 155px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.field {
  margin-bottom: 32px;
}

.titleField {
  input {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: $colorTextualBlack;
  }
}

.noteField {
  textarea {
    border: 1px solid #c4c4c4;
    box-sizing: border-box;

    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }
}

.fieldContainer {
  margin-bottom: 32px;

  .fieldLabel {
    margin-top: $standard-padding;
    font-size: 11px;
    font-weight: 600;
    color: $colorTextualGray;
  }

  .fieldSelect {
    width: 100%;
    color: $colorTextualBlack;
    font-size: 16px;
    background-color: none;
  }

  .fieldAgreement {
    margin-top: $standard-padding;
  }

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }

  .redBorderBottom {
    border-bottom: 1px solid #ff4d4f !important;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}
