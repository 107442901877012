@import 'assets/theme';

.container {
  display: flex;
  padding: $standard-padding * 2 0;
  margin-bottom: 100px;

  .leftSection {
    width: 300px;
    flex: 1 2 30%;
    padding-right: 24px;
    box-sizing: border-box;

    .titleSection {
      display: flex;
      flex-direction: column;
      height: 80px;
      min-width: 280px;
      @include rowGap(8px);

      .title {
        font-weight: 600;
        font-size: 26px;
        line-height: 35px;
        color: $colorTextualBlack;
      }

      .backButton {
        display: flex;
        align-items: center;
        color: $colorBlue1;
        width: max-content;
        @include columnGap(8px);
        cursor: pointer;

        .backButtonLabel {
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
        }
      }
    }
  }

  .rightSection {
    flex: 1 2 70%;

    .templateHeader {
      display: flex;
      justify-content: flex-end;
      height: 80px;

      .error {
        color: #ff1900;
        font-size: 12px;
        margin-top: 4px;
        margin-bottom: 8px;
      }

      .reportName {
        background-color: #f8f9fd;
        font-size: 24px;
        line-height: 20px;
        color: #323c47;
        margin-top: 20px;
        font-weight: 600;
      }

      .rightHeadingWrapper {
        display: flex;
        align-items: center;
        padding: 0 4px;
        @include columnGap(8px);

        .draftBtn {
          background-color: transparent;
          border: 1px solid $colorBlue1;
          color: $colorBlue1;
          font-weight: 400;
        }

        .draftActive {
          background-color: #f8f9fd !important;

          &:before {
            background-color: #f0f9f0;
          }
        }

        .previewBtn {
          display: flex;
          margin: 0;
          box-shadow: none;
        }

        .lastEditMessage {
          display: flex;
          justify-content: flex-end;
          font-style: italic;
          font-size: 14px;
          line-height: 21px;
          color: #202225;
          opacity: 1;
          transition: all 1s ease;
        }

        .lastEditMessageHide {
          @extend .lastEditMessage;
          opacity: 0;
        }
      }
    }
  }
}

.loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}
