@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding: $standard-padding * 3;
  background-color: #fff;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  .error {
    color: #ff1900;
    font-size: 12px;
    margin-bottom: 8px;
  }

  .badgeWrapper {
    display: flex;
    padding-bottom: 16px;

    .badge {
      padding: 4px 16px;
      margin: 0;
      background: #f2c94c;
      border-radius: 4px;
      color: #343434;
      font-size: 12px;
    }

    .newBadge {
      @extend .badge;
      color: #fff;
      background: #219653;
    }
  }

  .content {
    display: flex;

    .leftWrapper {
      padding-right: 32px;
      border-right: 2px solid #ebeff2;
      flex: 1 2 40%;

      .titleBox {
        display: flex;
        padding-bottom: 10px;

        .titleInput {
          margin-top: 20px;
          font-weight: 600;
          font-size: 20px;
        }

        .toggleSwitchContainer {
          border-bottom: 1px solid #c4c4c4;
        }
      }

      .descBox {
        padding-bottom: 40px;

        .descInput {
          margin-top: 20px;
        }
      }

      .title {
        display: flex;
        font-weight: bold;
        font-size: 18px;
      }

      .subTitle {
        display: flex;
        font-size: 14px;
        color: $colorTextualGray;
      }

      .list {
        max-height: 370px;
        overflow-y: auto;
        box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
        margin-bottom: 10px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 3px #e4e4e4;

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: $grey-light;
        }

        &::-webkit-scrollbar {
          width: 6px;
          height: 2px;
          background-color: $grey-light;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $mid-grey;
        }

        .subHeader {
          padding: 12px 16px;
          position: sticky;
          top: 0;
          background-color: #fff;
        }

        .clientListCard {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 12px 16px;

          &:not(:first-child) {
            margin-top: 4px;
          }

          .clientList {
            display: flex;
            align-items: center;

            .clientListWrapper {
              display: flex;
              align-items: center;

              &:not(:last-child) {
                margin-right: 4px;
              }

              .avatarWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
              }
            }

            .name {
              font-size: 14px;
              font-weight: 600;
              line-height: 23px;
              letter-spacing: 0.1px;
              color: #323c47;
              margin-left: 8px;
              display: flex;
              flex-direction: row;
              align-items: center;

              .addBtnName {
                margin-left: 5px;
                color: $colorBlue1;
                cursor: pointer;
              }

              .removeBtnName {
                margin-left: 5px;
                color: red;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .rightWrapper {
      padding-left: 32px;
      flex: 1 2 60%;

      .sectionHeading {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        line-height: 23px;
        letter-spacing: 0.1px;
        color: $colorTextualBlack;
      }

      .description {
        display: flex;
        align-items: center;
        margin: 8px 0 20px 0;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #343434;

        .icon {
          margin: 0 2px;
        }

        .starIcon {
          margin: 0 4px;
          color: $mhh-yellow;
        }
      }

      .buttonWrapper {
        display: flex;
        justify-content: flex-end;

        .saveBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 16px;
          min-width: 150px;
          border: 1px solid $colorBlue1;
          box-sizing: border-box;
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          text-align: center;
          color: $white;
          background: $colorBlue1;
          box-shadow: 0 4px 10px rgba(16, 156, 241, 0.24);
          border-radius: 4px;
          cursor: pointer;

          .icon {
            padding-right: 6px;
          }
        }

        .saveBtnFixed {
          position: fixed;
          bottom: 40px;
          right: 174px;
          border-radius: 55px !important;
          height: 55px;

          @media only screen and (max-height: 740px) {
            bottom: 11px;
            right: 150px;
          }
        }

        .saveBtnDisable {
          @extend .saveBtn;
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
}
