.container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 10%);

  .title {
    font-weight: 700;
    font-size: 12px;
    color: #949aa2;
    padding-left: 8px;
    padding-bottom: 5px;
  }

  .actions {
    font-weight: 600;
    font-size: 12px;
    color: blue;
    padding: 4px 8px;
    display: flex;
    justify-content: space-between;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
  }

  .listLoading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
  }

  .title {
    font-weight: 700;
    font-size: 12px;
    color: #949aa2;
    padding: 8px 8px 0;
  }

  .searchBoxWrapper {
    padding: 8px 8px 0;

    .searchBox {
      margin-top: 0;
    }
  }

  .items {
    direction: rtl;
    max-height: 360px;
    overflow-y: auto;
    overflow-x: visible;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: blue;
    }

    .noItems {
      direction: ltr;
      padding: 12px 0;
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 20px;
        margin-right: 6px;
      }
    }

    &.admin {
      &::-webkit-scrollbar-thumb {
        background-color: blue;
      }
    }
  }

  .scrollDownText {
    color: blue;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    transition: all 0.1s;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    user-select: none;
    padding-top: 4px;

    .scroll {
      display: flex;
      align-items: center;
    }

    &.scrolledToBottom {
      opacity: 0;
      cursor: unset !important;
    }

    i {
      font-size: 16px;
    }
  }

  .emptyScroll {
    background-color: white;
    color: white;
    height: 20px;
  }
}

.timeBound {
  .title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    color: gray;
  }

  .radioBtnWrapper {
    width: 100%;

    .radioBtn {
      width: 100%;
      margin: 0;
      padding: 6px 12px;

      &:not(:last-child) {
        border-bottom: 1px solid #ecedef;
      }
    }

    .radioLabel {
      margin: 0 0 0 -4px;
    }

    .selectedLabel {
      color: blue;
      font-size: 16px;
    }
  }
}
