@import 'assets/theme';

.headingTemplateContainer {
  position: absolute;
  top: 16px;
  right: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 20%;
}

.rightSection {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  width: 25%;

  .insertWidgetsButton {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    border: 1px solid $colorBlue1;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $colorBlue1;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;

    .icon {
      padding-right: 6px;
    }

    &:active {
      background-color: #f8f9fd;
    }
  }

  .menuWrapper {
    position: relative;
    z-index: 1;

    .menuBoxHide {
      position: absolute;
      background: #fff;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      visibility: hidden;
      opacity: 0;
      transform: translateY(0px);
      transition: all 0.3s;

      .listBox {
        padding: 16px;
        cursor: pointer;
        color: #707683;
        transition: all 0.2s ease;
        position: relative;

        &:not(:last-child) {
          border-bottom: 1px solid #e7e4e4;
        }

        &:hover {
          color: $colorBlue1;
        }

        .listTitle {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          padding-bottom: 4px;
        }

        .listDesc {
          font-size: 10px;
          line-height: 12px;
        }

        .comingSoon {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: #ffffff80;
          z-index: 1;
          cursor: default;
        }

        .tooltip {
          max-width: 200px;
          border-radius: 8px;
          line-height: 16px;
          font-size: 14px;
        }
      }
    }

    .menuBoxShow {
      @extend .menuBoxHide;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
