.headingField {
  &:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 16px;
  }
}

.multipleSelectField {
  width: 46% !important;

  .error {
    margin-bottom: 0;
  }
}
