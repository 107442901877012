@import 'assets/theme';

.viewContainer {
  margin-bottom: 16px;
  display: flex;
  border-radius: $standard-padding * 0.5;
  transition: all 0.1s ease-in-out;
  position: relative;
  cursor: pointer;

  &.selectedIntervention {
    padding: $standard-padding * 1.5 $standard-padding * 2;
    background-color: $mhh-lightest-yellow;
    border: 1px solid #bbc3ce;

    .completedIcon {
      color: $mhh-dark-blue;
    }

    .chevronRightIcon {
      display: block;
    }
  }

  &:hover {
    padding: $standard-padding * 1.5 $standard-padding * 2;
    background-color: $mhh-lightest-yellow;

    .completedIcon {
      color: $mhh-dark-blue;
    }

    .chevronRightIcon {
      display: block;
    }
  }

  .description {
    .title {
      font-weight: 600;
      font-size: 16px;
      color: $mhh-yellow;
    }

    .reason {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: $mhh-blurb-grey-100;
    }
  }

  .chevronRightIcon {
    position: absolute;
    right: 0;
    align-self: center;
    display: none;
    font-size: 24px;
  }
}
