@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  padding-bottom: $standard-padding * 2;

  .questionWrapper {
    display: flex;
    flex-direction: column;

    .subQuestion {
      font-size: 13px;
      color: #4b718e;
    }

    .answerWrapper {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        padding-bottom: 16px;
      }

      .answerBtn {
        background: #cfe8f3;
        border-radius: 20px;
        padding: 8px 16px;
        box-sizing: border-box;
        width: 100%;
        max-width: 130px;
        min-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        color: #343434;
      }

      .answerBtnChecked {
        @extend .answerBtn;
        background: #5666a6;
        color: #fff;
      }
    }
  }
}

:global(.cords-assessment-question) {
  .questionWrapper {
    font-weight: 600;
    font-size: 18px;

    .answerWrapper {
      .answerBtn {
        background: $mhh-light-yellow;
        color: $mhh-light-black;
        font-weight: 400;
        padding: 8px 12px;
        border-radius: 32px;
        font-size: 16px;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }

      .answerBtnChecked {
        background: $mhh-yellow !important;
        color: $mhh-light-black !important;
        font-weight: 600 !important;
      }
    }
  }
}
