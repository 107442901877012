.toolTipsIcon {
  margin-left: 8px;
  color: #fd8468;
  border-radius: 50%;
  cursor: pointer;
}

.tooltip {
  max-width: 230px;
  border-radius: 8px;
  line-height: 16px;
  font-size: 14px;
  padding: 12px;
  opacity: 0.95 !important;
}
