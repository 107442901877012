@import 'assets/theme';

.attachmentsContainer {
  margin-top: 16px;

  .title {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $colorTextualGray;
  }

  .attachmentContainer {
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $black;

    .icon {
      margin-right: 8px;
      color: #0085bd;
    }

    .viewButton {
      margin-left: 8px;
      padding: 0;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $colorBlue1;
      border: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
