@import 'assets/theme';

.loadingReport {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.container {
  display: flex;
  flex-direction: column;

  .noDataWrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 24px;
    background: #fff;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;

    .image {
      max-width: 250px;
      width: 100%;

      @media (max-width: $medium-large_screen-min-width) {
        max-width: 150px;
      }
    }

    .infoWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 40%;

      .text {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.1px;
        text-align: center;
        color: $black;
      }

      .link {
        display: flex;
        align-items: center;
        margin-top: 24px;
        padding: 8px 16px;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $colorBlue1;
        border: 1px solid $colorBlue1;
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;

        .icon {
          margin-right: 12px;
        }
      }
    }
  }

  .filterWrapper {
    display: flex;
    align-items: center;
    padding-bottom: 4px;

    .filterWrapperTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1px;
      color: #6a707e;
    }

    .filterWrapperFilter {
      display: flex;
      align-items: center;
      margin-left: 8px;
      color: $colorBlue1;
      font-weight: bold;
      font-size: 12px;
      cursor: pointer;

      .downDownIcon {
        font-size: 20px;
      }
    }
  }

  .cardContainer {
    padding: 24px;
    background: #fff;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;

    .noData {
      display: flex;
      justify-content: center;
      padding: 8px 0;
      font-size: 16px;
      font-weight: 600;
    }

    .listingWrapper {
      display: flex;
      background: #fff;
      box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      padding: 24px;
      cursor: pointer;
      transition: all 0.5s ease;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &:hover {
        background: #fbfbfb;
      }

      .contentWrapper {
        display: flex;
        flex: 1;

        .authorSection {
          display: flex;
          align-items: center;
          flex-direction: column;
          min-width: 90px;
          max-width: 170px;
          padding-right: 24px;
          flex: 1 5 15%;
        }

        .profileSection {
          display: flex;
          align-items: center;
          flex-direction: column;
          min-width: 90px;
          max-width: 170px;
          padding-right: 40px;
          flex: 1 5 15%;

          .prfImgActive {
            display: flex;
            border-radius: 50%;
            border: 2px solid #25be69;
            width: 60px;
            height: 60px;
            object-fit: cover;
          }

          .prfImgInactive {
            display: flex;
            border-radius: 50%;
            border: 2px solid #ff0000;
            width: 60px;
            height: 60px;
            object-fit: cover;
          }

          .name {
            padding-top: 10px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #494949;
          }
        }

        .reportNameSection {
          display: flex;
          align-items: center;
          min-width: 120px;
          max-width: 250px;
          padding-right: 16px;
          flex: 1 5 20%;
          font-weight: 600;
          font-size: 15px;

          color: #343434;
        }

        .statusSection {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 90px;
          max-width: 130px;
          padding-right: 8px;
          flex: 1 5 10%;
          color: #6575b1;

          .badge {
            display: flex;
            justify-content: center;
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
            min-width: 90px;
          }

          .published {
            @extend .badge;
            background-color: #219653;
          }

          .draft {
            @extend .badge;
            background-color: #f2c94c;
          }

          .unpublished {
            @extend .badge;
            background-color: $colorBlue2;
          }
        }

        .createdSection {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 90px;
          max-width: 190px;
          padding-right: 16px;
          flex: 1 5 20%;
          font-weight: 600;
          font-size: 14px;
          color: #6575b1;
        }

        .lastAccessedSection {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 90px;
          max-width: 150px;
          padding-right: 16px;
          flex: 1 5 20%;
          font-weight: 600;
          font-size: 14px;
          color: #6575b1;
        }

        .shareDetailSection {
          display: flex;
          align-items: center;
          min-width: 120px;
          max-width: 230px;
          flex: 1 20 20%;
          font-weight: 600;
          font-size: 14px;
          color: #6575b1;

          .shareIcon {
            padding-right: 16px;
          }

          .shareNameContainer {
            display: flex;
            flex-direction: column;

            .shareName {
              line-height: 16px;
            }
          }
        }
      }

      .buttonWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 50px;
        flex: 1 5 15%;

        .icon {
          border: 2px solid #6575b1;
          color: #6575b1;
          padding: 6px;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }

    .filterListWrap {
      @extend .listingWrapper;
      display: flex;
      background: #fff;
      box-shadow: none;
      border-radius: 0;
      padding: 0 24px;
      margin-bottom: 8px !important;
      cursor: auto;
      transition: none;

      &:hover {
        background: none;
      }

      .label {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #6a707e;
      }

      .lastAccessed {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #6a707e;
      }
    }
  }
}
