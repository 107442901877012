@import 'assets/theme';

.container {
  padding: 32px;
}

.infoRow {
  display: flex;
  flex-direction: column;
  @include rowGap(32px);

  .name {
    display: flex;
    & > * {
      flex: 0.67;
    }
  }

  .groupSubInfo {
    display: flex;
    flex-direction: column;
    max-width: 380px;
    @include rowGap(16px);
  }

  .subInfoRow {
    display: flex;
    @include columnGap(80px);
    align-items: flex-start;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
}

$oneThird: 33%;
$twoThirds: 66%;

.section {
  padding: 32px;
  border-top: 1px solid #ebeff2;
  position: relative;

  .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    letter-spacing: 0.1px;
    color: $colorTextualBlack;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: 14px;
  color: $colorTextualGray;
}

.durationGapSessionStartOptionRow {
  margin-top: 20px;
}

.rateDescriptionRow {
  margin-top: 40px;
}

.ratePrefix {
  margin-top: 4px;
  margin-right: 4px;
}

.input {
  margin: 0;
}

.selectContainer {
  display: flex;
  flex-direction: column;
  width: $oneThird;

  .select {
    width: 100%;
  }

  .customInput {
    display: flex;
    width: calc(100% - 12px);
    margin-top: 8px;

    .customText {
      margin-top: 4px;
      margin-left: 8px;
      font-size: 14px;
      font-weight: 600;
      color: $colorTextualGray;
    }
  }
}

.rateInput,
.sessionStartOptionSelect {
  width: $oneThird;
}

.descriptionInput,
.nameInput {
  width: $twoThirds;
}

.descriptionInput,
.selectContainer,
.nameInput,
.rateInput,
.sessionStartOptionSelect {
  &:not(:first-child) {
    margin-left: 12px;
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
}

.descriptionInput {
  margin-top: 22px;
}

.timezone {
  align-self: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $colorTextualGray;
}

.paymentRequired {
  display: flex;
  padding: 8px 16px;

  &.active {
    background-color: #f2f9ff;
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    width: 45%;
    padding-right: 8px;

    .checkbox {
      appearance: none;
      margin: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      color: #d5d5d5;
      cursor: pointer;
      outline: none;
      transition: all 0.1s linear;

      &:after {
        content: '\2713';
        display: none;
        justify-content: center;
        align-items: center;
        width: 16px;
      }

      &:checked {
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
        color: #fff;
        background-color: $colorBlue1;
        border: 2px solid $colorBlue1;

        &:after {
          display: flex;
        }
      }
    }

    .label {
      flex: 1;
      padding-left: 32px;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #343434;
      cursor: pointer;
    }
  }
}
