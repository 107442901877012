@import 'assets/theme';

.container {
  @include rowGap(30px);

  .subTitle {
    display: flex;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $colorTextualGray;
  }
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 32px;
  max-width: 380px;

  &.fieldError,
  .fieldError {
    font-size: 12px;
    color: #ff4d4f;

    input {
      border-bottom: 1px solid #ff4d4f !important;
    }
  }
}

.clientsContainer {
  background: $white;
  box-shadow: 0 2px 16px rgb(0 0 0 / 10%);
  border-radius: 8px;
  margin: 8px -12px 24px;
  padding: 12px 16px 16px;

  .clientTitle {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    color: $colorBlue1;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 32px;
}

.clientDetailTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $colorTextualGray;
}
