@import 'assets/theme';

.container {
  .label {
    font-size: 10px;
    color: $colorTextualGray;
    font-weight: 600;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: $standard-padding;
    color: $mhh-light-black;
  }

  .editContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 32px;
    background: #f8f9fd;
    box-shadow: 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-bottom: 16px;
    position: relative;

    .focusAreaWrapper {
      .badge {
        margin-top: $standard-padding * 0.25;
        margin-left: $standard-padding * 2;
        height: fit-content;
        background-color: $mhh-yellow;
        color: $mhh-dark-blue;
        border-radius: 25px;
        padding: $standard-padding * 0.5 $standard-padding * 4;
        font-weight: 600;
        font-size: 14px;
      }
    }

    .recommendWrapper {
      width: 50%;
      margin-bottom: $standard-padding * 2;

      .title {
        padding: 0px 16px 16px 0px;
        font-weight: 600;
        font-size: 16px;
        font-weight: 600;
        color: $mhh-light-black;
      }

      .checkboxContainer {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        margin-bottom: $standard-padding;
        @include columnGap($standard-padding * 2);

        .inputContainer {
          display: flex;
          align-items: center;
          height: 25px;

          .checkbox {
            width: 16px;
            height: 16px;
            border: 2px solid #d5d5d5;
            border-radius: 4px;
            cursor: pointer;

            &:checked {
              background: $white;
              border: 0.342857px solid $white;
            }
          }
        }

        .labelContainer {
          display: flex;
          flex-direction: column;
          cursor: pointer;

          .label {
            &.completed {
              color: $mhh-yellow;
              text-decoration: line-through;
            }
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            color: black;
            line-height: 25px;
          }

          .subLabel {
            font-size: 13px;
            font-weight: 400;
            color: #a0a0a0;
          }
        }
      }
    }
    .fieldContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-top: 12px;
      padding-bottom: 12px;
      width: 50%;
      input {
        background: #f8f9fd;
      }

      &.fieldError,
      .fieldError {
        font-size: 12px;
        color: #ff4d4f;
        input {
          border-bottom: 1px solid #ff4d4f !important;
        }
      }

      .interventionNames {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        margin-top: $standard-padding;

        .interventionBadge {
          display: flex;
          align-items: center;
          margin-top: $standard-padding * 0.25;
          margin-right: $standard-padding;
          width: fit-content;
          height: fit-content;
          background-color: $colorBlue3;
          color: $white;
          border-radius: 25px;
          padding: $standard-padding * 0.5 $standard-padding * 2;

          .icon {
            margin-top: 2px;
            margin-left: $standard-padding * 0.5;
            margin-right: -$standard-padding;
            font-size: 14px;
            cursor: pointer;
          }
          &.completedBadge {
            background-color: $mhh-blurb-grey-100;
            i {
              display: none;
            }
          }
        }
      }
    }

    .dueDateAndButtonWrapper {
      display: flex;
      align-items: flex-end;

      .dueDateWrapper {
        width: 50%;

        .label {
          font-size: 12px;
          color: $colorTextualGray;
          line-height: 16px;
          letter-spacing: 0.2px;
          font-weight: 600;
          margin-bottom: 8px;
        }

        .dateInput {
          width: 100%;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          padding: 8px 12px;
          background: #f8f9fd;
          input {
            font-weight: 600;
          }
        }
      }

      .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: calc(50% - 16px);
        margin-left: $standard-padding * 2;

        .noActionButton {
          max-height: 36px;
          min-width: 96px;
          margin-right: $standard-padding * 2;
          padding: $standard-padding * 1.5 $standard-padding * 2;
          background-color: transparent;
          border: 1px solid $mhh-yellow;
          border-radius: 4px;
          box-shadow: none;
        }

        .saveButtonWrapper {
          min-width: 96px;
          position: relative;

          .saveButton {
            min-width: 96px;
            max-height: 36px;
            padding: $standard-padding * 1.5 $standard-padding * 2;
            background-color: $mhh-yellow;
            color: $colorTextualBlack;
            border-radius: 4px;
            box-shadow: none;
          }

          .editModeError {
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }

    .deleteButton {
      position: absolute;
      color: #ff4d4f;
      cursor: pointer;
      top: 32px;
      right: 32px;
    }
  }

  .highlightTask {
    padding: $standard-padding * 1.5 $standard-padding * 2;
    background-color: $mhh-lightest-yellow;

    .completedIcon {
      color: $mhh-dark-blue;
    }

    .chevronRightIcon {
      display: block;
    }
  }

  .viewContainer {
    margin-bottom: 16px;
    display: flex;
    border-radius: $standard-padding * 0.5;
    transition: all 0.1s ease-in-out;
    position: relative;
    cursor: pointer;

    &.selectedIntervention {
      @extend .highlightTask;
      border: 1px solid #bbc3ce;
    }

    &:hover {
      @extend .highlightTask;
    }

    .icon {
      margin-top: 3px;
      margin-right: $standard-padding * 1.5;
      font-size: 20px;
    }

    .completedIcon {
      @extend .icon;
      color: $mhh-blurb-grey-100;
    }

    .notCompletedIcon {
      @extend .icon;
      color: $black;
    }

    .description {
      &.completed .title {
        color: $mhh-yellow;
        text-decoration: line-through;
      }

      .title {
        font-weight: 600;
        font-size: 16px;
        color: $mhh-light-black;
      }

      .dueDate {
        font-size: 14px;
        line-height: 16px;
        color: $mhh-blurb-grey-100;

        .completedLabel {
          color: #c4d600;
        }
      }
    }

    .chevronRightIcon {
      position: absolute;
      right: 0;
      align-self: center;
      display: none;
      font-size: 24px;
    }
  }
}
