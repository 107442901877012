@import 'assets/theme';

.container {
  background: #fff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px 20px;

  .header {
    display: flex;
    flex-direction: column;

    .topicLabel {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: $colorTextualGray;
    }

    .topic {
      padding: 2px 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: $colorTextualBlack;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 16px 0 8px;

    .questionWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 8px;
      background: #fbfbfb;
      user-select: none;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      .questionBox {
        display: flex;
      }

      .label {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #343434;
        padding-left: 8px;
      }
    }
  }
}
