@import 'assets/theme';

.modalContainer {
  display: flex;
  flex-direction: column;
}

.contentContainer {
  display: flex;
  flex-direction: column;

  .successMessageContainer {
    display: flex;
    background-color: #ebffeb;
    justify-content: space-evenly;
    align-items: center;
    padding: 24px 24px 40px;

    .successIcons {
      display: flex;
      flex: 1;
      padding-left: 20px;

      .calendarIcon {
        width: 66px;
      }

      .checkIconRelativeWrapper {
        position: relative;

        .checkIconAbsoluteWrapper {
          position: absolute;

          top: 25px;
          left: -30px;
        }
      }

      .checkIcon {
        font-size: 40px;
        padding: 16px;
        color: #4abd03;
        border: 4px solid #4abd04;
        border-radius: 50%;
        background-color: $white;
      }
    }

    .messageContainer {
      display: flex;
      flex-direction: column;
      flex: 2;

      .messageTitle {
        font-size: 16px;
        font-weight: bold;
      }

      .message {
        font-size: 14px;
      }

      .emailContainer {
        display: flex;
        padding: 8px;
        align-items: center;
        @include columnGap(8px);

        .emailDomainIcon {
          width: 28px;
        }

        .emailInfoContainer {
          display: flex;
          flex-direction: column;

          .emailAddress {
            color: #6575b1;
            font-size: 16px;
          }

          .emailDomain {
            font-size: 14px;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .syncCalendarContainer {
    display: flex;
    flex-direction: column;
    padding: 24px;
    @include rowGap(32px);

    .header {
      display: flex;
      flex-direction: column;
      @include rowGap(8px);

      .title {
        font-size: 20px;
        font-weight: 600;
      }
      .message {
        font-size: 12px;
      }
    }

    .calendarContentContainer {
      display: flex;
      flex-direction: column;
      @include rowGap(8px);
    }

    .buttonContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include rowGap(12px);

      .syncButton {
        width: 240px;
      }
    }
  }
}
