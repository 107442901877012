@import 'assets/theme';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .label {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: $mhh-dark-blue;
  }

  .barContainer {
    max-width: 90px;
  }

  .showContent {
    opacity: 1;
    transition: all 0.5s ease;
  }

  .hideContent {
    @extend .showContent;
    opacity: 0;
    height: 0;
    transition: all 0.5s ease;
    overflow: hidden;
  }
}

:export {
  blueColor: $mhh-yellow;
}
